// http://gender-decoder.katmatfield.com/static/documents/Gaucher-Friesen-Kay-JPSP-Gendered-Wording-in-Job-ads.pdf
// Index A

const masculineWords = [
    "active[\\w-]*",
    "adventurous[\\w-]*",
    "aggress[\\w-]*",
    "ambitio[\\w-]*",
    "analy[\\w-]*",
    "assert[\\w-]*",
    "athlet[\\w-]*",
    "autonom[\\w-]*",
    "boast[\\w-]*",
    "challeng[\\w-]*",
    "compet[\\w-]*",
    "confident[\\w-]*",
    "courag[\\w-]*",
    "decide[\\w-]*",
    "decisive[\\w-]*",
    "decision[\\w-]*",
    "determin[\\w-]*",
    "domina[\\w-]*",
    "force[\\w-]*",
    "greedy[\\w-]*",
    "head-?strong[\\w-]*",
    "hierarch[\\w-]*",
    "hostil[\\w-]*",
    "impulsive[\\w-]*",
    "independen[\\w-]*",
    "individual[\\w-]*",
    "intellect[\\w-]*",
    "lead[\\w-]*",
    "logic[\\w-]*",
    "masculine[\\w-]*",
    "objective[\\w-]*",
    "opinion[\\w-]*",
    "outspoken[\\w-]*",
    "persist[\\w-]*",
    "principle[\\w-]*",
    "reckless[\\w-]*",
    "stubborn[\\w-]*",
    "superior[\\w-]*",
    "self-confiden[\\w-]*",
    "self-sufficien[\\w-]*",
    "self-relian[\\w-]*"
];

const feminineWords = [
	"affectionate[\\w-]*",
	"child[\\w-]*",
	"cheer[\\w-]*",
	"commit[\\w-]*",
	"communal[\\w-]*",
	"compassion[\\w-]*",
	"connect[\\w-]*",
	"considerate[\\w-]*",
	"co-?operat[\\w-]*",
	"depend[\\w-]*",
	"emotiona[\\w-]*",
	"empath[\\w-]*",
	"feminine[\\w-]*",
	"flatterable[\\w-]*",
	"gentle[\\w-]*",
	"honest[\\w-]*",
	"inter-?persona[\\w-]*",
	"inter-?dependen[\\w-]*",
	"kind[\\w-]*",
	"kinship[\\w-]*",
	"loyal[\\w-]*",
	"modesty[\\w-]*",
	"nag[\\w-]*",
	"nurtur[\\w-]*",
	"pleasant[\\w-]*",
	"polite[\\w-]*",
	"quiet[\\w-]*",
	"respon[\\w-]*",
	"sensitiv[\\w-]*",
	"submissive[\\w-]*",
	"support[\\w-]*",
	"sympath[\\w-]*",
	"tender[\\w-]*",
	"together[\\w-]*",
	"trust[\\w-]*",
	"understand[\\w-]*",
	"warm[\\w-]*",
	"whin[\\w-]*",
	"yield[\\w-]*"
];

const masculineRegex = new RegExp(
  `\\b(${masculineWords.join('|')})`, 'gi'
);
const feminineRegex = new RegExp(
  `\\b(${feminineWords.join('|')})`, 'gi'
);

function findWithRegex(regex, text, callback, props) {
  let matchArr, start;
  let matches = 0;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length, props);
    matches += 1;
  }
  return matches;
}

export {
	masculineRegex,
	feminineRegex,
  findWithRegex
};
