import blue_fish from "../blocks/images/food/blue_fish.svg";
import green_soda from "../blocks/images/food/green_soda.svg";
import orange_mushroom from "../blocks/images/food/orange_mushroom.svg";
import pink_pie from "../blocks/images/food/pink_pie.svg";
import red_burger from "../blocks/images/food/red_burger.svg";
import violet_cheese from "../blocks/images/food/violet_cheese.svg";
import yellow_banana from "../blocks/images/food/yellow_banana.svg";

const HelperBlockIcons = (block_type) => {
  switch (block_type) {
    case "description":
      return green_soda;
    case "key_responsabilities":
      return violet_cheese;
    case "skills_and_requirements":
      return red_burger;
    case "benefits":
      return pink_pie;
    case "image":
      return orange_mushroom;
    case "company_overview":
      return blue_fish;
    case "fine_print":
      return yellow_banana;
    default:
        return yellow_banana;;
  }
};

export {HelperBlockIcons};
