/***
 *
 *   USERS
 *   Enables an admin to manage the users in their application
 *
 **********/

import React, { Fragment, useContext, useState, useEffect } from "react";
import {
  AuthContext,
  Card,
  AccountNav,
  Animate,
  Form,
  useAPI,
} from "components/lib";

export function CompanySettings(props) {
  const company = useAPI("/api/company/");
  const authContext = useContext(AuthContext);

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        <section className="title-row">
          <h2>Organisation settings</h2>
        </section>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Card title="Update Your Organisation Setttings" restrictWidth>
            {!company.loading && (
              <Form
                buttonText="Save"
                url="/api/company"
                method="PATCH"
                data={{
                  company_name: {
                    label: "Organisation name",
                    type: "text",
                    required: true,
                    value: company.data?.company_name
                      ? company.data?.company_name
                      : "",
                    errorMessage: "Please enter company name",
                  },
                  company_url: {
                    label: "Website URL",
                    type: "url",
                    required: true,
                    value: company.data?.company_url
                      ? company.data?.company_url
                      : "",
                    errorMessage: "Please enter organisation name",
                  },
                }}
                callback={(res) => {
                  // update the account name
                  if (authContext.user?.accounts?.length > 0) {
                    const accounts = [...authContext.user.accounts];
                    accounts[
                      accounts.findIndex(
                        (x) => x.id === authContext.user.account_id
                      )
                    ].name = res.data.data.account_name;
                    authContext.update({ accounts: accounts });
                  }

                  // update the user name
                  authContext.update({ name: res.data.data.name });
                }}
              />
            )}
          </Card>
        </div>
      </Animate>
    </Fragment>
  );
}
