import React, { useState, useEffect } from "react";
import { useAPI, Loader, PdfContentGenerator, EditorContext, BlockRenderer } from "components/lib";
import { themeList } from "../jobeditor/lib/themeList";

export function PdfPrintView(props) {
  // EDITOR STATES
  const [blockDictionary, setBlockDictionary] = useState({});
  const [jobBasicInfo, setJobBasicInfo] = useState({});
  const [blockOrder, setBlockOrder] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState();

  // Load job data
  const urlSplit = window.location.href.split("/");
  const jobId = urlSplit[4];
  const urlParams = new URLSearchParams(window.location.search);
  const currentCoverPageStyle = urlParams.get('currentCoverPageStyle');
  const loadData = useAPI("/api/job/" + jobId, "GET");
  const [data, setData] = useState("");

  const [jobData, setJobData] = useState(false);

  //check is data is loading
  useEffect(() => {
    if (!loadData.loading && loadData.data) {
      setData(loadData); 
    }
  }, [loadData]);

  //when done loading, set as job data.
  useEffect(() => {
    if (data.data) {
      setJobData(data.data);
      const job_info = JSON.parse(data.data.job_info);

      if (job_info) {
        // console.log("This added a setter for the job elements");
        setJobBasicInfo(job_info.jobBasicInfo);
        setBlockDictionary(job_info.blockDictionary);
        setBlockOrder(job_info.blockOrder);
        blockOrder.forEach((element) => {
          setSelectedBlock(job_info.blockOrder[element]);
        });
        setSelectedBlock(job_info.blockOrder[0]);
        setTheme(job_info.theme);
      }
      // if (!job_info) {
      //   console.log("this should add a title and basic info block");
      // }

      // Create a new sessions ID + log session Id in the page
    }
  }, [data]);

  // THEME STATE
  const [theme, setTheme] = useState(themeList("default"));

  // EDITOR CONTEXT (STORE)
  const editor_context_store = {
    jobData: jobData,
    jobBasicInfo: jobBasicInfo,
    blockDictionary: blockDictionary,
    blockOrder: blockOrder,
    theme: theme,
  };


  return (
    <>
      {jobData === false && <Loader />}
      {jobData !== false && (
        <EditorContext.Provider value={editor_context_store}>
          <PdfContentGenerator currentCoverPageStyle={currentCoverPageStyle} />
        </EditorContext.Provider>
      )}
    </>
  );
}
