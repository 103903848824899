import { Home } from 'views/website/home';
import { Contact } from 'views/website/contact';
import { Pricing } from 'views/website/pricing';
import { Terms } from 'views/website/terms';
import { Privacy } from 'views/website/privacy';

const Routes = [
  {
    path: '/',
    view: Home,
    layout: 'home',
    title: 'JobChef - Create great job postings. Fast.',
    description: 'Jobchef simplifies job posting creation to attract top candidates. Choose from templates or collaborate with colleagues. Get started in only 5 minutes!',
    
  },
  {
    path: '/contact',
    view: Contact,
    layout: 'home',
    title: 'JobChef - Get in touch with our sales team',
    description: 'Reach out to Jobchef for your inquiries. Our team is ready to assist you with any questions you may have about jobchef!',  },
  {
    path: '/pricing',
    view: Pricing,
    layout: 'home',
    title: 'JobChef - Pricing',
    description: 'One plan, full access to Jobchef. Enjoy full access to collaboration links, custom themes, and exclusive job posting templates. Get started today!',
  },
  {
    path: '/privacy',
    view: Privacy,
    layout: 'home',
    title: 'JobChef - Privacy Policy',
    description: 'Consult Jobchef’s Privacy Policy to understand how we handle your data. Learn about your rights and our commitment to protecting your information.',  },
  {
    path: '/terms',
    view: Terms,
    layout: 'home',
    title: 'JobChef - Terms & Conditions',
    description: 'Jobchef’s Terms and Conditions. Review our terms to make the most of Jobchef.',  },
]

export default Routes;