import React, { useContext, useEffect, useState } from "react";

import { EditorContext, Icon, DeleteModal, MyButton } from "components/lib";
import Style from "./HelperBlockCardPreview.module.scss";
import { Checkbox } from "@mantine/core";
import Modal from "@material-ui/core/Modal";

//bootstrap

const HelperBlockCardPreview = (props) => {
  const blockDictionary = useContext(EditorContext).blockDictionary;
  const setBlockDictionary = useContext(EditorContext).setBlockDictionary;
  const blockOrder = useContext(EditorContext).blockOrder;
  const setBlockOrder = useContext(EditorContext).setBlockOrder;
  const blockTypes = useContext(EditorContext).blockTypes;
  const setSelectedBlock = useContext(EditorContext).setSelectedBlock;
  const deleteBlockHelper = useContext(EditorContext).deleteBlockHelper;
  const addBlockHelper = useContext(EditorContext).addBlockHelper;

  //modal 
  const [open, setOpen] = useState(false);
  const blockidIfCheck = blockOrder[blockTypes.indexOf(props.block_type)]

  const handleDelete = () => {
    //delete block
    deleteBlockHelper(blockidIfCheck)
    setOpen(false);
  };

  ///

  let checked = blockTypes.includes(props.block_type);

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className={Style.delete_modal}>
          <h3>Remove this section?</h3>
          <p>
            Note that you won't be a able to undo this{" "}
            <span role="img" aria-label="Unsure">
              🙄
            </span>
          </p>
          <div className={Style.flexDivHorizontal}>
            <MyButton
              onClick={handleDelete}
              buttonStyle={"danger"}
              size={"medium"}
            >
              Delete <Icon image={"trash-2"} size={12} />
            </MyButton>
            <MyButton
              buttonStyle={"outline"}
              size={"medium"}
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </MyButton>
          </div>
        </div>
      </Modal>
      <div
        className={Style.helperBlockCard}
        onClick={() => {
          if (checked) {
            setOpen(true)
            //alert("delete modal");
          } else {
            addBlockHelper(props.block_type);
          }
        }}
      >
        <div className={Style.chekBoxWrapper}>
          <Checkbox
            checked={checked}
            color="violet"
            className={Style.checkboxCustom}
          />
        </div>
        <div className={Style.cardContentWrapper}>
          <img
            src={props.image}
            alt="Category of block that is currently being edited"
          />
          <div className={Style.helperBlockCardtext}>
            <h4>{props.header}</h4>
            <p>{props.short_description}</p>
          </div>
        </div>
      </div>
    </>
  );
};


export { HelperBlockCardPreview };
