import React, {useContext} from "react";
import Style from "./HelperBlockContextHeader.module.scss";

const HelperBlockContextHeader = (props) => {
  const divStyle = {
    borderBottom: "3px solid " + props.color,
  };


  return (
    <>
      <div className={Style.block_context_header}>
        {props.header_content && props.header_content}
        {!props.header_content && (
          <>
            <div className={Style.block_name}>
              <span className={Style.block_subtitle}>
                {"subHeader" in props ? props.subHeader : "SECTION TYPE"}
              </span>
              <h3>{props.header}</h3>
              <div className={Style.title_decoration} style={divStyle}></div>
            </div>
            <div className={Style.image}>
              <img src={props.image} alt="Icon showing the section type" />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export { HelperBlockContextHeader };
