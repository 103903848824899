import React from "react";
import { MyButton } from "components/lib";
import Style from "./draftSelect.module.scss";
import { ReactComponent as Classic } from "./img/classic.svg";
import { ReactComponent as Empty } from "./img/empty.svg";
import { ReactComponent as Recommended } from "./img/recommended_arrow.svg";
import { ReactComponent as GenerativeAiIcon } from "./img/generative_ai_icon.svg";

export function DraftSelect({ startEditClassicTemplate, props, setCurrentScreen, setOpenedDrawer, setSideElem }) {
  return <>
    <div className={Style.headerSection}>
      <h2>Get started with a draft?</h2>
      <p>
        Select one of the options below to start editing your content.
      </p>
    </div>
    <div className={Style.cardWrapper}>
      <div className={Style.GenerateJobOption}>
        <GenerativeAiIcon />
        <h3>Generate draft</h3>
        <p>
          The fastest option to create a draft for your job. Simply
          give a few details about the position and our AI will
          generate the job.{" "}
        </p>
        <MyButton
          onClick={() => {
            setCurrentScreen("generate_draft_mode");
          }}
          size="medium"
          buttonStyle="white"
        >
          Select
        </MyButton>
        <Recommended className={Style.recommendedLabel} />
      </div>
      <div className={Style.templateOptionsWrapper}>
        <div className={Style.templateOption}>
          <div className={Style.imageDescriptionWrapper}>
            <div className={Style.templateImg}>
              <Classic />
            </div>
            <div className={Style.description}>
              <h3>Classic</h3>
              <p>
                Straight to the point, simple. This job template
                includes all the basic sections for good job
                description.
              </p>
              <MyButton
                onClick={() => {
                  startEditClassicTemplate();
                }}
                size="medium"
                buttonStyle="primary"
              >
                Select
              </MyButton>
            </div>
          </div>
        </div>
        <div className={Style.templateOptionsWrapper}>
          <div className={Style.templateOption}>
            <div className={Style.imageDescriptionWrapper}>
              <div className={Style.templateImg}>
                <Empty />
              </div>
              <div className={Style.description}>
                <h3>Start from scratch</h3>
                <p>
                  No need to follow a template, add each sections of
                  your job posting one by one.
                </p>
                <MyButton
                  onClick={() => {
                    setSideElem("addSection");
                    setOpenedDrawer(true);
                    props.setShowEmptyContentPageModal(false);
                  }}
                  size="medium"
                  buttonStyle="primary"
                >
                  Select
                </MyButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
}
