/***
 *
 *   VIEW
 *   The view houses global components that are common to all views
 *   (notification, modal), handles errors and renders the layout
 *
 **********/

import React, { useState } from "react";
import {
  AppLayout,
  AuthLayout,
  HomeLayout,
  MissionControlLayout,
  OnboardingLayout,
  Modal,
  Notification,
  Loader,
  History,
  EditorLayout,
  EditorLayoutNoNav,
  EmptyLayout,
} from "../lib";

import "./scss/normalize.scss";
import "./scss/view.scss";
import "./scss/typography.scss";

export const ViewContext = React.createContext();

export function View(props) {
  // state
  const [notification, setNotification] = useState({
    visible: "hide",
    autoclose: true,
  });
  const [modal, setModal] = useState({});
  const [loading, setLoading] = useState(false);

  // layouts
  const layouts = {
    app: AppLayout,
    home: HomeLayout,
    auth: AuthLayout,
    onboarding: OnboardingLayout,
    missioncontrol: MissionControlLayout,
    editorLayout: EditorLayout,
    editorLayoutNoNav: EditorLayoutNoNav,
    emptyLayout: EmptyLayout,
  };

  // set title & layout
  document.title = props.title;

  // set MetaDescription
  let metaDescription = document.querySelector('meta[name="description"]');
  if (!metaDescription) {
    metaDescription = document.createElement("meta");
    metaDescription.setAttribute("name", "description");
    document.head.appendChild(metaDescription);
  }
  metaDescription.setAttribute(
    "content",
    props.description === undefined
      ? "Jobchef simplifies job posting creation to attract top candidates. Choose from templates or collaborate with colleagues. Get started in only 5 minutes!"
      : props.description
  );

  // set Canonical
  let link = document.querySelector("link[rel='canonical']");
  if (!link) {
    link = document.createElement("link");
    link.setAttribute("rel", "canonical");
    document.head.appendChild(link);
  }
  link.setAttribute("href", "https://jobchef.io" + props.path);

  let Layout = props.layout ? layouts[props.layout] : AppLayout;

  if (!props.display) return false;

  function showNotification(text, type, autoclose, format, icon) {
    setNotification({
      text: text,
      type: type,
      show: true,
      format: format,
      icon: icon,
      autoclose: autoclose,
    });

    if (autoclose) setTimeout(hideNotification, 2000);
  }

  function hideNotification() {
    setNotification({
      text: "",
      type: "",
      show: false,
      format: null,
      icon: null,
      autoclose: true,
    });
  }

  function showModal(content, callback) {
    let data = { ...modal };

    if (content) {
      for (let key in content) data[key] = content[key];

      data.show = true;
      data.callback = callback;
    }

    setModal(data);
  }

  function hideModal(cancel, res) {
    // callback?
    if (!cancel && modal.callback) modal.callback(modal.form, res);

    // reset the modal
    setModal({
      title: null,
      text: null,
      buttonText: null,
      url: null,
      method: null,
      show: false,
    });
  }

  function handleError(err) {
    let message = "There was a glitch in the matrix – please try again";

    if (err) {
      message = err.toString();

      if (err.response) {
        if (err.response.data?.message) message = err.response.data.message;

        if (err.response.status) {
          switch (err.response.status) {
            case 401:
              History.push("/signin");
              break;

            case 404:
              if (err.response.data.data === "noRedirect") {
                //History.push('/notfound'); //TODO : 404 page
                showNotification(message, "error", false);
                break;
              }
              History.push("/notfound");
              break;

            case 429:
              showNotification(err.response.data, "error", false);
              break;

            case 402: // payment required
              const user = JSON.parse(localStorage.getItem("user"));
              if (user.has_stripe_customer_id) {
                History.push("/account/upgrade");
              } else {
                History.push("/signup/plan");
              }
              // History.push("/account/upgrade?plan=" + err.response.data.plan);
              break;

            default:
              console.error(err);
              showNotification(message, "error", false);
              break;
          }
        }
      }
    }
  }

  function handleErrorString(message) {
    showNotification(message, "error", false);
  }

  const context = {
    notification: {
      show: showNotification,
      hide: hideNotification,
      data: notification,
    },
    modal: {
      show: showModal,
      hide: hideModal,
      data: modal,
    },

    setLoading: (state) => setLoading(state),
    handleError: handleError,
    handleErrorString: handleErrorString,
  };

  return (
    <ViewContext.Provider value={context}>
      {notification.show && <Notification {...notification} />}

      {loading && <Loader fullScreen />}

      {modal.show && <Modal {...modal} />}

      <Layout title={props.title} data={props.data}>
        {props.display}
      </Layout>
    </ViewContext.Provider>
  );
}
