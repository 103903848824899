import React, { useEffect, useRef, useContext } from "react";
import { EditorContext, Icon } from "components/lib";
import Style from "./textOnIUmageForm.module.scss";
import { Field, Form, Formik, useFormikContext, useField } from "formik";
import { ColorPicker } from "../themeConfigCard/colorPicker.js";
import _ from "lodash";

const TextOnImageForm = () => {
  let theme = useContext(EditorContext).theme;
  let setTheme = useContext(EditorContext).setTheme;
  const refBannerTextOptions = useRef(null);

  const bannerTextColorEditHelper = (x) => {
    let tempTheme = _.cloneDeep(theme);
    tempTheme.banner = _.assign({}, tempTheme.banner, {
      bannerTextColor:
        refBannerTextOptions.current.values.banner.bannerTextColor,
      bannerTextShadow:
        refBannerTextOptions.current.values.banner.bannerTextShadow,
      bannerBackgroundGradient:
        refBannerTextOptions.current.values.banner.bannerBackgroundGradient,
    });

    // console.log(refBannerOptions.current.values);
    // console.log(refBannerOptions.current.values.banner.bannerbackgroundColor);
    setTheme(tempTheme);
  };

  return (
    <div className={Style.textOnImageForm}>
      <h5>Text-on-image style</h5>
      <Formik initialValues={theme} innerRef={refBannerTextOptions}>
        <>
          <MyOnChangeComponent callback={bannerTextColorEditHelper} />
          <Form id="banner-text-style">
            <div className={Style.bannerTextForm}>
              <div className={Style.bannerTextOption}>
                <span>Banner text color</span>
                <div className={Style.arrowWrapper}>
                  <Field name={"banner.bannerTextColor"}>
                    {theme &&
                      ((props) => {
                        return (
                          <ColorPicker
                            name={"banner.bannerTextColor"}
                            startColor={(() => {
                              if (!theme || !theme["banner"]) {
                                return "";
                              } else if (theme["banner"].bannerTextColor) {
                                return theme["banner"].bannerTextColor;
                              } else {
                                return "#ffffff";
                              }
                            })()}
                            {...props}
                          />
                        );
                      })}
                  </Field>
                </div>
              </div>
              {theme.banner.bannerStyle === "image" && (
                <div className={Style.bannerTextOption}>
                  <span>Apply background gradient</span>
                  <SwitchButton name={"banner.bannerBackgroundGradient"} />{" "}
                </div>
              )}
              <div className={Style.bannerTextOption}>
                <span>Apply shadow to text</span>
                <SwitchButton name={"banner.bannerTextShadow"} />{" "}
              </div>
            </div>
          </Form>
        </>
      </Formik>
    </div>
  );
};

const MyOnChangeComponent = (props) => {
  const { values } = useFormikContext();
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    props.callback();
  }, [values]);

  return null;
};

const SwitchButton = ({ name }) => {
  const [field, meta, helpers] = useField(name);

  return (
    <button
      className={
        (field.value === false || field.value === undefined)
          ? Style.switchButton + " " + Style.switchButtonActive
          : Style.switchButton
      }
      onClick={() => helpers.setValue(!field.value)}
    >
      <div className={Style.switchIndicator}></div>
    </button>
  );
};
export { TextOnImageForm };
