export function themeList(theme) {
  switch (theme) {
    case "default":
      return {
        "body": {
          font: "Roboto",
          size: "18",
          textDecorationColor: "",
          color: "#393939",
          sizeType: "px",
          textAlign: "left",
          lineHeight: 24,
          letterSpacing: 0,
          marginBottom: 10,
          blockStyle: {
            fontWeight: 300,
            underline: false,
            italic: false,
            uppercase: false
          }
        },
        h1: {
          font: "Roboto",
          size: "44",
          textDecorationColor: "",
          color: "#393939",
          sizeType: "px",
          textAlign: "left",
          lineHeight: "49",
          letterSpacing: "0",
          marginBottom: "14",
          blockStyle: {
            fontWeight: "400",
            underline: false,
            italic: false,
            uppercase: true
          }
        },
        h2: {
          font: "Roboto",
          size: "26",
          textDecorationColor: "",
          color: "#393939",
          sizeType: "px",
          textAlign: "left",
          lineHeight: 28,
          letterSpacing: -2,
          marginBottom: 19,
          blockStyle: {
            fontWeight: "400",
            underline: false,
            italic: false,
            uppercase: false
          }
        },
        h3: {
          font: "Roboto",
          size: "18",
          textDecorationColor: "",
          color: "#393939",
          sizeType: "px",
          textAlign: "left",
          lineHeight: 25,
          letterSpacing: -1.6,
          marginBottom: 4,
          blockStyle: {
            fontWeight: "700",
            underline: false,
            italic: false,
            uppercase: false
          }
        },
        background: {
          color: "#f9f9f9"
        },
        coverImage :{},
        banner :{isActive:false},
      }

    case "bold":
      return {
        body: {
          font: "Inter",
          size: "18",
          textDecorationColor: "",
          color: "#30383D",
          sizeType: "px",
          textAlign: "left",
          lineHeight: "26",
          letterSpacing: "1.6",
          marginBottom: "16",
          blockStyle: {
            fontWeight: 300,
            underline: false,
            italic: false,
            uppercase: false
          }
        },
        h1: {
          font: "Inter",
          size: "64",
          textDecorationColor: "",
          color: "#30383D",
          sizeType: "px",
          textAlign: "left",
          lineHeight: "70",
          letterSpacing: -3.5,
          marginBottom: "32",
          blockStyle: {
            fontWeight: "900",
            underline: false,
            italic: false,
            uppercase: false
          }
        },
        h2: {
          font: "Inter",
          size: "42",
          textDecorationColor: "",
          color: "#30383D",
          sizeType: "px",
          textAlign: "left",
          lineHeight: "56",
          letterSpacing: "-3.5",
          marginBottom: "24",
          blockStyle: {
            fontWeight: "900",
            underline: true,
            italic: false,
            uppercase: false
          }
        },
        h3: {
          font: "Inter",
          size: "21",
          textDecorationColor: "",
          color: "#30383D",
          sizeType: "px",
          textAlign: "left",
          lineHeight: "35",
          letterSpacing: "1.6",
          marginBottom: "16",
          blockStyle: {
            fontWeight: "900",
            underline: false,
            italic: false,
            uppercase: false
          }
        },
        background: {
          color: "#fff"
        },
        coverImage :{},
        banner :{isActive:false},
      }
    
    case "classic":
      return {
        body: {
          font: "Montserrat",
          size: "16",
          textDecorationColor: "",
          color: "#373F45",
          sizeType: "px",
          textAlign: "left",
          lineHeight: "28",
          letterSpacing: 0,
          marginBottom: "0",
          blockStyle: {
            fontWeight: 400,
            underline: false,
            italic: false,
            uppercase: false
          }
        },
        h1: {
          font: "Montserrat",
          size: "44",
          textDecorationColor: "",
          color: "#393939",
          sizeType: "px",
          textAlign: "left",
          lineHeight: "54",
          letterSpacing: "0",
          marginBottom: "14",
          blockStyle: {
            fontWeight: "700",
            underline: false,
            italic: false,
            uppercase: false
          }
        },
        h2: {
          font: "Montserrat",
          size: "36",
          textDecorationColor: "",
          color: "#999999",
          sizeType: "px",
          textAlign: "left",
          lineHeight: "49",
          letterSpacing: "0",
          marginBottom: "14",
          blockStyle: {
            fontWeight: "400",
            underline: false,
            italic: false,
            uppercase: false
          }
        },
        h3: {
          font: "Montserrat",
          size: "24",
          textDecorationColor: "",
          color: "#373F45",
          sizeType: "px",
          textAlign: "left",
          lineHeight: "39",
          letterSpacing: "0",
          marginBottom: "0",
          blockStyle: {
            fontWeight: "700",
            underline: false,
            italic: false,
            uppercase: false
          }
        },
        background: {
          color: "#fff"
        },
        coverImage :{},
        banner :{isActive:false},
      };
    
    case "professional" :
      return {
        body: {
          font: "Open Sans",
          size: "18",
          textDecorationColor: "",
          color: "#373F45",
          sizeType: "px",
          textAlign: "left",
          lineHeight: "32",
          letterSpacing: "1.6",
          marginBottom: "0",
          blockStyle: {
            fontWeight: 400,
            underline: false,
            italic: false,
            uppercase: false
          }
        },
        h1: {
          font: "Domine",
          size: "44",
          textDecorationColor: "",
          color: "#306b98",
          sizeType: "px",
          textAlign: "left",
          lineHeight: "62",
          letterSpacing: "0",
          marginBottom: 0,
          blockStyle: {
            fontWeight: "700",
            underline: false,
            italic: false,
            uppercase: false
          }
        },
        h2: {
          font: "Domine",
          size: "35",
          textDecorationColor: "",
          color: "#373F45",
          sizeType: "px",
          textAlign: "left",
          lineHeight: "49",
          letterSpacing: "0",
          marginBottom: "14",
          blockStyle: {
            fontWeight: "700",
            underline: false,
            italic: false,
            uppercase: false
          }
        },
        h3: {
          font: "Domine",
          size: "28",
          textDecorationColor: "",
          color: "#373F45",
          sizeType: "px",
          textAlign: "left",
          lineHeight: "33",
          letterSpacing: "1.6",
          marginBottom: "0",
          blockStyle: {
            fontWeight: "700",
            underline: false,
            italic: false,
            uppercase: false
          }
        },
        background: {
          color: "#fff"
        },
        coverImage :{},
        banner :{isActive:false},

      }

    default:
      break;
  }
}
