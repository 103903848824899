import React, { useContext } from "react";

import { SelectThemeCard } from "./SelectThemeCard";


export function ThemePicker(props) {
  const themeList = props.themeList;
  return (
    <>
      <h3>Select your theme below</h3>
      <p>You can mouse over a theme to see a preview.</p>
      <SelectThemeCard
        fixedTheme={themeList("default")}
        setSecondaryView={()=>props.setSecondaryView}
        themeName={"DEFAULT"}
      />
      <SelectThemeCard
        fixedTheme={themeList("classic")}
        setSecondaryView={()=>props.setSecondaryView}
        themeName={"CLASSIC"}
      />
      <SelectThemeCard
        fixedTheme={themeList("professional")}
        themeName={"PROFESSIONAL"}
        setSecondaryView={()=>props.setSecondaryView}
      />
      <SelectThemeCard
        fixedTheme={themeList("bold")}
        themeName={"BOLD"}
        setSecondaryView={()=>props.setSecondaryView}
      />
    </>
  );
}
