import React from "react";

import {
  BlockDescriptionPreview,
  BlockDescriptionCardPreview,
  BlockDescriptionEditor,
  BlockDescriptionContext,
  BlockKeyResponsabilitiesPreview,
  BlockKeyResponsabilitiesCardPreview,
  BlockKeyResponsabilitiesEditor,
  BlockKeyResponsabilitiesContext,
  BlockSkillsAndRequirementsPreview,
  BlockSkillsAndRequirementsCardPreview,
  BlockSkillsAndRequirementsEditor,
  BlockSkillsAndRequirementsContext,
  BlockBenefitsPreview,
  BlockBenefitsCardPreview,
  BlockBenefitsEditor,
  BlockBenefitsContext,
  BlockImagePreview,
  BlockImageCardPreview,
  BlockImageEditor,
  BlockImageContext,
  BlockCompanyOverviewPreview,
  BlockCompanyOverviewCardPreview,
  BlockCompanyOverviewEditor,
  BlockCompanyOverviewContext,
  BlockFinePrintPreview,
  BlockFinePrintCardPreview,
  BlockFinePrintEditor,
  BlockFinePrintContext,
  BlockQuotePreview,
  BlockQuoteCardPreview,
  BlockQuoteEditor,
  BlockQuoteContext,
  //AddBlockPlaceholder
  SectionSelectContext,
} from "./blocks/_index";

export function BlockRenderer(props) {
  switch (props.blockType) {
    case "description":
      switch (props.mode) {
        case "preview":
          return <BlockDescriptionPreview blockId={props.blockId} publicRender={props.publicRender} />;
        case "editor":
          return <BlockDescriptionEditor blockId={props.blockId} />;
        default:
          return <p>the block didn't load correctly</p>;
      }
    case "key_responsabilities":
      switch (props.mode) {
        case "preview":
          return <BlockKeyResponsabilitiesPreview blockId={props.blockId} publicRender={props.publicRender} />;
        case "editor":
          return <BlockKeyResponsabilitiesEditor blockId={props.blockId} />;
        default:
          return <p>the key_responsabilities block didn't load correctly</p>;
      }
    case "skills_and_requirements":
      switch (props.mode) {
        case "preview":
          return <BlockSkillsAndRequirementsPreview blockId={props.blockId} publicRender={props.publicRender} />;
        case "editor":
          return <BlockSkillsAndRequirementsEditor blockId={props.blockId} />;
        default:
          return <p>the block didn't load correctly</p>;
      }
    case "benefits":
      switch (props.mode) {
        case "preview":
          return <BlockBenefitsPreview blockId={props.blockId} publicRender={props.publicRender} />;
        case "editor":
          return <BlockBenefitsEditor blockId={props.blockId} />;
        default:
          return <p>the block didn't load correctly</p>;
      }
    case "image":
      switch (props.mode) {
        case "preview":
          return <BlockImagePreview blockId={props.blockId} publicRender={props.publicRender} />;
        case "editor":
          return <BlockImageEditor blockId={props.blockId} />;
        default:
          return <p>the block didn't load correctly</p>;
      }
    case "company_overview":
      switch (props.mode) {
        case "preview":
          return <BlockCompanyOverviewPreview blockId={props.blockId} publicRender={props.publicRender} />;
        case "editor":
          return <BlockCompanyOverviewEditor blockId={props.blockId} />;
        default:
          return <p>the block didn't load correctly</p>;
      }
    case "fine_print":
      switch (props.mode) {
        case "preview":
          return <BlockFinePrintPreview blockId={props.blockId} publicRender={props.publicRender} />;
        case "editor":
          return <BlockFinePrintEditor blockId={props.blockId} />;
        default:
          return <p>the block didn't load correctly</p>;
      }
    case "quote":
      switch (props.mode) {
        case "preview":
          return <BlockQuotePreview blockId={props.blockId} publicRender={props.publicRender} />;
        case "editor":
          return <BlockQuoteEditor blockId={props.blockId} />;
        default:
          return <p>the block didn't load correctly</p>;
      }
    default:
      return <p>the block didn't load correctly</p>;
  }
}

export function ContextRenderer(props) {
  switch (props.blockType) {
    case "description":
      return <BlockDescriptionContext blockId={props.blockId} />;
    case "key_responsabilities":
      return <BlockKeyResponsabilitiesContext blockId={props.blockId} />;
    case "skills_and_requirements":
      return <BlockSkillsAndRequirementsContext blockId={props.blockId} />;
    case "benefits":
      return <BlockBenefitsContext blockId={props.blockId} />;
    case "image":
      return <BlockImageContext blockId={props.blockId} />;
    case "company_overview":
      return <BlockCompanyOverviewContext blockId={props.blockId} />;
    case "fine_print":
      return <BlockFinePrintContext blockId={props.blockId} />;
    case "quote":
      return <BlockQuoteContext blockId={props.blockId} />;
    default:
      return <SectionSelectContext blockId={props.blockId} setOpenedDrawer={props.setOpenedDrawer}/>;
  }
}

export function CardRenderer(props) {
  switch (props.blockType) {
    case "description":
      return <BlockDescriptionCardPreview blockId={props.blockId} />;
    case "key_responsabilities":
      return <BlockKeyResponsabilitiesCardPreview />;
    case "skills_and_requirements":
      return <BlockSkillsAndRequirementsCardPreview />;
    case "benefits":
      return <BlockBenefitsCardPreview />;
    case "image":
      return <BlockImageCardPreview blockId={props.blockId} />;
    case "company_overview":
      return <BlockCompanyOverviewCardPreview blockId={props.blockId} />;
    case "fine_print":
      return <BlockFinePrintCardPreview blockId={props.blockId} />;
    case "quote":
      return <BlockQuoteCardPreview blockId={props.blockId} />;
    default:
      return <p>Something didn't load properly.</p>
  }

  return <p>the block didn't load correctly</p>;
}
