import ProppableCompositeDraftDecorator from './ProppableCompositeDraftDecorator.js';
import { getCreateBlockStats, decorateTextAndUpdateBlockStats, analyseTextAndUpdateBlockStats } from './stats.js';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/perspective-subtle.css';


function buildDraftJsDecorators(editorId, stats, setStats) {
  function textAnalysisStrategy(contentBlock, decoration_callback, contentState) {
    const blockId = contentBlock.getKey();
    const text = contentBlock.getText();

    // Create new global stats state and get block stats
    const newStats = {...stats};
    const blockStats = getCreateBlockStats(newStats, editorId, blockId);

    decorateTextAndUpdateBlockStats(text, decoration_callback, blockStats)
    analyseTextAndUpdateBlockStats(text, blockStats)

    setStats(newStats);
  }

  const textAnalysisDecorator = props => {
    if (props.decorateType) {
      return (
        <Tippy content={"This word is usually perceived as more " + props.decorateType + ""} theme="gradient" arrow={true} animation="perspective-subtle">
          <span className={props.decorateType + "-words"}>
            {props.children}
          </span>
        </Tippy>
      );
    }
   return (
      <span>
        {props.children}
      </span>
    );
  };

  const compositeDecorator = new ProppableCompositeDraftDecorator([
    {
        strategy: textAnalysisStrategy,
        component: textAnalysisDecorator,
    }
  ]);

  return compositeDecorator
}


export default buildDraftJsDecorators;
