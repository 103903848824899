import { MasterDashboard } from 'views/missioncontrol/dashboard';
import { MasterAccounts } from 'views/missioncontrol/accounts';
import { MasterUsers } from 'views/missioncontrol/users';
import { MasterFeedback } from 'views/missioncontrol/feedback';
import { MasterLogs } from 'views/missioncontrol/log/list';
import { MasterLogDetail } from 'views/missioncontrol/log/detail';

const Routes = [
  {
    path: '/missioncontrol/dashboard',
    view: MasterDashboard,
    layout: 'missioncontrol',
    permission: 'master',
    title: 'Mission Control'
  },
  {
    path: '/missioncontrol/accounts',
    view: MasterAccounts,
    layout: 'missioncontrol',
    permission: 'master',
    title: 'Accounts'
  },
  {
    path: '/missioncontrol/users',
    view: MasterUsers,
    layout: 'missioncontrol',
    permission: 'master',
    title: 'Users'
  },
  {
    path: '/missioncontrol/feedback',
    view: MasterFeedback,
    layout: 'missioncontrol',
    permission: 'master',
    title: 'User Feedback'
  },
  {
    path: '/missioncontrol/logs',
    view: MasterLogs,
    layout: 'missioncontrol',
    permission: 'master',
    title: 'App Logs'
  },
  {
    path: '/missioncontrol/logs/:id',
    view: MasterLogDetail,
    layout: 'missioncontrol',
    permission: 'master',
    title: 'Log Detail'
  },
]

export default Routes;