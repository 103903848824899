import React, { useState, useEffect, useContext } from "react";
import {
  useAPI,
  Card,
  CreateNewJobModal,
  JobCard,
  JobCardMenu,
  JobSearchBar,
  Message,
  Grid,
  Stat,
  ViewContext,
  Icon,
} from "components/lib";
import Axios from "axios";
import Style from "./joblist.module.scss";

export function JobList(props) {
  const viewContext = useContext(ViewContext);
  const loadData = useAPI("/api/job");
  const [data, setData] = useState({});
  const [jobStats, setJobStats] = useState({});
  const [filter, setFilter] = useState({
    column: "created_at",
    direction: "down",
  });
  const [statusFilter, setStatusFilter] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const getJobs = () => {
    Axios.get("/api/job/", {
      params: { statusFilter: statusFilter, filter: filter },
    })
      .then((res) => {
        setData(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        viewContext.handleError(err);
      });
  };
  const getJobsStats = () => {
    Axios.get("/api/getJobStats", {})
      .then((res) => {
        setJobStats(res.data);
      })
      .catch((err) => {
        viewContext.handleError(err);
      });
  };

  useEffect(() => {
    getJobs();
    getJobsStats();
  }, [loadData, statusFilter, filter]);

  if (!data)
    return (
      <>
        <h1>No data</h1>
      </>
    );

  return (
    <>
      {data.data && (
        <>
          <Message
            closable
            title={"Hi there!"}
            text="Welcome to the dashboard. Where you can find your most recent jobs and create new ones."
            type="info"
          />
          <Grid cols="4">
            <div>
              <Stat
                loading={false}
                value={jobStats.data && jobStats.data.totalJobCount}
                label="Total jobs"
                icon="folder"
              />
            </div>
            <div>
              <Stat
                loading={false}
                value={jobStats.data && jobStats.data.draftCount}
                label="Drafts"
                icon="edit"
              />
            </div>
            <div>
              <Stat
                loading={false}
                value={jobStats.data && jobStats.data.privateCount}
                label="Private"
                icon="activity"
              />
            </div>
            <div>
              <Stat
                loading={false}
                value={jobStats.data && jobStats.data.publishedCount}
                label="Published"
                icon="flag"
              />
            </div>
          </Grid>
          <Card shadow="true">
            <div
              className={Style.searchBarAndCTAWrapper}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <JobSearchBar
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
              <div>
                <CreateNewJobModal />
              </div>
            </div>
            {/* {searchValue} */}
            <JobCardMenu
              filter={filter}
              setFilter={setFilter}
              setStatusFilter={setStatusFilter}
            />
            {jobStats?.data?.totalJobCount === 0 || data.data.length === 0 ? (
              <div>
                <div className={Style.noJobMessage}>
                  <h3>
                    {jobStats?.data?.totalJobCount === 0
                      ? "You don't have any job"
                      : "No jobs match your filters"}{" "}
                    <Icon
                      image={
                        jobStats?.data?.totalJobCount === 0
                          ? "folder"
                          : "filter"
                      }
                      size="18px"
                    />
                  </h3>
                  <p>
                    {jobStats?.data?.totalJobCount === 0
                      ? "This is where your jobs will appear. You can click on this link to create your first job:"
                      : "It seems you have applied a filter or typed something in the search bar that didn't return any results"}
                  </p>
                  {jobStats?.data?.totalJobCount === 0 && (
                    <CreateNewJobModal
                      customButtonText={"Create my first job"}
                    />
                  )}
                </div>
              </div>
            ) : (
              (() => {
                let filteredData = data.data.filter((item) => {
                  if (searchValue === "") return true;
                  return (
                    item.name
                      .toLowerCase()
                      .includes(searchValue.toLowerCase()) ||
                    item.category.includes(searchValue.toLowerCase())
                  );
                });

                if (filteredData.length === 0) {
                  return (
                    <div className={Style.noJobMessage}>
                      <h3>
                        No jobs match your filter{" "}
                        <Icon image="filter" size="18px" />
                      </h3>
                      <p>
                        It seems you have applied a filter or typed somethjing
                        in the search bar that didn't return any results
                      </p>
                    </div>
                  );
                } else {
                  return filteredData.map((item, index) => (
                    <JobCard
                      job={item}
                      getJobCallback={() => {
                        getJobs();
                        getJobsStats();
                      }}
                      key={"key_" + item.id}
                    ></JobCard>
                  ));
                }
              })()
            )}
          </Card>
        </>
      )}
    </>
  );
}
