import React, { useContext, useState, useRef, useEffect } from "react";
import { EditorContext, ViewContext } from "components/lib";
import Style from "./emptyContentPageModalAi.module.scss";
import Modal from "@material-ui/core/Modal";
import { GenerateLoadingScreen } from "./generateLoadingScreen.js";
import Axios from "axios";
import _ from "lodash";
import { DraftSelect } from "./DraftSelect";
import { FirstPromptScreen } from "./firstPromptScreen.js";
import { PromptResultScreen } from "./promptResultScreen.js";

const EmptyContentPageModalAi = (props) => {
  const viewContext = useContext(ViewContext);
  const [currentScreen, setCurrentScreen] = useState("draft_select");

  const [communicationStyle, setCommunicationStyle] = useState("normal");
  const [complexityLevel, setComplexityLevel] = useState("normal");
  const [inclusiveWriting, setInclusiveWriting] = useState(false);
  const [currentCharCount, setcurrentCharCount] = useState(0);

  const textAreaRef = useRef(null);
  const askForChangesTextAreaRef = useRef(null);
  // const resultText = useRef(null);
  const [resultText, setResultText] = useState(null);
  const timeoutRef = useRef(null);

  const handleTextChange = (event) => {
    setcurrentCharCount(event.target.value.length);
  };

  const addBlockHelper = useContext(EditorContext).addBlockHelper;
  const blockOrder = useContext(EditorContext).blockOrder;
  const setSelectedBlock = useContext(EditorContext).setSelectedBlock;
  const setSideElem = props.setSideElem;
  const setOpenedDrawer = props.setOpenedDrawer;

  const startEditClassicTemplate = () => {
    // window.location.reload();
    addBlockHelper("description");
    addBlockHelper("skills_and_requirements");
    addBlockHelper("benefits");
    addBlockHelper("company_overview");
    setSelectedBlock(blockOrder[0]);
    // console.log(addBlockHelper);
    props.setShowEmptyContentPageModal(false);
  };

  const startEditGeneratedTemplate = () => {
    // window.location.reload();
    addBlockHelper("description", resultText['summary']);
    addBlockHelper("skills_and_requirements", resultText['skills_and_requirements']);
    addBlockHelper("benefits", resultText['benefits']);
    addBlockHelper("key_responsabilities", resultText['key_responsibilities']);
    setSelectedBlock(blockOrder[0]);
    // console.log(addBlockHelper);
    props.setShowEmptyContentPageModal(false);
  };

  const abortControllerRef = useRef(null); // To keep track of the AbortController instance

  const submitGenerateRequest = () => {
    if (
      !_.isString(textAreaRef.current.value) ||
      _.isEmpty(textAreaRef.current.value)
    ) {
      viewContext.notification.show(
        "There was an error with your request! Please reload",
        "error",
        true,
        "toast"
      );
      return;
    }

    const newRequestObject = {
      userPrompt: textAreaRef.current.value,
      communicationStyle: communicationStyle,
      complexityLevel: complexityLevel,
      inclusiveWriting: inclusiveWriting,
    };

    abortControllerRef.current = new AbortController();

    ////////////////////////////////////
    //// STOP API TEMPORARILY

    // setResultText({
    //   summary: {
    //     section_title: "Summary",
    //     summary:
    //       "Join our team as a marketing manager and be a part of developing and implementing strategic marketing plans to drive brand awareness and generate leads. We are looking for a talented individual with a proven track record in marketing, strong analytical skills, and excellent communication abilities.",
    //   },
    //   skills_and_requirements: {
    //     section_title: "Skills & Requirements",
    //     short_introduction:
    //       "To excel in this role, you will need the following skills and qualifications:",
    //     list_of_skills_and_requirements: [
    //       "Proven track record of success in marketing",
    //       "Strong analytical skills",
    //       "Excellent communication abilities",
    //     ],
    //   },
    //   key_responsibilities: {
    //     section_title: "Key Responsibilities",
    //     short_introduction:
    //       "As a marketing manager, your key responsibilities will include:",
    //     list_of_key_responsibilities: [
    //       "Developing and implementing strategic marketing plans",
    //       "Driving brand awareness and lead generation",
    //       "Ensuring alignment with the sales team to maximize revenue opportunities",
    //     ],
    //   },
    //   benefits: {
    //     section_title: "Benefits",
    //     short_introduction:
    //       "Joining our team as a marketing manager comes with the following benefits:",
    //     list_of_benefits: [
    //       "Opportunity to make an impact in the exciting world of marketing",
    //       "Room for career growth and advancement",
    //       "Collaborative work environment",
    //     ],
    //   },
    // });

    // setCurrentScreen("result_mode");

    ////////////////////////////////////

    Axios.get("/api/generate/first_draft", {
      params: newRequestObject,
      signal: abortControllerRef.current.signal,
      timeout: 20000,
    })
      .then((res) => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        setCurrentScreen("result_mode");
        console.log(res.data.data);
        setResultText(res.data.data);
        //TODO : compile correctly the result into JSX
      })
      .catch((err) => {
        console.log(err);
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        if (!Axios.isCancel(err)) {
          viewContext.notification.show(
            "Axios.isCancel : There was an error with your request! Please reload",
            "error",
            true,
            "toast"
          );
        } else {
          console.log("Request cancelled");
        }
      });

    timeoutRef.current = setTimeout(() => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        // viewContext.notification.show(
        //   "Request timed out after 20 seconds",
        //   "warning",
        //   true,
        //   "toast"
        // );
      }
    }, 30000); 
  };

  const submitRevisionRequest = () => {
    if (
      !_.isString(askForChangesTextAreaRef.current.value) ||
      _.isEmpty(askForChangesTextAreaRef.current.value)
    ) {
      viewContext.notification.show(
        "There was an error with your request! Please reload",
        "error",
        true,
        "toast"
      );
      return;
    }

    const newRequestObject = {
      userPrompt: askForChangesTextAreaRef.current.value,
      latest_version: JSON.stringify(resultText),
      communicationStyle: communicationStyle,
      complexityLevel: complexityLevel,
      inclusiveWriting: inclusiveWriting,
    };

    console.log(newRequestObject);

    abortControllerRef.current = new AbortController();

    Axios.get("/api/generate/review_request", {
      params: newRequestObject,
      signal: abortControllerRef.current.signal,
      timeout: 20000,
    })
      .then((res) => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
        console.log(res.data.data);
        setCurrentScreen("result_mode");
        setResultText(res.data.data);
        //TODO : compile correctly the result into JSX
      })
      .catch((err) => {
        console.log(err);
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        if (!Axios.isCancel(err)) {
          viewContext.notification.show(
            "Axios.isCancel : There was an error with your request! Please reload",
            "error",
            true,
            "toast"
          );
        } else {
          console.log("Request cancelled");
        }
      });

    timeoutRef.current = setTimeout(() => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        // viewContext.notification.show(
        //   "Request timed out after 20 seconds",
        //   "warning",
        //   true,
        //   "toast"
        // );
      }
    }, 20000); // 20 seconds
  };

  const startEditing = () => {
    console.log("editing");
  };

  // Function to cancel the request
  const cancelRequest = () => {
    console.log("this was cancelled");
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      viewContext.notification.show(
        "Request has been cancelled by the user",
        "info",
        true,
        "toast"
      );
    }
  };

  return (
    <div className={Style.modalWrapper}>
      <Modal
        open={props.showEmptyContentPageModal}
        onClose={() => {
          // props.setShowEmptyContentPageModal(false);
        }}
      >
        <div className={Style.modalMain}>
          {currentScreen === "draft_select" && (
            <DraftSelect
              startEditClassicTemplate={startEditClassicTemplate}
              props={props}
              setCurrentScreen={setCurrentScreen}
              setOpenedDrawer={setOpenedDrawer}
              setSideElem={setSideElem}
            />
          )}
          {currentScreen === "generate_draft_mode" && (
            <FirstPromptScreen
              communicationStyle={communicationStyle}
              complexityLevel={complexityLevel}
              currentCharCount={currentCharCount}
              handleTextChange={handleTextChange}
              inclusiveWriting={inclusiveWriting}
              setCommunicationStyle={setCommunicationStyle}
              setComplexityLevel={setComplexityLevel}
              setCurrentScreen={setCurrentScreen}
              setInclusiveWriting={setInclusiveWriting}
              submitGenerateRequest={submitGenerateRequest}
              textAreaRef={textAreaRef}
            />
          )}
          {currentScreen === "result_mode" && (
            <PromptResultScreen
              communicationStyle={communicationStyle}
              complexityLevel={complexityLevel}
              currentCharCount={currentCharCount}
              handleTextChange={handleTextChange}
              inclusiveWriting={inclusiveWriting}
              setCommunicationStyle={setCommunicationStyle}
              setComplexityLevel={setComplexityLevel}
              setCurrentScreen={setCurrentScreen}
              setInclusiveWriting={setInclusiveWriting}
              submitRevisionRequest={submitRevisionRequest}
              startEditGeneratedTemplate={startEditGeneratedTemplate}
              askForChangesTextAreaRef={askForChangesTextAreaRef}
              resultText={resultText}
            />
          )}
          {currentScreen === "loading_screen_generate" && (
            <>
              <GenerateLoadingScreen
                waiting_for_response={true}
                setCurrentScreen={setCurrentScreen}
                cancelRequest={cancelRequest}
              />
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

const SwitchButton = (props) => {
  return (
    <button
      className={
        props.currentSwitchState
          ? Style.switchButton
          : Style.switchButton + " " + Style.switchButtonActive
      }
      style={props.orangeNotification ? { backgroundColor: "#F0AA61" } : {}}
      onClick={() => props.callback(!props.currentSwitchState)}
    >
      <div className={Style.switchIndicator}></div>
    </button>
  );
};

export { EmptyContentPageModalAi, SwitchButton };
