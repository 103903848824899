import React from "react";
import { Icon, MyButton } from "components/lib";
import Style from "./imageSelector.module.scss";

export function ImageSelector(props) {
  return (
    <div className={Style.imageSelector}>
      <div className={Style.img_container}>
        <img src={props.result.preview_url} alt="preview" />
        <div className={Style.btn_container}>
          <MyButton
            onClick={() => props.setPreviewFile(props.result)}
            size="small"
          >
            <Icon color="dark" image="maximize-2" />
            Preview
          </MyButton>
          <MyButton
            onClick={() => {
              // console.log("clicked on upload");
              // console.log(props.result.id);
              props.upload_file_to_server_unsplash(props.result.id);
            }}
            size="small"
            buttonStyle={"primary"}
          >
            Select {}
          </MyButton>
        </div>
      </div>
      <span className={Style.unsplash_attribution}>
        Photo by{" "}
        <a
          href={
            props.result.photographer_portfolio_url +
            "?utm_source=jobchef&utm_medium=referral"
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.result.photographer}
        </a>{" "}
        on{" "}
        <a
          href="https://unsplash.com/?utm_source=jobchef&utm_medium=referral"
          target="_blank"
          rel="noopener noreferrer"
        >
          Unsplash
        </a>
      </span>
    </div>
  );
}
