import React, { lazy, Suspense } from "react";
// import { Line } from "react-chartjs-2";
const Line = lazy(() =>
  import("react-chartjs-2").then((module) => ({ default: module.Line }))
);
export function SparkLineChart(props) {
  let options = { ...props.options };

  options.scales = {
    xAxes: [{ display: false }],
    yAxes: [{ display: false }],
  };

  options.maintainAspectRatio = false;
  options.responsive = true;

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Line data={props.data} options={options} />{" "}
    </Suspense>
  );
}
