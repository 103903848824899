import React, { useState, useContext } from "react";
import Style from "./jobCard.module.scss";
import { MyButton, History, Icon, ViewContext } from "components/lib";
import { ReactComponent as CaretUp } from "./img/caret-up-solid.svg";
import { ReactComponent as CaretDown } from "./img/caret-down-solid.svg";
import Modal from "@material-ui/core/Modal";
import Axios from "axios";
import _ from "lodash";
import Tippy from "@tippyjs/react";
import { Formik } from "formik";

const dateFromTimeStamp = (ts) => {
  const newDate = new Date(ts);
  return newDate.toLocaleDateString();
};

const JobCard = (props) => {
  return (
    <div className={Style.jobCard}>
      <h4
        onClick={() => {
          History.push("/jobeditor/" + props.job.id);
        }}
        style={{ cursor: "pointer" }}
        className={Style.j_name}
      >
        {props.job.name}
      </h4>
      <p className={Style.j_category}>{functionList[props.job.category]}</p>
      <p className={Style.j_dateCreated}>{dateFromTimeStamp(props.job.created_at)}</p>
      <JobStatusSelector
        jobId={props.job.id}
        initialValue={props.job.status}
        getJobs={props.getJobCallback}
        className={Style.j_statusSelector}
      />
      <div className={Style.actionButtonsWrapper}>
        <DuplicateJobModal
          jobId={props.job.id}
          copiedJobName={props.job.name}
          copiedJobCategory={props.job.category}
          getJobCallback={props.getJobCallback}
        />
        <DeleteJobModal
          jobId={props.job.id}
          getJobCallback={props.getJobCallback}
        />
        <MyButton
          buttonStyle={"primary"}
          onClick={() => {
            History.push("/jobeditor/" + props.job.id);
          }}
        >
          Edit
          <Icon image={"edit-2"} size={"0.875em"} className={Style.iconSize} />
        </MyButton>
      </div>
    </div>
  );
};

const JobCardMenu = (props) => {
  const changeFilter = (itemId) => {
    if (props.filter.column === itemId) {
      if (props.filter.direction === "down") {
        props.setFilter({
          column: itemId,
          direction: "up",
        });
      }
      if (props.filter.direction === "up") {
        props.setFilter({
          column: itemId,
          direction: "down",
        });
      }
    } else {
      props.setFilter({
        column: itemId,
        direction: "down",
      });
    }
  };

  return (
    <div className={Style.jobCardMenu}>
      <ItemWrapper
        label={"Name"}
        itemId={"name"}
        filter={props.filter}
        changeFilter={changeFilter}
      />
      <ItemWrapper
        label={"Category"}
        itemId={"category"}
        filter={props.filter}
        changeFilter={changeFilter}
      />
      <ItemWrapper
        label={"DateCreated"}
        itemId={"created_at"}
        filter={props.filter}
        changeFilter={changeFilter}
      />
      <ItemWrapper
        label={"Status"}
        itemId={"status"}
        filter={props.filter}
        changeFilter={changeFilter}
        setStatusFilter={props.setStatusFilter}
      />

      <span className={Style.menuItem}></span>
    </div>
  );
};

const ItemWrapper = (props) => {
  const [checkboxState, setCheckboxState] = useState({
    selectAll: true,
    draft: true,
    private: true,
    published: true,
  });

  const activeColumn = props.itemId === props.filter.column;

  const selectedStyle = activeColumn ? Style.menuItemStyleSelected : "";
  let styleUp = "";
  let styleDown = "";

  if (activeColumn && props.filter.direction === "up") {
    styleUp = Style.arrowStyleActive;
    styleDown = Style.arrowStyleInactive;
  }
  if (activeColumn && props.filter.direction === "down") {
    styleUp = Style.arrowStyleInactive;
    styleDown = Style.arrowStyleActive;
  }

  const handleInputChange = (event) => {
    const { name, checked } = event.target;

    // console.log(name, checked);

    let newState = { ...checkboxState, [name]: checked };

    if (name !== "selectAll" && !checked) {
      newState = { ...newState, selectAll: false };
    } else if (
      name !== "selectAll" &&
      Object.values(newState).every((value) => value === true)
    ) {
      newState = { ...newState, selectAll: true };
    } else if (name === "selectAll" && checked) {
      newState = {
        selectAll: true,
        draft: true,
        private: true,
        published: true,
      };
    } else if (name === "selectAll" && !checked) {
      newState = {
        selectAll: false,
        draft: false,
        private: false,
        published: false,
      };
    }

    setCheckboxState(newState);

    // Call the inputChangeCallback function
    props.setStatusFilter(newState);
  };

  if (props.itemId === "status") {
    return (
      <div className={Style.menuItem}>
        <Tippy
          interactive={true}
          content={
            <div className={Style.tooltipfilter}>
              <p>Apply filter:</p>
              <label>
                <input
                  type="checkbox"
                  name="selectAll"
                  checked={
                    checkboxState.draft &&
                    checkboxState.private &&
                    checkboxState.published
                  }
                  onChange={handleInputChange}
                />
                Select All
              </label>

              <label>
                <input
                  type="checkbox"
                  name="draft"
                  checked={checkboxState.draft}
                  onChange={handleInputChange}
                />
                Draft
              </label>

              <label>
                <input
                  type="checkbox"
                  name="private"
                  checked={checkboxState.private}
                  onChange={handleInputChange}
                />
                Private
              </label>

              <label>
                <input
                  type="checkbox"
                  name="published"
                  checked={checkboxState.published}
                  onChange={handleInputChange}
                />
                Published
              </label>
            </div>
          }
          placement="top"
          hideOnClick={false}
        >
          <div
            className={Style.itemWrapper + " " + selectedStyle}
            onClick={() => props.changeFilter(props.itemId)}
          >
            <span className={Style.menuItemLabel}>{props.label}</span>
            <div className={Style.filterIcon}>
              <Icon image="filter" size="12px" style={{ margin: "8px" }} />
            </div>
            <div className={Style.sortGroup}>
              <CaretUp className={Style.sortIcon + " " + styleUp} />
              <CaretDown className={Style.sortIcon + " " + styleDown} />
            </div>
          </div>
        </Tippy>
      </div>
    );
  } else {
    return (
      <div className={Style.menuItem}>
        <div
          className={Style.itemWrapper + " " + selectedStyle}
          onClick={() => props.changeFilter(props.itemId)}
        >
          <span className={Style.menuItemLabel}>{props.label}</span>
          <div className={Style.sortGroup}>
            <CaretUp className={Style.sortIcon + " " + styleUp} />
            <CaretDown className={Style.sortIcon + " " + styleDown} />
          </div>
        </div>
      </div>
    );
  }
};

const JobStatusSelector = (props) => {
  const viewContext = useContext(ViewContext);
  const [value, setValue] = useState(props.initialValue);

  const updateData = (value) => {
    // const tempsData = _.cloneDeep(props.data)
    // console.log(tempsData);
    // tempsData.data[props.jobId].status = ""
    // props.setData(tempsData)
  };

  const statusValueUpdater = (statusValue) => {
    Axios.patch("/api/job/jobstatus/" + props.jobId, {
      status: statusValue,
    })
      .then((res) => {
        viewContext.notification.show(
          "Job status updated !",
          "success",
          true,
          "toast"
        );
        props.getJobs();
      })
      .catch((err) => {
        viewContext.notification.show(
          "There was an error updating your job, please reload",
          "error",
          true,
          "toast"
        );
        viewContext.handleError(err);
      });
  };

  return (
    <div className={props.className}>
      <select
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          statusValueUpdater(e.target.value);
        }}
        className={Style.jobStatusSelector}
      >
        <option value="draft">Draft</option>
        <option value="private">Private</option>
        <option value="published">
          {value === "published" ? "Published" : "Publish"}
        </option>
      </select>
    </div>
  );
};

function DeleteJobModal(props) {
  const [open, setOpen] = useState(false);
  const viewContext = useContext(ViewContext);

  const deleteJob = (id) => {
    Axios.delete("/api/job/" + id, {
      category: props.category,
      name: props.name,
    })
      .then((res) => {
        // console.log(res);
        props.getJobCallback();
        viewContext.notification.show(
          "Job deleted !",
          "success",
          true,
          "toast"
        );
      })
      .catch((err) => {
        viewContext.notification.show(
          "There was an error deleting your job ! Please reload",
          "error",
          true,
          "toast"
        );
        viewContext.handleError(err);
      });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className={Style.modalMain}>
          <h2>Delete this job?</h2>
          <p>You won't be able to undo this action. </p>
          <div className={Style.buttonWrapper}>
            <MyButton
              buttonStyle={"outline"}
              size={"medium"}
              onClick={() => {
                setOpen(false);
              }}
              className={Style.cancelButton}
            >
              Cancel
            </MyButton>
            <MyButton
              onClick={() => {
                // console.log("Deleting...");
                setOpen(false);
                deleteJob(props.jobId);
              }}
              className={Style.deleteButton}
              buttonStyle={"danger"}
            >
              Confirm deletion
              <Icon
                image={"trash-2"}
                size={"0.875em"}
                className={Style.iconSize}
              />
            </MyButton>
          </div>
        </div>
      </Modal>
      <MyButton
        buttonStyle={"secondary"}
        onClick={() => {
          setOpen(true);
        }}
        className={Style.deleteButton}
      >
        Delete
        <Icon image={"trash-2"} size={"0.875em"} className={Style.iconSize} />
      </MyButton>
    </>
  );
}

function DuplicateJobModal(props) {
  const [open, setOpen] = useState(false);
  const viewContext = useContext(ViewContext);

  const duplicateJob = (values) => {
    Axios.post("/api/duplicate/" + props.jobId, {
      category: values.category,
      name: values.name,
    })
      .then((res) => {
        History.push("/jobeditor/" + res.data.id);
        // props.getJobCallback();    --> Only needed if no page change
      })
      .catch((err) => {
        viewContext.notification.show(
          "There was an error saving your job ! Please reload",
          "error",
          true,
          "toast"
        );
        viewContext.handleError(err);
      });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className={Style.delete_modal}>
          <h2>Create a copy</h2>

          <div className={Style.jobBeingCopied}>
            <p className={Style.introText}>The following job will be copied:</p>
            <p className={Style.jobName}>{props.copiedJobName}</p>
          </div>

          <Formik
            initialValues={{
              name: "Copy of " + props.copiedJobName,
              category: props.copiedJobCategory,
            }}
            validate={(values) => {
              const errors = {};

              if (!values.name) {
                errors.name = "Required";
              }
              if (!values.category || values.category === "default") {
                errors.category = "Required";
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              duplicateJob(values);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isValid,
              dirty,
            }) => (
              <form onSubmit={handleSubmit} className={Style.formStyle}>
                <div className={Style.inputWrapper}>
                  <p className={Style.itemTitle}>New job name</p>
                  <p className={Style.itemSubTitle}>
                    Remember, this <i>private name</i>, will only be displayed
                    whitin jobchef and won't be visible to candidates.{" "}
                    <span role="img" aria-label="Unsure">
                      😉
                    </span>
                  </p>
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    autocomplete="off"
                  />
                  <span className={Style.errorMessage}>
                    {errors.name && touched.name && errors.name}
                  </span>
                </div>
                <p className={Style.itemTitle}>Category</p>
                <p className={Style.itemSubTitle}>
                  Categories help you filter and report on your jobs.
                </p>
                <div className={Style.inputWrapper}>
                  <select
                    name="category"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.category}
                    className={Style.inputStyle}
                  >
                    <option value="default">Please select a category...</option>
                    {Object.keys(functionList).map((cat, index) => {
                      return <option value={cat}>{functionList[cat]}</option>;
                    })}
                  </select>
                  <span className={Style.errorMessage}>
                    {errors.category && touched.category && errors.category}
                  </span>
                </div>
                <div className={Style.flexDivHorizontal}>
                  <MyButton
                    onClick={() => {
                      setOpen(false);
                      handleSubmit();
                    }}
                    buttonStyle={!isValid ? "disabled" : "primary"}
                    size={"medium"}
                    className={Style.buttonsStyle}
                    type="submit"
                  >
                    Create
                    <Icon
                      image={"file-plus"}
                      size={14}
                      className={Style.buttonsIconStyle}
                    />
                  </MyButton>
                  <MyButton
                    buttonStyle={"outline"}
                    size={"medium"}
                    className={Style.buttonsStyle}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancel
                  </MyButton>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal>
      <MyButton
        buttonStyle={"secondary"}
        onClick={() => {
          setOpen(true);
        }}
        className={Style.deleteButton}
      >
        Duplicate
        <Icon image={"copy"} size={"0.875em"} className={Style.iconSize} />
      </MyButton>
    </>
  );
}

const functionList = {
  arts_design: "Arts & Design",
  business: "Business",
  Consulting: "Consulting",
  education: "Education",
  engineering: "Engineering",
  healthcare: "Healthcare",
  human_esources: "Human Resources",
  information_technology: "Information Technology",
  media_communications: "Media & Communications",
  product_project_management: "Product & Project Management",
  research_science: "Research & Science",
  retail_food_Services: "Retail & Food Services",
  skilled_labor_manufacturing: "Skilled Labor & Manufacturing",
  transportation: "Transportation",
  other: "Other",
};

export { JobCard, JobCardMenu };
