import React, { lazy, Suspense } from "react";
// import { Bar } from 'react-chartjs-2';
const Bar = lazy(() =>
  import("react-chartjs-2").then((module) => ({ default: module.Bar }))
);

export function BarChart(props) {
  let options = { ...props.options };
  options.maintainAspectRatio = false;
  options.responsive = true;

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Bar data={props.data} options={options} />
    </Suspense>
  );
}
