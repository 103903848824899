import React, { useContext, useEffect, useState } from "react";
import {
  HelperBlockContextHeader,
  HelperBlockContextBody,
  HelperContextSwitcher,
} from "../blocks/_index.js";
import { Icon, ClickToCopyText, CollapseToggle } from "../../lib";
import { CopyIcon, LightbulbRegular } from "../blocks/images/_index.js";
import Style from "../blocks/BlockAll.module.scss";
import { contextCardInfo } from "./contextCardInfo.js";

const HelperBlockContextCard = (props) => {
  return (
    <>
      <HelperBlockContextHeader
        header={props.header}
        image={props.image}
        color={props.color}
      ></HelperBlockContextHeader>
      <HelperBlockContextBody>
        <HelperContextSwitcher
          blockInfo={
            <>
              <div className={Style.subtitle}>
                <Icon image="message-circle" color={props.color}></Icon>
                <h3>What this section is about</h3>
              </div>
              {contextCardInfo[props.blockType].sectionMainInfo}
              <div className={Style.subtitle}>
                <LightbulbRegular className={props.iconStyle} />
                <h3>Tips</h3>
              </div>
              {contextCardInfo[props.blockType].sectionTips}
            </>
          }
          textSamples={
            <>
              <div className={Style.subtitle}>
                <CopyIcon className={props.iconStyle} />
                <h3 id="textSamples">Text samples</h3>
              </div>
              {Object.keys(contextCardInfo[props.blockType].textSamples).map(
                (item, index) => (
                  <CollapseToggle
                    toggleTitle={
                      contextCardInfo[props.blockType].textSamples[item].label
                    }
                    contentExpanded={
                      <>
                        <ul>
                          {contextCardInfo[props.blockType].textSamples[
                            item
                          ].list.map((value, index) => (
                            <li>
                              <ClickToCopyText elementToCopy={value}>
                                {value}
                              </ClickToCopyText>
                            </li>
                          ))}
                        </ul>
                      </>
                    }
                  ></CollapseToggle>
                )
              )}
              <br />
            </>
          }
        />
      </HelperBlockContextBody>
    </>
  );
};

export { HelperBlockContextCard };
