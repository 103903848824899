import React, { useEffect, useRef, useContext } from "react";
import Style from "./HelperBlockContextBody.module.scss";
import { EditorContext, MyButton } from "components/lib";
import { ScrollArea } from "@mantine/core";

const HelperBlockContextBody = (props) => {
  const contextScrollRef = useContext(EditorContext).contextScrollRef;

  return (
    <ScrollArea style={{ height: "100%", marginRight:'4px' }} offsetScrollbars>
      <div className={Style.main}>{props.children}</div>
    </ScrollArea>
  );
};

export { HelperBlockContextBody };
