import React, { useState, useContext, useRef } from "react";
import Style from "./collaborateModal.module.scss";
import { Icon, MyButton, ViewContext, useAPI, Loader } from "components/lib";
import Modal from "@material-ui/core/Modal";
import Axios from "axios";
import Tippy from "@tippyjs/react";
import { Formik, Field, Form } from "formik";

export function CollaborateModal(props) {
  const [open, setOpen] = useState(false);
  const viewContext = useContext(ViewContext);
  const collaborateLinkCopyRef = useRef(null);
  const [justClicked, setJustClick] = useState(false);
  const [collaborationLink, setCollaborationLink] = useState("");
  const [invitationSentByMail, setInvitationSentByMail] = useState(false);

  const user = useAPI("/api/user");
  // console.log(user);

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i; // Basic email regex

  const copieToClipboard = () => {
    if (collaborationLink) {
      navigator.clipboard.writeText(collaborationLink);
      setJustClick(true);
      setTimeout(function () {
        setJustClick(false);
      }, 1200);
    }
  };

  const getCollaborationLink = () => {
    Axios.get(
      "/api/job/" + window.location.href.split("/")[4] + "/sharelink",
      {}
    )
      .then((res) => {
        let windowslocation = window.location.href.split("/");
        const link =
          windowslocation[0] +
          "/" +
          windowslocation[1] +
          "/" +
          windowslocation[2] +
          "/" +
          "sharelink/" +
          windowslocation[4] +
          "?share=" +
          res.data.share_link;
        setCollaborationLink(link); // Update the collaboration link in the state
      })
      .catch((err) => {
        viewContext.notification.show(
          "There was an error saving your job ! Please reload",
          "error",
          true,
          "toast"
        );
        viewContext.handleError(err);
      });
  };

  async function send_collaboration_link(values, collaborationLink) {
    try {
      const res = await Axios.post("/api/collaborationinvite", {
        values,
        collaborationLink,
      });

      if (res.status === 200) {
        setInvitationSentByMail(true);
        viewContext.notification.show(
          "You message was sent!",
          "success",
          true,
          "toast"
        );
      }
    } catch (err) {
      viewContext.handleError(err);
    }
  }

  const topTextareaRef = useRef(null);
  const bottomTextareaRef = useRef(null);

  if (user.loading) {
    return <Loader />;
  }

  return (
    <>
      <MyButton
        onClick={() => {
          if (invitationSentByMail) {
            setInvitationSentByMail(false);
          }
          getCollaborationLink();
          setOpen(true);
        }}
        size={"small"}
        buttonStyle={"alternative"}
        style={{ marginLeft: "0.8rem" }}
      >
        <span className={Style.actionButtonsText}>Collaborate</span>
        <Icon image={"users"} size={12} className={Style.iconStyle} />
      </MyButton>

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className={Style.export_modal}>
          <h2>Collaborate on this job posting!</h2>
          <div className={Style.mainContentWrapper}>
            <div className={Style.columnOne}>
              <h3>Send by mail</h3>
              <p>
                Review the template below and send directly the email to your
                colleague.{" "}
              </p>
              {!invitationSentByMail && (
                <Formik
                  initialValues={{
                    email: "",
                    messageTop: `Hello,

${
  user?.data?.name
    ? user.data.name.charAt(0).toUpperCase() +
      user.data.name.slice(1).toLowerCase()
    : ""
} would like your help in creating the job description for:
${props.jobName}.

This step is where we kick-start our recruitment efforts. It will help us be on the same page about what you need and who we're looking for.

Just click the link below and follow the steps to dive right in:`,
                    messageBottom: `We're eager to kick off this recruitment process with you.

Your recruitment team`,
                  }}
                  validate={(values) => {
                    const errors = {};

                    if (!values.email) {
                      errors.email = "Required";
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = "Invalid email address";
                    }

                    if (values.messageTop.length < 1) {
                      errors.messageTop = "You need to include a message";
                    }

                    return errors;
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      // Validate the email using a regex
                      if (!emailRegex.test(values.email)) {
                        throw new Error("Invalid email address");
                      }

                      // Continue with your form submission logic

                      send_collaboration_link(
                        JSON.stringify(values),
                        collaborationLink
                      );

                      // Reset the form and setSubmitting
                      // This is optional, but you might want to clear the form after successful submission
                      // and allow the user to submit again if needed.
                      setSubmitting(false);
                    } catch (error) {
                      // Handle validation errors
                      console.error(error.message);
                    }
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isValid,
                    dirty,
                    isSubmitting,
                  }) => (
                    <Form className={Style.formWrapper}>
                      <label htmlFor="firstName">To:</label>
                      <span className={Style.errorMessage}>
                        {errors.email && touched.email && errors.email}
                      </span>
                      <Field
                        id="email"
                        name="email"
                        placeholder="john@url.com"
                      />
                      <label htmlFor="message" style={{ paddingTop: "8px" }}>
                        Message:
                      </label>
                      <span className={Style.errorMessage}>
                        {errors.messageTop &&
                          touched.messageTop &&
                          errors.messageTop}
                      </span>
                      <div className={Style.textAreaInputGroupWrapper}>
                        <textarea
                          id="messageTop"
                          name="messageTop"
                          placeholder="Enter your message here"
                          value={values.messageTop}
                          onChange={(e) => {
                            handleChange(e);
                            adjustTextareaHeight(topTextareaRef);
                          }}
                          onBlur={handleBlur}
                          className={Style.textAreaInputTop}
                          ref={topTextareaRef}
                        />
                        <div className={Style.CtaButtonCollaborate}>
                          <Tippy
                            content={
                              "This button will work when the email is sent"
                            }
                            placement="right"
                            className={Style.tooltipNormal}
                          >
                            <div
                              className={Style.styledButton}
                              href={collaborationLink}
                              alt="link to collaborate"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Collaborate
                            </div>
                          </Tippy>
                          <a
                            className={Style.unstyledLink}
                            href={collaborationLink}
                            style={{ fontSize: "9ax" }}
                          >
                            {collaborationLink}
                          </a>
                        </div>
                        <textarea
                          id="messageBottom"
                          name="messageBottom"
                          placeholder="Enter your message here"
                          value={values.messageBottom}
                          onChange={(e) => {
                            handleChange(e);
                            adjustTextareaHeight(bottomTextareaRef);
                          }}
                          onBlur={handleBlur}
                          className={Style.textAreaInputBottom}
                          ref={bottomTextareaRef}
                        />
                      </div>
                      <button
                        className={Style.submitButton}
                        type="submit"
                        buttonStyle="primary"
                        disabled={isSubmitting}
                      >
                        Send
                      </button>
                    </Form>
                  )}
                </Formik>
              )}
              {invitationSentByMail && (
                <div className={Style.invitationSentLabel}>
                  Your email was sent!
                </div>
              )}
            </div>
            <div className={Style.columnTwo}>
              <h3>Copy link</h3>
              <p>Copy the link below and share directly with a colleague. </p>
              <div className={Style.copyLinkWrapper}>
                <textarea
                  ref={collaborateLinkCopyRef}
                  className={Style.textareaCopyLink}
                  value={collaborationLink} // Set the value of the textarea
                  disabled
                ></textarea>
                <Tippy
                  content={justClicked === false ? "Click to copy" : "Copied!"}
                  placement="bottom"
                  hideOnClick={false}
                  className={
                    justClicked === false
                      ? Style.tooltipNormal
                      : Style.tooltipAfterClick
                  }
                >
                  <div
                    className={Style.clickToCopyText}
                    onClick={() => copieToClipboard()}
                  >
                    <div className={Style.CopyButton}>
                      Copy
                      <Icon
                        image={"copy"}
                        size={14}
                        className={Style.iconStyle}
                      />
                    </div>
                    {props.children}
                  </div>
                </Tippy>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

const adjustTextareaHeight = (textareaRef) => {
  const textarea = textareaRef.current;
  textarea.style.height = "auto"; // Reset the height to auto to allow it to shrink and grow
  textarea.style.height = `${Math.max(textarea.scrollHeight, 40)}px`; // Set the height to the scrollHeight or minimum height
};
