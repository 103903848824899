import React, { useContext, useEffect } from "react";
import Style from "./jobBasicInfoPreview.module.scss";
import { EditorContext, Icon, Loader } from "components/lib";

export function JobBasicInfoPreview(props) {
  const jobBasicInfo = useContext(EditorContext).jobBasicInfo.values;

  if (jobBasicInfo) {
    return (
      <div className={Style.jobBasicInfoPreview}>
        <h3>{jobBasicInfo.job_public_name}</h3>
        <br />
        <div className={Style.jobIndicators}>
          {jobBasicInfo.employment_type && jobBasicInfo.employment_type_visibility  && (
            <div className={Style.singleItem}>
              <Icon image="briefcase" size="0.9em"></Icon>
              {employmentTypeLabel(jobBasicInfo.employment_type)}
            </div>
          )}
          {jobBasicInfo.location && jobBasicInfo.location_visibility  && (
            <div className={Style.singleItem}>
              <Icon image="map-pin" size="0.9em"></Icon>
              {jobBasicInfo.location}
            </div>
          )}
          {jobBasicInfo.workplace_type && jobBasicInfo.workplace_type_visibility  && (
            <div className={Style.singleItem}>
              <Icon image="home" size="0.9em"></Icon>
              {workplaceTypeLabel(jobBasicInfo.workplace_type)}
            </div>
          )}

          {jobBasicInfo.salary && jobBasicInfo.salary_visibility && (
            <div className={Style.singleItem}>
              <Icon image="award" size="0.9em"></Icon>
              {jobBasicInfo.salary}
            </div>
          )}
          {jobBasicInfo.company && jobBasicInfo.company_visibility && (
            <div className={Style.singleItem}>
              <Icon image="flag" size="0.9em"></Icon>
              {jobBasicInfo.company}
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return <Loader />;
  }
}

const  employmentTypeLabel = (key) => {
  switch (key) {
    case "full_time":
      return "Full-time";

    case "part_time":
      return "Part-time";

    case "contract":
      return "Contract";

    case "temporary":
      return "Temporary";

    case "volunteer":
      return "Volunteer";

    case "internship":
      return "Internship";

    default:
      break;
  }
};


const workplaceTypeLabel = (key) => {
  switch (key) {
    case "on_site":
      return "On-site";

    case "hybrid":
      return "Hybrid";

    case "remote":
      return "Remote";

    default:
      break;
  }
};
