/***
 *
 *   SIGN IN
 *   Sign in form for all account types (including master).
 *
 **********/

import React, { useContext } from "react";
import {
  AuthContext,
  Form,
  Link,
  Logo,
} from "components/lib";
import Style from "./auth.module.scss";

export function Signin(props) {
  const context = useContext(AuthContext);

  return (
      <div className={Style.mainCard}>
        <div className={Style.contentWrapper}>
          <div className={Style.logoWrapper}><Logo version="full_logo_color" className={Style.logo}></Logo></div>
          <h2>Sign In to Jobchef</h2>
          <Form
            data={{
              email: {
                label: "Email",
                type: "email",
                required: true,
              },
              password: {
                label: "Password",
                type: "password",
                required: true,
              },
              forgotpassword: {
                type: "link",
                url: "/forgotpassword",
                text: "Forgot your password?",
              },
            }}
            url="/api/auth"
            method="POST"
            buttonText="Sign In"
            callback={context.signin}
          />

          <span className={Style.forgotPassword}>
            Don't have an account? <Link url="/signup" text="Sign Up" />
          </span>
        </div>
      </div>
  );
}
