import React, { useContext, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import {
  HelperBlockCardPreview,
  HelperBlockContextCard,
  HelperDisplayCheckerSetterHelper,
} from "./_index.js";
import { Loader, EditorContext, MyTextEditor, MyButton } from "../../lib";
import green_soda from "./images/food/green_soda.svg";
import Style from "./BlockAll.module.scss";
import parse from "html-react-parser";

const blockColor = "#cbe5c4";
const blockTextColor = "#8CC57D";
const blockName = "Job summary";

const block_description_data_generator = (content) => {
  const new_uuid = uuid();

  let default_content = {};

  if (content) {
    default_content[new_uuid + "title"] = "<h2>" + content.section_title + "</h2>";
    default_content[new_uuid + "body"] = content.summary
  }

  return {
    key: new_uuid,
    data: {
      version: 1,
      id: new_uuid, //block
      type: "description",
      content: default_content,
    },
  };
};

const BlockDescriptionPreview = (props) => {
  // Content items
  const selectedBlock = useContext(EditorContext).selectedBlock;
  const blockDictionary = useContext(EditorContext).blockDictionary;
  const [isContent, setIsContent] = useState(false);
  const currentStep = useContext(EditorContext).currentStep;

  //let contentEmptyOrUndefinedChecker =
  //  _contentValueHelper("title") === "" && _contentValueHelper("body") === "";

  const _contentValueHelper = (contentKey) => {
    if (
      blockDictionary[props.blockId].content[props.blockId + contentKey] ===
        "" ||
      blockDictionary[props.blockId].content[props.blockId + contentKey] ===
        undefined
    ) {
      return "";
    } else {
      return blockDictionary[props.blockId].content[props.blockId + contentKey];
    }
  };

  const contentValueArray = ["title", "body"];
  const contentListArray = [];

  useEffect(() => {
    HelperDisplayCheckerSetterHelper(
      contentValueArray,
      contentListArray,
      blockDictionary,
      props.blockId,
      setIsContent
    );
    return () => {
      HelperDisplayCheckerSetterHelper(
        contentValueArray,
        contentListArray,
        blockDictionary,
        props.blockId,
        setIsContent
      );
    };
  }, []);

  useEffect(() => {
    HelperDisplayCheckerSetterHelper(
      contentValueArray,
      contentListArray,
      blockDictionary,
      props.blockId,
      setIsContent
    );
  }, [selectedBlock]);

  if (props.publicRender && !isContent) {
    return null;
  }

  return (
    <>
      {blockDictionary[props.blockId] === null && <Loader />}
      {blockDictionary[props.blockId] !== null &&
        !isContent &&
        !props.publicRender && (
          <div
            className={Style.emptyBlock}
            style={{ borderColor: blockColor, color: blockColor }}
          >
            <h2>{blockName}</h2>
            <p>Click to add content</p>
          </div>
        )}
      {blockDictionary[props.blockId] !== null &&
        isContent &&
        currentStep !== "block_editor" && (
          <div className={"jc_block"}>
            <div className="style_jc description_jc">
              {parse(_contentValueHelper("title"))}
              {parse(_contentValueHelper("body"))}
            </div>
          </div>
        )}
      {blockDictionary[props.blockId] !== null &&
        isContent &&
        currentStep === "block_editor" && (
          <>
            {parse(_contentValueHelper("title"))}
            {parse(_contentValueHelper("body"))}
          </>
        )}
    </>
  );
};

const BlockDescriptionCardPreview = (props) => {
  return (
    <HelperBlockCardPreview
      image={green_soda}
      header={blockName}
      short_description="This is where it all starts. A short and sweet description to pique applicants' interest."
      block_type="description"
      color="#f5f5f5"
      color_light="#f5f5f5"
    />
  );
};

const BlockDescriptionEditor = (props) => {
  const setSelectedBlock = useContext(EditorContext).setSelectedBlock;

  useEffect(() => {
    // Cleanup function to be executed when component unmounts
    return () => {
      // console.log("component BlockDescriptionEditor unmounted");
    };
  }, []);

  return (
    <>
      <div className={Style.editorCloseButtonWrapper}>
        <h3
          className={Style.sectionTitle}
          style={{ color: blockTextColor, textTransform: "uppercase" }}
        >
          {blockName}
        </h3>
        <MyButton
          buttonStyle="secondary"
          size="small"
          onClick={() => {
            setSelectedBlock(null);
          }}
        >
          Close
        </MyButton>
      </div>
      <br />
      <p className={Style.sectionSubtitle}>Section title :</p>
      <MyTextEditor
        blockId={props.blockId}
        contentKey={props.blockId + "title"}
        placeholderText={"Type a summary section title..."}
        contentBlockType="header-two"
        tabIndex={1}
        quickAddOptions={[
          "Your mission",
          "Job summary",
          "Your role in a few words",
        ]}
      />
      <p className={Style.sectionSubtitle}>Short summary of the job :</p>
      <MyTextEditor
        blockId={props.blockId}
        contentKey={props.blockId + "body"}
        placeholderText={"Type the summary for this job..."}
        tabIndex={2}
        ctaViewSampleText
      />
    </>
  );
};

const BlockDescriptionContext = (props) => {
  // Content items
  //const blockDictionary = useContext(EditorContext).blockDictionary;

  return (
    <HelperBlockContextCard
      header={"SUMMARY"}
      blockType={"description"}
      color="#C9E3C2"
      image={green_soda}
      iconStyle={Style.green}
    />
  );
};

export {
  block_description_data_generator,
  BlockDescriptionPreview,
  BlockDescriptionCardPreview,
  BlockDescriptionEditor,
  BlockDescriptionContext,
};
