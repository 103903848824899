import React from 'react';
import { MyButton } from "components/lib";
import Style from './jobVersionExpiryModal.module.scss';
import Modal from "@material-ui/core/Modal";

function JobVersionExpiryModal(props) {
  // getModalStyle is not a pure function, we roll the style only on the first render

  return (
    <div className={Style.modalWrapper}>
      <Modal
        open={props.showJobVersionExpiryModal}
      >
        <div className={Style.modalMain}>
          <h2>It seems this job is opened elsewhere</h2>
          <p>
            Another person is currently updating the job or this job was simply opened on another tab or browser.{" "}
            <span role="img" aria-label="Unsure">
              🕵️
            </span>
          </p>
          <p>
            <b>Simply refresh to page</b> if you wish to resume editing the job here.
          </p>


          <MyButton
            onClick={() => {
              window.location.reload();
            }}
            size="medium"
            buttonStyle="primary"
            icon="refresh-ccw"
          >
            Reload page
          </MyButton>
        </div>
      </Modal>
    </div>
  );
}

export { JobVersionExpiryModal };