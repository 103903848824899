/***
 *
 *   FOOTER (homepage)
 *   Static homepage footer
 *
 **********/

import React from "react";
import { Animate, Row, Content, Link, Logo } from "components/lib";
import Style from "./footer.module.scss";

export function Footer(props) {
  return (
    <Animate type="slideup">
      <footer className={Style.footer}>
        <div className={Style.logoWrapper}>
          <Logo className={Style.logoFooter} version="full_logo_black_and_white_inverted" />
        </div>
        <nav>
          <Link url="/" text="Home" />
          <Link url="/pricing" text="Pricing" />
          <Link url="/signin" text="Sign in" />
          <Link url="/signup" text="Sign up" />
          <Link url="/terms" text="Terms" />
          <Link url="/privacy" text="Privacy" />
          <Link url="/contact" text="Contact" />
        </nav>

        <span>{`Copyright © Jobchef ${new Date().getFullYear()}`}</span>
        {/* <address>Gravity Inc. 42 The Milky Way. The Universe</address> */}
      </footer>
    </Animate>
  );
}
