import React, { useContext, useRef, useState, useEffect } from "react";
import {
  MyButton,
  EditorContext,
  HelperBlockIcons,
  Icon,
} from "components/lib";
import Style from "./jobeditorResponsiveHelper.module.scss";
import { Drawer, ScrollArea } from "@mantine/core";
import noBlockSection from "./lib/no_section_selected.svg";

function JobEditorResponsiveHelper(props) {
  const sideElem = props.sideElem
  const setSideElem = props.setSideElem
  const openedDrawer = props.openedDrawer
  const setOpenedDrawer = props.setOpenedDrawer
  

  const blockOrder = useContext(EditorContext).blockOrder;
  const blockDictionary = useContext(EditorContext).blockDictionary;
  const setSelectedBlock = useContext(EditorContext).setSelectedBlock;
  const selectedBlock = useContext(EditorContext).selectedBlock;
  const dragAndDropAreaRef = useContext(EditorContext).dragAndDropAreaRef;
  const viewportRef = useRef();

  useEffect(() => {
    const order_of_selected = blockOrder.indexOf(selectedBlock);
    if (blockOrder.includes(selectedBlock)) {
      scrollTo(order_of_selected);
    }
  }, [selectedBlock]);

  const getToggleSections = () => {
    return (
      <>
        {blockOrder.map((item, index) => (
          <MyButton
            size={"medium"}
            buttonStyle={"ghost"}
            onClick={() => {
              setSelectedBlock(item);
              //console.log(blockDictionary[item]["ref"]);
              //blockDictionary[item]["ref"].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }}
            key={item}
            className={
              selectedBlock !== item
                ? Style.sideBarIcons
                : Style.sideBarIconsSelected
            }
          >
            {<img src={HelperBlockIcons(blockDictionary[item].type)} />}
          </MyButton>
        ))}
        <MyButton
          size={"medium"}
          buttonStyle={"ghost"}
          onClick={() => {
            setSideElem("addSection");
            setOpenedDrawer(true);
          }}
          className={Style.sideBarIcons}
        >
          <Icon image={"plus"} size={"18px"} />
        </MyButton>
      </>
    );
  };

  const scrollTo = (index_of_elem) => {
    // const index_of_elem = 2
    let scroll_height = 0;
    // console.log(dragAndDropAreaRef.current.clientHeight);
    // console.log(dragAndDropAreaRef.current.children);
    for (
      let index = 0;
      index < dragAndDropAreaRef.current.children.length;
      index++
    ) {
      if (index < index_of_elem) {
        scroll_height =
          scroll_height +
          dragAndDropAreaRef.current.children[index].clientHeight +
          20;
      }
    }
    viewportRef.current.scrollTo({ top: scroll_height, behavior: "smooth" });
  };

  return (
    <div className={Style.editorMain}>
      <div className={Style.editorMain__toggleBarLeft}>
        {getToggleSections()}
      </div>
      <div className={Style.editorMain__mainContent}>
        <div className={Style.editorMain__toggleBarTop}>
          {getToggleSections()}
        </div>
        <ScrollArea
          style={{ height: "100%", width: "100%" }}
          offsetScrollbars
          viewportRef={viewportRef}
        >
          {props.mainContent}
        </ScrollArea>
      </div>
      <div className={Style.editorMain__blockInfoAndConfig}>
        <div className={Style.editorContextCard} style={selectedBlock === null ? {boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.00)", border: "0px"} : {}}>
          <ScrollArea
            style={{ height: "100%", width: "100%" }}
            offsetScrollbars
          >
            {selectedBlock && props.blockInfoAndConfig}
            {!selectedBlock && (
              <div className={Style.noBlockSelectedMessage}>
                <img src={noBlockSection} alt="No blocks are selected"/>
                <h3>Select a section to start editing.</h3>
                <p>or add a new one:</p>
                <MyButton
                  onClick={() => {
                    setSideElem("addSection");
                    setOpenedDrawer(true);
                  }}
                  size={"large"}
                >
                  Add new section
                </MyButton>
              </div>
            )}
          </ScrollArea>
        </div>
      </div>
      <div className={Style.editorMain__tips}>{props.tips}</div>
      <div className={Style.editorMain__toggleBarRight}>
        {selectedBlock !== null && (
          <MyButton
            onClick={() => {
              setSideElem("default");
              if (window.innerWidth <= 1280) {
                setOpenedDrawer(true);
              }
            }}
            className={
              Style.toggleBarButton + " " + Style.toggleBarButton__section
            }
            size={"small"}
            buttonStyle={"ghost"}
          >
            <div className={Style.inButtonIcon}>
              {/* {selectedBlock !== null && 
              <img
              //src={HelperBlockIcons(blockDictionary[selectedBlock].type)}
              alt="Section info icon"
              />
            } */}
              Section info
            </div>
          </MyButton>
        )}
        <MyButton
          onClick={() => {
            setSideElem("tips");
            if (window.innerWidth <= 1280) {
              setOpenedDrawer(true);
            }
          }}
          className={Style.toggleBarButton}
          size={"small"}
          buttonStyle={"ghost"}
        >
          <div className={Style.inButtonIcon}>
            <div
              className={Style.iconWrapper}
              style={{
                background: "#73B0F4",
              }}
            >
              <Icon size={"14px"} image={"activity"} color={"white"}></Icon>
            </div>
            Insights
          </div>
        </MyButton>
      </div>
      <Drawer
        opened={openedDrawer}
        onClose={() => setOpenedDrawer(false)}
        size={window.innerWidth <= 780 ? "80%" : "40%"}
        position="right"
        padding="0"
        hideCloseButton="true"
      >
        <div
          className={Style.drawerHeader}
          style={{
            background: "#73B0F4",
          }}
        >
          <div>
            {/* {sideElem === "default" && (
              <img
                src={selectedBlock !== null && HelperBlockIcons(blockDictionary[selectedBlock].type)}
                alt="Section info icon"
                className={Style.drawerHeaderIcon}
              />
            )} */}
            {sideElem === "tips" && <Icon image={"activity"} color={"white"} />}
            {sideElem === "addSection" && (
              <Icon image={"plus-square"} color={"white"} />
            )}

            <h4>
              {sideElem === "default" && "Block information"}
              {sideElem === "tips" && "Insights"}
              {sideElem === "addSection" && "Add section"}
            </h4>
          </div>
          <MyButton onClick={() => setOpenedDrawer(false)} buttonStyle="ghost">
            <Icon color={"white"} image={"x"} size={"18px"} />
          </MyButton>
        </div>
        {sideElem === "default" && props.blockInfoAndConfig}
        {sideElem === "tips" && props.tips}
        {sideElem === "addSection" && props.addSection}
      </Drawer>
    </div>
  );
}

export default JobEditorResponsiveHelper;
