import React, { useEffect, useRef, useContext, useState } from "react";
import { Icon, EditorContext, ThemePicker, MyButton } from "components/lib";
import Style from "./themeConfigCard.module.scss";
import { Field, Form, Formik, useFormikContext } from "formik";
import { TextInputs } from "./lib/textInputs.js";
import { PdfOptions } from "./lib/pdfOptions.js";
import { CoverImagePicker } from "./lib/coverImagePicker.js";
import { BackgroundColorInput } from "./backgroundColorInput.js";
import { ColorPicker } from "../themeConfigCard/colorPicker.js";

//import { ReactComponent as header_image } from "./img/undraw_content_team_3epn.svg";
//import no_banner_option from "./img/no_banner_option.svg";
import { ReactComponent as NoBannerOption } from "./img/noCover.svg";
//import WithBannerOption from "./img/with_banner_option.svg";
import { ReactComponent as WithBannerOption } from "./img/bannerCover.svg";
import { ScrollArea } from "@mantine/core";
import _, { assign } from "lodash";

import { delete_helper_cover_image } from "../../lib/imageUploadHelper.js";
import { TextOnImageForm } from "./textOnImageForm.js";

const MyOnChangeComponent = (props) => {
  const { values } = useFormikContext();
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    props.callback();
  }, [values]);

  return null;
};

export function ThemeConfigCard(props) {
  let theme = useContext(EditorContext).theme;
  let setTheme = useContext(EditorContext).setTheme;
  const ref = useRef(null);
  const refBannerOptions = useRef(null);
  const [cropMode, setCropMode] = useState(false);
  const [secondaryView, setSecondaryView] = useState(null);
  const [banner, setBanner] = useState(theme.banner.isActive);
  const [bannerStyle, setBannerStyle] = useState("");
  const [curretTextStyle, setCurretTextStyle] = useState("h1");
  const user = JSON.parse(localStorage.getItem("user"));
  const token = "Bearer " + (user ? user.token : "");

  // Setting default values
  useEffect(() => {
    //Set banner style to plain color if none selected
    if (theme.banner.bannerStyle === "image") {
      setBannerStyle("image");
    } else {
      setBannerStyle("plain_color");
    }

    // Add default color to background of banner if none.
  }, []);

  useEffect(() => {
    let tempTheme = _.cloneDeep(theme);

    tempTheme.banner = _.assign(tempTheme.banner, {
      bannerStyle: bannerStyle,
    });

    setTheme(tempTheme); //TODO : fix this causing save on visit of the theme page
  }, [bannerStyle]);

  useEffect(() => {
    let tempTheme = _.cloneDeep(theme);

    if (banner === true) {
      tempTheme.banner = _.assign(tempTheme.banner, {
        isActive: true,
      });
    }

    if (banner === false) {
      tempTheme.banner = _.assign(tempTheme.banner, {
        isActive: false,
      });
    }

    setTheme(tempTheme); //TODO : fix this causing save on visit of the theme page
  }, [banner]);

  const styleEditHelper = (fieldToUpdate, newValue) => {
    if (fieldToUpdate) {
      const tempNewValueObject = {};
      tempNewValueObject[fieldToUpdate] = newValue;
      const newtheme = Object.assign({}, theme, ref?.current?.values);
      setTheme(newtheme);
      // console.log(newtheme);
      return false;
    }
    const newtheme = Object.assign({}, theme, ref?.current?.values);
    setTheme(newtheme);
  };

  const bannerbackgroundColorEditHelper = (x) => {
    let tempTheme = _.cloneDeep(theme);
    tempTheme.banner = _.assign({}, tempTheme.banner, {
      bannerbackgroundColor:
        refBannerOptions.current.values.banner.bannerbackgroundColor,
    });

    // console.log(refBannerOptions.current.values);
    // console.log(refBannerOptions.current.values.banner.bannerbackgroundColor);
    setTheme(tempTheme);
  };

  return (
    <div className={Style.themeConfigCard}>
      <div className={Style.siderbarHeader}>
        <div className={Style.h1Wrapper}>
          <h2>Style your ad</h2>
        </div>
      </div>
      {secondaryView !== null && (
        <div className={Style.backButtonWrapper}>
          <MyButton
            //className={Style.backBTN}
            onClick={() => {
              if (cropMode === true) {
                setCropMode(false);
              }
              setSecondaryView(null);
            }}
            icon={"arrow-left"}
            size={"small"}
            buttonStyle={"secondary"}
          >
            Back
          </MyButton>
          {secondaryView === "editTheme" && <span>Edit theme below</span>}
        </div>
      )}
      <ScrollArea offsetScrollbars>
        <div
          className={
            secondaryView === null
              ? Style.mainViewWrapper
              : Style.mainViewWrapperWithBackButton
          }
        >
          {(props.previewMode === "desktop" ||
            props.previewMode === "mobile") &&
            secondaryView === null && (
              <>
                <div className={Style.defaultViewWrapper}>
                  <p className={Style.itemTitle}>
                    <Icon
                      image="layout"
                      size="1.2em"
                      className={Style.sectionIcon}
                    />
                    Header section
                  </p>
                  <p className={Style.headerSubTitle}>
                    The banner will only appear on your job's landing page. It
                    won't when you export the HTML only.
                  </p>
                  <div className={Style.bannerSelectOptions}>
                    <button
                      className={
                        banner ? Style.BannerOptionSelected : Style.BannerOption
                      }
                      onClick={() => setBanner(true)}
                    >
                      <p>With banner</p>
                      <WithBannerOption />
                    </button>
                    <button
                      className={
                        !banner
                          ? Style.BannerOptionSelected
                          : Style.BannerOption
                      }
                      onClick={() => setBanner(false)}
                    >
                      <p>No banner</p>
                      <NoBannerOption />
                    </button>
                  </div>
                  {banner && (
                    <>
                      <div
                        className={Style.arrowUpIcon}
                        style={{
                          transform: "translate(65px, 6px)",
                          width: "30px",
                        }}
                      >
                        <svg
                          width="30"
                          height="15"
                          viewBox="0 0 40 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20 0L39.0526 19.5H0.947441L20 0Z"
                            fill="#6363AC"
                          />
                        </svg>
                      </div>
                      <div className={Style.bannerOptionsWrapper}>
                        <>
                          <div className={Style.helperBannerTypeSwitcher}>
                            <button
                              onClick={() => {
                                if (bannerStyle !== "plain_color") {
                                  setBannerStyle("plain_color");
                                }
                              }}
                              className={
                                // blockContextSwitcher === "blockInfo"
                                bannerStyle === "plain_color"
                                  ? Style.activeButton
                                  : ""
                              }
                            >
                              Plain color
                              <Icon image="edit" size="12px" />
                            </button>
                            <button
                              onClick={() => {
                                if (bannerStyle !== "image") {
                                  setBannerStyle("image");
                                }
                              }}
                              className={
                                bannerStyle === "image"
                                  ? Style.activeButton
                                  : ""
                              }
                            >
                              Image
                              <Icon image="image" size="12px" />
                            </button>
                          </div>
                          {theme.banner.bannerStyle === "plain_color" && (
                            <div style={{ width: "100%" }}>
                              <h5>Select a background color:</h5>
                              <Formik
                                initialValues={theme}
                                innerRef={refBannerOptions}
                              >
                                <div style={{ marginLeft: "16px" }}>
                                  <MyOnChangeComponent
                                    callback={bannerbackgroundColorEditHelper}
                                  />
                                  <Form id="banner-style">
                                    <div
                                      className={Style.inputSelect}
                                      style={{
                                        width: "calc(100% / 2 - 6.4px)",
                                      }}
                                    >
                                      <Field
                                        name={"banner.bannerbackgroundColor"}
                                      >
                                        {theme &&
                                          ((props) => {
                                            return (
                                              <ColorPicker
                                                name={
                                                  "banner.bannerbackgroundColor"
                                                }
                                                // startColor={
                                                //   theme["banner"]
                                                //     .bannerbackgroundColor
                                                // }
                                                startColor={(() => {
                                                  if (
                                                    !theme ||
                                                    !theme["banner"]
                                                  ) {
                                                    return "";
                                                  } else if (
                                                    theme["banner"]
                                                      .bannerbackgroundColor
                                                  ) {
                                                    return theme["banner"]
                                                      .bannerbackgroundColor;
                                                  } else {
                                                    return "#333333";
                                                  }
                                                })()}
                                                {...props}
                                              />
                                            );
                                          })}
                                      </Field>
                                    </div>
                                  </Form>
                                </div>
                              </Formik>
                            </div>
                          )}
                          {(theme.coverImage["landingCover"] === undefined ||
                            theme.coverImage["landingCover"].file === null) &&
                            theme.banner.bannerStyle === "image" && (
                              <>
                                <div className={Style.imageSelector}>
                                  <h5>Select an image for your cover</h5>
                                  <div className={Style.ImageAndButtons}>
                                    <MyButton
                                      size="small"
                                      buttonStyle={"primary"}
                                      onClick={() =>
                                        setSecondaryView("editCoverImage")
                                      }
                                    >
                                      Select image
                                    </MyButton>
                                  </div>
                                </div>
                                <div className={Style.textOnImageStyling}></div>
                              </>
                            )}
                          {theme.coverImage["landingCover"] &&
                            theme.coverImage["landingCover"].file &&
                            !theme.coverImage["landingCover"].cropped_file && (
                              <>
                                <div className={Style.imageSelector}>
                                  <h5>Your cover image</h5>
                                  <div className={Style.ImageAndButtons}>
                                    <img
                                      src={
                                        theme.coverImage["landingCover"].file
                                          .public_url
                                      }
                                      alt="cover of the pdf"
                                    />
                                    <div className={Style.landingCoverButtons}>
                                      <MyButton
                                        size="small"
                                        buttonStyle={"primary"}
                                        onClick={() =>
                                          setSecondaryView("editCoverImage")
                                        }
                                        className={Style.addButton}
                                      >
                                        Replace image
                                      </MyButton>
                                      <MyButton
                                        size="small"
                                        buttonStyle={"primary"}
                                        onClick={() => {
                                          setCropMode(true);
                                          setSecondaryView("editCoverImage");
                                        }}
                                        className={Style.cropButton}
                                      >
                                        Crop image
                                      </MyButton>
                                      <MyButton
                                        size="small"
                                        buttonStyle={"secondary"}
                                        onClick={() => {
                                          setCropMode(false);
                                          delete_helper_cover_image(
                                            theme,
                                            setTheme,
                                            token,
                                            "landingCover"
                                          );
                                        }}
                                        className={Style.removeButton}
                                      >
                                        Remove image
                                        <Icon image={"trash-2"} size={12} />
                                      </MyButton>
                                    </div>
                                  </div>
                                  <div className={Style.headerSubTitle}>
                                    <p>
                                      This image doesn't have to correct size.
                                      Select <b>Crop image</b> or change the{" "}
                                      <b>type of cover</b> to generate a new
                                      PDF.
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                          {theme.banner.bannerStyle === "image" &&
                            theme.coverImage["landingCover"] &&
                            theme.coverImage["landingCover"].cropped_file && (
                              <div className={Style.imageSelector}>
                                <h5>Your cover image</h5>
                                <div className={Style.ImageAndButtons}>
                                  <img
                                    src={
                                      theme.coverImage["landingCover"]
                                        .cropped_file.public_url
                                        ? theme.coverImage["landingCover"]
                                            .cropped_file.public_url
                                        : theme.coverImage["landingCover"].file
                                            .public_url
                                    }
                                    alt="cover of the pdf"
                                  />
                                  <div className={Style.landingCoverButtons}>
                                    <MyButton
                                      size="small"
                                      buttonStyle={"primary"}
                                      onClick={() =>
                                        setSecondaryView("editCoverImage")
                                      }
                                      className={Style.addButton}
                                    >
                                      Replace image
                                    </MyButton>
                                    <MyButton
                                      size="small"
                                      buttonStyle={"primary"}
                                      onClick={() => {
                                        setCropMode(true);
                                        setSecondaryView("editCoverImage");
                                      }}
                                      className={Style.cropButton}
                                    >
                                      Edit crop
                                    </MyButton>
                                    <MyButton
                                      size="small"
                                      buttonStyle={"secondary"}
                                      onClick={() => {
                                        setCropMode(false);
                                        delete_helper_cover_image(
                                          theme,
                                          setTheme,
                                          token,
                                          "landingCover"
                                        );
                                      }}
                                      className={Style.removeButton}
                                    >
                                      Remove{" "}
                                      <Icon image={"trash-2"} size={12} />
                                    </MyButton>
                                  </div>
                                </div>
                                <div className={Style.textOnImageStyling}></div>
                              </div>
                            )}
                          <TextOnImageForm />
                        </>
                      </div>
                    </>
                  )}
                  <p className={Style.itemTitle} style={{ marginTop: "34px" }}>
                    <Icon
                      image="type"
                      size="1.2em"
                      className={Style.sectionIcon}
                    />
                    Text and colors style
                  </p>
                  <p className={Style.headerSubTitle}>
                    The bellow theme is currently applied to style your job posting.
                    You can select a new theme or modify the current one.
                  </p>

                  <div
                    className={Style.themePicker}
                    style={{ backgroundColor: theme.background.color }} //HERE
                  >
                    <div>
                      <h1
                        style={{
                          color: theme.h1.color,
                          fontFamily: theme.h1.font,
                          fontSize: "1.2em",
                        }}
                      >
                        The quick brown fox
                      </h1>
                      <h3
                        style={{
                          color: theme.h2.color,
                          fontFamily: theme.h2.font,
                          fontSize: "0.8em",
                          marginBottom: "0px",
                        }}
                      >
                        Jumps over the lazy dog
                      </h3>
                      <p
                        style={{
                          color: theme.body.color,
                          fontFamily: theme.body.font,
                          fontSize: "0.6em",
                        }}
                      >
                        The quick brown fox jumps over the lazy dog
                      </p>
                    </div>
                  </div>
                  <div className={Style.buttonWrapper}>
                    <MyButton
                      onClick={() => {
                        setSecondaryView("selectTheme");
                      }}
                      buttonStyle={"primary"}
                    >
                      Select new font theme
                    </MyButton>
                    <MyButton
                      onClick={() => {
                        setSecondaryView("editTheme");
                      }}
                      buttonStyle={"primary"}
                    >
                      Edit theme
                    </MyButton>
                  </div>
                </div>
              </>
            )}
          {props.previewMode === "pdf" && secondaryView === null && (
            <>
              <div className={Style.defaultViewWrapper}>
                {/* <p className={Style.itemTitle}>
                  <Icon
                    image="file"
                    size="1.2em"
                    className={Style.sectionIcon}
                  />
                  PDF Cover
                </p>
                <p className={Style.headerSubTitle}>
                  Choose your cover setting below.
                </p>
                <PdfOptions
                  setSecondaryView={setSecondaryView}
                  coverPage={props.coverPage}
                  setCoverPage={props.setCoverPage}
                  setCropMode={setCropMode}
                /> */}
                <p className={Style.itemTitle} style={{ marginTop: "00px" }}>
                  <Icon
                    image="type"
                    size="1.2em"
                    className={Style.sectionIcon}
                  />
                  Text and colors style
                </p>
                <p className={Style.headerSubTitle}>
                  The bellow theme is currently applied to style your job posting.
                  You can select a new theme or modify the current one.
                </p>

                <div
                  className={Style.themePicker}
                  style={{ backgroundColor: theme.background.color }} //HERE
                >
                  <div>
                    <h1
                      style={{
                        color: theme.h1.color,
                        fontFamily: theme.h1.font,
                        fontSize: "1.2em",
                      }}
                    >
                      The quick brown fox
                    </h1>
                    <h3
                      style={{
                        color: theme.h2.color,
                        fontFamily: theme.h2.font,
                        fontSize: "0.8em",
                        marginBottom: "0px",
                      }}
                    >
                      Jumps over the lazy dog
                    </h3>
                    <p
                      style={{
                        color: theme.body.color,
                        fontFamily: theme.body.font,
                        fontSize: "0.6em",
                      }}
                    >
                      The quick brown fox jumps over the lazy dog
                    </p>
                  </div>
                </div>
                <div className={Style.buttonWrapper}>
                  <MyButton
                    onClick={() => {
                      setSecondaryView("selectTheme");
                    }}
                    buttonStyle={"primary"}
                  >
                    Select new font theme
                  </MyButton>
                  <MyButton
                    onClick={() => {
                      setSecondaryView("editTheme");
                    }}
                    buttonStyle={"primary"}
                  >
                    Edit theme
                  </MyButton>
                </div>
              </div>
            </>
          )}
          {secondaryView === "selectTheme" && (
            <ThemePicker
              themeList={props.themeList}
              setSecondaryView={() => setSecondaryView(null)}
            />
          )}

          {secondaryView === "editTheme" && (
            <>
              <div className={Style.themeConfigCard}>
                <Formik initialValues={theme} innerRef={ref}>
                  <>
                    <MyOnChangeComponent callback={styleEditHelper} />
                    <Form id="style_form">
                      <div className={Style.formTitle}>
                        <Icon color="#ffffff" image="type" />
                        <h3>Text styles</h3>
                      </div>

                      <>
                        <div className={Style.textStyleSelector}>
                          <MyButton
                            onClick={() => {
                              setCurretTextStyle("h1");
                            }}
                            className={
                              curretTextStyle === "h1"
                                ? Style.buttonStateActive
                                : ""
                            }
                          >
                            H1
                          </MyButton>
                          <MyButton
                            onClick={() => {
                              setCurretTextStyle("h2");
                            }}
                            className={
                              curretTextStyle === "h2"
                                ? Style.buttonStateActive
                                : ""
                            }
                          >
                            H2
                          </MyButton>
                          <MyButton
                            onClick={() => {
                              setCurretTextStyle("h3");
                            }}
                            className={
                              curretTextStyle === "h3"
                                ? Style.buttonStateActive
                                : ""
                            }
                          >
                            H3
                          </MyButton>
                          <MyButton
                            onClick={() => {
                              setCurretTextStyle("body");
                            }}
                            className={
                              curretTextStyle === "body"
                                ? Style.buttonStateActive
                                : ""
                            }
                          >
                            Body
                          </MyButton>
                        </div>
                        {curretTextStyle === "h1" && (
                          <TextInputs
                            inputDescription={"H1"}
                            inputID={"h1"}
                            theme={theme}
                            hideColorPicker={true}
                          />
                        )}
                        {curretTextStyle === "h2" && (
                          <TextInputs
                            inputDescription={"H2"}
                            inputID={"h2"}
                            theme={theme}
                          />
                        )}
                        {curretTextStyle === "h3" && (
                          <TextInputs
                            inputDescription={"H3"}
                            inputID={"h3"}
                            theme={theme}
                          />
                        )}
                        {curretTextStyle === "body" && (
                          <TextInputs
                            inputDescription={"Body"}
                            inputID={"body"}
                            theme={theme}
                          />
                        )}
                      </>

                      <BackgroundColorInput theme={theme} />
                    </Form>
                  </>
                </Formik>
                <br />
              </div>
            </>
          )}

          {secondaryView === "editCoverImage" && (
            <CoverImagePicker
              coverPage={
                props.previewMode === "desktop" ||
                props.previewMode === "mobile"
                  ? "landingCover"
                  : props.coverPage
              }
              setSecondaryView={setSecondaryView}
              cropMode={cropMode}
              setCropMode={setCropMode}
            />
          )}
        </div>
      </ScrollArea>
    </div>
  );
}
