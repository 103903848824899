import _ from "lodash";

/// theme.coverImage.smallImage||fullpage||

///// CREATE
const Settings = require("settings.json");

const create_new_file_object = (file) => {
  // console.log(file);
  const new_file = {
    id: file.data.id,
    mimetype: file.data.mimetype,
    filename: file.data.filename,
    originalname: file.data.originalname,
    path: file.data.path,
    public_url: Settings[process.env.NODE_ENV].server_url  + "/" + file.data.path,
  };

  return new_file;
};

/////////////////////////////////////////////////////////
//////////////// NEW IMAGE SETTER HELPER ////////////////
/////////////////////////////////////////////////////////

///// Block image

const image_import_helper_block_image = (
  file,
  blockId,
  blockDictionary,
  setBlockDictionary
) => {
  file = JSON.parse(file);

  const copyLocation = _.cloneDeep(blockDictionary);

  Object.assign(copyLocation[blockId].content, {
    file: create_new_file_object(file),
  });

  setBlockDictionary(copyLocation);
};

///// Block image crop

const image_crop_import_helper_block_image = (
  file,
  crop_info,
  setCropMode,
  blockId,
  blockDictionary,
  setBlockDictionary
) => {
  const new_file = JSON.parse(file);

  const copyLocation = _.cloneDeep(blockDictionary);

  Object.assign(copyLocation[blockId].content, {
    cropped_file: create_new_file_object(new_file),
    crop_information: crop_info,
  });

  setBlockDictionary(copyLocation);
  setCropMode(null);
};

////////////////////////////////////////////////////////////

///// Cover image

const image_import_helper_cover_image = (
  file,
  theme,
  setTheme,
  setSelectedImage,
  setCropMode,
  imageType
) => {
  file = JSON.parse(file);

  const copyTheme = _.cloneDeep(theme);

  // console.log(imageType);

  if (!copyTheme.coverImage[imageType]) {
    copyTheme.coverImage[imageType] = {};
  }

  Object.assign(copyTheme.coverImage[imageType], {
    file: create_new_file_object(file),
  });

  setTheme(copyTheme);
  setSelectedImage(copyTheme.coverImage[imageType].file.public_url);

  setCropMode(true);
};

// const image_import_helper_company_logo = (
//   file,
// ) => {
//   file = JSON.parse(file);


//   if (!copyTheme.coverImage[imageType]) {
//     copyTheme.coverImage[imageType] = {};
//   }

//   Object.assign(copyTheme.coverImage[imageType], {
//     file: create_new_file_object(file),
//   });


// };

///// Cover image crop

const image_crop_import_helper_cover_image = (
  file,
  crop_info,
  setCropMode,
  theme,
  setTheme,
  setSecondaryView,
  imageType
) => {
  const new_file = JSON.parse(file);

  const copyTheme = _.cloneDeep(theme);

  if (!copyTheme.coverImage[imageType]) {
    copyTheme.coverImage[imageType] = {};
  }

  Object.assign(copyTheme.coverImage[imageType], {
    cropped_file: create_new_file_object(new_file),
    crop_information: crop_info,
  });

  setTheme(copyTheme);
  setCropMode(false);
  setSecondaryView(null);
};

/////////////////////////////////////////////////////////
////////////////// DELETE IMAGE HELPER //////////////////
/////////////////////////////////////////////////////////

///// Del block image (+if crop)

const delete_helper_block_image = (
  blockId,
  blockDictionary,
  setBlockDictionary,
  setPreviewCrop,
  token
) => {
  const copyLocation = _.cloneDeep(blockDictionary);

  // Check if crop of image exists and delete

  if (blockDictionary[blockId].content["cropped_file"] !== null) {
    // console.log("crop found, deleting ...");
    const cropped_image_id =
      blockDictionary[blockId].content["cropped_file"].id;
    // console.log("deleting cropped image with ID... ", cropped_image_id);
    server_request_to_delete_image(cropped_image_id, token);

    copyLocation[blockId].content["cropped_file"] = null;
    copyLocation[blockId].content["crop_information"] = null;
  }

  const original_image_id = blockDictionary[blockId].content["file"].id;
  // console.log("deleting image with ID ... ", original_image_id);

  server_request_to_delete_image(original_image_id, token);

  copyLocation[blockId].content["file"] = null;

  setBlockDictionary(copyLocation);
  setPreviewCrop(null);
};

///// Del block image crop

const delete_helper_block_image_crop = (
  blockId,
  blockDictionary,
  setBlockDictionary,
  setPreviewCrop,
  token
) => {
  const copyLocation = _.cloneDeep(blockDictionary);

  const cropped_image_id = blockDictionary[blockId].content["cropped_file"].id;
  // console.log("deleting cropped image with ID... ", cropped_image_id);
  server_request_to_delete_image(cropped_image_id, token);

  copyLocation[blockId].content["cropped_file"] = null;
  copyLocation[blockId].content["crop_information"] = null;

  setBlockDictionary(copyLocation);
  setPreviewCrop(null);
};

///// Del cover image (+if crop)

const delete_helper_cover_image = (theme, setTheme, token, imageType) => {
  const copyTheme = _.cloneDeep(theme);

  // console.log(copyTheme);
  if (copyTheme.coverImage[imageType] === null) {
    return;
  }

  if (copyTheme.coverImage[imageType]["cropped_file"]) {
    // console.log("crop found, deleting ...");
    const cropped_image_id = copyTheme.coverImage[imageType]["cropped_file"].id;
    // console.log("deleting cropped image with ID... ", cropped_image_id);
    server_request_to_delete_image(cropped_image_id, token);

  }

  const original_image_id = copyTheme.coverImage[imageType]["file"].id;
  // console.log("deleting image with ID ... ", original_image_id);

  server_request_to_delete_image(original_image_id, token);

  delete copyTheme.coverImage[imageType];

  setTheme(copyTheme);
};

///// Del cover image crop

// const delete_helper_cover_image_crop = (theme, setTheme, token) => {
//   const copyTheme = _.cloneDeep(theme);

//   const cropped_image_id = copyTheme.coverImage["cropped_file"].id;
//   console.log("deleting cropped image with ID... ", cropped_image_id);
//   server_request_to_delete_image(cropped_image_id, token);

//   copyTheme.coverImage["cropped_file"] = null;
//   copyTheme.coverImage["crop_information"] = null;

//   setTheme(copyTheme);
// };

const server_request_to_delete_image = (id, token) => {
  var xhr = new XMLHttpRequest();

  xhr.addEventListener("load", () => {
    if (xhr.status === 200) {
      // console.log(xhr.response);
    } else {
      // console.log(xhr.response);
    }
  });

  xhr.open("DELETE", "/api/userImage/" + id);
  xhr.setRequestHeader("Authorization", token);
  xhr.send();
};

export {
  image_import_helper_block_image,
  image_import_helper_cover_image,
  image_crop_import_helper_block_image,
  image_crop_import_helper_cover_image,
  delete_helper_block_image,
  delete_helper_block_image_crop,
  delete_helper_cover_image,
  // delete_helper_cover_image_crop,
};
