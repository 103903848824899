import React, { useState, useEffect } from "react";
import Style from "./screenShotCaroussel.module.scss";
// import screen1 from "./img/screen1_min.jpg";
// import screen2 from "./img/screen2.png";
// import screen3 from "./img/screen3.png";
// import screen4 from "./img/screen4.png";
import screen1 from "./img/screen1_min.jpg";
import screen2 from "./img/screen2_min.jpg";
import screen3 from "./img/screen3_min.jpg";
import screen4 from "./img/screen4_min.jpg";
import placeholder_screen from "./img/placeholder_screen.svg";
import dotted_background from "./img/dotted_background.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

export function ScreenShotCaroussel(props) {
  const [currentView, setCurrentView] = useState(0);
  const menuItems = [
    { name: "Add Content", image: screen1 },
    { name: "Edit Style", image: screen2 },
    { name: "Collaborate", image: screen3 },
    { name: "Export", image: screen4 },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentView((prevView) => (prevView + 1) % menuItems.length);
    }, 4200); // Change image every 3000 milliseconds (3 seconds)

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  return (
    <div className={Style.wrapper}>
      <div className={Style.dotted_background}>
        <img src={dotted_background} />
      </div>
      <div
        className={Style.screenShotCaroussel}
        style={{
          backgroundImage:
            "linear-gradient(to bottom, #FFEC5C 50%, #f6f6f6 50%)",
        }}
      >
        <div className={Style.screenMenu}>
          <button
            className={Style.menuItem}
            onClick={() => setCurrentView(0)}
            style={currentView !== 0 ? { color: "#AA9F4A" } : {}}
          >
            {menuItems[0].name}
          </button>
          <button
            className={Style.menuItem}
            onClick={() => setCurrentView(1)}
            style={currentView !== 1 ? { color: "#AA9F4A" } : {}}
          >
            {menuItems[1].name}
          </button>
          <button
            className={Style.menuItem}
            onClick={() => setCurrentView(2)}
            style={currentView !== 2 ? { color: "#AA9F4A" } : {}}
          >
            {menuItems[2].name}
          </button>
          <button
            className={Style.menuItem}
            onClick={() => setCurrentView(3)}
            style={currentView !== 3 ? { color: "#AA9F4A" } : {}}
          >
            {menuItems[3].name}
          </button>
        </div>
        <div style={currentView === 0 ? {} : { display: "none" }}>
          <div className={Style.screenShotWrapper}>
            <LazyLoadImage
              effect="blur"
              alt="How the tool allows to add Content to a job posting"
              src={menuItems[0].image}
              width={"100%"}
              height={"100%"}
              placeholderSrc={placeholder_screen}
            />
          </div>
        </div>
        <div style={currentView === 1 ? {} : { display: "none" }}>
          <div className={Style.screenShotWrapper}>
            <LazyLoadImage
              effect="blur"
              alt="How the edit the style of a job posting"
              src={menuItems[1].image}
              width={"100%"}
              height={"100%"}
              placeholderSrc={placeholder_screen}
            />
          </div>
        </div>
        <div style={currentView === 2 ? {} : { display: "none" }}>
          <div className={Style.screenShotWrapper}>
            <LazyLoadImage
              effect="blur"
              alt="How to use the collaboration feature of the job posting creation tool"
              src={menuItems[2].image}
              width={"100%"}
              height={"100%"}
              placeholderSrc={placeholder_screen}
            />
          </div>
        </div>
        <div style={currentView === 3 ? {} : { display: "none" }}>
          <div className={Style.screenShotWrapper}>
            <LazyLoadImage
              effect="blur"
              alt="How to export the job posting to different format"
              src={menuItems[3].image}
              width={"100%"}
              height={"100%"}
              placeholderSrc={placeholder_screen}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
