/***
 *
 *   usePlans hook
 *   fetch, format and return the price plans
 *
 **********/

import { useState, useEffect } from "react";
import { useAPI } from "components/lib";

export function usePlans() {
  const [state, setState] = useState({ data: null, loading: false });
  const plans = useAPI("/api/account/plans");

  useEffect(() => {
    setState({ loading: true });
    
    // format plans
    if (plans?.data?.plans) {
      let formatted = [];
      
      // UNCOMMENT FOR MULTI PLAN
      // plans.data.plans.map((plan) => {
        //   let label = `${plan.name} (${plan.currency.symbol}${plan.price}/${plan.interval})`;
        //   return formatted.push({ value: plan.id, label: label });
        // });:
        
        // Signle plan:
        let main_plan = plans.data.plans[0];
        let label = `${main_plan.name} (${main_plan.currency.symbol}${main_plan.price}/${main_plan.interval})`;
        formatted.push({ value: main_plan.id, label: label, multi_currency: plans.data.plans[0].all_currency });

        setState({
        data: {
          list: formatted,
          active: plans.data.active,
          raw: plans.data,
        },
        loading: false,
      });
    }
  }, [plans]);

  return state;
}
