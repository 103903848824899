import React, { useState, useEffect } from "react";
import Style from "./clientReferalEditorial.module.scss";
import { ReactComponent as Top } from "./img/top.svg";
import { ReactComponent as Bottom } from "./img/bottom.svg";
import { ReactComponent as Octaveoscar } from "./img/oxo.svg";
import labelify from "./img/labelify.svg";

export function ClientReferalEditorial(props) {
  return (
    <div className={Style.clientReferalEditorial}>
      <Top className={Style.waves} />
      <div className={Style.middle}>
        <h2 className={Style.sectionTitle}>People Love using Jobchef</h2>
        <div className={Style.clients}>
          <div className={Style.client}>
            <Octaveoscar style={{ fill: "#313131" }} />
            <p>
              Efficient and collaborative, Jobchef transforms the task of
              writing job postings into an easy, creative process with its excellent
              template choices and seamless sharing capabilities.
            </p>
            <p className={Style.name}>PAUL</p>
            <p className={Style.company}>Supramega</p>
          </div>
          <div className={Style.client}>
            <img src={labelify} />
            <p>
              Jobchef has truly simplified our job posting creation process. We
              frequently use its templates and collaboration feature, which
              speeds up ad creation and enhances effectiveness, allowing us to
              dedicate more time to value-added tasks.
            </p>
            <p className={Style.name}>CÉDRIC</p>
            <p className={Style.company}>Labelify</p>
          </div>
        </div>
      </div>
      <Bottom className={Style.waves} />
    </div>
  );
}
