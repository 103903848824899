import React, { lazy, Suspense } from "react";
// import { Pie } from 'react-chartjs-2';
const Pie = lazy(() =>
  import("react-chartjs-2").then((module) => ({ default: module.Pie }))
);

export function PieChart(props) {
  let options = { ...props.options };
  options.responsive = true;
  options.maintainAspectRatio = false;
  options.scales.yAxes[0].ticks = { display: false };
  options.scales.xAxes[0].ticks = { display: false };
  options.scales.xAxes[0] = { display: false };
  options.scales.yAxes[0] = { display: false };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Pie data={props.data} options={options} />
    </Suspense>
  );
}
