import React from 'react';
import { Icon } from 'components/lib';
import Style from './saveStatusIndicator.module.scss';

export function SaveStatusIndicator(props){
  let saveStatus = props.saveStatus

  return (
    <div className={ Style.saveStatusIndicator }>
      <Icon image={'save'} size={'14px'}/>
      {saveStatus === "Saving..." &&
      <Icon image={'refresh-cw'} size={'12px'} className={Style.saveIconRotate} />
      }
      <Icon image={'check'} size={'12px'} className={Style.saveIcon} />
      <p className={Style.saveLabel}>{saveStatus}</p>
   </div>
  );
}
