import React, { useState } from "react";
import Style from "./imageSearch.module.scss";
import magnifier from "./img/magnifier.svg";

export function ImageSearch(props) {
  const [timer, setTimer] = useState(null);

  const on_key_up = (e) => {
    clearTimeout(timer);
    if (e.keyCode === 13) {
      // console.log("pressed enter");
      props.get_results(e.target.value);
      props.setCurrentSearchKeyword(e.target.value);
    } else {
      const search_value = e.target.value;
      const new_timer = setTimeout(
        () => {
          props.get_results(search_value);
          props.setCurrentSearchKeyword(search_value);
        },
        500,
        search_value
      );
      setTimer(new_timer);
    }
  };

  return (
    <div className={Style.imageSearch}>
      <input
        type="text"
        placeholder="Type to search..."
        onKeyUp={on_key_up}
        className={Style.input}
      />
      <img src={magnifier} className={Style.magnifier} alt="magnifier" />
    </div>
  );
}
