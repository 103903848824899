import React, { useEffect, useContext } from "react";
import { EditorContext, MyTextEditor } from "components/lib";
import Style from "./HelperBlockEditorItemList.module.scss";
import { v4 as uuid } from "uuid";
import { getDefaultKeyBinding } from "draft-js";
import parse from "html-react-parser";

const HelperBlockEditorItemList = (props) => {
  let blockDictionary = useContext(EditorContext).blockDictionary;
  let setBlockDictionary = useContext(EditorContext).setBlockDictionary;

  useEffect(() => {
    if (!checkIfLastItemIsEmpty()) {
      addInputEnd();
    }
  });

  const KeypressCallback = (e, item) => {
    if (e.key === "Backspace") {
      if (checkIfItemIsEmpty(item)) {
        deleteItem(item);
      }
    }
    if (e.key === "Tab") {
      if (checkIfItemIsEmpty(item)) {
        deleteItem(item);
      }
    }
    return getDefaultKeyBinding(e);
  };

  const checkIfLastItemIsEmpty = () => {
    const itemList = blockDictionary[props.blockId].content[props.listName];
    if (itemList.length === 0) {
      return false;
    }
    const lastItem = itemList[itemList.length - 1];
    return checkIfItemIsEmpty(lastItem);
  };

  const _contentValueHelper = (contentKey) => {
    if (
      blockDictionary[props.blockId].content[props.blockId + contentKey] ===
        "" ||
      blockDictionary[props.blockId].content[props.blockId + contentKey] ===
        undefined
    ) {
      return "";
    } else {
      return blockDictionary[props.blockId].content[props.blockId + contentKey];
    }
  };

  const checkIfItemIsEmpty = (item) => {
    const content =
      blockDictionary[props.blockId].content[
        props.blockId + "_listItem_" + item
      ];
    const itemIsEmptyOrUnderfined = content === undefined || content === "";
    return itemIsEmptyOrUnderfined;
  };

  const deleteItem = (item) => {
    const newBlockDictionary = { ...blockDictionary }; // Ref.current)
    const newList = newBlockDictionary[props.blockId].content[props.listName];
    const index = newList.indexOf(item);
    if (index > -1) {
      newList.splice(index, 1);
    }
    delete newBlockDictionary[props.blockId].content[
      props.blockId + "_listItem_" + item
    ];
    setBlockDictionary(newBlockDictionary);
  };

  const addInputEnd = () => {
    let newBlockDictionary = { ...blockDictionary };
    const newList = newBlockDictionary[props.blockId].content[props.listName];
    newList.push(uuid());
    setBlockDictionary(newBlockDictionary);
  };

  return (
    <div className={Style.main}>
      {blockDictionary[props.blockId].content[props.listName].map(
        (item, index) => (
          <div
            className={Style.itemStyle}
            key={props.blockId + "_listItem_" + item}
          >
            <p style={{ marginRight: "10px" }}>•</p>
            <div style={{ width: "100%" }}>
              <MyTextEditor
                blockId={props.blockId}
                className="tippyStyle"
                contentKey={props.blockId + "_listItem_" + item}
                placeholderText={props.placeholderText}
                keyBindingFn={(e) => KeypressCallback(e, item)}
                tabIndex={props.tabIndex}
              />
            </div>
          </div>
        )
      )}
    </div>
  );
};

const HelperBlockEditorItemListPreview = (props) => {
  let blockDictionary = useContext(EditorContext).blockDictionary;
  const _contentValueHelper = (contentKey) => {
    if (
      blockDictionary[props.blockId].content[props.blockId + contentKey] ===
        "" ||
      blockDictionary[props.blockId].content[props.blockId + contentKey] ===
        undefined
    ) {
      return "";
    } else {
      return blockDictionary[props.blockId].content[props.blockId + contentKey];
    }
  };
  let contentEmptyOrUndefinedChecker = _contentValueHelper("title") === "";

  return (
    <ul className={Style.listItemStyle}>
      {blockDictionary[props.blockId].content[props.listName]
        .filter((item) => {
          return (
            typeof blockDictionary[props.blockId].content[
              props.blockId + "_listItem_" + item
            ] !== "undefined"
          );
        })
        .filter((item) => {
          return (
            blockDictionary[props.blockId].content[
              props.blockId + "_listItem_" + item
            ] !== "<p><br></p>"
          );
        })
        .map((item, index) => (
          <li key={props.blockId + "_listItem_" + item}>
            {parse(_contentValueHelper("_listItem_" + item))}
          </li>
        ))}
    </ul>
  );
};

export { HelperBlockEditorItemList, HelperBlockEditorItemListPreview };
