import React, { useState, useEffect } from "react";
import Style from "./planCard.module.scss";
import { ReactComponent as CADFlag } from "./img/flags/CAD.svg";
import { ReactComponent as EURFlag } from "./img/flags/EUR.svg";
import { ReactComponent as GBPFlag } from "./img/flags/GBP.svg";
import { ReactComponent as USDFlag } from "./img/flags/USD.svg";
import { Select, Group } from "@mantine/core";
import { currencyChoiceHelper } from "../../../lib/locationHelper";

export function PlanCard(props) {
  const [currencyChoice, setcurrencyChoice] = useState("cad");
  useEffect(() => {
    currencyChoiceHelper(null, setcurrencyChoice);
  }, []);

  useEffect(() => {
    props.onChange("plan", props.options[0].value, true);
  }, []);

  useEffect(() => {
    props.onChange("currency", currencyChoice, true);
  }, [currencyChoice]);

  const currencies = props.options[0].multi_currency;

  return (
    <div className={Style.planCard}>
      <div className={Style.topSection}>
        <h3>Standard pricing</h3>
        <CurrencySelect
          currencyChoice={currencyChoice}
          setcurrencyChoice={setcurrencyChoice}
        />
      </div>
      <div className={Style.middleSection}>
        <PriceLabel currencyInfo={currencies[currencyChoice]} />
        <div className={Style.plusSign}>+</div>
        <PriceLabel
          currencyInfo={currencies[currencyChoice]}
          type="secondary"
        />
      </div>
      <div className={Style.bottomSection}>
        <p>
          Full access the Jobchef app and all it’s feature. Including
          collaboration links, job templates and more.
        </p>
            </div>

      <input
        type="hidden"
        id={props.name}
        value="price_1NfPE7DNZKfitSNO4K5QRP7V"
      ></input>

      {/* <PriceLabel currency={currencyChoice} size={"small"} /> */}
    </div>
  );
}

export function CurrencySelect(props) {
  const FlagIcon = (props) => {
    switch (props.flag) {
      case "eur":
        return <EURFlag height={"12px"} />;
      case "usd":
        return <USDFlag height={"12px"} />;
      case "gbp":
        return <GBPFlag height={"12px"} />;
      case "cad":
        return <CADFlag height={"12px"} />;
      default:
        return <CADFlag height={"12px"} />;
    }
  };

  const SelectItem = React.forwardRef(({ value, label, ...others }, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <FlagIcon
          flag={value}
          style={{ width: 18, height: 12, marginRight: 8 }}
        />
        <div>{label}</div>
      </Group>
    </div>
  ));

  return (
    <div className={Style.currencySelect}>
      <Select
        value={props.currencyChoice}
        onChange={(value) => {
          currencyChoiceHelper(value, props.setcurrencyChoice);
        }}
        placeholder="Select currency"
        itemComponent={SelectItem}
        icon={<FlagIcon size={12} flagname="cad" flag={props.currencyChoice} />}
        data={[
          { value: "cad", label: "CAD" },
          { value: "usd", label: "USD" },
          { value: "gbp", label: "GBP" },
          { value: "eur", label: "EUR" },
        ]}
        classNames={{
          item: Style.selectStyledropdownItems,
          selected: Style.selectStyleSelected,
          input: Style.selectStyleInput,
          dropdown: Style.selectStyleDropdown,
          root: Style.selectStyleRoot,
        }}
      />
    </div>
  );
}

const PriceLabel = (props) => {
  return (
    <div
      className={
        props.type === "secondary"
          ? Style.priceLabelSecondary
          : Style.priceLabel
      }
    >
      {props.type === "secondary" ? (
        <span className={Style.optionalLabel}>(optional)</span>
      ) : (
        ""
      )}
      <div>
        <span className={Style.currency}>{props.currencyInfo.symbol}</span>
        <span className={Style.ammount}>
          {props.type === "secondary"
            ? props.currencyInfo.additional_seat_price
            : props.currencyInfo.price}
        </span>
        <span className={Style.currencyLabel}>{props.currencyInfo.name}</span>
      </div>
      <p className={Style.forWhat}>
        {props.type === "secondary" ? "per additional user" : "per month"}
      </p>
    </div>
  );
};