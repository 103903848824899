import React, { useContext } from "react";
import { CardRenderer, EditorContext, MyButton } from "../../lib";
import Style from "./AddBlockPlaceholder.module.scss";
import { ScrollArea } from "@mantine/core";

const SectionSelectContext = (props) => {
  const setOpenedDrawer = props.setOpenedDrawer

  return (
    <div className={Style.contextCardWrapper}>
      <ScrollArea style={{ height: "100%" }} offsetScrollbars>
        <div className={Style.contentWrapper}>
          <p>
            You don't need to add all of them, but each section can plays a role
            in making your job posting clearer to candidate.
          </p>
          <CardRenderer blockType="description" />
          <CardRenderer blockType="company_overview" />
          <CardRenderer blockType="skills_and_requirements" />
          <CardRenderer blockType="key_responsabilities" />
          <CardRenderer blockType="benefits" />
          <CardRenderer blockType="fine_print" />
          <CardRenderer blockType="image" />
          <MyButton
            size="medium"
            buttonStyle="primary"
            onClick={() => {
              setOpenedDrawer(false)
            }}
            className={Style.closeButton}
          >Close</MyButton>
          
        </div>
      </ScrollArea>
    </div>
  );
};

export { SectionSelectContext };
