import React, { useState, useEffect } from "react";
import Style from "./howItWorks.module.scss";
import background_card from "./img/background_card.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

// Placeholder or a lightweight initial image
// import placeholder from "./img/placeholder.svg"; // Optional: If you have a placeholder

export function HowItWorks(props) {
  const [currentPreview, setCurrentPreview] = useState("copy_text_snippet");
  const [images, setImages] = useState({
    copy_text_snippet: null,
    add_new_sections: null,
    drag_and_drop: null,
    give_it_style: null,
  });

  useEffect(() => {
    // Dynamically import images based on the current preview
    const loadImage = async (imageName) => {
      try {
        const module = await import(`./img/${imageName}.gif`);
        setImages((prevImages) => ({
          ...prevImages,
          [imageName]: module.default,
        }));
      } catch (error) {
        console.error("Failed to load image:", imageName, error);
      }
    };

    // Load the initial image or all if necessary
    loadImage(currentPreview);
  }, [currentPreview]);

  const elementsToShow = {
    copy_text_snippet: {
      text: "Save time with text snippets",
      image: images.copy_text_snippet,
    },
    add_new_sections: {
      text: "Add new sections",
      image: images.add_new_sections,
    },
    drag_and_drop: {
      text: "Reorganise your sections",
      image: images.drag_and_drop,
    },
    give_it_style: {
      text: "Give it some style",
      image: images.give_it_style,
    },
  };

  return (
    <div className={Style.howItWorks}>
      <div className={Style.selectorElement}>
        <h2 className={Style.sectionTitle}>As easy as pie</h2>
        {Object.keys(elementsToShow).map((item, index) => (
          <button
            key={index} // Added key for React list
            onClick={() => setCurrentPreview(item)}
            style={
              currentPreview === item ? { backgroundColor: "#D9D9D9" } : {}
            }
          >
            {elementsToShow[item].text}
          </button>
        ))}
        <div className={Style.andMoreLabel}>And more!</div>
      </div>
      <div
        className={Style.previewElement}
        style={{ backgroundImage: "url(" + background_card + ")" }}
      >
        {elementsToShow[currentPreview].image ? (
          <div className={Style.previewGif}>
            {/* <img
              src={elementsToShow[currentPreview].image}
              className={Style.previewGif}
              alt={`Preview of ${elementsToShow[currentPreview].text}`}
            /> */}
            <LazyLoadImage
              effect="blur"
              alt="Preview"
              src={elementsToShow[currentPreview].image}
              width={"100%"}
              height={"100%"}
              // placeholderSrc={placeholder_screen}
            />
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
}
