import { Profile } from 'views/account/profile';
import { Password } from 'views/account/password';
import { Billing } from 'views/account/billing';
import { Upgrade } from 'views/account/upgrade';
import { Users } from 'views/account/users';
import { CompanySettings } from 'views/account/companySettings';

const Routes = [
  {
    path: '/account',
    view: Profile,
    layout: 'app',
    permission: 'user',
    title: 'Your Profile'
  },
  {
    path: '/account/password',
    view: Password,
    layout: 'app',
    permission: 'user',
    title: 'Your Password'
  },
  {
    path: '/account/billing',
    view: Billing,
    layout: 'app',
    permission: 'owner',
    title: 'Billing'
  },
  {
    path: '/account/upgrade',
    view: Upgrade,
    layout: 'app',
    permission: 'user',
    title: 'Re-activate subscription'
  },
  {
    path: '/account/users',
    view: Users,
    layout: 'app',
    permission: 'owner',
    title: 'Users'
  },
  {
    path: '/account/companySettings',
    view: CompanySettings,
    layout: 'app',
    permission: 'owner',
    title: 'Company settings'
  },
]

export default Routes;