/***
 *
 *   SIGN UP STEP 1
 *   Signup form for account owners
 *   Step 1: create account
 *   Step 2: select plan and make payment
 *
 **********/

import React, { useContext, useEffect } from "react";
import {
  AuthContext,
  Logo,
  Form,
  Link,
} from "components/lib";
import { Redirect } from "react-router-dom";
import Style from "../auth.module.scss";
import { currencyChoiceHelper } from "../../../lib/locationHelper";

export function Signup(props) {
  const context = useContext(AuthContext);
  
    useEffect(() => {
      currencyChoiceHelper(null, console.log);
    }, []);

  // check if the user is already logged in
  const user = JSON.parse(localStorage.getItem("user"));
  if (user?.token) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className={Style.mainCard}>
      <div className={Style.contentWrapper}>
        <div className={Style.logoWrapper}><Logo version="full_logo_color" className={Style.logo}></Logo></div>
        <h2>Create Your Account</h2>

        {/* <SocialSignin network={['facebook', 'twitter']} showOr signup /> */}

        <Form
          data={{
            name: {
              label: "First Name",
              value: "",
              type: "text",
              required: true,
              errorMessage: "Please enter your first name",
              maxCharCount: 32
            },
            email: {
              label: "Email",
              value: "",
              type: "email",
              required: true,
            },
            password: {
              label: "Password",
              type: "password",
              required: true,
              complexPassword: true,
            },
            confirm_password: {
              type: "hidden",
              value: null,
            },
          }}
          url="/api/account"
          method="POST"
          buttonText="Create Account"
          callback={context.signin}
        />

        <span>
          Already registered? <Link url="/signin" text="Sign In" />
        </span>
      </div>
    </div>
  );
}
