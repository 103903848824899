import React, { useContext } from "react";
import { v4 as uuid } from "uuid";
import {
  HelperBlockContextHeader,
  HelperBlockContextBody,
  HelperBlockCardPreview,
} from "./_index.js";
import { Loader, EditorContext, MyTextEditor } from "../../lib";
import red_burger from "./images/food/red_burger.svg";
import parse from "html-react-parser";

const block_quote_data_generator = () => {
  const new_uuid = uuid();

  return {
    key: new_uuid,
    data: {
      version: 1,
      id: new_uuid, //block
      type: "quote",
      content: {
        title: "<h2>Quote</h2>",
        description:
          "<p>For all your legal lingo, hiring must-haves, and other fun stuff.",
        item_list: [
          "<p>first element</p>",
          "<p>second element</p>",
          "<p>third element</p>",
          "<p>fourth element</p>",
          "",
        ],
      },
    },
  };
};

const BlockQuotePreview = (props) => {
  // Content items
  const blockDictionary = useContext(EditorContext).blockDictionary;
  const _contentValueHelper = (contentKey) => {
    if (
      blockDictionary[props.blockId].content[props.blockId + contentKey] ===
        "" ||
      blockDictionary[props.blockId].content[props.blockId + contentKey] ===
        undefined
    ) {
      return "";
    } else {
      return blockDictionary[props.blockId].content[props.blockId + contentKey];
    }
  };
  let contentEmptyOrUndefinedChecker = _contentValueHelper("title") === "";
  return (
    <>
      {blockDictionary[props.blockId] === null && <Loader />}
      {blockDictionary[props.blockId] !== null && (
        <div className={"jc_block"}>
          <div className="style_jc quote_jc">
            {parse(_contentValueHelper("title"))}
            {parse(_contentValueHelper("body"))}
          </div>{" "}
        </div>
      )}
    </>
  );
};

const BlockQuoteCardPreview = (props) => {
  return (
    <HelperBlockCardPreview
      image={red_burger}
      header="Quote"
      short_description="Because nothing sells a candidate more than the words of a leader. You can quote us on that."
      block_type="quote"
      color="--red-medium"
      color_light="#D95565"
    />
  );
};

const BlockQuoteEditor = (props) => {
  return (
    <>
      <MyTextEditor
        blockId={props.blockId}
        contentKey={props.blockId + "title"}
      />
      <MyTextEditor
        blockId={props.blockId}
        contentKey={props.blockId + "description"}
      />
    </>
  );
};

const BlockQuoteContext = (props) => {
  return (
    <>
      <HelperBlockContextHeader
        header={"Quote"}
        image={red_burger}
        color="#D95565"
      ></HelperBlockContextHeader>
      <HelperBlockContextBody>
        <p>
          Similar to the Image block, the Quote block can be used to add some
          dynamism and depth to your job profile.
        </p>
        <p>
          Pick a quote from a leader that captures the spirit of your company,
          or a team member who raves about the job or the team.{" "}
        </p>
      </HelperBlockContextBody>
    </>
  );
};

export {
  BlockQuotePreview,
  BlockQuoteCardPreview,
  BlockQuoteEditor,
  BlockQuoteContext,
  block_quote_data_generator,
};
