/***
 *
 *   LOGO
 *   Replace the image in /images with your own logo
 *
 **********/

import React from "react";
import ClassNames from "classnames";
import { Link } from "components/lib";

import { ReactComponent as FullLogoColor } from "./images/full_logo_color.svg";
import { ReactComponent as FullLogoBlackAndWhite } from "./images/full_logo_black_and_white.svg";
import { ReactComponent as LogoIconColor } from "./images/icon_color.svg";
import { ReactComponent as LogoIconBlackAndWhite } from "./images/icon_black_and_white.svg";
import { ReactComponent as FullLogoColorInverted } from "./images/full_logo_color_inverted.svg";
import { ReactComponent as FullLogoBlackAndWhiteInverted } from "./images/full_logo_black_and_white_inverted.svg";
import { ReactComponent as LogoIconColorInverted } from "./images/icon_color_inverted.svg";
import { ReactComponent as LogoIconBlackAndWhiteInverted } from "./images/icon_black_and_white_inverted.svg";

export function Logo(props) {
  const css = ClassNames([props.className]);

  let logoVersion;

  switch (props.version) {
    case "full_logo_color":
      logoVersion = <FullLogoColor />;
      break;

    case "full_logo_black_and_white":
      logoVersion = <FullLogoBlackAndWhite />;
      break;

    case "icon_color":
      logoVersion = <LogoIconColor />;
      break;

    case "icon_black_and_white":
      logoVersion = <LogoIconBlackAndWhite />;
      break;
    case "full_logo_color_inverted":
      logoVersion = <FullLogoColorInverted />;
      break;

    case "full_logo_black_and_white_inverted":
      logoVersion = <FullLogoBlackAndWhiteInverted />;
      break;

    case "icon_color_inverted":
      logoVersion = <LogoIconColorInverted />;
      break;

    case "icon_black_and_white_inverted":
      logoVersion = <LogoIconBlackAndWhiteInverted />;
      break;

    default:
      logoVersion = <p>No logo selected</p>;
      break;
  }

  return (
    <Link url="/" className={css}>
      {logoVersion}
    </Link>
  );
}
