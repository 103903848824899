/***
 *
 *   SIGN UP STEP 2
 *   Signup form for account owners
 *   Step 1: create account
 *   Step 2: select plan and make payment
 *
 **********/

import React, { useContext, useState } from "react";
import {
  ReassurancePayment,
  AuthContext,
  Logo,
  PaymentForm,
  usePlans,
  Link,
  History,
} from "components/lib";
import { ReactComponent as CADFlag } from "./img/flags/CAD.svg";
import { ReactComponent as EURFlag } from "./img/flags/EUR.svg";
import { ReactComponent as GBPFlag } from "./img/flags/GBP.svg";
import { ReactComponent as USDFlag } from "./img/flags/USD.svg";
import Style from "../auth.module.scss";
import { Group } from "@mantine/core";


const FlagIcon = (props) => {
  switch (props.flag) {
    case "EU":
      return <EURFlag height={"12px"} />;
    case "US":
      return <USDFlag height={"12px"} />;
    case "GB":
      return <GBPFlag height={"12px"} />;
    case "CA":
      return <CADFlag height={"12px"} />;
    default:
      break;
  }
};

export function SignupPlan(props) {
  const context = useContext(AuthContext);
  const plans = usePlans();
  const plan = window.location.hash.substring(1);

  if (!plans.data) return false;

  const extra = {};

  const coupon = localStorage.getItem("stripe_coupon");
  if (coupon) {
    extra["coupon"] = {
      label: "coupon",
      type: "text",
      required: false,
      value: coupon,
    };
  }

  const SelectItem = React.forwardRef(({ value, label, ...others }, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <FlagIcon
          flag={value}
          style={{ width: 18, height: 12, marginRight: 8 }}
        />
        <div>{label}</div>
      </Group>
    </div>
  ));

  return (
    <div className={Style.mainCard}>
      <div className={Style.contentWrapper}>
        <div className={Style.logoWrapper}>
          <Logo version="full_logo_color" className={Style.logo}></Logo>
        </div>
        <h2>Activate Your Plan</h2>
        <p style={{textAlign:"center"}}><strong style={{backgroundColor:"#fced74", padding:"4px 2px", marginRight:"2px"}}> No charge today.</strong>Billing starts <strong>after your 30 days trial</strong>, cancel at any time.</p>
        <PaymentForm
          data={{
            plan: {
              label: "Plan",
              type: "planCard",
              options: plans.data.list,
              default: plan,
              required: true,
            },
            currency: {
              label: "Plan",
              type: "hidden",
            },
            token: {
              label: "Credit Card",
              type: "creditcard",
              required: true,
            },
            ...extra,
            customSeperator: {
              type: "seperator",
              content: <ReassurancePayment />,
            },
          }}
          plan={plan}
          url="/api/account/plan"
          method="POST"
          buttonText="Start 30 day trial"
          callback={(res) => {
            // save the plan to context, then redirect
            context.update({
              plan: res.data.plan,
              subscription: res.data.subscription,
              account_active: res.data.account_active,
            });
            History.push(res.data.onboarded ? "/dashboard" : "/welcome");
          }}
        />

        <Link url="/account" text="Manage Your Account" />
      </div>
    </div>
  );
}

