import React, { useState, useEffect } from "react";
import Style from "./jobSearchBar.module.scss";
import magnifier from "./img/magnifier.svg";

export function JobSearchBar(props) {
  const [timer, setTimer] = useState(null);

  const on_key_up = (e) => {
    clearTimeout(timer);
    if (e.keyCode === 13) {
      // console.log("pressed enter");
    } else {
      props.setSearchValue(e.target.value);
      const new_timer = setTimeout(
        () => {
          // console.log(props.searchValue);
        },
        500,
        props.searchValue
      );
      setTimer(new_timer);
    }
  };

  const clearInput = () => {
    props.setSearchValue(""); // Clear the input value through the parent component
  };

  useEffect(() => {
    // Update the input value when the prop changes
    if (props.searchValue !== document.getElementById("searchInput").value) {
      document.getElementById("searchInput").value = props.searchValue;
    }
  }, [props.searchValue]);

  return (
    <div className={Style.imageSearch}>
      <input
        id="searchInput"
        type="text"
        placeholder="Type to search..."
        onKeyUp={on_key_up}
        className={Style.input}
      />
      <img src={magnifier} className={Style.magnifier} alt="magnifier" />
    </div>
  );
}
