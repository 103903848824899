/***
 *
 *   FORGOT PASSWORD
 *   Trigger a password reset process
 *
 **********/

import React from "react";
import { Form, Logo } from "components/lib";
import Style from "../auth.module.scss";

export function ForgotPassword(props) {
  return (
    <div className={Style.mainCard}>
      <div className={Style.contentWrapper}>
        <div className={Style.logoWrapper}><Logo version="full_logo_color" className={Style.logo}></Logo></div>
        <h2>Reset Your Password</h2>

        <p>
          Enter your email address and we'll send you instructions to reset your
          password.
        </p>

        <Form
          data={{
            email: {
              label: "Email",
              type: "email",
              required: true,
            },
          }}
          url="/api/auth/password/reset/request"
          method="POST"
          buttonText="Reset Password"
        />
      </div>
    </div>
  );
}
