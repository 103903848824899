/***
*
*   PRICING
*   Modify the PricePlans component with your own features
*
**********/

import React, { useEffect } from 'react';
import { Animate, PriceCard } from 'components/lib';

export function Pricing(props){

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const coupon = params.get('coupon');
    if (coupon) {
      localStorage.setItem('stripe_coupon', coupon);
    }
  }, []);
  
  return (
    <Animate type='pop'>
      <PriceCard />
    </Animate>
  )
}
