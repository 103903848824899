import React, { lazy, Suspense } from "react";
// import { Line } from 'react-chartjs-2';
const Line = lazy(() =>
  import("react-chartjs-2").then((module) => ({ default: module.Line }))
);

export function LineChart(props) {
  let options = { ...props.options };
  options.maintainAspectRatio = false;
  options.responsive = true;

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Line data={props.data} options={options} />
    </Suspense>
  );
}
