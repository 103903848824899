import React, { useState, useEffect, useContext } from "react";
import Style from "./coverImagePicker.module.scss";
import {
  FilePondWrapper,
  UnsplashSelector,
  EditorContext,
  ImageCropperWrapper,
  ViewContext,
} from "components/lib";
import { ReactComponent as MagnifierIcon } from "../img/magnifier_icon.svg";
import { ReactComponent as UploadIcon } from "../img/upload_icon.svg";

export function CoverImagePicker(props) {
  const [selectedImage, setSelectedImage] = useState(false);

  let theme = useContext(EditorContext).theme;
  let setTheme = useContext(EditorContext).setTheme;
  let previewCrop = useContext(EditorContext).previewCrop;

  return (
    <>
      {!selectedImage && !props.cropMode && (
        <div className={Style.addImageState}>
          <div className={Style.infoTextLineBox}>
            <p className={Style.textLine}>
              The cover page selected requires{" "}
              <i>
                {/* an image of {coverPageAspectRatiosHelper[props.coverPage].width} x{" "}
                {coverPageAspectRatiosHelper[props.coverPage].height}
                {" pixels. "} */}
              </i>{" "}
              Images of different size can simply be cropped on the next step.
            </p>
          </div>
          <div className={Style.subtitle}>
            <UploadIcon className={Style.yellow} />
            <h3>Upload a file:</h3>
          </div>
          <FilePondWrapper
            importHelperParameters={[
              theme,
              setTheme,
              setSelectedImage,
              props.setCropMode,
              props.coverPage,
            ]}
            imageType={"coverImage"}
          />
          <div className={Style.subtitle}>
            <MagnifierIcon className={Style.yellow} />
            <h3>Search for an image:</h3>
          </div>
          <UnsplashSelector
            importHelperParameters={[
              theme,
              setTheme,
              setSelectedImage,
              props.setCropMode,
              props.coverPage,
            ]}
            imageType={"coverImage"}
          />
        </div>
      )}
      {props.cropMode && (
        <div className={Style.selectedImageState}>
          <canvas
            ref={previewCrop}
            style={{
              maxWidth: "100%",
              display: "none",
            }}
          />
          <ImageCropperWrapper
            imageLocation={theme.coverImage[props.coverPage]}
            selectedImag={selectedImage}
            fixedAspect={coverPageAspectRatiosHelper(props.coverPage)}
            imageType={props.coverPage}
            setCropMode={props.setCropMode}
            importHelperParameters={[theme, setTheme, props.setSecondaryView]}
            imageFixedSize={imageFixedSizeHelper(props.coverPage)}
          />
        </div>
      )}
    </>
  );
}

const coverPageAspectRatiosHelper = (coverPage) => {
  switch (coverPage) {
    case "noBanner":
      return { unit: "%", width: 100, aspect: 100 / 100 };
    case "smallCover":
      return { unit: "%", width: 100, aspect: 314 / 262 };
    case "fullPageCover":
      return { unit: "%", width: 100, aspect: 612 / 542 };
    case "bannerCover":
      return { unit: "%", width: 100, aspect: 612 / 280 };
    case "landingCover":
      return { unit: "%", width: 100, aspect: 960 / 240 };
    default:
      break;
  }
};

const imgQualityMulitplier = 300/72  // 72 -> 300

const imageFixedSizeHelper = (coverPage) => {
  switch (coverPage) {
    case "noBanner":
      return { fixedWidth: 1, fixedHeight: 1 };
    case "smallCover":
      return { fixedWidth: 314 * imgQualityMulitplier, fixedHeight: 262 * imgQualityMulitplier };
    case "fullPageCover":
      return { fixedWidth: 612 * imgQualityMulitplier, fixedHeight: 542 * imgQualityMulitplier};
    case "bannerCover":
      return { fixedWidth: 1440  * imgQualityMulitplier, fixedHeight: 240 * imgQualityMulitplier};
    default:
      break;
  }
};
