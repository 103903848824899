import React, { useEffect, useState } from "react";
import { MyButton, Icon } from "components/lib";
import { ReactComponent as GenerativeAiIcon } from "./img/generative_ai_icon.svg";
import Style from "./generateLoadingScreen.module.scss";
import { random } from "lodash";

const GenerateLoadingScreen = (props) => {
  const [currentTextNumber, setCurrentTextNumber] = useState(0);
  // Initialize loadingTexts with random timeouts on component mount
  const [loadingTexts, setLoadingText] = useState(() =>
    [
      "Analyzing prompt...",
      "Crafting your job post...",
      "Adding tone of voice...",
      "Generating job sections...",
    ].map((text) => [text, 1000 + Math.random() * 2000])
  );

  useEffect(() => {
    if (currentTextNumber < loadingTexts.length - 1) {
      const timer = setTimeout(() => {
        setCurrentTextNumber((currentTextNumber) => currentTextNumber + 1);
      }, loadingTexts[currentTextNumber][1]);
      return () => clearTimeout(timer);
    } else {
      // Loop or stop here based on your requirement
      // setCurrentTextNumber(0); // Reset to start over
    }
  }, [currentTextNumber, loadingTexts]);

  // Helper function to get text based on currentTextNumber adjustment
  const getText = (adjustment) => {
    const index = currentTextNumber + adjustment;
    if (index >= 0 && index < loadingTexts.length) {
      return loadingTexts[index][0];
    }
    return ""; // Return empty string if out of bounds
  };

  const takingTooLong = () => {
    const newItem = ["This is taking longer than expected...", 0];
    const newArray = [...loadingTexts, newItem];
    setLoadingText(newArray);
  };

  return (
    <div className={Style.generateLoadingScreen}>
      <GenerativeAiIcon />
      {props.waiting_for_response && (
        <div className={Style.textDisplayWrapper}>
          <div className={Style.cell1}>{getText(-2)}</div>
          <div className={Style.cell2}>{getText(-1)}</div>
          <div className={Style.cell3}>{getText(0)}</div>
          <div className={Style.cell4}>{getText(1)}</div>
          <div className={Style.cell5}>{getText(2)}</div>
        </div>
      )}
      {!props.waiting_for_response && (
        <div className={Style.textDisplayWrapper}>
          <div className={Style.cell1}></div>
          <div className={Style.cell2}></div>
          <div className={Style.cell3}>
            <Icon image="check" size={"8px"}></Icon>Finished. Updating...
          </div>
          <div className={Style.cell4}></div>
          <div className={Style.cell5}></div>
        </div>
      )}
      <MyButton
        buttonStyle="secondary"
        onClick={() => {
          props.cancelRequest()
          props.setCurrentScreen("generate_draft_mode");
        }}
      >
        Cancel
      </MyButton>
    </div>
  );
};

export { GenerateLoadingScreen };

const classes = [
  Style.smallTop,
  Style.MediumTop,
  Style.Normal,
  Style.MediumBottom,
  Style.smallBottom,
];
