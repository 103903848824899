const mantineTheme = {
  fontFamily: "Roboto, Helvetica Neue, Arial, sans-serif",
  scrollbar: {
    'thumb' : "red",
  },
  colors: {
    violet: [
      "#D4D4E8",
      "#C7C7E1",
      "#BBBBDB",
      "#AEAED4",
      "#A2A2CD",
      "#9595C7",
      "#8989C0",
      "#7C7CB9",
      "#7070B3",
      "#6363AC",
    ],
    red: [
      "#FCF3F4",
      "#F8DFE2",
      "#F3CBD0",
      "#EFB7BE",
      "#EAA3AC",
      "#E6909A",
      "#E17C88",
      "#DD6876",
      "#D85464",
      "#D44052",
    ],
    gray: [
      "#F8F9FA",
      "#E2E3E4",
      "#CCCDCE",
      "#B6B7B8",
      "#A0A1A2",
      "#8B8B8B",
      "#757575",
      "#5F5F5F",
      "#494949",
      "#333333",
    ],
    blue: [
      "#E7F5FF",
      "#D7ECFE",
      "#C8E3FC",
      "#B8DAFB",
      "#A9D1F9",
      "#99C7F8",
      "#8ABEF6",
      "#7AB5F5",
      "#6BACF3",
      "#5BA3F2",
    ],
    green: [
      "#D7EBD2",
      "#CDE6C6",
      "#C2E1BA",
      "#B8DBAF",
      "#ADD6A3",
      "#A3D197",
      "#98CC8B",
      "#8EC680",
      "#83C174",
      "#79BC68",
    ],
    orange: [
      "#FDF4EC",
      "#FBEADA",
      "#FAE1C8",
      "#F8D7B6",
      "#F6CDA4",
      "#F5C492",
      "#F3BA80",
      "#F1B06E",
      "#F0A75C",
      "#EE9D4A",
    ],
    yellow: [
      "#FFFDEB",
      "#FFFBD8",
      "#FFF9C4",
      "#FFF7B1",
      "#FFF59D",
      "#FFF38A",
      "#FFF176",
      "#FFEF63",
      "#FFED4F",
      "#FFEB3C",
    ],
  },
};

export { mantineTheme };

///
/*

https://meyerweb.com/eric/tools/color-blend/#6363AC:D4D4E8:8:hex

console.log(
$("#pal9").value+", "+
$("#pal8").value+", "+
$("#pal7").value+", "+
$("#pal6").value+", "+
$("#pal5").value+", "+
$("#pal4").value+", "+
$("#pal3").value+", "+
$("#pal2").value+", "+
$("#pal1").value+", "+
$("#pal0").value+", "
)

*/
///
