import React from 'react';
import Style from './emptyLayout.module.scss';
import '../layout.scss'; // globals

export function EmptyLayout(props){
  
    // context & style

    return (
    <div className={ Style.emptyLayout }>
      { <props.children {...props.data }/> }
   </div>
  );
};

