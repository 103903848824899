import React, { useState } from "react";
import Style from "./ToggleButtons.module.scss";
import { Field } from "formik";

export const ToggleButtonMultiple = (props) => {
  const [toggleValue, setToggleValue] = useState(400);
  
  return (
    <Field
      name={props.inputID + ".blockStyle." + props.blockStyleElement}>
      {({ field, form, meta }) => (
        <div className={Style.toggleButtonsWrapper}>
          {props.toggleItems
            .filter((item) => {
              return true
            })
            .map((item, index) => {
              return (
                <button
                  key={index}
                  onClick={() => {
                    setToggleValue(item.value);
                    form.setFieldValue(field.name, item.value);
                  }}
                  className={`${Style.toggleButtonsGroup} ${field.value === item.value ? Style.optionSelected : Style.optionNotSelect}`}
                  style={item.customStyle}
                >
                  {item.name}
                </button>
              );
            })}
        </div>
      )}
    </Field>
  );
};

export const ToggleButtonSingle = (props) => {
  const [toggleValue, setToggleValue] = useState(props.toggleItems.value);

  return (
    <Field
      name={props.inputID + ".blockStyle." + props.blockStyleElement}>
      {({ field, form, meta }) => (
        <div className={Style.toggleButtonsWrapper} style={{width: "calc(100% / 3 - 6.4px)"}}>
          <button
            onClick={() => {
              const newToggleValue = toggleValue
              setToggleValue(!newToggleValue);
              form.setFieldValue(field.name, newToggleValue);
            }}
            className={`${Style.toggleButtons} ${field.value === props.toggleItems.value ? Style.optionSelected : Style.optionNotSelect}`}
            style={props.toggleItems.customStyle}
          >
            {props.toggleItems.name}
          </button>
        </div>
      )}
    </Field>
  );
};
