import React, { useContext, useRef } from "react";
import { Editor, EditorState, RichUtils, Modifier } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import styled from "styled-components";
import { EditorContext, MyButton } from "components/lib";
import buildDraftJsDecorators from "./lib/buildDraftJsDecorators.js";
import { recalculateEditorStats } from "./lib/stats.js";

const StyledEditorContainer = styled.div`

.DraftEditor-editorContainer  {
  //background-color: rgba(255, 255, 255, 0);
  z-index: 1;
}

.public-DraftEditorPlaceholder-root {
  color: pink;
  &> div { 
    position:relative;
    margin-bottom: -30px;
    margin-left: 10px;
  } 
  z-index: 2;
  vertical-align: baseline;
  display:none;
}
public-DraftEditor-content {
  border: 1px solid black;
}

DraftEditor-root {
  display: flex;
  justify-content: center;
}

.public-DraftStyleDefault-block {
  background-color: #fbfbfb;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding: 12px 8px;
}

.public-DraftStyleDefault-block > span > span {
  //border-bottom: 1px dashed #ababab;
  margin: 0px 0px 15px 0px;
  line-height: 1.6em;
}

.inline-style-options {
  margin-left: 4px;
  align-items: baseline;
}

h2 {
  letter-spacing: 0.5px;
  font-size:1em;
  line-height:auto;
  font-weight: 500;
}

.inline-style-options button {
  border: 1px solid #d2d2d2;
  background-color: #f2f5ff;
  font-size: 0.625em
  color: #818181;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 2px;
  margin-top: 10px;
  padding: 2px 6px;
}
margin-bottom: 20px;

.inline-style-options button:active {
  transform: scale(0.95);
}

.inline-style-options button:not(.active):hover {
  background-color: #d2d2d2;
}

.inline-style-options button.active {
  background-color: #7e79a3;
  border-color: transparent;
  color: #ffffff;
}

.quickAdd {
  flex-grow:1;
  margin-left: 8px;
  
  p {
    margin-bottom: 0px;
    font-size: 0.875em;
    min-width: 80px;
  }
  display: flex;
  flex-direction: row-reverse;
}

.quickAddItems {
  display: flex;
  flex-direction: row;
  align-items:left;
  flex-wrap: wrap;
  button {
    margin: 0px 8px 8px 0px;
    padding: 4px 8px;
    background-color: #6363AC;
    color: white;
    border: 1px solid darken(#FBFBFF,90%);
    border-radius:4px;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  }
  p {
    font-size: 12px;
    color: #a3a3a3;
    font-style: italic;
  }
}

.masculine-words {
  border-bottom: 3px solid #8EDE8C;
  cursor:pointer;
}

.feminine-words {
  border-bottom: 3px solid #8CBFF4;
  cursor:pointer;
}

.tippy-box[data-theme~='gradient'] {
  background: pink;
  box-shadow: 0 8px 12px #c9a0ff;
  font-weight: bold;
}

`;

const _getCurrentInputState = (blockContent, compositeDecorator) => {
  const inputState = {
    editorState: helperInputStateGenerator(blockContent, compositeDecorator),
  };

  return inputState;
};

const helperInputStateGenerator = (content, compositeDecorator) => {
  if (content === false || content === undefined) {
    //console.log("This blockKey and content loaded without placeholder");
    return EditorState.createEmpty(compositeDecorator);
  } else {
    //console.log("This blockKey and content loaded without blockType");
    return EditorState.createWithContent(
      stateFromHTML(content),
      compositeDecorator
    );
  }
};

export function MyTextEditor(props) {
  const blockId = props.blockId + props.contentKey;
  const blockDictionary = useContext(EditorContext).blockDictionary;
  const setBlockDictionary = useContext(EditorContext).setBlockDictionary;
  const stats = useContext(EditorContext).stats;
  const setStats = useContext(EditorContext).setStats;
  const setBlockContextSwitcher =
    useContext(EditorContext).setBlockContextSwitcher;
  const blockContextSwitcher = useContext(EditorContext).blockContextSwitcher;

  const blockContent = blockDictionary[props.blockId].content[props.contentKey];
  const compositeDecorator = buildDraftJsDecorators(blockId, stats, setStats);
  const quickAddOptions = props.quickAddOptions;
  const ctaViewSampleText = props.ctaViewSampleText;

  const helperBlockDictionaryUpdater = (
    contentKey,
    newContent,
    contentGroup,
    isEmpty
  ) => {
    let newBlockDictionary = { ...blockDictionary };

    // if (isEmpty) {
    //   newContent = false;
    // }

    if (contentGroup !== "noGroup") {
      newBlockDictionary[props.blockId].content[contentGroup][contentKey] =
        isEmpty ? newContent : "";
    } else {
      newBlockDictionary[props.blockId].content[contentKey] = isEmpty
        ? newContent
        : "";
    }
    setBlockDictionary(newBlockDictionary);
  };

  let domEditor = useRef();
  function setDomEditorRef(ref) {
    domEditor.current = ref;
  }

  function focus() {
    if (domEditor.current) {
      domEditor.current.focus();
    }
  }

  return (
    <div onClick={focus}>
      <EditorEntity
        selectedBlock={props.blockId}
        contentKey={props.contentKey}
        blockContent={blockContent}
        helperBlockDictionaryUpdater={helperBlockDictionaryUpdater}
        setDomEditorRef={setDomEditorRef}
        placeholderText={props.placeholderText}
        contentBlockType={props.contentBlockType}
        contentGroup={props.contentGroup ? props.contentGroup : "noGroup"}
        keyBindingFn={props.keyBindingFn}
        tabIndex={props.tabIndex}
        inspirationCTA={props.inspirationCTA}
        compositeDecorator={compositeDecorator}
        stats={stats}
        setStats={setStats}
        blockId={blockId}
        quickAddOptions={quickAddOptions}
        ctaViewSampleText={ctaViewSampleText}
        setBlockContextSwitcher={setBlockContextSwitcher}
        blockContextSwitcher={blockContextSwitcher}
      />
    </div>
  );
}

class EditorEntity extends React.Component {
  constructor(props) {
    super(props);
    this.setDomEditorRef = props.setDomEditorRef;
    this.placeholderText = props.placeholderText
      ? props.placeholderText
      : "Start typing here...";
    this.contentBlockType = props.contentBlockType
      ? props.contentBlockType
      : "unstyled";
    this.state = _getCurrentInputState(
      props.blockContent,
      props.compositeDecorator
    );

    //Calculates stats of the editors at load
    const newStats = { ...props.stats };
    recalculateEditorStats(props.blockId, newStats, this.state.editorState);
    props.setStats(newStats);

    this.onChange = (editorState) => {
      // // Keep the stats in sync
      const newStats = { ...props.stats };
      recalculateEditorStats(props.blockId, newStats, editorState);
      props.setStats(newStats);

      if (
        RichUtils.getCurrentBlockType(editorState) !== this.contentBlockType
      ) {
        editorState = RichUtils.toggleBlockType(
          editorState,
          this.contentBlockType
        );
      }

      this.setState({ editorState });

      let isEmpty =
        editorState.getCurrentContent().hasText() &&
        editorState.getCurrentContent().getPlainText().length > 0;

      props.helperBlockDictionaryUpdater(
        props.contentKey,
        stateToHTML(editorState.getCurrentContent()),
        props.contentGroup,
        isEmpty
      );

      // [ ] TODO : Check if some optimisation can be done here. It's crazy to do all this at every keystroke.
    };
    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    this.toggleInlineStyle = this.toggleInlineStyle.bind(this);
    this.keyBindingFn = props.keyBindingFn;
    this.contentKey = props.contentKey;
    this.tabIndex = props.tabIndex;
    this.inspirationCTA = props.inspirationCTA;
    this.handleReplaceAllText = (text, editorState) => {
      let currentContent = editorState.getCurrentContent();
      let selection = editorState.getSelection().merge({
        anchorKey: currentContent.getFirstBlock().getKey(),
        anchorOffset: 0,

        focusOffset: currentContent.getLastBlock().getText().length,
        focusKey: currentContent.getLastBlock().getKey(),
      });

      const newState = Modifier.replaceText(currentContent, selection, text);

      this.onChange(EditorState.push(editorState, newState, "insert-fragment"));

      return true;
    };
    this.handlePastedText = (text, html, editorState) => {
      if (html == null) {
        // console.log("not html: ", text);
        //paste as plain text here.
        return false;
      } else if (html !== null) {
        var sanitizeHtml = require("sanitize-html");
        // console.log("Pasted html: ", html);
        const cleanHtml = sanitizeHtml(html, {
          allowedTags: ["b", "i", "em", "strong", "u", "span"],
          allowedAttributes: [], // i.e.'a': [ 'href' ]
          allowedIframeHostnames: [], //i.e. ['www.youtube.com']
        });
        // console.log("Cleaned Html: ", cleanHtml);

        const blockMapFromcleanHtml = stateFromHTML(cleanHtml).blockMap;

        const newState = Modifier.replaceWithFragment(
          this.state.editorState.getCurrentContent(),
          this.state.editorState.getSelection(),
          blockMapFromcleanHtml
        );

        this.onChange(
          EditorState.push(this.state.editorState, newState, "insert-fragment")
        );

        return true;
      } else {
        return false;
      }
    };
  }

  handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      this.onChange(newState);
      return "handled";
    }

    return "not-handled";
  }

  toggleInlineStyle(event) {
    event.preventDefault();
    let style = event.currentTarget.getAttribute("data-style");
    this.setState({
      editorState: RichUtils.toggleInlineStyle(this.state.editorState, style),
    });
  }

  renderInlineStyleButton(value, style) {
    const currentInlineStyle = this.state.editorState.getCurrentInlineStyle();

    let className = "";
    if (currentInlineStyle.has(style)) {
      className = "active";
    }

    const setHTML = { __html: value };

    return (
      <button
        type="button"
        key={style}
        data-style={style}
        className={className}
        onMouseDown={this.toggleInlineStyle}
      >
        <span dangerouslySetInnerHTML={setHTML}></span>
      </button>
    );
  }

  render() {
    const inlineStyleButtons = [
      {
        value: "<strong>B</strong>",
        style: "BOLD",
      },

      {
        value: "<em>I</em>",
        style: "ITALIC",
      },

      {
        value: "<u>U</u>",
        style: "UNDERLINE",
      },

      {
        value: "<del>S</del>",
        style: "STRIKETHROUGH",
      },
    ];

    return (
      <StyledEditorContainer>
        <div className="draft_input" id={this.contentKey}>
          <Editor
            editorState={this.state.editorState}
            onChange={this.onChange}
            handleKeyCommand={this.handleKeyCommand}
            placeholder={this.placeholderText}
            handlePastedText={this.handlePastedText}
            onFocus={this.focus}
            onBlur={this.blur}
            ref={this.setDomEditorRef}
            keyBindingFn={this.keyBindingFn}
            tabIndex={this.tabIndex}
          />
        </div>
        <div
          className="inline-style-options"
          style={{
            display: this.state.editorState.getSelection().getHasFocus()
              ? "flex"
              : "none",
          }}
        >
          {inlineStyleButtons.map((button) => {
            return this.renderInlineStyleButton(button.value, button.style);
          })}
          {this.props.quickAddOptions && (
            <div className="quickAdd">
              <div className="quickAddItems">
                {this.props.quickAddOptions.map((button) => (
                  <button
                    onMouseDown={(e) => {
                      e.preventDefault();
                      this.handleReplaceAllText(button, this.state.editorState);
                    }}
                  >
                    {button}
                  </button>
                ))}
              </div>
              <p>Quick add : </p>
            </div>
          )}
          {this.props.ctaViewSampleText && (
            <div className="quickAdd">
              <div className="quickAddItems">
                {this.props.blockContextSwitcher !== "textSamples" && (
                  <button
                    onMouseDown={(e) => {
                      e.preventDefault();
                      this.props.setBlockContextSwitcher("textSamples");
                    }}
                  >
                    View text snippets
                  </button>
                )}
                {this.props.blockContextSwitcher === "textSamples" && (
                  <p>You can copy text samples in the context card</p>
                )}
              </div>
            </div>
          )}
        </div>
        {/* All inline styles here: https://bit.ly/2xSMQzq */}
      </StyledEditorContainer>
    );
  }
}
