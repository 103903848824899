import React from "react";
import Style from "./basicInfoResponsiveHelper.module.scss";
import {} from "components/lib";

function BasicInfoResponsiveHelper(props) {
  return (
    <div className={Style.basicInfoResponsiveHelper}>
      <div className={Style.basicInfoMain}>{props.basicInfoMain}</div>
      <div className={Style.basicInfoPreview}>{props.basicInfoPreview}</div>
    </div>
  );
}

export default BasicInfoResponsiveHelper;
