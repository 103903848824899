import React, { useContext, useState } from "react";
import { MyButton, EditorContext, ViewContext } from "components/lib";
import Axios from "axios";

export function DownloadPDFbutton(props) {
  const theme = useContext(EditorContext).theme;
  const jobData = useContext(EditorContext).jobData;
  const jobBasicInfo = useContext(EditorContext).jobBasicInfo.values;
  const viewContext = useContext(ViewContext);
  const [loadingStatus, setLoadingStatus] = useState(false);
  
  const generateAndDownloadPDF = (callbackPDFGenerator) => {
    setLoadingStatus(true)
    // console.log(theme);
    Axios.post("/api/PDFgenerator", {
      backgroundColor: theme.background.color,
      jobId: jobData.id,
      jobTitle: jobBasicInfo.job_public_name,
      coverImage: {
        currentCoverPageStyle: "noBanner",
        coverImageLocation: null,
      },
    })
      .then((res) => {
        setLoadingStatus(false)
        // console.log(res.data.data.uuid);
        const pdfPublicUrl =
          "/uploads/pdf/" + res.data.data.uuid + ".pdf";
        // Create an anchor element with the download attribute set to the desired filename
        const link = document.createElement("a");
        link.setAttribute("download", jobBasicInfo.job_public_name.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '-') + ".pdf");
        link.href = pdfPublicUrl;

        // Programmatically click the anchor element to initiate the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      })
      .catch((err) => {
        setLoadingStatus(false)
        viewContext.notification.show(
          "There was an error generating a PDF ! Please reload",
          "error",
          true,
          "toast"
        );
        callbackPDFGenerator("errorGeneratingPDF");
        viewContext.handleError(err);
      });
  };

  return (
    <MyButton
      onClick={() => {
        generateAndDownloadPDF();
      }}
      buttonStyle="primary"
      icon={"download"}
      loading={loadingStatus}
      size="12px"
    >
      Download
    </MyButton>
  );
}
