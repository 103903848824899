import React from "react";
import Axios from "axios";

//Mantine theme provider
import { MantineProvider } from "@mantine/core";

// components
import { Router, Route, Switch } from "react-router-dom";
import { PrivateRoute, AuthProvider } from "./auth";
import { History, View } from "components/lib";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { mantineTheme } from "./mantineTheme.js";

import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";

// 404
import { NotFound } from "views/error/404";

// settings
const Settings = require("settings.json");
const StripePromise = loadStripe(
  Settings[process.env.NODE_ENV].stripe.publishableAPIKey
);

const Routes = [
  ...require("routes/account").default,
  ...require("routes/app").default,
  ...require("routes/auth").default,
  ...require("routes/website").default,
  ...require("routes/missioncontrol").default,
];

//adding sentry

Sentry.init({
  dsn: "https://2e50942d9c4da8b69443b9d19265bc10@o578260.ingest.sentry.io/4506533269405696",
  integrations: [Sentry.browserTracingIntegration()],
  // Performance Monitoring
  tracesSampleRate: 0.01, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/jobchef\.io/],
});

export default function App(props) {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user?.token) {
    // add auth token to api header calls
    Axios.defaults.headers.common["Authorization"] = "Bearer " + user.token;
  }

  
  // render the routes
  return (
    <Elements stripe={StripePromise}>
      <AuthProvider>
        <Router history={History}>
          <Switch>
            {Routes.map((route) => {
              const RouteType = route.permission ? PrivateRoute : Route;
              
              return (
                <RouteType
                  key={route.path}
                  exact
                  path={route.path}
                  permission={route.permission}
                  render={(data) => (
                    <MantineProvider theme={mantineTheme}>
                      <View
                        display={route.view}
                        layout={route.layout}
                        title={route.title}
                        description={route.description}
                        data={data}
                        path={route.path}
                      />
                    </MantineProvider>
                  )}
                />
              );
            })}

            {/* 404 */}
            <Route
              render={() => (
                <View display={NotFound} layout="home" title="404 Not Found" />
              )}
            />
          </Switch>
        </Router>
      </AuthProvider>
    </Elements>
  );
}
