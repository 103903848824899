const HelperDisplayCheckerSetterHelper = (
  contentValueArray,
  contentListArray,
  blockDictionary,
  blockId,
  setIsContent
) => {

  for (let i = 0; i < contentValueArray.length; i++) {
    const element = contentValueArray[i];
    if (_contentValueHelper(element, blockDictionary, blockId) !== "") {
      setIsContent(true);
      return true;
    }
  }
  for (let i = 0; i < contentListArray.length; i++) {
    const element = contentListArray[i];
    if (_listLengthCheckerHelper(element,blockDictionary, blockId)) {
      setIsContent(true)
      return true
    }
  }

  setIsContent(false);
  return false;
};

const _contentValueHelper = (contentKey, blockDictionary, blockId) => {
  if (
    blockDictionary[blockId].content[blockId + contentKey] === "" ||
    blockDictionary[blockId].content[blockId + contentKey] === undefined
  ) {
    return "";
  } else {
    return blockDictionary[blockId].content[blockId + contentKey];
  }
};

const _listLengthCheckerHelper = (contentKey, blockDictionary, blockId) => {
  const list_length = blockDictionary[blockId].content[contentKey].length;

  if (list_length === 0) {
    // console.log(list_length === 0);
    return false;
  }
  for (
    let index = 0;
    index < blockDictionary[blockId].content[contentKey].length;
    index++
  ) {
    if (
      _contentValueHelper(
        "_listItem_" + blockDictionary[blockId].content[contentKey][index],
        blockDictionary,
        blockId
      ) !== ""
    ) {
      return true;
    }
  }
  return false;
};

export { HelperDisplayCheckerSetterHelper };
