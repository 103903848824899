import React from "react";

export const contextCardInfo = {
  benefits: {
    sectionMainInfo: (
      <>
        <p>
          You know that thing that gets you so excited to wake up in the
          mornings and head to your job? (Other than, you know, your job?)
        </p>
        <p>
          This is the spot to tell prospective applicants about the perks and
          benefits that set this role apart from the rest.
        </p>
        <p>
          Use the overview section of this block to highlight any general
          benefits, and the list section to call out two to four extra special
          perks.
        </p>
      </>
    ),
    sectionTips: (
      <>
        <p>For this block, you should…</p>
        <ul>
          <li>
            <strong>Think visually: </strong> Every company can say they have
            great culture. Not every company has a beer fridge or a kickball
            team. When providing perks, think of tangible things you can point
            to.
          </li>
          <li>
            <strong>Think about your audience:</strong> Different perks matter
            to different people. If you’re hiring for a junior role, you might
            want to talk about happy hours or tuition reimbursement. A more
            seasoned pro might want to know about retirement plans and company
            shares.
          </li>
          <li>
            <strong>Think differently: </strong> Some perks are pretty much
            industry standard. Tick those off in your overview, but use the list
            function to highlight what benefits you have that your competitors
            don’t.
          </li>
        </ul>
      </>
    ),
    textSamples: {
      sectionTitle: {
        label: "Section Title",
        list: ["Benefits", "Perks and Incentives", "Benefits Package"],
      },
      shortIntroduction: {
        label: "Short introduction on the benefits",
        list: [
          // "The benefits and opportunities here are second to none. From day one, every employee enjoys industry-leading benefits including [401k, PTO, etc]. We’re here to help you become industry leaders, which is why we offer a wide array of opportunities to continue growing your talent outside of your 9-5 responsibilities:",
          // "The best ideas come when you’re having fun, so [company] is all aboutsmashing the walls between work and play. The average employee stays[number] years because they know there’s not another culture quitelike ours. Can you blame them? Who could pass on perks like these?:",
          // "We’ve grown by [percentage] since opening our doors in [year,] but this is just the beginning. We’ve got big plans for the future, and now is your chance to get in on the ground floor and help shape the future of [industry]. You’ll enjoy perks that those big box corporations can’t provide, like:",
          "We believe creating the right environment for our employees is what drives the best work. Here are some of the benefits you'll get by joining us:",
        ],
      },
      listOfPerks: {
        label: "List of key benefits",
        list: [
          "Opportunity for Career Advancement and Growth within the Company",
          "Supportive and Collaborative Work Environment",
          "Employee Recognition and Rewards Programs",
          "Maternity/Paternity Leave and Family-Friendly Policies",
          "Company-sponsored Social Events and Team Building Activities",
          "Health and Wellness Benefits, including Gym Memberships or Fitness Classes",
          "Tuition Reimbursement for Continuing Education and Professional Development",
          "Employee Stock Ownership or Profit-Sharing Programs",
          "Travel and Expense Reimbursement for Business-related Trips",
          "Access to Cutting-edge Technology and Tools for Enhanced Productivity",
        ],
      },
    },
  },
  description: {
    sectionMainInfo: (
      <>
        <p>
          This block is usually the first—and possibly only—part of the job
          description prospective applicants will see.
        </p>
        <p>
          It's a quick overview of the position designed to help them determine
          whether it is the right fit for them.
        </p>
        <p>
          You'll have a chance to talk about the specific qualifications later,
          so use this block to grab their interest and convey how amazing the
          opportunity is.
        </p>
      </>
    ),
    sectionTips: (
      <>
        <p>For this block, you should…</p>

        <ul>
          <li>
            <strong>Keep it short.</strong> 200 words at most. Don't
            worry--there's plenty of space for the details later.
          </li>
          <li>
            <strong>Keep it simple.</strong> Remember this is a job description,
            not a mystery novel. Twists and turns are a turn-off for applicants.
          </li>
          <li>
            <strong>Keep it cool.</strong> The applicant will try to impress
            you, but first you need to impress them. How would someone in this
            role describe what they do on a first date?
          </li>
        </ul>
      </>
    ),
    textSamples: {
      sectionTitle: {
        label: "Section Title",
        list: ["Job summary", "Your mission", "Your role in a few words"],
      },
      shortIntroduction: {
        label: "Short summary of the job",
        list: [
          "Seeking a motivated go-getter to lead our [amazing team name] as we expand into [exciting region]. A great growth opportunity for someone interested in [career-advancing skill] and developing a network in the [incredible industry] space.",
          "Break into the [your incredible industry] with this role based in our [chic metropolitan city] office. Support our [mentor position title] on an industry-leading initiative to [inspiring social mission].",
          "If you like running multiple projects, this is the role for you. We’re looking for a multi-talented multi-tasker who can handle our [very interesting responsibility], [even more interesting responsibility], and [the most interesting responsibility—wow, we might apply for this one!].",
        ],
      },
    },
  },
  company_overview: {
    sectionMainInfo: (
      <>
        <p>
          You know what it's like to work for your company. Your applicants
          don't (or at least, not yet!)
        </p>
        <p>
          This is your chance to outline your story, values and culture and help
          them know if they'll be a good fit. It also gives your applicants
          material to use in their cover letters.
        </p>
      </>
    ),
    sectionTips: (
      <>
        <p>For this block, you should focus…</p>

        <ul>
          <li>
            <strong>A little on yesterday:</strong> You probably have a company
            history you can copy and paste from your website. But if an
            applicant is doing their homework, they’ll find that on your
            website. Keep your origin story brief.
          </li>
          <li>
            <strong>More on today: </strong> Applicants are more interested in
            what it’s like to work at your company today. What are your big
            initiatives and products? Recent wins and achievements?
          </li>
          <li>
            <strong>The most on the future:</strong> The question “Where do you
            see yourself in five years?” truly goes both ways. Consider where
            your company is headed, and how this candidate can help you get
            there.
          </li>
        </ul>
      </>
    ),
    textSamples: {
      sectionTitle: {
        label: "Section Title",
        list: [
          "Company overview",
          "Our company",
          "Who we are",
          "A little bit about us",
        ],
      },
      shortIntroduction: {
        label: "Company overview ",
        list: [
          "Transforming the world with digital innovation is our core mission. Our platform empowers a diverse range of users, from budding creatives to major global brands, providing the tools needed to craft and share outstanding digital experiences. Our passion lies in enabling users to produce stunning visuals, engaging videos, and functional apps, revolutionizing the way businesses engage with customers across all digital platforms. ",
          "Here at [Company name], our goal is to attract top talent, dedicated to fostering an exceptional work environment where respect and equal opportunities are paramount. We believe that great ideas can emerge from any level of our organization, and we're excited about the possibility that the next groundbreaking idea could be yours!",
        ],
      },
    },
  },
  fine_print: {
    sectionMainInfo: (
      <>
        <p>
          This is the spot where you put all the stuff from your legal team
          about how the hiring process is fair and square.
        </p>
        <p>
          This will likely be the same for every job you post. (That's kinda how
          fair and square works, right?)
        </p>
      </>
    ),
    sectionTips: (
      <>
        <p>Some things you can include here…</p>
        <ul>
          <li>
            <strong>Hiring practices:</strong> Discuss how you are an equal
            opportunity employer, and include any information about your
            diversity and inclusion commitments.
          </li>
          <li>
            <strong>Applicant FYIs:</strong> Give them a heads up about any
            background checks they may need to undergo or documents they may
            need to provide.
          </li>
          <li>
            <strong>Next steps: </strong> If you are unable to contact every
            candidate who applies, this is a good place to say so. You can also
            include dates when successful candidates may be contacted.
          </li>
        </ul>
      </>
    ),
    textSamples: {
      sectionTitle: {
        label: "Section Title",
        list: ["The fine prints", "Things you should know"],
      },
      shortIntroduction: {
        label: "Fine prints overview ",
        list: [
          "At [Company Name], we champion an inclusive, diverse work environment. We are an equal opportunity employer that values every team member's unique perspectives and backgrounds. Our commitment to inclusion drives innovation and collaboration, ensuring all applicants and employees are treated fairly, regardless of race, gender, age, disability, or other protected statuses.",
          "[Company Name] is dedicated to being an equal opportunity workplace and an affirmative action employer. We ensure equal employment opportunities are available to all, irrespective of race, color, ancestry, religion, sex, national origin, sexual orientation, age, citizenship, marital status, disability, gender identity, or Veteran status."
        ],
      },
    },
  },
  key_responsabilities: {
    sectionMainInfo: (
      <>
        <p>
          If the "job summary" was the movie trailer, this is the actual show.
          Help your applicant understand what someone in this job would actually
          be doing all day, every day.
        </p>
        <p>
          Use a paragraph to expand on the summary by offering specific details
          on the project and the team. Then, use the bullet points to break down
          the day-to-day tasks they will be expected to tackle.
        </p>
      </>
    ),
    sectionTips: (
      <>
        <p>For this block, you should…</p>

        <ul>
          <li>
            <strong>Continue, not copy:</strong> If someone is reading this
            section, it means they were enticed by the job summary, so you don’t
            need to repeat it here. What else do they need to know?
          </li>
          <li>
            <strong>Excite, not exaggerate:</strong> You’re still trying to sell
            potential applicants, but don’t promise them a job you can’t
            deliver. Keep it focused on the concrete details of the position.
          </li>
          <li>
            <strong>Prioritise, not alphabetise:</strong> Applicants assume the
            first responsibility listed is the most important. Try assigning
            each task a percentage based on how much of their workday it will
            take up, and put the tasks with the highest numbers on top. If
            something will take up less than 10% of the day, you can probably
            skip it.
          </li>
        </ul>
      </>
    ),
    textSamples: {
      sectionTitle: {
        label: "Section Title",
        list: ["Key responsabilities"],
      },
      shortIntroduction: {
        label: "Key responsabilities overview ",
        list: [
          "After growing by [percentage] last year, our team is looking for a rockstar [role] to manage our popular [service or product] team. Reporting into the [job position] and leading a team of [number], you will be responsible for [key quality indicator, e.g. ensuring seamless customer experiences]. To do this, you’ll lead on a handful of key tasks: [list concrete responsibilities].",
          "Are you a [industry] professional looking for your next challenge? Our business is [product name], an exciting new product that will amazing thing that the product does]. In this role, you will have the chance to build a [job responsibility e.g. marketing/finance] plan from the bottom up and write the next chapter in our company’s iconic story. Here’s how you’ll do that: [list concrete responsibilities].",
          "Our company has a simple mission: [simple mission]. To help us meet our goal of [% or # goal reflecting your mission], we’re seeking a new [position title] to supercharge our progress in the [area where you need help]. If you’re a [professional] or a [professional] with experience in [key industry or skill, e.g. sustainable growth, or rapid product development] we’d love to talk! Key responsibilities of this role include: [list concrete responsibilities].",
        ],
      },
    },
  },
  skills_and_requirements: {
    sectionMainInfo: (
      <>
        <p>
          Your first two blocks will garner a lot of interest in your position.
          But your goal isn’t to get the most applicants, it’s to get the right
          applicants.
        </p>
        <p>
          With this block, you can be clear about the must-haves, nice-to-haves,
          and amazing-to-haves of the position. This will help narrow the field
          to the right candidates, saving you—and them—time.
        </p>
      </>
    ),
    sectionTips: (
      <>
        <p>For this block, you should…</p>
        <ul>
          <li>
            <strong>Be specific: </strong> Candidates should be able to answer
            each of these requirements with an objective yes or no. Things like
            “fast learner” and “natural leader” are qualities, not
            qualifications.
          </li>
          <li>
            <strong>Be realistic:</strong> Start with the basic, must-have
            requirements for the position, and build up towards the skills and
            talents that will really help a candidate stand out. Use phrases
            like “preferred,” or “nice-to-have” to flag the qualifications that
            aren’t absolute deal-breakers.
          </li>
          <li>
            <strong>Be comprehensive:</strong> Don’t forget about basic
            qualifications like citizenship, certifications, and language
            proficiency.
          </li>
        </ul>
      </>
    ),
    textSamples: {
      sectionTitle: {
        label: "Section Title",
        list: ["Skills & Requirements", "Expected qualifications"],
      },
      shortIntroduction: {
        label: "Short introduction",
        list: [
          "This role demands specific qualifications. Please review them carefully before applying.",
          "Before applying, carefully consider the specific qualifications needed for this position.",
          "Let's make sure we're a match! Check out the key requirements before hitting that apply button.",
        ],
      },
      listOfSkills: {
        label: "List of skills ",
        list: [
          "[Years]+ of experience in [industry] or related industry",
          "Proficient in [language/software/programming language]",
          "[Certification] from [official certifying organisation]",
          "Prior experience in [specific task, e.g. launching a product, planning conferences, using specific equipment or machinery]",
          "[Associate’s/Bachelor’s/Master’s] Degree in [Topic]",
          "Prior experience managing a direct report",
        ],
      },
    },
  },
};
