import React, { useContext, useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import {
  HelperBlockCardPreview,
  HelperBlockContextCard,
  HelperDisplayCheckerSetterHelper,
} from "./_index.js";
import { Loader, EditorContext, MyTextEditor, MyButton } from "../../lib";
import yellow_banana from "./images/food/yellow_banana.svg";
import parse from "html-react-parser";

import Style from "./BlockAll.module.scss";

const blockColor = "#faeda7";
const blockTextColor = "#faeda7";
const blockName = "The fine print";

const block_fine_print_data_generator = () => {
  const new_uuid = uuid();

  return {
    key: new_uuid,
    data: {
      version: 1,
      id: new_uuid, //block
      type: "fine_print",
      content: {},
    },
  };
};

const BlockFinePrintPreview = (props) => {
  // Content items
  const selectedBlock = useContext(EditorContext).selectedBlock;
  const blockDictionary = useContext(EditorContext).blockDictionary;
  const currentStep = useContext(EditorContext).currentStep;

  const [isContent, setIsContent] = useState(false);

  const _contentValueHelper = (contentKey) => {
    if (
      blockDictionary[props.blockId].content[props.blockId + contentKey] ===
        "" ||
      blockDictionary[props.blockId].content[props.blockId + contentKey] ===
        undefined
    ) {
      return "";
    } else {
      return blockDictionary[props.blockId].content[props.blockId + contentKey];
    }
  };

  const contentValueArray = ["title", "body"];
  const contentListArray = [];

  useEffect(() => {
    HelperDisplayCheckerSetterHelper(
      contentValueArray,
      contentListArray,
      blockDictionary,
      props.blockId,
      setIsContent
    );
    return () => {
      HelperDisplayCheckerSetterHelper(
        contentValueArray,
        contentListArray,
        blockDictionary,
        props.blockId,
        setIsContent
      );
    };
  }, []);

  useEffect(() => {
    HelperDisplayCheckerSetterHelper(
      contentValueArray,
      contentListArray,
      blockDictionary,
      props.blockId,
      setIsContent
    );
  }, [selectedBlock]);

  if (props.publicRender && !isContent) {
    return null;
  }
  return (
    <>
      {blockDictionary[props.blockId] === null && <Loader />}

      {blockDictionary[props.blockId] !== null && !isContent && (
        <div
          className={Style.emptyBlock}
          style={{ borderColor: blockColor, color: blockColor }}
        >
          <h2>{blockName}</h2>
          <p>Click to add content</p>
        </div>
      )}
      {blockDictionary[props.blockId] !== null &&
        isContent &&
        currentStep !== "block_editor" && (
          <div className={"jc_block"}>
            <div className="style_jc fine_prints_jc">
              {parse(_contentValueHelper("title"))}
              {parse(_contentValueHelper("body"))}
            </div>{" "}
          </div>
        )}
      {blockDictionary[props.blockId] !== null &&
        isContent &&
        currentStep === "block_editor" && (
          <>
              {parse(_contentValueHelper("title"))}
              {parse(_contentValueHelper("body"))}
          </>
        )}
    </>
  );
};

const BlockFinePrintCardPreview = (props) => {
  return (
    <HelperBlockCardPreview
      image={yellow_banana}
      header="The Fine Prints"
      short_description="For all your legal lingo, hiring must-haves, and other fun stuff."
      block_type="fine_print"
      color="--red-medium"
      color_light="#FBF2C2"
    />
  );
};

const BlockFinePrintEditor = (props) => {
  const setSelectedBlock = useContext(EditorContext).setSelectedBlock;

  return (
    <>
      <div className={Style.editorCloseButtonWrapper}>
        <h3
          className={Style.sectionTitle}
          style={{ color: blockTextColor, textTransform: "uppercase" }}
        >
          {blockName}
        </h3>
        <MyButton
          buttonStyle="secondary"
          size="small"
          onClick={() => {
            setSelectedBlock(null);
          }}
        >
          Close
        </MyButton>
      </div>
      <br />
      <p className={Style.sectionSubtitle}>Section title :</p>
      <MyTextEditor
        blockId={props.blockId}
        contentKey={props.blockId + "title"}
        placeholderText={"Type a summary section title..."}
        contentBlockType="header-two"
        quickAddOptions={["Things you should know"]}
      />
      <p className={Style.sectionSubtitle}>Short fine print content :</p>
      <MyTextEditor
        blockId={props.blockId}
        contentKey={props.blockId + "body"}
        placeholderText={"Type the summary for this job..."}
        ctaViewSampleText
      />
    </>
  );
};

const BlockFinePrintContext = (props) => {
  return (
    <HelperBlockContextCard
      header={"THE FINE PRINTS"}
      blockType={"fine_print"}
      color="#f7e26b"
      image={yellow_banana}
      iconStyle={Style.yellow}
    />
  );
};

export {
  BlockFinePrintPreview,
  BlockFinePrintCardPreview,
  BlockFinePrintEditor,
  BlockFinePrintContext,
  block_fine_print_data_generator,
};
