import React, { useState, useContext } from "react";
import { MyButton, Icon, ViewContext, History } from "components/lib";
import Style from "./createNewJobModal.module.scss";
import Modal from "@material-ui/core/Modal";
import { Formik } from "formik";
import Axios from "axios";

export function CreateNewJobModal(props) {
  const viewContext = useContext(ViewContext);

  const [open, setOpen] = useState(false);


  const createJob = (props) => {

    Axios.post("/api/job/", {
      category: props.category,
      name: props.name,
    })
      .then((res) => {
        // console.log(res.status);
        // console.log(res.data.data.id);
        History.push("/jobeditor/" + res.data.data.id);
      })
      .catch((err) => {
        viewContext.notification.show(
          "There was an error saving your job ! Please reload",
          "error",
          true,
          "toast"
        );
        viewContext.handleError(err);
      });
  };
  return (
    <>
      <MyButton
        onClick={() => {
          setOpen(true);
        }}
        size="medium"
        buttonStyle={"primary"}
      >
        {props.customButtonText ? props.customButtonText : "Create new job"}
      </MyButton>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className={Style.delete_modal}>
          <h2>Create a new job</h2>

          <Formik
            initialValues={{ name: "", category: "" }}
            validate={(values) => {
              const errors = {};

              if (!values.name) {
                errors.name = "Required";
              }
              if (!values.category || values.category === "default") {
                errors.category = "Required";
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              createJob(values);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isValid,
              dirty,
            }) => (
              <form onSubmit={handleSubmit} className={Style.formStyle}>
                <div className={Style.inputWrapper}>
                  <p className={Style.itemTitle}>Job name</p>
                  <p className={Style.itemSubTitle}>
                    Give it a <i>private name</i>, you will be able to give a
                    public name on the next step.{" "}
                    <span role="img" aria-label="Unsure">
                      😉
                    </span>
                  </p>
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    autocomplete="off"
                  />
                  <span className={Style.errorMessage}>
                    {errors.name && touched.name && errors.name}
                  </span>
                </div>
                <p className={Style.itemTitle}>Category</p>
                <p className={Style.itemSubTitle}>
                  Categories help you filter and report on your jobs.
                </p>
                <div className={Style.inputWrapper}>
                  <select
                    name="category"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.category}
                    className={Style.inputStyle}
                  >
                    <option value="default">Please select a category...</option>
                    {Object.keys(functionList).map((cat,index) => {
                      return <option value={cat}>{functionList[cat]}</option>;
                    })}
                  </select>
                  <span className={Style.errorMessage}>
                    {errors.category && touched.category && errors.category}
                  </span>
                </div>
                {/*https://github.com/harpreetkhalsagtbit/country-state-city */}
                <div className={Style.flexDivHorizontal}>
                  <MyButton
                    onClick={() => handleSubmit()}
                    buttonStyle={!(isValid && dirty) ? "disabled" : "primary"}
                    size={"medium"}
                    className={Style.buttonsStyle}
                  >
                    Create
                    <Icon
                      image={"file-plus"}
                      size={14}
                      className={Style.buttonsIconStyle}
                    />
                  </MyButton>
                  <MyButton
                    buttonStyle={"outline"}
                    size={"medium"}
                    className={Style.buttonsStyle}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancel
                  </MyButton>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
}

const functionList = {
  arts_design:"Arts & Design",
  business: "Business",
  Consulting: "Consulting",
  education: "Education",
  engineering: "Engineering",
  healthcare: "Healthcare",
  human_esources: "Human Resources",
  information_technology: "Information Technology",
  media_communications: "Media & Communications",
  product_project_management: "Product & Project Management",
  research_science: "Research & Science",
  retail_food_Services: "Retail & Food Services",
  skilled_labor_manufacturing: "Skilled Labor & Manufacturing",
  transportation: "Transportation",
  other: "Other",
};
