/***
 *
 *   RESET PASSWORD
 *   User can set a new password using the token
 *
 **********/

import React, { useContext } from "react";
import { Animate, Row, AuthContext, Card, Form } from "components/lib";
import Style from "../auth.module.scss";

export function ResetPassword(props) {
  // context
  const context = useContext(AuthContext);

  // check for token
  const qs = props.location.search;
  const token = qs.substring(qs.indexOf("?token=") + 7);

  return (
    <div className={Style.mainCard}>
      <div className={Style.contentWrapper}>
        <h2>Reset Your Password</h2>
        <Card restrictWidth center>
          <Form
            data={{
              jwt: {
                type: "hidden",
                value: token,
              },
              email: {
                label: "Email",
                type: "email",
              },
              password: {
                label: "New Password",
                type: "password",
                required: true,
                complexPassword: true,
              },
            }}
            url="/api/auth/password/reset"
            method="POST"
            buttonText="Set New Password"
            callback={(data) => {
              context.signin(data);
            }}
          />
        </Card>
      </div>
    </div>
  );
}
