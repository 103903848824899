/***
 *
 *   AUTHENTICATION
 *   Auth provider to manage auth functions throughout
 *   the application. <PrivateRoute> component to
 *   protect internal application routes from unauthenticated
 *   access.
 *
 **********/

import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Redirect, Route } from "react-router-dom";

// auth context
export const AuthContext = React.createContext();

const useAPI = require("components/lib").useAPI;
const permissions = require("./permissions");

export function AuthProvider(props) {
  const cache = JSON.parse(localStorage.getItem("user"));
  const [user, setUser] = useState(cache);
  const auth = useAPI(user ? "/api/auth" : null);

  useEffect(() => {
    // update the auth status
    if (!auth.loading && auth.data) {
      auth.data.authenticated ? update(auth.data) : signout();
    }
  }, [auth]);

  function signin(res) {
    if (res.data) {
      console.log(res.data);

      localStorage.setItem("user", JSON.stringify(res.data));

      if (res.data.permission === "master")
        return (window.location = "/missioncontrol/dashboard");

      if (!res.data.plan && !res.data.has_stripe_customer_id && res.data.permission === "owner") {
        return (window.location = "/signup/plan");
      }

      // if (!res.data.plan) {
      //   return (window.location = "/signup/plan")
      // };

      return (window.location = res.data.onboarded ? "/dashboard" : "/welcome");
    }
  }

  async function signout() {
    Axios({ method: "delete", url: "/api/auth" });
    localStorage.clear();
    window.location = "/signin";
  }

  async function switchAccount(id) {
    const res = await Axios({
      method: "post",
      url: "/api/auth/switch",
      data: { account: id },
    });

    if (res.data.data) signin(res.data);
  }

  function update(data) {
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      for (let key in data) {
        if (Array.isArray(data[key])) {
          user[key] = data[key];
        } else if (typeof data[key] === "object") {
          for (let innerKey in data[key]) {
            user[key][innerKey] = data[key][innerKey];
          }
        } else {
          user[key] = data[key];
        }
      }

      localStorage.setItem("user", JSON.stringify(user));
      setUser(user);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user: user,
        signin: signin,
        signout: signout,
        update: update,
        switchAccount: switchAccount,
        permission: permissions[user?.permission],
      }}
      {...props}
    />
  );
}

// custom route object checks for an auth token before
// rendering the route – redirects if token is not present

export function PrivateRoute(props) {
  return (
    <Route
      {...props}
      render={(data) => {
        // check user
        const user = JSON.parse(localStorage.getItem("user"));
        const path = window.location.pathname;
        
        if (user?.token) {
          // Clear data if token is expired
          const token_exp = JSON.parse(
            window.atob(user.token.split(".")[1])
          ).exp;
          if (Date.now() >= token_exp * 1000) {
            console.log("Token expired!");
            localStorage.clear();
            return <Redirect to="/signin" />;
          }
          
          let always_allowed_paths =
          path === "/signup/plan" ||
          path === "/account/upgrade" ||
          path === "/account" ||
          path === "/account/password" ||
          path === "/help";
          
          if (permissions[props.permission][props.permission]) {
            if (always_allowed_paths) {
              return <props.render {...props} />;
            }

            if (!user.account_active && user.account_active !== undefined) { // Added underfined here because the Router should only be used when the account_active has been set in the localstorage
              if (user.has_stripe_customer_id) {
                return <Redirect to="/account/upgrade" />;
              } else {
                return <Redirect to="/signup/plan" />;
              }
            }
            
            // user is good
            return <props.render {...props} />;
          }
        }
        
        // user is not authenticated
        return <Redirect to="/signin" />;
      }}
    />
  );
}

// export function TODELETE_PrivateRoute(props) {
//   return (
//     <Route
//       {...props}
//       render={(data) => {
//         // check user
//         const user = JSON.parse(localStorage.getItem("user"));
//         const path = window.location.pathname;

//         if (user?.token) {
//           // Clear data if token is expired
//           const token_exp = JSON.parse(
//             window.atob(user.token.split(".")[1])
//           ).exp;
//           if (Date.now() >= token_exp * 1000) {
//             console.log("Token expired!");
//             localStorage.clear();
//             return <Redirect to="/signin" />;
//           }

//           if (permissions[props.permission][props.permission]) {
//             // user has no plan
//             if (
//               !user.plan &&
//               path !== "/account" &&
//               path !== "/account/upgrade" &&
//               path !== "/signup/plan"
//             ) {
//               if (user.has_stripe_customer_id) {
//                 return <Redirect to="/account/upgrade" />;
//               } else {
//                 return <Redirect to="/signup/plan" />;
//               }
//             }

//             // user has no subscription
//             if (
//               user.subscription !== "active" &&
//               user.subscription !== "trialing" &&
//               user.permission !== "master"
//             ) {
//               // TODO : This is where the account it locked when not active

//               if (
//                 !path.includes("/account/billing") &&
//                 !path.includes("/signup/plan") &&
//                 !path.includes("/account/upgrade") &&
//                 !path.includes("/account")
//               ) {
//                 return <Redirect to="/account/billing" />;
//               }
//             }

//             // user is good
//             return <props.render {...props} />;
//           }
//         }

//         // user is not authenticated
//         return <Redirect to="/signin" />;
//       }}
//     />
//   );
// }
