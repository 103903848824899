/***
 *
 *   MAGIC SIGN IN
 *   Confirms magic token and redirects to dashboard if successful
 *
 **********/

import Axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { AuthContext, Logo, Message } from "components/lib";
import Style from "../auth.module.scss";

export function MagicSignin(props) {
  const qs = props.location.search;
  const context = useContext(AuthContext);
  const [message, setMessage] = useState({
    type: "success",
    title: "Using The Magic",
    text: "Signing you in now...",
  });

  useEffect(() => {
    const verifyToken = async (token) => {
      try {
        const res = await Axios.post("/api/auth/magic/verify", {
          token: token,
        });
        res.status === 200 ? context.signin(res) : invalidLink();
      } catch (err) {
        invalidLink();
      }
    };

    if (qs.includes("?token=")) {
      // check token exists
      verifyToken(qs.substring(qs.indexOf("?token=") + 7));
    } else {
      invalidLink();
    }
  }, [qs, context]);

  function invalidLink() {
    setMessage({
      type: "error",
      title: "Magic Link is invalid",
      text: "Please generate a new link and try again",
      buttonLink: "/signin",
      buttonText: "Back to Sign In",
    });
  }

  return (
    <div className={Style.mainCard}>
      <div className={Style.contentWrapper}>
        <div style={{ width: "28em", margin: "0 auto" }}>
          <Message
            type={message.type}
            title={message.title}
            text={message.text}
            buttonText={message.buttonText}
            buttonLink={message.buttonLink}
          />
        </div>
      </div>
    </div>
  );
}
