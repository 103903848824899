import React, { useContext,useEffect } from "react";
import Style from "./HelperContextSwitcher.module.scss";
import { Icon, EditorContext } from "components/lib";

const HelperContextSwitcher = (props) => {
  const setBlockContextSwitcher = useContext(EditorContext).setBlockContextSwitcher;
  const blockContextSwitcher = useContext(EditorContext).blockContextSwitcher;

  useEffect(() => {
    setBlockContextSwitcher("blockInfo")
  }, [])

  return (
    <>
      <div className={Style.helperContextSwitcher}>
        <button
          onClick={() => setBlockContextSwitcher("blockInfo")}
          className={blockContextSwitcher === "blockInfo" ? Style.activeButton : ""}
        >
          Section information
          <Icon image="file-text" size="12px" />
        </button>
        <button
          onClick={() => setBlockContextSwitcher("textSamples")}
          className={blockContextSwitcher === "textSamples" ? Style.activeButton : ""}
        >
          Text snippets
          <Icon image="clipboard" size="12px" />
        </button>
      </div>
      {blockContextSwitcher === "blockInfo" && props.blockInfo}
      {blockContextSwitcher === "textSamples" && props.textSamples}
    </>
  );
};

export { HelperContextSwitcher };
