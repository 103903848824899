import React, { useContext, useState } from "react";
import Style from "./themePicker.module.scss";
import { EditorContext, MyButton } from "components/lib";
import _ from "lodash";

export function SelectThemeCard(props) {
  const setTheme = useContext(EditorContext).setTheme;
  let theme = useContext(EditorContext).theme;

  const [loadTheme] = useState(_.cloneDeep(theme));

  if (props?.fixedTheme) {
    theme = props.fixedTheme;
  }

  return (
    <div
      className={Style.ThemePicker}
      // onMouseEnter={() => {
      //   setTheme(theme);
      // }}
      // onMouseLeave={() => {
      //   setTheme(loadTheme);
      // }} // TODO : This was working before, but it was triggering a save on every mouse over ...
    >
      <div>
        <span className={Style.pickerSubtitle}>
          {props.themeName ? props.themeName : "CUSTOM"}
        </span>
        <div>
          <h1
            style={{
              color: theme.h1.color,
              fontFamily: theme.h1.font,
              fontSize: "1.2em",
            }}
          >
            The quick brown fox
          </h1>
          <h3
            style={{
              color: theme.h2.color,
              fontFamily: theme.h2.font,
              fontSize: "0.8em",
              marginBottom: "0px",
            }}
          >
            Jumps over the lazy dog
          </h3>
          <p
            style={{
              color: theme.body.color,
              fontFamily: theme.body.font,
              fontSize: "0.6em",
            }}
          >
            The quick brown fox jumps over the lazy dog
          </p>
        </div>
      </div>
      <MyButton
        onClick={() => {
          setTheme(theme);
          props.setSecondaryView();
        }}
        buttonStyle={"primary"}
      >
        select
      </MyButton>
    </div>
  );
}
