import React, { Fragment } from 'react';
import { useAPI } from 'components/lib';

export function UserImage(props){

  const data = useAPI('/api/userImage');

  if (!data)
    return false;

  return(
    <Fragment>

      {/* render view data */ }

    </Fragment>

  );
}
