import React, { useContext } from "react";
import {
  Icon,
} from "components/lib";
import Style from "./reassurancePayment.module.scss";

import { ReactComponent as PoweredBy } from "./img/powered_by_stripe.svg";

export function ReassurancePayment(props) {
  return (
    <div className={Style.guarantee}>
      <Icon image="lock" color="#313131" />{" "}
      <span className={Style.label}>
        Guaranteed
        <strong> safe & secure </strong> checkout
      </span>
      <PoweredBy />
    </div>
  );
}
