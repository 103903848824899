import React from "react";
import { Icon } from "components/lib";
import Style from "./backgroundColorInput.module.scss";
import { Field } from "formik";
import { ColorPicker } from "./colorPicker.js";

export const BackgroundColorInput = (props) => {
  const theme = props.theme;


  return (
    <>
          <div className={Style.formTitle}>
            <Icon color="#ffffff" image="bold" />
            <h3>Background color</h3>
          </div>
          <>
            <div
              style={{
                display: "flex",
                borderRadius: "4px",
                padding: "8px 12px 12px 25px",
              }}
            >
              <div style={{ width: "100%" }}>
                <div style={{ width: "100%", marginBottom: "12px" }}>
                  <p className={Style.inputLabel}>Background Color : </p>
                  <div style={{ marginLeft: "auto" }}>
                    <Field name="background.color">
                      {(props) => (
                        <ColorPicker
                          name={"background.color"}
                          startColor={theme.background.color}
                          {...props}
                        /> 
                      )}
                    </Field>
                  </div>
                </div>

              </div>
            </div>
          </>
    </>
  );
};
