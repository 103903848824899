import React, { useState } from "react";
import Style from "./jobIsUnpublished.module.scss";
import { Icon, MyButton } from "components/lib";

export const JobIsUnpublished = () => {
  return (
    <div className={Style.jobIsUnpublished}>
      <Icon image="search" size="40px" />
      <h1>Job Posting Unavailable</h1>
      <p>It appears that this job posting is currently unavailable for new applications.</p>
      <p>
      There could be several reasons for this, such as the position being filled already or the recruiter not accepting new applications at this time.
      </p>
      {/* <a href="" buttonStyle="primary">Visit company website</MyButton> */}
    </div>
  );
};
