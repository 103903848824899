import React, { useEffect, useContext } from "react";
import { MyButton, EditorContext, Icon } from "components/lib";
import Style from "./jobBasicInfoForm.module.scss";
import { Formik, useFormikContext, Field } from "formik";
import { ScrollArea } from "@mantine/core";

const MyOnChangeComponent = () => {
  const { values, errors, isValid, dirty, touched, setValues } =
    useFormikContext();
  const jobBasicInfo = useContext(EditorContext).jobBasicInfo;
  const setJobBasicInfo = useContext(EditorContext).setJobBasicInfo;

  useEffect(() => {
    if (jobBasicInfo.values) {
      setValues(jobBasicInfo.values);
    }
  }, []);

  useEffect(() => {
    const basicInfoCompleted = isValid && dirty;
    const newjobBasicInfo = { values: values, errors: basicInfoCompleted }; //TODO : use lodash ?
    setJobBasicInfo(newjobBasicInfo);
  }, [values, touched, errors]);

  return null;
};

export function JobBasicInfoForm(props) {
  const setCurrentStep = useContext(EditorContext).setCurrentStep;

  return (
    <div className={Style.editorBasicInfoCard}>
      <div className={Style.jobBasicInfoForm}>
        <h2>Basic information</h2>
        <p className={Style.headerSubTitle}>
          Start by adding some key information about your job — if needed,
          toggle the visibility icon <Icon image="eye" size="0.8em" /> to hide
          this information from your job posting.
        </p>
        <div className={Style.divider}></div>
        <Formik
          initialValues={{
            job_public_name: "",
            workplace_type_visibility: true,
            employment_type_visibility: true,
            location_visibility: true,
            salary_visibility: true,
            company_visibility: true,
          }}
          validate={(values) => {
            const errors = {};

            if (!values.job_public_name || values.job_public_name === "") {
              errors.job_public_name = "Required";
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              alert(JSON.stringify(values, null, 2));

              setSubmitting(false);
            }, 400);
          }}
        >
          {({
            values,
            errors,
            touched,
            isValid,
            dirty,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <>
              <MyOnChangeComponent callback={() => {return}} />
              <form onSubmit={handleSubmit} className={Style.formStyle}>
                <ScrollArea type="always" className={Style.scrollAreaHeight} offsetScrollbars>
                  <div className={Style.inputWrapper}>
                    <p className={Style.itemTitle}>
                      Public job title{" "}
                      <span className={Style.requiredIndicator}>*</span>
                    </p>
                    <p className={Style.headerSubTitle}>
                      This is the title candidates will see externaly.
                    </p>
                    <input
                      type="text"
                      name="job_public_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.job_public_name}
                    />

                    <span className={Style.errorMessage}>
                      {errors.job_public_name &&
                        touched.job_public_name &&
                        errors.job_public_name}
                    </span>
                  </div>
                  <p className={Style.itemTitle}>Key information</p>
                  <p className={Style.headerSubTitle}>
                    Remember this information will be <i>quickly scanned</i> by
                    potential candidates, it's first element of your job posting.
                  </p>

                  <div className={Style.inputWrapper}>
                    <p className={Style.itemTitle}>Location</p>
                    <div className={Style.tuggleEYEconWrapper}>
                      <input
                        type="text"
                        name="location"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.location}
                      />
                      <Field
                        name="location_visibility"
                        component={EyeIconToggle}
                      />
                    </div>
                    <span className={Style.errorMessage}>
                      {errors.location && touched.location && errors.location}
                    </span>
                  </div>
                  <div className={Style.inputWrapper}>
                    <p className={Style.itemTitle}>Employment type</p>
                    <div className={Style.tuggleEYEconWrapper}>
                      <Field as="select" name="employment_type">
                        <option value="">Choose one…</option>
                        <option value="full_time">Full-time</option>
                        <option value="part_time">Part-time</option>
                        <option value="contract">Contract</option>
                        <option value="temporary">Temporary</option>
                        <option value="volunteer">Volunteer</option>
                        <option value="internship">Internship</option>
                      </Field>
                      <Field
                        name="employment_type_visibility"
                        component={EyeIconToggle}
                      />
                    </div>
                    <span className={Style.errorMessage}>
                      {errors.employment_type &&
                        touched.employment_type &&
                        errors.employment_type}
                    </span>
                  </div>
                  <div className={Style.inputWrapper}>
                    <p className={Style.itemTitle}>Workplace type</p>
                    <div className={Style.tuggleEYEconWrapper}>
                      <Field as="select" name="workplace_type">
                        <option value="">Choose one…</option>
                        <option value="on_site">On-site</option>
                        <option value="hybrid">Hybrid</option>
                        <option value="remote">Remote</option>
                      </Field>
                      <Field
                        name="workplace_type_visibility"
                        component={EyeIconToggle}
                      />
                    </div>
                    <span className={Style.errorMessage}>
                      {errors.workplace_type &&
                        touched.workplace_type &&
                        errors.workplace_type}
                    </span>
                  </div>
                  <div className={Style.inputWrapper}>
                    <p className={Style.itemTitle}>Salary range</p>
                    <div className={Style.tuggleEYEconWrapper}>
                    <input
                      type="text"
                      name="salary"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.salary}
                    />
                     <Field
                        name="salary_visibility"
                        component={EyeIconToggle}
                      />
                    </div>
                    <span className={Style.errorMessage}>
                      {errors.salary && touched.salary && errors.salary}
                    </span>
                  </div>
                  <div className={Style.inputWrapper}>
                    <p className={Style.itemTitle}>Company</p>
                    <div className={Style.tuggleEYEconWrapper}>
                    <input
                      type="text"
                      name="company"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.company}
                    />
                     <Field
                        name="company_visibility"
                        component={EyeIconToggle}
                      />
                    </div>
                    <span className={Style.errorMessage}>
                      {errors.company && touched.company && errors.company}
                    </span>
                  </div>
                </ScrollArea>
                <MyButton
                  size="medium"
                  buttonStyle={isValid && dirty ? "primary" : "disabled"}
                  onClick={() => {
                    if (isValid && dirty) {
                      setCurrentStep("block_editor");
                    }
                  }}
                  className={Style.submitButton}
                >
                  Next step
                </MyButton>
              </form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
}

// const EyeIconToggle = (props) => {
//   return (
//     <Field name={props.name} value="true">
//       {({
//         field, // { name, value, onChange, onBlur }
//         form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
//         meta,
//       }) => (
//           <Icon image="eye" size="1.3em" onClick={()=>console.log("field.value")}/>
//       )}
//     </Field>
//   );
// };

const EyeIconToggle = ({ field, colors, form, ...props }) => {
  return (
    <div
      onClick={() => {
        form.setFieldValue(field.name, !field.value);
        // console.log(field.name);
        // console.log(field.value);
      }}
    >
      <Icon image={field.value ? "eye" : "eye-off"} size="1.3em"></Icon>
    </div>
  );
};
// <Icon image="eye" size="1.3em" onClick={() => form.setFieldValue(' ', "myValue")}>
