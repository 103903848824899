import Axios from "axios";

export const currencyChoiceHelper = (country, setcurrencyChoice) => {
  if (country === null) {
    const localStorageLocationValue = localStorage.getItem("vLoc");

    if (localStorageLocationValue) {
      setcurrencyChoice(localStorageLocationValue);
      return localStorageLocationValue;
    }
    if (!localStorageLocationValue) {
      Axios.get("/api/utility/location")
        .then((res) => {
          const detectedCountry = countryFilter(res.data.data);
          localStorage.setItem("vLoc", detectedCountry);
          setcurrencyChoice(detectedCountry);
          return detectedCountry;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  } else {
    console.log(country);
    localStorage.setItem("vLoc", country);
    setcurrencyChoice(countryFilter(country));
  }

  return country;
};

const countryFilter = (Country) => {

  if (Country === "US") {
    return "usd";
  } else if (Country === "GB") {
    return "gbp";
  } else if (Country === "usd") {
    return "usd";
  } else if (Country === "gbp") {
    return "gbp";
  } else if (countryCodes.includes(Country)) {
    return "eur";
  } else {
    return "cad";
  }
};

const countryCodes = [
  "AT",
  "BE",
  "HR",
  "CY",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "PT",
  "SK",
  "SI",
  "ES",
  "EU",
  "eur",
];
