import React, { useState, useEffect } from "react";
import {
  useAPI,
  Loader,
  BlockCompilerToHTML,
  EditorContext,
  JobIsUnpublished,
} from "components/lib";
import { themeList } from "../jobeditor/lib/themeList";

export function JobPublicView(props) {
  // EDITOR STATES
  const [blockDictionary, setBlockDictionary] = useState({});
  const [jobBasicInfo, setJobBasicInfo] = useState({});
  const [blockOrder, setBlockOrder] = useState([]);

  // Load job data
  const urlSplit = window.location.href.split("/");
  const jobId = urlSplit[4];
  const loadData = useAPI("/api/publicJobData/" + jobId, "GET");
  const [theme, setTheme] = useState(themeList("default"));
  const [jobData, setJobData] = useState(false);
  const [showApplyButton, setShowApplyButton] = useState(false);

  //check is data is loading
  useEffect(() => {
    if (!loadData.loading && loadData.data) {
      if (loadData.data === "notPublished") {
        setJobData("notPublished");
      }
      if (loadData.data !== "notPublished") {
        setJobData(loadData.data);
        const job_info = JSON.parse(loadData.data.job_info);

        if (job_info) {
          setJobBasicInfo(job_info.jobBasicInfo);
          setBlockDictionary(job_info.blockDictionary);
          setBlockOrder(job_info.blockOrder);
          setTheme(job_info.theme);
        }
        const showButton = loadData.data.apply_button_status === 1 &&
        loadData.data.apply_button_value !== "" &&
        loadData.data.apply_button_value !== null

        if(showButton) {
          setShowApplyButton(true)
        }

        // if (!job_info) {
        //   console.log("It seems the job info did not load");
        // }

      }
    }
  }, [loadData]);

  // EDITOR CONTEXT (STORE)
  const editor_context_store = {
    jobData: jobData,
    jobBasicInfo: jobBasicInfo,
    blockDictionary: blockDictionary,
    blockOrder: blockOrder,
    theme: theme,
  };

  return (
    <>
      {jobData === false && <Loader />}
      {jobData !== false && jobData === "notPublished" && <JobIsUnpublished />}
      {jobData !== false && jobData !== "notPublished" && (
        <EditorContext.Provider value={editor_context_store}>
          <BlockCompilerToHTML islandingPage={true} showApplyButton={showApplyButton} applyUrl={loadData.data.apply_button_value}/>
        </EditorContext.Provider>
      )}
    </>
  );
}
