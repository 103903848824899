import 'react-app-polyfill/ie9';
import './global_css/OverlayScrollbars_custom.css'; //changed some styling directly in this folder for scrollbars
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/app';

ReactDOM.render(
  <App></App>,
  document.getElementById('root')
);
