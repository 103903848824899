import { Dashboard } from 'views/dashboard/dashboard';
import { Help } from 'views/dashboard/help';
import { OnboardingView } from 'views/onboarding/onboarding';
import { UserImage } from 'views/userImage';
import { Job } from 'views/job/job';
import { JobList } from 'views/job/joblist';
import { JobEditor } from 'views/jobeditor/jobeditor';
import { JobPublicView } from 'views/jobPublicView/jobPublicView';
import { PdfPrintView } from 'views/pdfPrintView/pdfPrintView';

const Routes = [
  {
    path: '/dashboard',
    view: JobList,
    layout: 'app',
    permission: 'user',
    title: 'Dashboard'
  },
  {
    path: '/dashboard2', //TODO : Delete this view. Useful to keep for developpement
    view: Dashboard,
    layout: 'app',
    permission: 'user',
    title: 'Dashboard'
  },
  {
    path: '/welcome',
    view: OnboardingView,
    layout: 'onboarding',
    permission: 'user',
    title: 'Welcome'
  },
  {
    path: '/help',
    view: Help,
    layout: 'app',
    permission: 'user',
    title: 'Get Help'
  },
  {
    path: '/job/:id',
    view: Job,
    layout: 'app',
    permission: 'user',
    title: 'Job'
  },
  {
    path: '/joblist',
    view: JobList,
    layout: 'app',
    permission: 'user',
    title: 'My Jobs'
  },
  {
    path: '/jobeditor/:id',
    view: JobEditor,
    layout: 'editorLayout',
    //permission: 'user',
    title: 'Job editor'
  },
  {
    path: '/userImage',
    view: UserImage,
    layout: 'app',
    permission: 'user',
    title: 'UserImage'
  },
  {
    path: '/pdfPrintView/:id', // for printing the pdf
    view: PdfPrintView,
    layout: 'emptyLayout',
    // permission: 'user',
    title: 'PdfPrintView'
  },
  {
    path: '/public/:id', //share publicly
    view: JobPublicView,
    layout: 'emptyLayout',
    // permission: 'user',
    title: 'Job description'
  },
  {
    path: '/sharelink/:id',
    view: JobEditor,
    layout: 'editorLayoutNoNav',
    //permission: 'user',
    title: 'Job'
  },
]

export default Routes;
