/***
 *
 *   AUTH LAYOUT
 *   Layout for the signup/signin pages
 *
 **********/

import React from "react";
import { HomeNav, Footer, Animate } from "components/lib";
import Style from "./auth.module.scss";
import { ReactComponent as Octaveoscar } from "./img/oxo.svg";

export function AuthLayout(props) {
  return (
    <Animate type="pop">
      <main className={Style.auth}>
        {/* <HomeNav /> */}
        <div className={Style.pageWrapper}>
          <div className={Style.featureHighlight}>
            <div className={Style.quoteWrapper}>
              <p className={Style.quote}>
                Efficient and collaborative, Jobchef transforms the task of
                writing job postings into an easy, creative process with its
                excellent template choices and seamless sharing capabilities.
              </p>
              <p className={Style.name}>PAUL</p>
              <Octaveoscar/>
            </div>
          </div>
          <div className={Style.main}>{<props.children {...props.data} />}</div>
        </div>
        <Footer />
      </main>{" "}
    </Animate>
  );
}
