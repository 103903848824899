import React, { useContext, useState } from "react";
import { v4 as uuid } from "uuid";
import {
  HelperBlockContextHeader,
  HelperBlockContextBody,
  HelperBlockCardPreview,
} from "./_index.js";
import {
  EditorContext,
  FilePondWrapper,
  UnsplashSelector,
  Icon,
  ImageCropperWrapper,
  ViewContext,
  Loader,
} from "../../lib";
import image_icon from "./images/image_icon.svg";
import orange_mushroom from "./images/food/orange_mushroom.svg";

import {
  UploadIcon,
  MagnifierIcon,
  ImageIconSmall,
  WidthIconFlush,
  WidthIconFull,
  WidthIconSmall,
} from "./images/_index.js";
import Style from "./BlockAll.module.scss";
import {
  delete_helper_block_image,
  delete_helper_block_image_crop,
} from "../../../lib/imageUploadHelper.js";

//Allows to show banner on top for errors
/*import {
  FilePondApp,
  ButtonConfirm,
  UnsplashSelector,
  LibrarySelector,
  ImageCropper
} from "../_index.js";*/

//bootstrap
//import Button from "react-bootstrap/Button";
//import Row from "react-bootstrap/Row";

const blockColor = "#F6D6B6";
const blockName = "Image";

const block_image_data_generator = () => {
  const new_uuid = uuid();

  return {
    key: new_uuid,
    data: {
      version: 1,
      id: new_uuid,
      type: "image",
      content: {
        file: null,
        cropped_file: null,
        crop_information: null,
        layout: {
          maxWidth: "100%",
        },
      },
    },
  };
};

const BlockImageCardPreview = (props) => {
  return (
    <HelperBlockCardPreview
      image={orange_mushroom}
      header="Image"
      short_description="Add a visual pop to your job profile with an image that illustrates your company."
      block_type="image"
      color="#F6D6B6"
      color_light="#FFF4E2"
    />
  );
};

const BlockImagePreview = (props) => {
  // Content items
  let blockDictionary = useContext(EditorContext).blockDictionary;

  let isFile = blockDictionary[props.blockId].content["file"] !== null;

  let isCroppedFile =
    blockDictionary[props.blockId].content["cropped_file"] !== null;

  let max_width = blockDictionary[props.blockId].content.layout.maxWidth;

  if (props.blockId in blockDictionary) {
    return (
      <>
        {blockDictionary[props.blockId] === null && <Loader />}

        {blockDictionary[props.blockId] !== null && (
          <>
            {!isFile && (
              <div
                className={Style.emptyBlock}
                style={{ borderColor: blockColor, color: blockColor }}
              >
                <h2>{blockName}</h2>
                <p>Click to add content</p>
              </div>
            )}
            {isFile && !isCroppedFile && (
              <div className={"jc_block"}>
                <div className="style_jc image_jc">
                  <img
                    src={blockDictionary[props.blockId].content.file.public_url}
                    style={{ maxWidth: max_width }}
                    alt="temp"
                  />
                </div>
              </div>
            )}

            {isFile && isCroppedFile && (
              <div className={"jc_block"}>
                <div className="style_jc image_jc">
                  <img
                    src={
                      blockDictionary[props.blockId].content.cropped_file
                        .public_url
                    }
                    style={{ maxWidth: max_width }}
                    alt="temp"
                  />{" "}
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  }
  return <h1>Block didn't load it seems</h1>;
};

const BlockImageEditor = (props) => {
  // Content items
  let blockDictionary = useContext(EditorContext).blockDictionary;
  let previewCrop = useContext(EditorContext).previewCrop;

  let isFile = blockDictionary[props.blockId].content["file"] !== null;
  let isCroppedFile =
    blockDictionary[props.blockId].content["cropped_file"] !== null;
  let isCropPreview = previewCrop !== null;

  let max_width = blockDictionary[props.blockId].content.layout.maxWidth;

  // console.log(previewCrop);

  return (
    <div className={Style.preview_and_editor}>
      {!isFile && (
        <div className="image_placeholder">
          <img src={image_icon} alt="temp" />
        </div>
      )}
      {isCropPreview && (
        <>
          <div>
            <canvas
              ref={previewCrop}
              style={{
                maxWidth: "100%",
              }}
            />
          </div>
        </>
      )}
      {!isCropPreview && isFile && !isCroppedFile && (
        <>
          <img
            src={blockDictionary[props.blockId].content.file.public_url}
            style={{ maxWidth: max_width }}
            alt="temp"
          />
        </>
      )}
      {!isCropPreview && isFile && isCroppedFile && (
        <>
          <img
            src={blockDictionary[props.blockId].content.cropped_file.public_url}
            style={{ maxWidth: max_width, alignSelf: "center" }}
            alt="temp"
          />
        </>
      )}
    </div>
  );
};

const BlockImageContext = (props) => {
  let blockDictionary = useContext(EditorContext).blockDictionary;
  const setBlockDictionary = useContext(EditorContext).setBlockDictionary;
  const [cropMode, setCropMode] = useState(null);
  let setPreviewCrop = useContext(EditorContext).setPreviewCrop;

  // contest for the notification on top of page
  const context = useContext(ViewContext);

  // Get info of user (to add in headers)
  const user = JSON.parse(localStorage.getItem("user"));
  const token = "Bearer " + (user ? user.token : '');

  let isCroppedFile =
    blockDictionary[props.blockId].content["cropped_file"] !== null;

  const setImageLayout = (value) => {
    const copyBlockList = {
      ...blockDictionary,
    };
    copyBlockList[props.blockId].content.layout.maxWidth = value;
    setBlockDictionary(copyBlockList);
  };

  if (blockDictionary[props.blockId].content["file"] === null) {
    return (
      <>
        <HelperBlockContextHeader
          header={"IMAGE"}
          color={"#F6D6B6"}
          image={orange_mushroom}
        ></HelperBlockContextHeader>
        <HelperBlockContextBody>
          <p>
            Use the image block to break up sections and some visual interest to
            your job post. It's also a different way to illustrate what makes
            your company unique.
          </p>
          <p>
            Use this block sparingly: they say a picture's worth a thousand
            words and your applicants already have plenty to read!
          </p>
          <div className={Style.subtitle}>
            <UploadIcon className={Style.yellow} />
            <h3>Upload a file:</h3>
          </div>
          <FilePondWrapper
            importHelperParameters={[
              props.blockId,
              blockDictionary,
              setBlockDictionary,
            ]}
            imageType={"blockImage"}
          />
          <div className={Style.subtitle}>
            <MagnifierIcon className={Style.yellow} />
            <h3>Search for an image:</h3>
          </div>
          <UnsplashSelector
            importHelperParameters={[
              props.blockId,
              blockDictionary,
              setBlockDictionary,
            ]}
            imageType={"blockImage"}
          />
        </HelperBlockContextBody>
      </>
    );
  } else {
    return (
      <>
        <HelperBlockContextHeader
          header={"Image"}
          color={"#F6D6B6"}
          image={orange_mushroom}
        ></HelperBlockContextHeader>
        {cropMode !== true && (
          <HelperBlockContextBody>
            <p>
              Use the image block to break up sections and some visual interest
              to your job post. It's also a different way to illustrate what
              makes your company unique.
            </p>
            <p>
              Use this block sparingly: they say a picture's worth a thousand
              words and your applicants already have plenty to read!
            </p>
            <div className={Style.subtitle}>
              <ImageIconSmall className={Style.yellow} />
              <h3>Selected image:</h3>
            </div>
            <div className={Style.image_selected_preview}>
              <img
                src={blockDictionary[props.blockId].content.file.public_url}
                alt="select asset for the block"
              />
              <div className={Style.img_options}>
                <div className={Style.width_buttons}>
                  <button
                    onClick={() => {
                      setImageLayout("100%");
                    }}
                  >
                    <WidthIconFull className={Style.svg_width} />
                  </button>
                  <button
                    onClick={() => {
                      setImageLayout("80%");
                    }}
                  >
                    <WidthIconFlush />
                  </button>
                  <button
                    onClick={() => {
                      setImageLayout("50%");
                    }}
                  >
                    <WidthIconSmall />
                  </button>
                </div>
                <button onClick={() => setCropMode(true)}>
                  {!isCroppedFile ? "Crop" : "Edit crop"}
                  <Icon image={"crop"} size={12} />
                </button>
                {isCroppedFile && (
                  <button
                    onClick={() => {
                      delete_helper_block_image_crop(
                        props.blockId,
                        blockDictionary,
                        setBlockDictionary,
                        setPreviewCrop,
                        token
                      );
                    }}
                  >
                    Reset image <Icon image={"crop"} size={12} />
                  </button>
                )}
                <button
                  onClick={() => {
                    delete_helper_block_image(
                      props.blockId,
                      blockDictionary,
                      setBlockDictionary,
                      setPreviewCrop,
                      token
                    );
                  }}
                >
                  Remove image <Icon image={"trash-2"} size={12} />
                </button>
              </div>
            </div>
          </HelperBlockContextBody>
        )}
        {cropMode === true && (
          <ImageCropperWrapper
            setCropMode={setCropMode}
            imageLocation={blockDictionary[props.blockId].content}
            importHelperParameters={[
              props.blockId,
              blockDictionary,
              setBlockDictionary,
            ]}
            imageType={"blockImage"}
            delete_image={delete_helper_block_image}
          />
        )}
      </>
    );
  }
};

export {
  BlockImagePreview,
  BlockImageCardPreview,
  BlockImageEditor,
  BlockImageContext,
  block_image_data_generator,
};
