import { Icon, EditorContext } from "components/lib";
import React, { useState, useContext } from "react";
import Style from "./jobInsightsSidebar.module.scss";
import { ScrollArea } from "@mantine/core";
import Overview from "./overview.js";
import { ReactComponent as QuestionMark } from "./img/question_mark_icon.svg";
import { ReactComponent as CurrentIndicator } from "./img/currentIndicator.svg";
import Tippy from "@tippyjs/react";


export function JobInsightsSidebar(props) {
  const stats = useContext(EditorContext).stats;
  // console.log(stats.masculines);
  // console.log(stats.feminines);
  const blockOrder = useContext(EditorContext).blockOrder;
  const [justClicked, setJustClick] = useState(false);

  let readTime = () => {
    let decimalTime = stats.words / 200;
    let minutes = decimalTime - (decimalTime % 1);
    let seconds = Math.round((decimalTime % 1) * 60, 0);
    if (seconds <= 9) {
      seconds = "0" + seconds;
    }
    return minutes + ":" + seconds;
  };

  // READING LEVEL

  const levelsList = {
    // score: [age, grade, schooling]
    0: ["5-6", "Kindergarten", ""],
    1: ["5-6", "Kindergarten", "preschool"],
    2: ["6-7", "First/Second Grade", "elementary school"],
    3: ["7-9", "Third Grade", "elementary school"],
    4: ["9-10", "Fourth Grade", "elementary school"],
    5: ["10-11", "Fifth Grade", "elementary school"],
    6: ["11-12", "Sixth Grade", "middle school"],
    7: ["12-13", "Seventh Grade", "middle school"],
    8: ["13-14", "Eighth Grade", "middle school"],
    9: ["14-15", "Ninth Grade", "high school"],
    10: ["15-16", "Tenth Grade", "high school"],
    11: ["16-17", "Eleventh Grade", "high school"],
    12: ["17-18", "Twelfth grade", "high school"],
    13: ["18-24", "College student", "post-secondary education"],
    14: ["24+", "Professor", "post-secondary education"],
    15: ["24+", "Professor", ""],
  };
  const schooling = [
    "",
    "preschool",
    "elementary school",
    "middle school",
    "high school",
    "post-secondary education",
    "",
  ];

  let levelAdjusted = Math.round(stats.level);
  if (levelAdjusted > 14) {
    levelAdjusted = 14;
  }
  if (levelAdjusted < 1) {
    levelAdjusted = 1;
  }
  let indexOfSchooling = schooling.indexOf(levelsList[levelAdjusted][2]);
  let schoolingLevel = levelsList[levelAdjusted][2];
  let belowSchoolingLevel = schooling[indexOfSchooling - 1];
  let aboveSchoolingLevel = schooling[indexOfSchooling + 1];

  // GENDER BALANCE
  const genderScore = () => {
    const percent_of_masculine_words = (stats.masculines / stats.words) * 1000;
    const percent_of_feminine_words = (stats.feminines / stats.words) * 1000;

    return percent_of_masculine_words;
  };

  return (
    <div className={Style.jobInsightsSidebar}>
      <div className={Style.header}>
        <Icon image={"activity"} color={"white"} />
        <h4>Insights</h4>
      </div>
      <ScrollArea
        style={{ height: "calc( 100vh - 61.1px - 78px", marginRight: "4px" }}
        offsetScrollbars
      >
        <InsightHelper
          title="General"
          color="sadasd"
          customElement={
            <>
              <div className={Style.generalInsightCard}>
                <p>{stats.words} words</p>
              </div>
              <div className={Style.generalInsightCard}>
                <p>{stats.sentences} sentences</p>
              </div>
              <Tippy
                content={
                  <p>
                    Average read time is based on an average reading speed of
                    200 works per minute
                  </p>
                }
                placement="left"
                className={Style.tooltip}
              >
                <div className={Style.generalInsightCard}>
                  <p>{readTime()} min read</p>
                  <QuestionMark />
                </div>
              </Tippy>
              <div className={Style.generalInsightCard}>
                <p>{blockOrder.length} sections</p>
              </div>
            </>
          }
        />
        <InsightHelper
          title={"Readability level"}
          tippyText={
            <>
              <p style={{ marginBottom: "0px" }}>
                The readability level is determined using the Automated Readability Index (ARI)
              </p>
              <p style={{ fontSize: "0.875em" }}>
                This index evaluates the length of sentences and words to estimate the complexity of understanding a given text
              </p>
            </>
          }
          customElement={
            <>
              <div className={Style.scoresIndicator}>
                <div className={Style.valuesWrapper}>
                  <p className={Style.aboveItem}>{belowSchoolingLevel}</p>
                  <p className={Style.mainItem}>
                    <CurrentIndicator className={Style.currentIndicator} />{" "}
                    {schoolingLevel}
                  </p>
                  <p className={Style.belowItem}>{aboveSchoolingLevel}</p>
                </div>
              </div>
            </>
          }
        />
        <InsightHelper
          title="Gender related words"
          tippyText={
            <>
              <p style={{ marginBottom: "0px" }}>
                Certain words tend to be associated with one specific gender
              </p>
              <p style={{ fontSize: "0.875em" }}>
                This association is merely indicative, and avoiding these words
                won't necessarily change how your job is perceived in terms of
                attractiveness.
              </p>
            </>
          }
          customElement={
            <>
              {/* <div className={Style.scoresIndicator}>
                <div className={Style.valuesWrapper}>
                <p className={Style.aboveItem}>
                {Math.round(stats.level) - 1} mostly neutral
                  </p>
                  <p className={Style.mainItem}>
                    <CurrentIndicator className={Style.currentIndicator} />{" "}
                    {Math.round(stats.level)} mostly neutral
                  </p>
                  <p className={Style.belowItem}>
                  {Math.round(stats.level) + 1} more masculine
                  </p>
                  </div>
                </div> */}
              <div className={Style.scoresIndicator}>
                <div className={Style.valuesWrapper}>
                  <p className={Style.mainItem}>
                    <strong>
                      <p>
                        <CurrentIndicator className={Style.currentIndicator} />{" "}
                        {stats.masculines} words
                      </p>{" "}
                    </strong>{" "}
                    tend to be perceived as masculine
                  </p>
                  <p className={Style.mainItem}>
                    <strong>
                      <p>
                        <CurrentIndicator className={Style.currentIndicator} />{" "}
                        {stats.feminines} words
                      </p>
                    </strong>{" "}
                    tend to be perceived as feminine
                  </p>
                </div>
              </div>
              {/* <p className={Style.styledP}>
                Avoiding words that tend to be associated with one gender don't necessarily make your job more attractive.
              </p> */}
            </>
          }
        />

        {/* <Overview /> */}
      </ScrollArea>
    </div>
  );
}

const InsightHelper = (props) => {
  // props :
  //  title : string
  //  details : string ?
  //  color : warning : #F0AA61, issue: #d95565, good: #8CC57D

  let color = () => {
    switch (props.color) {
      case "good":
        return "#8CC57D";
      case "warning":
        return "#F0AA61";
      case "issue":
        return "#d95565";
      default:
        return "#D9D9D9";
    }
  };

  return (
    <div className={Style.insightItem}>
      <div
        className={Style.insightIndicator}
        style={{ backgroundColor: color }}
      ></div>
      {props.tippyText && (
        <Tippy
          content={<p>{props.tippyText}</p>}
          placement="left"
          className={Style.tooltip}
        >
          <div className={Style.insightTitle}>
            <h5>{props.title}</h5>
            <QuestionMark />
          </div>
        </Tippy>
      )}
      {!props.tippyText && <h5>{props.title}</h5>}
      {props.customElement}
      {props.details && <p className={Style.styledP}>{props.details}</p>}
    </div>
  );
};
