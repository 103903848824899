import React, { useState } from "react";
import { MyButton, Icon } from "components/lib";
import Style from "./collapseToggle.module.scss";


/*
props =>
      toggleTitle
      contentExpanded
      contentHidden 
*/

export function CollapseToggle(props) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={Style.collapseToggle}>
      <div className={Style.titleRow}>
        <MyButton
          buttonStyle={"ghost"}
          onClick={() => {
            setExpanded(!expanded);
          }}
          className={Style.arrowButton}
        >
          <Icon
            image={expanded ? "chevron-right" : "chevron-down"}
          />
        </MyButton>
        <div
          onClick={() => {
            setExpanded(!expanded);
          }}
          style={{ cursor: "pointer" }}
        >
          {props.toggleTitle}
        </div>
        <MyButton
          buttonStyle={"ghost"}
          onClick={() => {
            setExpanded(!expanded);
          }}
          className={Style.tittleExpandButton}
        >
          {expanded ? "Expand" : "Hide"}
        </MyButton>
      </div>
      <div
        style={{ display: !expanded ? "block" : "none" }}
        className={Style.contentOfToggle}
      >
        {props.contentExpanded}
      </div>
      <div style={{ display: expanded ? "block" : "none" }}>
        {props?.contentHidden ? props.contentHidden : ""}
      </div>
    </div>
  );
}
