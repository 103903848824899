import React, { useState, useEffect, useRef } from "react";
import Tippy from "@tippyjs/react";
import { HexColorPicker, HexColorInput } from "react-colorful";
import Style from "./colorPicker.module.scss";

export function ColorPicker(props) {
  const [selectedColor, setSelectedColor] = useState(props.startColor);
  const [tippyVisible, setTippyVisible] = useState(false);
  const [tippyTimeoutId, setTippyTimeoutId] = useState(null);
  const [inputFocused, setInputFocused] = useState(false);
  const inputRef = useRef(null);
  const tippyRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputRef.current &&
        tippyRef.current &&
        !inputRef.current.contains(event.target) &&
        !tippyRef.current.popper.contains(event.target)
      ) {
        setInputFocused(false);
        setTippyVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef, tippyRef]);

  useEffect(() => {
    if (props.form) {
      props.form.setFieldValue(props.name, selectedColor);
    }
  }, [selectedColor]);

  const handleInputFocus = () => {
    setInputFocused(true);
    setTippyVisible(true);
  };

  const handleInputBlur = () => {
    setInputFocused(false);
    if (!tippyVisible) {
      setTippyVisible(false);
    }
  };

  const handleMouseEnter = () => {
    clearTimeout(tippyTimeoutId);
    setTippyVisible(true);
  };

  const handleMouseLeave = () => {
    const timeoutId = setTimeout(() => {
      if (!inputFocused) {
        setTippyVisible(false);
      }
    }, 120);
    setTippyTimeoutId(timeoutId);
  };

  const handleComponentClick = () => {
    setInputFocused(true);
    if (!tippyVisible) {
      setTippyVisible(true);
    }
  };

  return (
    <div
      className={Style.tippyWrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleComponentClick}
    >
      <Tippy
        interactive={true}
        placement={"bottom"}
        style={{ backgroundColor: "pink" }}
        content={
          <div className={Style.toppyPopInStyle}>
            <HexColorPicker color={selectedColor} onChange={setSelectedColor} />
          </div>
        }
        theme={"lightbackground"}
        visible={
          tippyVisible || (inputFocused && inputRef.current?.contains(document.activeElement))
        }
        popperOptions={{
          modifiers: [
            {
              name: "preventOverflow",
              options: {
                boundary: "viewport",
              },
            },
          ],
        }}
        onCreate={(instance) => {
          tippyRef.current = instance;
        }}
      >
        <div className={Style.wrapperStyle}>
          <div className={Style.hexInput} ref={inputRef}>
            <div
              className={Style.colorIndicator}
              style={{ backgroundColor: selectedColor, marginRight: "10px", cursor: "pointer" }}
              onClick={() => inputRef.current?.querySelector("input")?.focus()}
            ></div>
            <HexColorInput
              style={{
                width: "100px",
                height: "100%",
                borderRadius: "0.25em",
                textAlign: "center",
              }}
              color={selectedColor}
              onChange={setSelectedColor}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            />
          </div>
        </div>
      </Tippy>
    </div>
  );
}
