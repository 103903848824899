import React, { useContext, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import {
  HelperBlockCardPreview,
  HelperBlockEditorItemList,
  HelperBlockEditorItemListPreview,
  HelperBlockContextCard,
  HelperDisplayCheckerSetterHelper,
} from "./_index.js";
import { Loader, EditorContext, MyTextEditor, MyButton } from "../../lib";
import violet_cheese from "./images/food/violet_cheese.svg";
import parse from "html-react-parser";

import Style from "./BlockAll.module.scss";

const blockColor = "#C2CFFB";
const blockTextColor = "#C2CFFB";
const blockName = "Key responsabilities";

const block_key_responsabilities_data_generator = (content) => {
  const new_uuid = uuid();

  let default_content = {
    key_responsabilities: [],
  };

  if (content) {
    default_content[new_uuid + "title"] = "<h2>" + content.section_title + "</h2>";
    default_content[new_uuid + "body"] = content.short_introduction;
    for (
      let index = 0;
      index < content.list_of_key_responsibilities.length;
      index++
    ) {
      const new_item_id = uuid();
      const elem = content.list_of_key_responsibilities[index];
      default_content[new_uuid + "_listItem_" + new_item_id] = elem;
      default_content.key_responsabilities.push(new_item_id);
    }
  }

  return {
    key: new_uuid,
    data: {
      version: 1,
      id: new_uuid, //block
      type: "key_responsabilities",
      content: default_content,
    },
  };
};

const BlockKeyResponsabilitiesPreview = (props) => {
  const selectedBlock = useContext(EditorContext).selectedBlock;
  const blockDictionary = useContext(EditorContext).blockDictionary;
  const currentStep = useContext(EditorContext).currentStep;
  const [isContent, setIsContent] = useState(false);

  const _contentValueHelper = (contentKey) => {
    if (
      blockDictionary[props.blockId].content[props.blockId + contentKey] ===
        "" ||
      blockDictionary[props.blockId].content[props.blockId + contentKey] ===
        undefined
    ) {
      return "";
    } else {
      return blockDictionary[props.blockId].content[props.blockId + contentKey];
    }
  };

  const contentValueArray = ["title", "body"];
  const contentListArray = ["key_responsabilities"];

  useEffect(() => {
    HelperDisplayCheckerSetterHelper(
      contentValueArray,
      contentListArray,
      blockDictionary,
      props.blockId,
      setIsContent
    );
    return () => {
      HelperDisplayCheckerSetterHelper(
        contentValueArray,
        contentListArray,
        blockDictionary,
        props.blockId,
        setIsContent
      );
    };
  }, []);

  useEffect(() => {
    HelperDisplayCheckerSetterHelper(
      contentValueArray,
      contentListArray,
      blockDictionary,
      props.blockId,
      setIsContent
    );
  }, [selectedBlock]);

  if (props.publicRender && !isContent) {
    return null;
  }
  return (
    <>
      {blockDictionary[props.blockId] === null && <Loader />}

      {blockDictionary[props.blockId] !== null &&
        !isContent &&
        !props.publicRender && (
          <div
            className={Style.emptyBlock}
            style={{ borderColor: blockColor, color: blockColor }}
          >
            <h2>{blockName}</h2>
            <p>Click to add content</p>
          </div>
        )}
      {blockDictionary[props.blockId] !== null &&
        isContent &&
        currentStep !== "block_editor" && (
          <div className={"jc_block"}>
            <div className="style_jc key_responsabilities_jc">
              {parse(_contentValueHelper("title"))}
              {parse(_contentValueHelper("body"))}

              <HelperBlockEditorItemListPreview
                listName={"key_responsabilities"}
                blockId={props.blockId}
              />
            </div>{" "}
          </div>
        )}
      {blockDictionary[props.blockId] !== null &&
        isContent &&
        currentStep === "block_editor" && (
          <>
            {parse(_contentValueHelper("title"))}
            {parse(_contentValueHelper("body"))}

            <HelperBlockEditorItemListPreview
              listName={"key_responsabilities"}
              blockId={props.blockId}
            />
          </>
        )}
    </>
  );
};

const BlockKeyResponsabilitiesCardPreview = (props) => {
  return (
    <HelperBlockCardPreview
      image={violet_cheese}
      header="Key responsabilities"
      short_description="Help applicants imagine what a day in the life would look like on the job."
      block_type="key_responsabilities"
      color="--red-medium"
      color_light="#C2CFFB"
    />
  );
};

const BlockKeyResponsabilitiesEditor = (props) => {
  const setSelectedBlock = useContext(EditorContext).setSelectedBlock;

  return (
    <>
      <div className={Style.editorCloseButtonWrapper}>
        <h3
          className={Style.sectionTitle}
          style={{ color: blockTextColor, textTransform: "uppercase" }}
        >
          {blockName}
        </h3>
        <MyButton
          buttonStyle="secondary"
          size="small"
          onClick={() => {
            setSelectedBlock(null);
          }}
        >
          Close
        </MyButton>
      </div>{" "}
      <br />
      <p className={Style.sectionSubtitle}>Section title :</p>
      <MyTextEditor
        blockId={props.blockId}
        contentKey={props.blockId + "title"}
        placeholderText={"Type a summary section title..."}
        contentBlockType="header-two"
        tabIndex={1}
        quickAddOptions={["Key responsabilities"]}
      />
      <p className={Style.sectionSubtitle}>
        Short introduction on the key responsabilities :
      </p>
      <MyTextEditor
        blockId={props.blockId}
        contentKey={props.blockId + "body"}
        placeholderText={"Type the summary for this job..."}
        tabIndex={2}
        ctaViewSampleText
      />
      <p className={Style.sectionSubtitle}>List of key responsabilities :</p>
      <HelperBlockEditorItemList
        listName={"key_responsabilities"}
        blockId={props.blockId}
        placeholderText={"Type a key responsability..."}
        tabIndex={3}
        ctaViewSampleText
      />
    </>
  );
};

const BlockKeyResponsabilitiesContext = (props) => {
  return (
    <HelperBlockContextCard
      header={"KEY RESPONSABILITIES"}
      blockType={"key_responsabilities"}
      color="#C2CFFB"
      image={violet_cheese}
      iconStyle={Style.lightPurple}
    />
  );
};

export {
  BlockKeyResponsabilitiesPreview,
  BlockKeyResponsabilitiesCardPreview,
  BlockKeyResponsabilitiesEditor,
  BlockKeyResponsabilitiesContext,
  block_key_responsabilities_data_generator,
};
