import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Icon,
  MyButton,
  ViewContext,
  BlockCompilerToHTML,
  DownloadPDFbutton,
} from "components/lib";
import { Field, Form, Formik, useFormikContext, ErrorMessage } from "formik";
import Style from "./exportModal.module.scss";
import Modal from "@material-ui/core/Modal";
import juice from "juice";
import "tippy.js/dist/tippy.css"; // optional
import Tippy from "@tippyjs/react";
import Axios from "axios";
const cheerio = require("cheerio");

export function ExportModal(props) {
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [open, setOpen] = useState(false);

  const [htmlOrTextValue, setHtmlOrTextValue] = useState("");
  const [styleAndFormating, setStyleAndFormating] = useState(true);
  const [includeImage, setIncludeImage] = useState(true);
  const [textOnly, setTextOnly] = useState(false);

  const [published, setPublished] = useState(true);
  const [applyCTA, setApplyCTA] = useState(false);
  const [applyURL, setApplyURL] = useState("");

  const jobLinkCopyRef = useRef(null);
  const refShareURL = useRef(null);

  const HTMLOrTextTextAreaRef = useRef(null);
  const htmlHiddenElemRef = useRef(null);

  const viewContext = useContext(ViewContext);

  useEffect(() => {
    if (textOnly === true) {
      setStyleAndFormating(false);
      setIncludeImage(false);
    }
    if (!textOnly && !styleAndFormating && !includeImage) {
      setStyleAndFormating(true);
      setIncludeImage(true);
    }
  }, [textOnly]);

  useEffect(() => {
    if (styleAndFormating === true || includeImage === true) {
      setTextOnly(false);
    }
  }, [styleAndFormating, includeImage]);

  useEffect(() => {
    statusAndApplyValueGetter();
  }, []);

  useEffect(() => {
    if (htmlHiddenElemRef.current) {
      let html = htmlHiddenElemRef.current.innerHTML;

      if (!styleAndFormating) {
        html = removeStyle(html);
      }

      if (!includeImage) {
        html = removeImageTag(html);
      }

      //Include style inline w/ juice
      html = juice(html);

      // Generate text only version
      if (textOnly) {
        html = getTextOnlyVersion(html);
      }

      setHtmlOrTextValue(html);
    }
  }, [htmlHiddenElemRef, open, textOnly, styleAndFormating, includeImage]);

  const publishStatusUpdater = () => {
    Axios.patch("/api/job/jobstatus/" + props.jobId, {
      status: published === true ? "private" : "published",
    })
      .then((res) => {
        // console.log(res.data.data);
        if (res.data.data === "published") {
          setPublished(true);
        } else {
          setPublished(false);
        }
        viewContext.notification.show(
          "Job status updated !",
          "success",
          true,
          "toast"
        );
      })
      .catch((err) => {
        viewContext.notification.show(
          "There was an error updating your job, please reload",
          "error",
          true,
          "toast"
        );
        viewContext.handleError(err);
      });
  };

  const applyButtonStatusUpdater = (value) => {
    // console.log(value);

    Axios.patch("/api/job/applyButtonStatus/" + props.jobId, {
      apply_button_status: value,
    })
      .then((res) => {
        // console.log(res.data.data);

        setApplyCTA(res.data.data);

        viewContext.notification.show(
          "Job updated !",
          "success",
          true,
          "toast"
        );
      })
      .catch((err) => {
        viewContext.notification.show(
          "There was an error updating your job, please reload",
          "error",
          true,
          "toast"
        );
        viewContext.handleError(err);
      });
  };

  const applyButtonValueUpdater = (url) => {
    // console.log(url);
    Axios.patch("/api/job/applyButtonValue/" + props.jobId, {
      apply_button_value: url,
    })
      .then((res) => {
        // console.log(res.data.data);
        setApplyURL(res.data.data);
        viewContext.notification.show(
          "Job status updated !",
          "success",
          true,
          "toast"
        );
      })
      .catch((err) => {
        viewContext.notification.show(
          "There was an error updating your job, please reload",
          "error",
          true,
          "toast"
        );
        viewContext.handleError(err);
      });
  };

  const statusAndApplyValueGetter = () => {
    Axios.get("/api/job/jobstatus/" + props.jobId)
      .then((res) => {
        if (res.data.data.status === "published") {
          setPublished(true);
        } else {
          setPublished(false);
        }

        if (res.data.data.apply_button_status === 1) {
          setApplyCTA(true);
        } else {
          setApplyCTA(false);
        }

        if (res.data.data.apply_button_value !== null) {
          setApplyURL(res.data.data.apply_button_value);
        }

        return res.data.data.status;
      })
      .catch((err) => {
        viewContext.handleError(err);
      });
  };

  const getJobLinkValue = () => {
    return jobLinkCopyRef.current.value;
  };

  const getHTMLOrTextAndsetInTextArea = () => {
    return HTMLOrTextTextAreaRef.current.value;
  };

  const [justClicked, setJustClick] = useState(false);

  const copieToClipboard = (getData) => {
    const data = getData();
    navigator.clipboard.writeText(data);
    setJustClick(true);
    setTimeout(function () {
      setJustClick(false);
    }, 1200);
  };

  return (
    <>
      <div
        style={{ display: "none" }}
        ref={htmlHiddenElemRef}
        id="rendered_html"
      >
        {props.isBlocks && <BlockCompilerToHTML />}
      </div>
      <MyButton
        onClick={() => {
          setOpen(true);
        }}
        size={"small"}
        buttonStyle={"primary"}
        style={{ marginLeft: "0.8rem" }}
      >
        Export <Icon image={"flag"} size={12} className={Style.iconStyle} />
      </MyButton>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className={Style.export_modal}>
          <h2>Export your job!</h2>
          <div className={Style.methodsWrapper}>
            <div className={Style.exportMethodWrapper}>
              <h3>Landing page</h3>
              <div className={Style.twoColumnWrapper}>
                <div className={Style.mainExport}>
                  <p>
                    Share this link to candidates so they can view the job posting.{" "}
                  </p>
                  <div className={Style.copyLinkWrapper}>
                    <textarea
                      ref={jobLinkCopyRef}
                      className={Style.textareaCopyLink}
                      value={window.location.origin + "/public/" + props.jobId}
                      disabled
                    ></textarea>
                    <Tippy
                      content={
                        justClicked === false ? "Click to copy" : "Copied!"
                      }
                      placement="bottom"
                      hideOnClick={false}
                      className={
                        justClicked === false
                          ? Style.tooltipNormal
                          : Style.tooltipAfterClick
                      }
                    >
                      <div
                        className={Style.clickToCopyText}
                        onClick={() => {
                          copieToClipboard(() => getJobLinkValue());
                        }}
                      >
                        <div className={Style.CopyButton}>
                          Copy
                          <Icon
                            image={"copy"}
                            size={14}
                            className={Style.iconStyle}
                          />
                        </div>
                        {props.children}
                      </div>
                    </Tippy>
                    <div>
                      <div
                        className={Style.CopyButton}
                        onClick={() => {
                          window.open(getJobLinkValue());
                        }}
                      >
                        <Icon
                          image={"external-link"}
                          size={14}
                          className={Style.iconStyle}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Style.secondarySettings}>
                  <p className={Style.subtitleSmall}>Publish status</p>
                  <p style={{ marginBottom: "4px" }}>
                    Turn on to make your job public.
                  </p>
                  <SwitchButton
                    currentSwitchState={published}
                    callback={() => publishStatusUpdater()}
                  />
                </div>
                <div className={Style.secondarySettings}>
                  <p className={Style.subtitleSmall}>
                    Include <i>apply button?</i>
                  </p>
                  <p style={{ marginBottom: "4px" }}>
                    Turn on to show apply button on your job.
                  </p>
                  <SwitchButton
                    currentSwitchState={applyCTA}
                    callback={(applyCTA) => applyButtonStatusUpdater(applyCTA)}
                  />
                  {applyCTA && (
                    <>
                      <p className={Style.smallSubtitle}>URL</p>
                      <Formik
                        initialValues={{ url: applyURL }}
                        validate={validate}
                        innerRef={refShareURL}
                      >
                        <>
                          <MyOnChangeComponent
                            callback={(x) => applyButtonValueUpdater(x)}
                            refShareURL={refShareURL}
                          />
                          <Form id="shareURL">
                            <div className={Style.inputSelect} style={{marginLeft: "8px"}}>
                              <Field
                                name="url"
                                type="text"
                                placeholder="Enter URL here"
                              />
                              <ErrorMessage
                                name="url"
                                component="div"
                                className={Style.errorMessage}
                              />
                            </div>
                          </Form>
                        </>
                      </Formik>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                borderRight: "1px solid rgb(194, 194, 194)",
              }}
            ></div>
            <div className={Style.exportMethodWrapper}>
              <h3>HTML & Text</h3>
              <p>For when you simply want to get the content of your job. </p>
              <div className={Style.twoColumnWrapper}>
                <div className={Style.mainExport}>
                  <textarea
                    ref={HTMLOrTextTextAreaRef}
                    className={Style.HTMLtextarea}
                    spellcheck="false"
                    value={htmlOrTextValue}
                  ></textarea>
                  <Tippy
                    content={
                      justClicked === false ? "Click to copy" : "Copied!"
                    }
                    placement="bottom"
                    hideOnClick={false}
                    className={
                      justClicked === false
                        ? Style.tooltipNormal
                        : Style.tooltipAfterClick
                    }
                  >
                    <div
                      className={Style.clickToCopyText}
                      onClick={() => {
                        copieToClipboard(() => getHTMLOrTextAndsetInTextArea());
                      }}
                    >
                      <div className={Style.CopyButton}>
                        Copy
                        <Icon
                          image={"copy"}
                          size={14}
                          className={Style.iconStyle}
                        />
                      </div>
                      {props.children}
                    </div>
                  </Tippy>
                </div>

                <div className={Style.secondarySettings}>
                  <p className={Style.subtitleSmall}>
                    Include style & formatting
                  </p>
                  <SwitchButton
                    currentSwitchState={styleAndFormating}
                    callback={setStyleAndFormating}
                  />
                  <p className={Style.subtitleSmall}>Include images</p>
                  <SwitchButton
                    currentSwitchState={includeImage}
                    callback={setIncludeImage}
                  />
                  <p className={Style.subtitleSmall}>Text only export</p>
                  <SwitchButton
                    currentSwitchState={textOnly}
                    callback={setTextOnly}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                borderRight: "1px solid rgb(194, 194, 194)",
              }}
            ></div>
            <div className={Style.exportMethodWrapper}>
              <h3>PDF document</h3>
              <p>Download you job posting as PDF document directly. </p>
              <div className={Style.copyLinkWrapper}>
                <DownloadPDFbutton />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
const MyOnChangeComponent = (props) => {
  const { values, validateForm } = useFormikContext();
  const isInitialRender = useRef(true);
  const validationTimeoutRef = useRef(null);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (validationTimeoutRef.current) {
      clearTimeout(validationTimeoutRef.current);
    }

    validationTimeoutRef.current = setTimeout(() => {
      validateForm(values).then((errors) => {
        if (Object.keys(errors).length === 0) {
          // console.log("Form is valid", values.url);
          props.callback(values.url);
          return;
        }
        return;
      });
    }, 900);

    return () => {
      if (validationTimeoutRef.current) {
        clearTimeout(validationTimeoutRef.current);
      }
    };
  }, [values.url]);

  return null;
};

const validate = (values) => {
  const errors = {};

  // Simple URL validation using regex (this can be more comprehensive if needed)
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name and extension
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator

  if (values.url.length > 255) {
    errors.url =
      "URL is too long, should be less than 255 charaters instead of " +
      values.url.length;
  }

  if (!urlPattern.test(values.url)) {
    errors.url = "Must be a valid URL";
  }

  return errors;
};

const SwitchButton = (props) => {
  return (
    <button
      className={
        props.currentSwitchState
          ? Style.switchButton
          : Style.switchButton + " " + Style.switchButtonActive
      }
      style={props.orangeNotification ? { backgroundColor: "#F0AA61" } : {}}
      onClick={() => props.callback(!props.currentSwitchState)}
    >
      <div className={Style.switchIndicator}></div>
    </button>
  );
};

function getTextOnlyVersion(html) {
  const $ = cheerio.load(html);
  html = $.text();

  const parts = [];

  $(".job_title_jc").each((i, el) => {
    const part = $(el).text().trim();
    if (part !== "") {
      //test
      parts.push(part);
    }
  });
  parts.push("");

  $(".basic_info_item").each((i, el) => {
    let part = $(el).text().trim();
    part = "- " + part;
    if (part !== "") {
      parts.push(part);
    }
  });
  parts.push("");
  parts.push("");

  $(".jc_block").each((i, el) => {
    $(el)
      .children()
      .children()
      .each((i, el) => {
        const tagName = el.tagName.toLowerCase();
        if (tagName === "h2" || tagName === "h3" || tagName === "p") {
          parts.push($(el).text());
        } else if (tagName === "ul") {
          $(el)
            .children()
            .each((j, li) => {
              if ($(li).text()) {
                parts.push(`- ${$(li).text()}`);
              }
            });
        }
        parts.push("");
      });
    parts.push("");
  });

  const result = parts.join("\n");
  return result;
}

function removeStyle(html) {
  const $ = cheerio.load(html, null, false);
  $("style").remove();
  return $.html();
}

function removeImageTag(html) {
  const $ = cheerio.load(html, null, false);
  $("img").remove();
  return $.html();
}
