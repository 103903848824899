/***
 *
 *   ONBOARDING LAYOUT
 *   Simple layout to focus on user onboarding actions
 *
 **********/

import React, { useEffect } from "react";
import Style from "./onboarding.module.scss";
import dotted_background from "./img/dotted_background.svg";

export function OnboardingLayout(props) {
  return (
    <div className={Style.main}>
      <main className={Style.onboarding}>
        {<props.children {...props.data} />}
      </main>
      <img
        src={dotted_background}
        className={Style.shadowGradient}
        alt="A GIF showing how a text snippet"
      />
    </div>
  );
}
