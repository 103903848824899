import React, { useRef } from "react";
import { MyButton } from "components/lib";
import Style from "./promptResultScreen.module.scss";
import { ReactComponent as GenerativeAiIcon } from "./img/generative_ai_icon.svg";
import { ReactComponent as Lightbulb } from "./img/lightbulb_regular.svg";
import { SwitchButton } from "./emptyContentPageModalAi";

export function PromptResultScreen({
  communicationStyle,
  complexityLevel,
  currentCharCount,
  handleTextChange,
  inclusiveWriting,
  setCommunicationStyle,
  setComplexityLevel,
  setCurrentScreen,
  setInclusiveWriting,
  submitRevisionRequest,
  startEditGeneratedTemplate,
  askForChangesTextAreaRef,
  resultText,
}) {
  return (
    <>
      <div className={Style.headerSection}>
        <div className={Style.iconAndTitleWrapper}>
          <GenerativeAiIcon />
          <h2>Your draft was generated!</h2>
        </div>
      </div>
      <div className={Style.generatJobPostingWrapper}>
        <div className={Style.leftColInput}>
          <p>Current draft</p>
          <div className={Style.textareaWrapperMain}>
            {resultText && resultText.summary && (
              <div>
                <h3>{resultText.summary.section_title}</h3>
                <p>{resultText.summary.summary}</p>
                <h3>{resultText.key_responsibilities.section_title}</h3>
                <p>{resultText.key_responsibilities.short_introduction}</p>
                <ul>
                  {resultText.key_responsibilities.list_of_key_responsibilities.map(
                    (value, index) => {
                      return (
                        <li key={"key_responsibility_" + index}>{value}</li>
                      );
                    }
                  )}
                </ul>
                <h3>{resultText.skills_and_requirements.section_title}</h3>
                <p>{resultText.skills_and_requirements.short_introduction}</p>
                <ul>
                  {resultText.skills_and_requirements.list_of_skills_and_requirements.map(
                    (value, index) => {
                      return (
                        <li key={"skills_and_requirements_" + index}>
                          {value}
                        </li>
                      );
                    }
                  )}
                </ul>
                <h3>{resultText.benefits.section_title}</h3>
                <p>{resultText.benefits.short_introduction}</p>
                <ul>
                  {resultText.benefits.list_of_benefits.map((value, index) => {
                    return <li key={"benefit_" + index}>{value}</li>;
                  })}
                </ul>
              </div>
            )}
          </div>
          <div className={Style.submitButtonsWrapper}>
            <MyButton
              onClick={() => setCurrentScreen("draft_select")}
              buttonStyle="outline"
              size="large"
            >
              {"   "}Discard draft{"   "}
            </MyButton>
            <MyButton
              onClick={() => {
                startEditGeneratedTemplate();
              }}
              buttonStyle="primary"
              size="large"
            >
              Start editing
            </MyButton>
          </div>
        </div>
        <div className={Style.rightConfigAndSubmit}>
          <p>Ask for changes ?</p>
          <div className={Style.textareaWrapperAskForChanges}>
            <textarea
              ref={askForChangesTextAreaRef}
              onChange={handleTextChange}
            ></textarea>
            <span>{currentCharCount + " "}/ 400</span>
          </div>
          <div className={Style.adviceWrapper}>
            <Lightbulb />
            <div className={Style.text}>
              <p>
                Give direction for changes to the job description. Be direct and
                precise with your instruction.{" "}
              </p>
            </div>
          </div>
          <div className={Style.toggleMenuWrapper}>
            <p>Tone of voice</p>
            <label className={Style.settingLabel}>Communication style</label>
            <div className={Style.buttonGroup}>
              <button
                onClick={() => setCommunicationStyle("normal")}
                className={
                  communicationStyle === "normal" ? Style.buttonActive : ""
                }
              >
                Normal
              </button>
              <button
                onClick={() => setCommunicationStyle("professional")}
                className={
                  communicationStyle === "professional"
                    ? Style.buttonActive
                    : ""
                }
              >
                Professional
              </button>
              <button
                onClick={() => setCommunicationStyle("friendly")}
                className={
                  communicationStyle === "friendly" ? Style.buttonActive : ""
                }
              >
                Friendly
              </button>
              <button
                onClick={() => setCommunicationStyle("inspirational")}
                className={
                  communicationStyle === "inspirational"
                    ? Style.buttonActive
                    : ""
                }
              >
                Inspirational
              </button>
            </div>
            <div className={Style.rowWrapper}>
              <div className={Style.firstCol}>
                <label className={Style.settingLabel}>Complexity Level</label>
                <div className={Style.buttonGroup}>
                  <button
                    onClick={() => setComplexityLevel("normal")}
                    className={
                      complexityLevel === "normal" ? Style.buttonActive : ""
                    }
                  >
                    Normal
                  </button>
                  <button
                    onClick={() => setComplexityLevel("simple")}
                    className={
                      complexityLevel === "simple" ? Style.buttonActive : ""
                    }
                  >
                    Simple
                  </button>
                  <button
                    onClick={() => setComplexityLevel("detailed")}
                    className={
                      complexityLevel === "detailed" ? Style.buttonActive : ""
                    }
                  >
                    Detailed
                  </button>
                </div>
              </div>
              <div className={Style.secondCol}>
                <label className={Style.settingLabel}>Inclusive writing </label>
                <SwitchButton
                  currentSwitchState={inclusiveWriting}
                  callback={() => setInclusiveWriting(!inclusiveWriting)}
                />
              </div>
            </div>
          </div>
          <div className={Style.submitButtonsWrapper}>
            <MyButton
              onClick={() => {
                if (askForChangesTextAreaRef?.current?.value.length > 0) {
                  setCurrentScreen("loading_screen_generate");
                  submitRevisionRequest();
                }
              }}
              buttonStyle={
                askForChangesTextAreaRef?.current?.value.length > 0
                  ? "primary"
                  : "disabled"
              }
              size="large"
            >
              Generate {"   "}
              <GenerativeAiIcon
                style={
                  askForChangesTextAreaRef?.current?.value.length > 0
                    ? {}
                    : { fill: "#333333",path: { fill: "#333333" } }
                }
              />
            </MyButton>
          </div>
        </div>
      </div>
    </>
  );
}
