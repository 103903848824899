/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import Style from "./textInputs.module.scss";
import { Field } from "formik";
import { ColorPicker } from "../colorPicker.js";
import WebFont from "webfontloader";
import { CollapseToggle, Icon } from "components/lib";
import { ToggleButtonMultiple, ToggleButtonSingle } from "../ToggleButtons";
import align_center from "../img/configIcons/align_center.svg";
import align_justify from "../img/configIcons/align_justify.svg";
import align_left from "../img/configIcons/align_left.svg";
import align_right from "../img/configIcons/align_right.svg";
import align_center_white from "../img/configIcons/align_center_white.svg";
import align_justify_white from "../img/configIcons/align_justify_white.svg";
import align_left_white from "../img/configIcons/align_left_white.svg";
import align_right_white from "../img/configIcons/align_right_white.svg";
import height from "../img/configIcons/height.svg";
import space_after from "../img/configIcons/space_after.svg";
import letterSpacing from "../img/configIcons/spacing.svg";
import text_size from "../img/configIcons/text_size.svg";
import increase from "../img/configIcons/increase.svg";
import decrease from "../img/configIcons/decrease.svg";
import underlineThikness from "../img/configIcons/underlineThikness.svg";

WebFont.load({
  google: {
    families: [
      "Lato:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900",
      "Titillium Web:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900",
      "sans-serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900",
      "Roboto:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900",
      "Open Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900",
      "Source Sans Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900",
      "Domine:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900",
      "Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900",
      "Oswald:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900",
      "Merriweather:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900",
      "Inter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900",
    ],
  },
});

export const TextInputs = (props) => {
  const inputID = props.inputID;
  const theme = props.theme;

  useEffect(() => {}, []);

  const fonts = [
    //visible name , styling
    ["Lato", "Lato"], //REMOVE
    ["Merriweather", "Merriweather"],
    ["Montserrat", "Montserrat"],
    ["Domine", "Domine"],
    ["Open Sans", "Open Sans"],
    ["Oswald", "Oswald"],
    ["Roboto", "Roboto"],
    ["Sans-serif", "sans-serif"],
    ["Source Sans Pro", "Source Sans Pro"],
    ["Inter", "Inter"],
  ];

  const fontTextDensity = [
    //visible name , styling
    ["Dense", "1.2"],
    ["Normal", "1.6"],
    ["Loose", "2.2"],
  ];

  const blockTextStyle = [
    {
      name: "Light",
      value: 300,
      customStyle: { fontWeight: "100" },
    },
    {
      name: "Normal",
      value: 400,
      customStyle: { fontWeight: "400" },
    },
    {
      name: "Bold",
      value: 700,
      customStyle: { fontWeight: "700" },
    },
    {
      name: "Extra Bold",
      value: 900,
      customStyle: { fontWeight: "900" },
    },
  ];

  return (
    <div className={Style.collapseWrapper}>
      <div className={Style.editBoxWrapper}>
        <div className={Style.buttonWrapper}>
          <div
            className={Style.inputSelect}
            style={{ width: "calc(100% / 3 * 2 - 6.4px)" }}
          >
            <Field
              as="select"
              name={inputID + ".font"}
              className={Style.width2of3}
            >
              {fonts.map((item, index) => (
                <option
                  key={index}
                  value={item[1]}
                  style={{ fontFamily: item[1] }}
                >
                  {item[0]}
                </option>
              ))}
            </Field>
          </div>
          <div
            className={Style.textInputWithIcon}
            style={{ width: "calc(100% / 3 - 6.4px)" }}
          >
            <img src={text_size} className={Style.inputIcon} />
            <Field as="input" name={props.inputID + ".size"}></Field>
          </div>
        </div>

        <div className={Style.buttonWrapper}>
          <div
            className={Style.inputSelect}
            style={{ width: "calc(100% / 2 - 6.4px)" }}
          >
            <Field as="select" name={inputID + ".blockStyle." + "fontWeight"}>
              {blockTextStyle.map((item, index) => (
                <option key={index} value={item.value} style={item.customStyle}>
                  {item.name}
                </option>
              ))}
            </Field>
          </div>
          <div
            className={Style.inputSelect}
            style={
              (theme.banner.isActive && props.hideColorPicker)
                ? {
                  width: "calc(100% / 2 - 6.4px)",
                  visibility: "hidden",
                }
                : {
                  width: "calc(100% / 2 - 6.4px)",
                  }
            }
          >
            <Field name={inputID + ".color"}>
              {props.theme &&
                ((props) => {
                  return (
                    <ColorPicker
                      name={inputID + ".color"}
                      startColor={theme[inputID].color}
                      {...props}
                    />
                  );
                })}
            </Field>
          </div>
        </div>
        <Field name={props.inputID + ".textAlign"}>
          {({ field, form, meta }) => (
            <div
              className={Style.radioButtons}
              style={{ width: "calc(100% / 3 * 2 - 6.4px)" }}
            >
              <button
                onClick={() => {
                  form.setFieldValue(field.name, "left");
                }}
                style={
                  form.values[props.inputID].textAlign === "left"
                    ? {}
                    : { backgroundColor: "white" }
                }
              >
                <img
                  src={
                    form.values[props.inputID].textAlign !== "left"
                      ? align_left
                      : align_left_white
                  }
                  className={Style.inputIcon}
                />
              </button>
              <button
                onClick={() => {
                  form.setFieldValue(field.name, "center");
                }}
                style={
                  form.values[props.inputID].textAlign === "center"
                    ? {}
                    : { backgroundColor: "white" }
                }
              >
                <img
                  src={
                    form.values[props.inputID].textAlign !== "center"
                      ? align_center
                      : align_center_white
                  }
                  className={Style.inputIcon}
                />
              </button>
              <button
                onClick={() => {
                  form.setFieldValue(field.name, "right");
                }}
                style={
                  form.values[props.inputID].textAlign === "right"
                    ? {}
                    : { backgroundColor: "white" }
                }
              >
                <img
                  src={
                    form.values[props.inputID].textAlign !== "right"
                      ? align_right
                      : align_right_white
                  }
                  className={Style.inputIcon}
                />
              </button>
              <button
                onClick={() => {
                  form.setFieldValue(field.name, "justify");
                }}
                style={
                  form.values[props.inputID].textAlign === "justify"
                    ? {}
                    : { backgroundColor: "white", color: "#333" }
                }
              >
                <img
                  src={
                    form.values[props.inputID].textAlign !== "justify"
                      ? align_justify
                      : align_justify_white
                  }
                  className={Style.inputIcon}
                />
              </button>
            </div>
          )}
        </Field>
        <br />
        <div className={Style.buttonWrapper}>
          <div style={{ display: "flex", width: "100%" }}>
            <ToggleButtonSingle
              inputID={props.inputID}
              blockStyleElement={"underline"}
              toggleItems={{
                name: "underline",
                value: true,
                customStyle: { textDecoration: "Underline" },
              }}
            />
            <ToggleButtonSingle
              inputID={props.inputID}
              blockStyleElement={"italic"}
              toggleItems={{
                name: "italic",
                value: true,
                customStyle: { fontStyle: "italic" },
              }}
            />
            <ToggleButtonSingle
              inputID={props.inputID}
              blockStyleElement={"uppercase"}
              toggleItems={{
                name: "uppercase",
                value: true,
                customStyle: { textTransform: "uppercase" },
              }}
            />
          </div>
        </div>
        {theme[inputID].blockStyle.underline && (
          <div className={Style.rowWrapper}>
            <Icon image="corner-down-right" size="12px" />
            <div
              className={Style.inputSelect}
              style={{ width: "calc(100% / 2 - 6.4px)" }}
            >
              <Field name={inputID + ".textDecorationColor"}>
                {props.theme &&
                  ((props) => {
                    return (
                      <ColorPicker
                        name={inputID + ".textDecorationColor"}
                        startColor={theme[inputID].textDecorationColor}
                        {...props}
                      />
                    );
                  })}
              </Field>
            </div>
            <p>Line color</p>
          </div>
        )}

        <br />
        <div className={Style.buttonWrapper}>
          <div
            className={Style.textInputWithIconAndPlusMinus}
            style={{ width: "calc(100% / 2 - 6.4px)" }}
          >
            <img src={height} className={Style.inputIcon} />
            <Field name={props.inputID + ".lineHeight"}>
              {({ field, form, meta }) => (
                <>
                  <img
                    onClick={() => {
                      form.setFieldValue(
                        props.inputID + ".lineHeight",
                        parseInt(field.value) - 1
                      );
                    }}
                    src={decrease}
                    className={Style.inputIconSmall}
                  />
                  <input type="text" {...field} />
                  <img
                    onClick={() => {
                      form.setFieldValue(
                        props.inputID + ".lineHeight",
                        parseInt(field.value) + 1
                      );
                    }}
                    src={increase}
                    className={Style.inputIconSmall}
                  />
                </>
              )}
            </Field>
          </div>
          <div
            className={Style.textInputWithIconAndPlusMinus}
            style={{ width: "calc(100% / 2 - 6.4px)" }}
          >
            <img src={letterSpacing} className={Style.inputIcon} />
            <Field name={props.inputID + ".letterSpacing"}>
              {({ field, form, meta }) => (
                <>
                  <img
                    onClick={() => {
                      let newFieldValue = (field.value * 10 - 1) / 10;
                      form.setFieldValue(
                        props.inputID + ".letterSpacing",
                        newFieldValue
                      );
                    }}
                    src={decrease}
                    className={Style.inputIconSmall}
                  />
                  <input type="text" {...field} />
                  <img
                    onClick={() => {
                      let newFieldValue = (field.value * 10 + 1) / 10;
                      form.setFieldValue(
                        props.inputID + ".letterSpacing",
                        newFieldValue
                      );
                    }}
                    src={increase}
                    className={Style.inputIconSmall}
                  />
                </>
              )}
            </Field>
          </div>
        </div>
        <div
          className={Style.textInputWithIconAndPlusMinus}
          style={{ width: "calc(100% / 2 - 6.4px)" }}
        >
          <img src={space_after} className={Style.inputIcon} />
          <Field name={props.inputID + ".marginBottom"}>
            {({ field, form, meta }) => (
              <>
                <img
                  onClick={() => {
                    form.setFieldValue(
                      props.inputID + ".marginBottom",
                      changeValue(parseInt(field.value), 1, "remove")
                    );
                  }}
                  src={decrease}
                  className={Style.inputIconSmall}
                />
                <input type="text" {...field} />
                <img
                  onClick={() => {
                    form.setFieldValue(
                      props.inputID + ".marginBottom",
                      changeValue(parseInt(field.value), 1, "add")
                    );
                  }}
                  src={increase}
                  className={Style.inputIconSmall}
                />
              </>
            )}
          </Field>
        </div>

        <br />
      </div>
      {/* </>
        } */}
      {/* /> */}
    </div>
  );
};

const changeValue = (value, delta, increaseOrDecrease) => {
  // console.log(value);
  if (typeof value === "number") {
    switch (increaseOrDecrease) {
      case "add":
        const newValue = value + delta;
        return newValue;
      case "remove":
        return value - delta;
      default:
        break;
    }
  } else {
  }
};

// return (
//   <div className={Style.collapseWrapper}>
//     <CollapseToggle
//       defaultState={"collapsed"}
//       toggleTitle={
//         <div className={Style.formSubtitleWrapper}>
//           <h2 className={Style.formSubtitle}>{props.inputDescription}</h2>
//         </div>
//       }
//       contentExpanded={
//         <>
//           <div className={Style.editBoxWrapper}>
//             <div className={Style.buttonWrapper}>
//               <div>
//                 <p className={Style.inputLabel}>Fonts : </p>
//                 <Field
//                   as="select"
//                   name={inputID + ".font"}
//                   className={Style.inputStyle + " " + Style.inputStyleFont}
//                 >
//                   {fonts.map((item, index) => (
//                     <option
//                       key={index}
//                       value={item[1]}
//                       style={{ fontFamily: item[1] }}
//                     >
//                       {item[0]}
//                     </option>
//                   ))}
//                 </Field>
//               </div>
//               <div>
//                 <p className={Style.inputLabel}>Size : </p>

//                 <div style={{ display: "flex", alignItems: "baseline" }}>
//                   <Field
//                     as="input"
//                     name={props.inputID + ".size"}
//                     style={{ width: "60px", marginRight: "10px" }}
//                     className={Style.inputStyle}
//                   ></Field>
//                   <p className={Style.inputMetrics}>px</p>
//                 </div>
//               </div>
//             </div>

//             <div className={Style.buttonWrapper}>

//               <div>
//                 <p className={Style.inputLabel}>Spacing : </p>

//                 <div style={{ display: "flex" }}>
//                   <Field
//                     as="select"
//                     name={props.inputID + ".lineHeight"}
//                     className={Style.inputStyle}
//                   >
//                     {fontTextDensity.map((item, index) => (
//                       <option
//                         key={index}
//                         value={item[1]}
//                         style={{ fontFamily: item[1] }}
//                       >
//                         {item[0]}
//                       </option>
//                     ))}
//                   </Field>
//                 </div>
//               </div>
//               <div>
//                 <p className={Style.inputLabel}>Color : </p>
//                 <Field name={inputID + ".color"}>
//                   {props.theme &&
//                     ((props) => {
//                       return (
//                         <ColorPicker
//                           name={inputID + ".color"}
//                           startColor={theme[inputID].color}
//                           {...props}
//                         />
//                       );
//                     })}
//                 </Field>
//               </div>
//             </div>
//             <div className={Style.buttonWrapper}>
//               <div>
//                 <p className={Style.inputLabel}>Block styling : </p>

//                 <div style={{ display: "flex" }}>
//                   <ToggleButtonMultiple
//                     inputID={props.inputID}
//                     blockStyleElement={"fontWeight"}
//                     toggleItems={blockTextStyle}
//                   />
//                 </div>
//                 <div style={{ display: "flex" }}>
//                   <ToggleButtonSingle
//                     inputID={props.inputID}
//                     blockStyleElement={"underline"}
//                     toggleItems={{
//                       name: "underline",
//                       value: true,
//                       customStyle: { textDecoration: "Underline" },
//                     }}
//                   />
//                   <ToggleButtonSingle
//                     inputID={props.inputID}
//                     blockStyleElement={"italic"}
//                     toggleItems={{
//                       name: "italic",
//                       value: true,
//                       customStyle: { fontStyle: "italic" },
//                     }}
//                   />
//                   <ToggleButtonSingle
//                     inputID={props.inputID}
//                     blockStyleElement={"uppercase"}
//                     toggleItems={{
//                       name: "uppercase",
//                       value: true,
//                       customStyle: { textTransform: "uppercase" },
//                     }}
//                   />
//                 </div>
//                 <div>
//                 <p className={Style.inputLabel}>marginBottom: </p>

//                 <div style={{ display: "flex", alignItems: "baseline" }}>
//                   <Field
//                     as="input"
//                     name={props.inputID + ".marginBottom"}
//                     style={{ width: "160px", marginRight: "10px" }}
//                     className={Style.inputStyle}
//                   ></Field>
//                 </div>
//                 <p className={Style.inputLabel}>lineHeight: </p>

//                 <div style={{ display: "flex", alignItems: "baseline" }}>
//                   <Field
//                     as="input"
//                     name={props.inputID + ".lineHeight"}
//                     style={{ width: "160px", marginRight: "10px" }}
//                     className={Style.inputStyle}
//                   ></Field>
//                 </div>
//                 <p className={Style.inputLabel}>letterSpacing: </p>

//                 <div style={{ display: "flex", alignItems: "baseline" }}>
//                   <Field
//                     as="input"
//                     name={props.inputID + ".letterSpacing"}
//                     style={{ width: "160px", marginRight: "10px" }}
//                     className={Style.inputStyle}
//                   ></Field>
//                 </div>
//               </div>
//               </div>
//             </div>
//           </div>
//         </>
//       }
//     />
//   </div>
// );
