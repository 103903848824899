import React, { useContext, useState } from "react";
import {
  Icon,
  History,
  EditorContext,
  MyButton,
  SaveStatusIndicator,
  ExportModal,
  CollaborateModal,
} from "components/lib";
import Style from "./editorNav.module.scss";

export function EditorNav(props) {
  const currentStep = useContext(EditorContext).currentStep;
  const jobBasicInfo = useContext(EditorContext).jobBasicInfo;
  const blockDictionary = useContext(EditorContext).blockDictionary;
  const theme = useContext(EditorContext).theme;
  const setCurrentStep = useContext(EditorContext).setCurrentStep;
  const share_link_mode = useContext(EditorContext).share_link_mode;

  return (
    <div className={Style.editorNav}>
      <div className={Style.editorNav__leftItems}>
        <div style={share_link_mode ? { display: "none" } : {}}>
          <MyButton size={"small"} onClick={() => History.push("/joblist")}>
            <Icon image="arrow-left" size={16} />
          </MyButton>
        </div>
        <span className={Style.jobName}>{props.jobName}</span>
        <SaveStatusIndicator saveStatus={props.saveStatus} />
      </div>
      <div className={Style.editorNav__centerItems}>
        <MyButton
          onClick={() => {
            setCurrentStep("basic_information");
            console.log(blockDictionary);
          }}
          className={Style.steps}
          size={"small"}
          buttonStyle={"ghost"}
          active={currentStep === "basic_information"}
        >
          <div className={Style.stepIndicator}>1</div>
          Basic information
        </MyButton>
        <MyButton
          onClick={() => {
            console.log(blockDictionary);
            if (jobBasicInfo.errors) {
              setCurrentStep("block_editor");
            }
          }}
          className={Style.steps + Style.active}
          size={"small"}
          buttonStyle={jobBasicInfo.errors ? "ghost" : "disabled"}
          active={currentStep === "block_editor"}
        >
          <div className={Style.stepIndicator}>2</div>
          Content
        </MyButton>
        <MyButton
          onClick={() => {
            if (jobBasicInfo.errors && props.isBlocks) {
              setCurrentStep("theme_selector");
            }
          }}
          className={Style.steps}
          size={"small"}
          buttonStyle={
            jobBasicInfo.errors && props.isBlocks ? "ghost" : "disabled"
          }
          active={currentStep === "theme_selector"}
        >
          <div className={Style.stepIndicator}>3</div>
          Preview
        </MyButton>
      </div>
      <div className={Style.editorNav__rightItems}>
        {!share_link_mode && (
          <>
            <div>
              <CollaborateModal jobName={props.jobName} />
            </div>
            <ExportModal jobId={props.jobId} isBlocks={props.isBlocks} />
          </>
        )}
        {share_link_mode && (
          <p className={Style.guestEditLabel}>Guest edit mode</p>
        )}
      </div>
    </div>
  );
}
