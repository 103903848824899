import React from "react";
import { MyButton } from "components/lib";
import Style from "./firstPromptScreen.module.scss";
import { ReactComponent as GenerativeAiIcon } from "./img/generative_ai_icon.svg";
import { ReactComponent as Lightbulb } from "./img/lightbulb_regular.svg";
import { SwitchButton } from "./emptyContentPageModalAi";

export function FirstPromptScreen({ communicationStyle, complexityLevel, currentCharCount, handleTextChange, inclusiveWriting, setCommunicationStyle, setComplexityLevel, setCurrentScreen, setInclusiveWriting, submitGenerateRequest, textAreaRef }) {
  return <>
    <div className={Style.headerSection}>
      <div className={Style.iconAndTitleWrapper}>
        <GenerativeAiIcon />
        <h2>Generate Job Posting Draft</h2>
      </div>
      <p>Decribe the position in a few words</p>
    </div>
    <div className={Style.generatJobPostingWrapper}>
      <div className={Style.leftColInput}>
        <div className={Style.textareaWrapper}>
          <textarea
            ref={textAreaRef}
            onChange={handleTextChange}
          ></textarea>
          <span>{currentCharCount + " "}/ 2000</span>
        </div>
        <div className={Style.adviceWrapper}>
          <Lightbulb />
          <div className={Style.text}>
            <p>
              Briefly describe the job using any words or phrases that
              come to mind – no need for complete sentences or a
              specific structure. Our AI will handle the formatting.{" "}
            </p>
            <p className={Style.tips}>
              Tip: For best results, briefly mention key
              qualifications, responsibilities, etc., in your
              description.
            </p>
          </div>
        </div>
      </div>
      <div className={Style.rightConfigAndSubmit}>
        <div className={Style.toggleMenuWrapper}>
          <p>Tone of voice</p>
          <label className={Style.settingLabel}>
            Communication style
          </label>
          <div className={Style.buttonGroup}>
            <button
              onClick={() => setCommunicationStyle("normal")}
              className={communicationStyle === "normal"
                ? Style.buttonActive
                : ""}
            >
              Normal
            </button>
            <button
              onClick={() => setCommunicationStyle("professional")}
              className={communicationStyle === "professional"
                ? Style.buttonActive
                : ""}
            >
              Professional
            </button>
            <button
              onClick={() => setCommunicationStyle("friendly")}
              className={communicationStyle === "friendly"
                ? Style.buttonActive
                : ""}
            >
              Friendly
            </button>
            <button
              onClick={() => setCommunicationStyle("inspirational")}
              className={communicationStyle === "inspirational"
                ? Style.buttonActive
                : ""}
            >
              Inspirational
            </button>
          </div>
          <div className={Style.rowWrapper}>
            <div className={Style.firstCol}>
              <label className={Style.settingLabel}>
                Complexity Level
              </label>
              <div className={Style.buttonGroup}>
                <button
                  onClick={() => setComplexityLevel("normal")}
                  className={complexityLevel === "normal"
                    ? Style.buttonActive
                    : ""}
                >
                  Normal
                </button>
                <button
                  onClick={() => setComplexityLevel("simple")}
                  className={complexityLevel === "simple"
                    ? Style.buttonActive
                    : ""}
                >
                  Simple
                </button>
                <button
                  onClick={() => setComplexityLevel("detailed")}
                  className={complexityLevel === "detailed"
                    ? Style.buttonActive
                    : ""}
                >
                  Detailed
                </button>
              </div>
            </div>
            <div className={Style.secondCol}>
              <label className={Style.settingLabel}>
                Inclusive writing{" "}
              </label>
              <SwitchButton
                currentSwitchState={inclusiveWriting}
                callback={() => setInclusiveWriting(!inclusiveWriting)} />
            </div>
          </div>
        </div>
        <div className={Style.submitButtonsWrapper}>
          <MyButton
            onClick={() => setCurrentScreen("draft_select")}
            buttonStyle="outline"
            size="large"
          >
            {"   "}Cancel{"   "}
          </MyButton>
          <MyButton
            onClick={() => {
              setCurrentScreen("loading_screen_generate");
              submitGenerateRequest();
            }}
            buttonStyle="primary"
            size="large"
          >
            Generate {"   "}
            <GenerativeAiIcon />
          </MyButton>
        </div>
      </div>
    </div>
  </>;
}
