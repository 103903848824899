import React, { useState, useRef, useContext } from "react";
import { EditorContext, BlockRenderer, Icon } from "components/lib";

export function PdfContentGenerator(props) {
  const jobBasicInfo = useContext(EditorContext).jobBasicInfo.values;
  const blockDictionary = useContext(EditorContext).blockDictionary;
  const blockOrder = useContext(EditorContext).blockOrder;
  const theme = useContext(EditorContext).theme;
  const ref = useRef();

  let contentDisplacement = 0;
  let showTitle = true;

  const textSizeAdjustement = 1

  if (props.currentCoverPageStyle === "noBanner") {
    contentDisplacement = 0;
  }
  if (props.currentCoverPageStyle === "smallCover") {
    contentDisplacement = 360;
    showTitle = false;
  }
  if (props.currentCoverPageStyle === "fullPageCover") {
    contentDisplacement = 1291;
    showTitle = false;
  }
  if (props.currentCoverPageStyle === "bannerCover") {
    contentDisplacement = 500;
  }

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          html, body{
            -webkit-print-color-adjust:exact !important;
            print-color-adjust:exact !important;
            height:100%;
            display: block
          }
          @font-face {
            font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format('woff2');
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
          }

          #main {
          }
          .skipPageSpacer{
            height:${contentDisplacement}px;
            width:200px;
          }
          .background_jc { 
            background-color: ${theme.background.color};
          }
          .background_full_page {
            position: fixed;
            height: 100%;
            width: 100%;
            background-color: ${theme.background.color};
            z-index:-1;
          }
          .style_jc > h1, .style_jc > h2, .style_jc > h3, .style_jc > h4, .style_jc > h5, .style_jc > h6, .style_jc > p, .style_jc > li {
            font-weight: normal;
            word-break: break-word;
            hyphens: manual;
          }
          .style_jc > h1 { 
            hyphens: manual;
            font-family: ${theme.h1?.font};
            font-size: ${theme.h1.size * textSizeAdjustement + "px"};
            font-weight: ${theme.h1.blockStyle.fontWeight};
            color: ${theme.h1.color};
            text-align: ${theme.h1.textAlign};
            line-height: ${theme.h1.lineHeight + "px"};
            letter-spacing: ${theme.h1.letterSpacing + "px"};
            margin-bottom: ${theme.h1.marginBottom + "px"};
            text-decoration: ${
              theme.h1.blockStyle.underline === true ? "underline" : "none"
            };
            text-decoration-color: ${
              theme.h1.textDecorationColor !== ""
                ? theme.h1.textDecorationColor
                : "none"
            };
            font-style: ${
              theme.h1.blockStyle.italic === true ? "italic" : "normal"
            };
            text-transform: ${
              theme.h1.blockStyle.uppercase === true ? "uppercase" : "normal"
            };
            break-after: avoid;
          }
         
          .style_jc > h2 { 
            font-family: ${theme.h2.font};
            font-size: ${theme.h2.size * textSizeAdjustement + "px"};
            font-weight: ${theme.h2.blockStyle.fontWeight};
            color: ${theme.h2.color};
            text-align: ${theme.h2.textAlign};
            // line-height: ${theme.h2.lineHeight + "px"};
            letter-spacing: ${1 * (theme.h2.letterSpacing / 100) + "em"};
            margin-bottom: ${theme.h2.marginBottom + "px"};
            text-decoration: ${
              theme.h2.blockStyle.underline === true ? "underline" : "none"
            };
            text-decoration-color: ${
              theme.h2.textDecorationColor !== ""
                ? theme.h2.textDecorationColor
                : "none"
            };
            font-style: ${
              theme.h2.blockStyle.italic === true ? "italic" : "normal"
            };
            text-transform: ${
              theme.h2.blockStyle.uppercase === true ? "uppercase" : "normal"
            };
            break-after: avoid;
          }

          .style_jc > h3 { 
            font-family: ${theme.h3.font};
            font-size: ${theme.h3.size * textSizeAdjustement + "px"};
            font-weight: ${theme.h3.blockStyle.fontWeight};
            color: ${theme.h3.color};
            text-align: ${theme.h3.textAlign};
            // line-height: ${theme.h3.lineHeight + "px"};
            letter-spacing: ${1 * (theme.h3.letterSpacing / 100) + "em"};
            margin-bottom: ${theme.h3.marginBottom + "px"};
            text-decoration: ${
              theme.h3.blockStyle.underline === true ? "underline" : "none"
            };
            text-decoration-color: ${
              theme.h3.textDecorationColor !== ""
                ? theme.h1.textDecorationColor
                : "none"
            };
            font-style: ${
              theme.h3.blockStyle.italic === true ? "italic" : "normal"
            };
            text-transform: ${
              theme.h3.blockStyle.uppercase === true ? "uppercase" : "normal"
            };
          }

          .style_jc > p, .jc_block > ul > li  { 
            font-family: ${theme.body.font};
            font-size: ${theme.body.size * textSizeAdjustement + "px"};
            font-weight: ${theme.body.blockStyle.fontWeight};
            color: ${theme.body.color};
            text-align: ${theme.body.textAlign};
            // line-height: ${theme.body.lineHeight + "px"};
            letter-spacing: ${1 * (theme.body.letterSpacing / 100) + "em"};
            margin-bottom: ${theme.body.marginBottom + "px"};
            display:block;
              // break-inside: avoid;
            text-decoration: ${
              theme.body.blockStyle.underline === true ? "underline" : "none"
            };
            text-decoration-color: ${
              theme.body.textDecorationColor !== ""
                ? theme.h1.textDecorationColor
                : "none"
            };
            font-style: ${
              theme.body.blockStyle.italic === true ? "italic" : "normal"
            };
            text-transform: ${
              theme.body.blockStyle.uppercase === true ? "uppercase" : "normal"
            };
          }

          .jc_block > ul > li  { 
              list-style-type: circle;
              display: list-item;
              margin-left: 8px;
          }

          .basic_info_jc {
            display: flex;
            flex-direction: row;
            width: 100%;
            margin-bottom: 20px;
            flex-wrap: wrap;
          } 

          .basic_info_item  {
            color: ${theme.body.color};
            font-size: ${theme.body.size * textSizeAdjustement + "px"};
            padding: auto;
            margin-bottom: 6px;
            margin-right: 12px;
          }

          .basic_info_item > svg  {
            margin: auto 6px;
            vertical-align: baseline;
            color: ${theme.body.color};
          }

          .basic_info_label {
            color: ${theme.h2.color};
          }

          .style_jc > img  {
            margin: 0px auto;
            page-break-before: always
          }

          .jc_block {
            margin-bottom: 40px;
          } 
        `,
        }}
      />
      <div className="background_full_page"></div>
      <div className="style_jc background_jc" id="main" ref={ref}>
        <div className="skipPageSpacer"></div>

        {showTitle && <h1>{jobBasicInfo.job_public_name} </h1>}
        <div className="basic_info_jc">
          {jobBasicInfo.employment_type &&
            jobBasicInfo.employment_type_visibility && (
              <div className="basic_info_item">
                <Icon image="briefcase" size="0.85em"></Icon>
                {employmentTypeLabel(jobBasicInfo.employment_type)}
              </div>
            )}
          {jobBasicInfo.location && jobBasicInfo.location_visibility && (
            <div className="basic_info_item">
              <Icon image="map-pin" size="0.85em"></Icon>
              {jobBasicInfo.location}
            </div>
          )}
          {jobBasicInfo.workplace_type &&
            jobBasicInfo.workplace_type_visibility && (
              <div className="basic_info_item">
                <Icon image="home" size="0.85em"></Icon>
                {workplaceTypeLabel(jobBasicInfo.workplace_type)}
              </div>
            )}

          {jobBasicInfo.salary && jobBasicInfo.salary_visibility && (
            <div className="basic_info_item">
              <Icon image="award" size="0.85em"></Icon>
              {jobBasicInfo.salary}
            </div>
          )}
          {jobBasicInfo.company && jobBasicInfo.company_visibility && (
            <div className="basic_info_item">
              <Icon image="flag" size="0.85em"></Icon>
              {jobBasicInfo.company}
            </div>
          )}
        </div>

        {blockOrder
          .filter((item) => blockDictionary[item]?.content.file !== null)
          .map((item, index) => (
              <BlockRenderer
                blockType={blockDictionary[item].type}
                mode={"preview"}
                blockId={item}
                key={item}
                publicRender={true}
              />
          ))}
      </div>
    </>
  );
}

const  employmentTypeLabel = (key) => {
  switch (key) {
    case "full_time":
      return "Full-time";

    case "part_time":
      return "Part-time";

    case "contract":
      return "Contract";

    case "temporary":
      return "Temporary";

    case "volunteer":
      return "Volunteer";

    case "internship":
      return "Internship";

    default:
      break;
  }
};


const workplaceTypeLabel = (key) => {
  switch (key) {
    case "on_site":
      return "On-site";

    case "hybrid":
      return "Hybrid";

    case "remote":
      return "Remote";

    default:
      break;
  }
};
