import React, { useContext } from "react";
import Style from "./themeEditorResponsiveHelper.module.scss";
import {
  PdfRenderer,
  LandingPagePreview,
  MyButton,
  Icon,
  EditorContext,
} from "components/lib";
// import {
//   LandingPagePreview,
//   MobileLandingPagePreview,
// } from "../../components/themeLandingPagePreview/themeLandingPagePreview.js";

function ThemeEditorResponsiveHelper(props) {

  const share_link_mode = useContext(EditorContext).share_link_mode;


  return (
    <div className={Style.themeEditorResponsiveHelper}>
      <div className={Style.MainArea}>
        <div className={Style.themeModeSelector}>
          <MyButton
            onClick={() => {
              props.setPreviewMode("desktop");
            }}
            size={"small"}
            buttonStyle={"ghost"}
            className={Style.buttomWrapper}
          >
            <div className={Style.inButtonIcon}>
              <div
                className={Style.iconWrapper}
                style={{
                  background:
                    props.previewMode === "desktop" ? "#73B0F4" : "white",
                }}
              >
                <Icon
                  size={"14px"}
                  image={"layout"}
                  color={props.previewMode === "desktop" ? "white" : ""}
                ></Icon>
              </div>
              Landing page
            </div>
          </MyButton>
          <MyButton
            onClick={() => {
              props.setPreviewMode("mobile");
            }}
            size={"small"}
            buttonStyle={"ghost"}
            className={Style.buttomWrapper}
          >
            <div className={Style.inButtonIcon}>
              <div
                className={Style.iconWrapper}
                style={{
                  background:
                    props.previewMode === "mobile" ? "#73B0F4" : "white",
                }}
              >
                <Icon
                  size={"14px"}
                  image={"smartphone"}
                  color={props.previewMode === "mobile" ? "white" : ""}
                ></Icon>
              </div>
              Mobile
            </div>
          </MyButton>
          {!share_link_mode && (
            <MyButton
              onClick={() => {
                props.setPreviewMode("pdf");
              }}
              size={"small"}
              buttonStyle={"ghost"}
              className={Style.buttomWrapper}
            >
              <div className={Style.inButtonIcon}>
                <div
                  className={Style.iconWrapper}
                  style={{
                    background:
                      props.previewMode === "pdf" ? "#73B0F4" : "white",
                  }}
                >
                  <Icon
                    size={"14px"}
                    image={"file-text"}
                    color={props.previewMode === "pdf" ? "white" : ""}
                  ></Icon>
                </div>
                PDF
              </div>
            </MyButton>
          )}
        </div>
        <div className={Style.previewSpace}>
          {props.previewMode === "desktop" && <LandingPagePreview />}
          {props.previewMode === "mobile" && <LandingPagePreview mobileMode />}
          {props.previewMode === "pdf" && (
            <PdfRenderer
              coverPage={props.coverPage}
              setCoverPage={props.setCoverPage}
              previewMode={props.previewMode}
            />
          )}
        </div>
        <div className={Style.settingsSpace}>{props.settingSpace}</div>
      </div>
    </div>
  );
}

export default ThemeEditorResponsiveHelper;
