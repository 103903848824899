/***
 *
 *   BILLING / PLAN
 *   Update the billing plan
 *
 **********/

import React, { useContext, useState, useRef } from "react";
import {
  ViewContext,
  Card,
  Message,
  MyButton,
  usePlans,
  Icon,
} from "components/lib";
import Style from "./plan.module.scss";
import Axios from "axios";
import Modal from "@material-ui/core/Modal";
import FirstUserImage from "../../../components/priceCard/img/first-user-is-free.svg";
import icon_collaborate from "../../../components/priceCard/img/icon_collaborate.svg";
import icon_edit_pen from "../../../components/priceCard/img/icon_edit_pen.svg";
import icon_image from "../../../components/priceCard/img/icon_image.svg";
import icon_snippets from "../../../components/priceCard/img/icon_snippets.svg";
import icon_theme from "../../../components/priceCard/img/icon_theme.svg";
import icon_unlimited from "../../../components/priceCard/img/icon_unlimited.svg";

export function BillingPlan(props) {
  const plans = usePlans();
  const viewContext = useContext(ViewContext);
  const [open, setOpen] = useState(false);
  const [priceLabel, setPriceLabel] = useState();

  const [resumeButtonLoading, setResumeButtonLoading] = useState(false);
  let subscription = {
    ...props.subscription.data.data,
    status: props.subscription.data.status,
  };

  const planInfoRef = useRef(null);

  if (plans?.data && !planInfoRef.current) {
    planInfoRef.current = plans.data.list[0].multi_currency[subscription.currency];
    setPriceLabel(planInfoRef.current);
    // setPriceLabel(plans.data.list[0].label);
  }

  const setAutoRenewStatus = (newAutoRenewStatus) => {
    Axios.patch("/api/account/plan/setAutoRenewStatus", {
      newAutoRenewStatus: newAutoRenewStatus, // False will stop auto-renew on the next billing phase
    })
      .then((res) => {
        props.getSubscription(() => setResumeButtonLoading(false));
      })
      .catch((err) => {
        viewContext.handleError(err);
      });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className={Style.delete_modal}>
          <h3>
            You are about to cancel your{" "}
            {subscription.status === "trialing" ? "trial" : "subscription"}
          </h3>
          <p>
            By clicking{" "}
            {subscription.status === "trialing"
              ? "Cancel Trial"
              : "Pause Subscription"}
            , your subscription will not auto-renew on{" "}
            <u>
              {subscription &&
                subscription.status === "active" &&
                subscription.current_period_end}
              {subscription &&
                subscription.status === "trialing" &&
                subscription.trial_end_date}
            </u>{" "}
            a the end of the current billing period.{" "}
          </p>
          <p>
            As a result, on that date:
            <ul className={Style.bullets_style}>
              <li>
                You and your team will lose access to all Jobchef features.
              </li>
              <li>All your published posts will be unpublished.</li>
            </ul>
          </p>
          <div className={Style.disclaimer}>
            <p>
              <Icon image="alert-triangle" size="12px" />
              If you resubscribe later, promotional pricing applied previously
              may not be available.
            </p>
            <p>
              <a href="/contact">Contact us</a> if you need help or wish to
              discuss alternatives.
            </p>
          </div>
          <div className={Style.flexDivHorizontal}>
            <MyButton
              buttonStyle={"outline"}
              size={"medium"}
              onClick={() => {
                setOpen(false);
              }}
            >
              Go back
            </MyButton>

            <MyButton
              onClick={() => {
                setResumeButtonLoading(true);
                setAutoRenewStatus(false);
                setOpen(false);
              }}
              buttonStyle={"danger"}
              size={"medium"}
              loading={resumeButtonLoading}
            >
              {subscription.status === "trialing"
                ? "Cancel Trial"
                : "Pause Subscription"}
            </MyButton>
            {/* By */}
            {/* Add a "Cancel Trial is "trialing" is true */}
          </div>
        </div>
      </Modal>
      <Card
        loading={plans.loading || props.subscription.loading}
        // restrictWidth
        className={props.className + " " + Style.mainCard}
      >
        {!props.loading && subscription && (
          <>
            {!subscription.cancel_at && (
              <>
                {subscription.status === "active" && (
                  <Message
                    type="info"
                    title="Your Current Billing Cycle"
                    text={`From ${subscription.current_period_start} to ${subscription.current_period_end}`}
                  />
                )}
                {subscription.status === "trialing" && (
                  <Message
                    type="success"
                    title="Trial is active"
                    text={`Your 30 trial is active, normal billing will commence on ${subscription.trial_end_date}.`}
                  />
                )}
              </>
            )}
            {subscription.cancel_at && (
              <>
                {subscription.status === "active" && (
                  <Message
                    type="warning"
                    title={`Your subscription ends on ${subscription.current_period_end}`}
                    text={
                      <>
                        It will automatically be cancelled at the end of the
                        billing period. Want to stay on board? You can{" "}
                        <button
                          style={{
                            padding: "4px",
                            color: "#313131",
                            backgroundColor: "#f8dbbd",
                          }}
                          onClick={() => {
                            setResumeButtonLoading(true);
                            setAutoRenewStatus(true);
                          }}
                          disabled={resumeButtonLoading}
                        >
                          resume your subscription
                        </button>{" "}
                        at anytime before then — quick and easy!
                      </>
                    }
                    //From ${subscription.current_period_start} to ${subscription.current_period_end}
                  />
                )}
                {subscription.status === "trialing" && (
                  <Message
                    type="warning"
                    title={`Heads up – Your trial will end soon`}
                    text={
                      <>
                        You've opted to cancel your trial, but no worries, you
                        can still access Jobchef until{" "}
                        <u>{subscription.current_period_end}</u>. After that,
                        your account will be deactivated. Changed your mind? You
                        can{" "}
                        <button
                          style={{
                            padding: "4px",
                            color: "#313131",
                            backgroundColor: "#f8dbbd",
                          }}
                          onClick={() => {
                            setResumeButtonLoading(true);
                            setAutoRenewStatus(true);
                          }}
                          disabled={resumeButtonLoading}
                        >
                          resume your trial
                        </button>{" "}
                        anytime before the expiration — quick and easy!
                      </>
                    }
                    //From ${subscription.current_period_start} to ${subscription.current_period_end}
                  />
                )}
              </>
            )}
          </>
        )}

        {/* UNCOMMENT for MULTI PLAN */}
        {/* <Form
          data={{
            plan: {
              label: "Your subscription plan",
              type: "select",
              required: true,
              default:
                props?.subscription?.data?.status === "trialing"
                  ? plans?.data?.raw.plans[0]
                  : plans?.data?.active,
              options: plans?.data?.list,
            },
          }}
          url="/api/account/plan"
          method="PATCH"
          buttonText="Update Plan"
          callback={(res) => {
            context.update({ plan: res.data.data.plan });
          }}
        /> */}
        <div className={Style.subscriptionCard}>
          <h3>Your plan:</h3>
          {priceLabel && (
            <div className={Style.middleSection}>
              <PriceLabel currencyInfo={priceLabel} />
              <div className={Style.plusSign}>+</div>
              <PriceLabel
                currencyInfo={priceLabel}
                type="secondary"
              />
            </div>
          )}
          
          <p>
            Access the Jobchef app and all it’s feature. Including collaboration
            links, job templates and more.
          </p>
          <h3>What’s included:</h3>
          <div className={Style.whatsIncludedContent}>
            {Object.keys(cards_included).map((key, index) => {
              return (
                <div className={Style.includeItem}>
                  <img
                    className={Style.includeCardImage}
                    src={cards_included[key].image}
                    alt="First user if free"
                  />
                  <div>
                    <h4 className={Style.includeCardTitle}>
                      {cards_included[key].title}
                    </h4>
                    <p className={Style.includeCardDescription}>
                      {cards_included[key].description}
                    </p>
                  </div>
                </div>
              );
            })}
            <div className={Style.subscriptionActionButton}>
              {!subscription.cancel_at && (
                <MyButton
                  onClick={() => setOpen(true)}
                  buttonStyle={"outline"}
                  size={"medium"}
                  loading={resumeButtonLoading}
                >
                  {subscription.status === "trialing"
                    ? "Cancel Trial"
                    : "Pause Subscription"}
                </MyButton>
              )}
              {subscription.cancel_at && (
                <MyButton
                  onClick={() => {
                    setResumeButtonLoading(true);
                    setAutoRenewStatus(true);
                  }}
                  buttonStyle={"primary"}
                  size={"medium"}
                  loading={resumeButtonLoading}
                >
                  {subscription.status === "trialing"
                    ? "Resume Trial"
                    : "Resume Subscription"}
                </MyButton>
              )}
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}

const cards_included = {
  icon_unlimited: {
    title: "Unlimited Jobs",
    description: "Create and share as many jobs as you’d need",
    image: icon_unlimited,
  },
  icon_collaborate: {
    title: "Share collaboration links",
    description:
      "Easily get help from a colleague on a job posting creation by sharing a link",
    image: icon_collaborate,
  },
  icon_theme: {
    title: "Apply and modify themes",
    description:
      "Create a job posting with your personal touch by using one of our themes or designing your own",
    image: icon_theme,
  },
  icon_snippets: {
    title: "Copy text snippets",
    description: "Speed up editing your job posting by copying text snippets",
    image: icon_snippets,
  },
  icon_image: {
    title: "Add photos to your ads",
    description:
      "Easily upload or search for photos to help illustrate your job offer",
    image: icon_image,
  },
  icon_edit_pen: {
    title: "Proven job postings templates",
    description:
      "Win over employers and recruiters by using one of our professionally-designed job posting templates",
    image: icon_edit_pen,
  },
};

const PriceLabel = (props) => {
  return (
    <div
      className={
        props.type === "secondary"
          ? Style.priceLabelSecondary
          : Style.priceLabel
      }
    >
      {props.type === "secondary" ? (
        <span className={Style.optionalLabel}>(optional)</span>
      ) : (
        ""
      )}
      <div>
        <span className={Style.currency}>{props.currencyInfo.symbol}</span>
        <span className={Style.ammount}>
          {props.type === "secondary"
            ? props.currencyInfo.additional_seat_price
            : props.currencyInfo.price}
        </span>
        <span className={Style.currencyLabel}>{props.currencyInfo.name}</span>
      </div>
      <p className={Style.forWhat}>
        {props.type === "secondary" ? "per additional user" : "per month"}
      </p>
    </div>
  );
};
