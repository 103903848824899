import React, { useState, useEffect } from "react";
import Style from "./homepageFAQ.module.scss";
import { History, Icon } from "components/lib";

export function HomepageFAQ(props) {
  const FaqList = {
    collaboration: {
      title:
        "How user-friendly is Jobchef, especially when collaborating with team members on job postings?",
      mainText: (
        <>
          <p>
            <strong>Jobchef is designed for collaboration</strong>. If you're
            working on a job for a colleague, simply create and share a link,
            allowing them to edit the job posting directly.
          </p>
          <p>
            Involving the future manager or someone who will work closely with
            the new recruit will not only save time but also{" "}
            <strong>
              ensure the job posting is even more relevant and effective
            </strong>
            .
          </p>
        </>
      ),
    },
    themes: {
      title: "Do I need to design and pick colors for every job posting?",
      mainText: (
        <>
          <p>
            Not at all! Jobchef has ready-made templates that you can apply to
            your job posting with just one click, making the process simple and
            efficient.
          </p>
        </>
      ),
    },
    application_via_jobchef: {
      title: "Can candidates directly apply through Jobchef?",
      mainText: (
        <>
          <p>
            Unfortunately not, Jobchef is designed to assist you in{" "}
            <strong>crafting compelling job postings</strong>. You have the
            option to include a link to your job landing page, but the
            application process will occur outside Jobchef.
          </p>
          <p>
            If you don't already have an applicant tracking system, we recommend
            to combine Jobchef and an external form provider to collect
            applications. A good option is Typeform, which offers a range of{" "}
            <a
              href="https://www.typeform.com/templates/t/employee-job-application/"
              target="_blank"
              rel="noreferrer"
            >
              Job Application Form Template
            </a>{" "}
            for free.
          </p>
        </>
      ),
    },
    replace_ats: {
      title:
        "Does Jobchef serve as a replacement for my Applicant Tracking System (ATS) or candidate CRM",
      mainText: (
        <>
          <p>
            Jobchef doesn't replace your Applicant Tracking System (ATS) or
            candidate CRM. Instead, it excels at crafting compelling job
            postings to attract candidates and effectively present job details,
            particularly on platforms like LinkedIn. While you can include an
            apply link in your job posting, the actual application management
            takes place outside Jobchef.
          </p>
          <p>
            In combination with Jobchef, you can use a form to collect
            applications. A good option is Typeform, which offers a range of{" "}
            <a
              href="https://www.typeform.com/templates/t/employee-job-application/"
              target="_blank"
              rel="noreferrer"
            >
              Job Application Form Template
            </a>{" "}
            for free.
          </p>
        </>
      ),
    },
  };

  return (
    <div className={Style.homepageFAQ}>
      <div className={Style.componentWrapper}>
        <div className={Style.titleWrapper}>
          <h2>FAQ</h2>
          <div className={Style.contactUsAbove}>
            <p className={Style.sectionSubstitle}>
              Haven’t found what you’re looking for?{" "}
            </p>
            <div>
              <button
                className={Style.sectionSubstitle}
                buttonStyle="ghost"
                size="large"
                onClick={() => History.push("/contact")}
              >
                Contact us.
              </button>
            </div>
          </div>
        </div>
        <div className={Style.cardsWrapper}>
          {Object.keys(FaqList).map((elem, index) => (
            <div className={Style.card} key={"homepageFAQ_" + index}>
              <CollapsableFAQ
                questionTitle={<h3>{FaqList[elem].title}</h3>}
                contentExpanded={<div>{FaqList[elem].mainText}</div>}
              />
            </div>
          ))}
        </div>
        <div className={Style.contactUsBelow}>
          <p className={Style.sectionSubstitle}>
            Haven’t found what you’re looking for?{" "}
          </p>
          <button
            className={Style.sectionSubstitle}
            buttonStyle="ghost"
            size="large"
            onClick={() => History.push("/contact")}
          >
            Contact us.
          </button>
        </div>
      </div>
    </div>
  );
}

const CollapsableFAQ = (props) => {
  const [toggled, setToggled] = useState(!false);

  return (
    <div className={Style.collapsableFAQ}>
      <div
        className={Style.mainRow}
        onClick={() => {
          setToggled(!toggled);
        }}
      >
        <div className={Style.question}>{props.questionTitle}</div>
        <button className={Style.toggleIcon}>
          <Icon
            image={toggled ? "chevron-down" : "chevron-up"}
            size="20px"
          ></Icon>
        </button>
      </div>
      <div
        className={Style.hidableContent}
        style={{
          height: !toggled ? "auto" : "0",
          opacity: !toggled ? 1 : 0,
          overflow: "hidden",
          transition: "height 0.6s ease-in-out, opacity 0.6s ease-in-out",
        }}
      >
        {props.contentExpanded}
      </div>
    </div>
  );
};
