import React, { lazy, Suspense, useContext } from "react";
import {
  image_import_helper_block_image,
  image_import_helper_cover_image,
} from "../../lib/imageUploadHelper.js";
// import { FilePond } from "react-filepond";
import { ViewContext } from "components/lib";
import Axios from "axios";
import "filepond/dist/filepond.min.css";

const FilePond = lazy(() =>
  import("react-filepond").then((module) => ({ default: module.FilePond }))
);

/*

The filePondWrapper component can be placed anywhere in the app. All is needed is to pass a callback to the component that will return the server ID of the image uploaded.

Note: You can't delete images at the moment directly from the filepond. This is in the TODO. For now, the callback should also remove the pond and have some option to delete the image from there.

<FilePondWrapper 
  uploadCallback={image_server_id=>{console.log(image_server_id)}}
/>

*/

export function FilePondWrapper(props) {

  const viewContext = useContext(ViewContext);

  const files = [];

  const user = JSON.parse(localStorage.getItem("user"));
  const token = "Bearer " + (user ? user.token : "");

  let api_url = "/api/filepondUploader";
  const jobId = localStorage.getItem("jobId");

  if (props.imageType !== "companyLogo") {
    api_url = api_url + "?job_id=" + jobId;
  }

  if (props.imageType === "companyLogo") {
    api_url = "/api/filepondUploader/companyLogo";
  }

  return (
    <div className="filepondwrapper">
      <Suspense fallback={<div>Loading...</div>}>
        <FilePond
          credits="false"
          files={files}
          onprocessfile={(error, file) => {
            if (error) {
              // console.log("There was an error:");
              // console.log(error);
            }
            // console.log("File uploaded succesfully:");
            // console.log(file.serverId);
            if (props.imageType === "blockImage") {
              image_import_helper_block_image(
                file.serverId,
                ...props.importHelperParameters
              );
            }
            if (props.imageType === "coverImage") {
              image_import_helper_cover_image(
                file.serverId,
                ...props.importHelperParameters
              );
            }
            if (props.imageType === "companyLogo") {
              const imageData = JSON.parse(file.serverId).data;

              Axios.patch("/api/company/companyLogo", {
                params: { company_logo: imageData.id },
              })
                .then((res) => {
                  // console.log(res.data);
                  props.companyLogoUpdateCallback(res.data);
                })
                .catch((err) => {
                  viewContext.handleError(err);
                });
            }
          }}
          allowMultiple={false}
          maxFiles={1}
          server={{
            url: api_url,
            process: {
              headers: {
                Authorization: token,
              },
            },
          }}
          name="files"
          labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
        />
      </Suspense>
    </div>
  );
}
