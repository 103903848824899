import React, { useContext, useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import {
  HelperBlockCardPreview,
  HelperBlockEditorItemList,
  HelperBlockEditorItemListPreview,
  HelperBlockContextCard,
  HelperDisplayCheckerSetterHelper,
} from "./_index.js";
import { Loader, EditorContext, MyTextEditor, MyButton } from "../../lib";
import red_burger from "./images/food/red_burger.svg";
import Style from "./BlockAll.module.scss";
import parse from "html-react-parser";

const blockColor = "#ECB0B7";
const blockTextColor = "#ECB0B7";
const blockName = "Skills & Requirements";

const block_skills_and_requirements_data_generator = (content) => {
  const new_uuid = uuid();

  let default_content = {
    primary_skills: [],
    secondary_skills: [],
  };

  if (content) {
    default_content[new_uuid + "title"] = "<h2>" + content.section_title + "</h2>";
    default_content[new_uuid + "body"] = content.short_introduction;
    for (
      let index = 0;
      index < content.list_of_skills_and_requirements.length;
      index++
    ) {
      const new_item_id = uuid();
      const elem = content.list_of_skills_and_requirements[index];
      default_content[new_uuid + "_listItem_" + new_item_id] = elem;
      default_content.primary_skills.push(new_item_id);
    }
  }

  return {
    key: new_uuid,
    data: {
      version: 1,
      id: new_uuid, //block
      type: "skills_and_requirements",
      content: default_content,
    },
  };
};

const BlockSkillsAndRequirementsPreview = (props) => {
  const selectedBlock = useContext(EditorContext).selectedBlock;
  const blockDictionary = useContext(EditorContext).blockDictionary;
  const currentStep = useContext(EditorContext).currentStep;
  const [isContent, setIsContent] = useState(false);

  const _contentValueHelper = (contentKey) => {
    if (
      blockDictionary[props.blockId].content[props.blockId + contentKey] ===
        "" ||
      blockDictionary[props.blockId].content[props.blockId + contentKey] ===
        undefined
    ) {
      return "";
    } else {
      return blockDictionary[props.blockId].content[props.blockId + contentKey];
    }
  };

  const contentValueArray = [
    "title",
    "body",
    "subtitle_primary_skills",
    "subtitle_secondary_skills",
  ];
  const contentListArray = ["primary_skills", "secondary_skills"];

  useEffect(() => {
    HelperDisplayCheckerSetterHelper(
      contentValueArray,
      contentListArray,
      blockDictionary,
      props.blockId,
      setIsContent
    );
    return () => {
      HelperDisplayCheckerSetterHelper(
        contentValueArray,
        contentListArray,
        blockDictionary,
        props.blockId,
        setIsContent
      );
    };
  }, []);

  useEffect(() => {
    HelperDisplayCheckerSetterHelper(
      contentValueArray,
      contentListArray,
      blockDictionary,
      props.blockId,
      setIsContent
    );
  }, [selectedBlock]);

  if (props.publicRender && !isContent) {
    return null;
  }

  return (
    <>
      {blockDictionary[props.blockId] === null && <Loader />}

      {blockDictionary[props.blockId] !== null && !isContent && (
        <div
          className={Style.emptyBlock}
          style={{ borderColor: blockColor, color: blockColor }}
        >
          <h2>{blockName}</h2>
          <p>Click to add content</p>
        </div>
      )}
      {blockDictionary[props.blockId] !== null &&
        isContent &&
        currentStep !== "block_editor" && (
          <div className={"jc_block"}>
            <div className="style_jc skills_and_requirements_jc">
              {parse(_contentValueHelper("title"))}
              {parse(_contentValueHelper("body"))}
              {parse(_contentValueHelper("subtitle_primary_skills"))}
              <HelperBlockEditorItemListPreview
                listName={"primary_skills"}
                blockId={props.blockId}
              />
              {parse(_contentValueHelper("subtitle_secondary_skills"))}
              <HelperBlockEditorItemListPreview
                listName={"secondary_skills"}
                blockId={props.blockId}
              />
            </div>{" "}
          </div>
        )}
      {blockDictionary[props.blockId] !== null &&
        isContent &&
        currentStep === "block_editor" && (
          <>
              {parse(_contentValueHelper("title"))}
              {parse(_contentValueHelper("body"))}
              {parse(_contentValueHelper("subtitle_primary_skills"))}
              <HelperBlockEditorItemListPreview
                listName={"primary_skills"}
                blockId={props.blockId}
              />
              {parse(_contentValueHelper("subtitle_secondary_skills"))}
              <HelperBlockEditorItemListPreview
                listName={"secondary_skills"}
                blockId={props.blockId}
              />
            
          </>
        )}
    </>
  );
};

const BlockSkillsAndRequirementsCardPreview = (props) => {
  return (
    <HelperBlockCardPreview
      image={red_burger}
      header="Skills and Requirements"
      short_description="This is your chance to get specific. What does an applicant need in order to be considered?"
      block_type="skills_and_requirements"
      color="--red-medium"
      color_light="#ECB0B7"
    />
  );
};

const BlockSkillsAndRequirementsEditor = (props) => {
  const setSelectedBlock = useContext(EditorContext).setSelectedBlock;
  const blockDictionary = useContext(EditorContext).blockDictionary;
  const setBlockDictionary = useContext(EditorContext).setSelectedBlock;
  const [secondaryToggle, setSecondaryToggle] = useState(false);

  const clearSecondarySkillInfo = (blockId) => {
    let newBlockDictionary = { ...blockDictionary };

    newBlockDictionary[blockId].content[blockId + "subtitle_primary_skills"] =
      "";

    newBlockDictionary[blockId].content[blockId + "subtitle_secondary_skills"] =
      "";

    for (
      let i = 0;
      i < newBlockDictionary[blockId].content["secondary_skills"].length;
      i++
    ) {
      const element =
        newBlockDictionary[blockId].content["secondary_skills"][i];
      if (
        i + 1 ===
        newBlockDictionary[blockId].content["secondary_skills"].length
      ) {
        newBlockDictionary[blockId].content["secondary_skills"] = [element];
      } else {
        delete newBlockDictionary[blockId].content[
          blockId + "_listItem_" + element
        ];
      }
      // console.log(newBlockDictionary[blockId]);
    }

    setBlockDictionary(newBlockDictionary);
  };

  return (
    <>
      <div className={Style.editorCloseButtonWrapper}>
        <h3
          className={Style.sectionTitle}
          style={{ color: blockTextColor, textTransform: "uppercase" }}
        >
          {blockName}
        </h3>
        <MyButton
          buttonStyle="secondary"
          size="small"
          onClick={() => {
            setSelectedBlock(null);
          }}
        >
          Close
        </MyButton>
      </div>{" "}
      <br />
      <p className={Style.sectionSubtitle}>Section title :</p>
      <MyTextEditor
        blockId={props.blockId}
        contentKey={props.blockId + "title"}
        placeholderText={"Type a section title..."}
        contentBlockType="header-two"
        tabIndex={1}
        quickAddOptions={["Skills & Requirements", "Expected qualifications"]}
      />
      <p className={Style.sectionSubtitle}>Short introduction paragraph :</p>
      <MyTextEditor
        blockId={props.blockId}
        contentKey={props.blockId + "body"}
        placeholderText={"Type short introduction ..."}
        tabIndex={2}
        ctaViewSampleText
      />
      {secondaryToggle && (
        <>
          <p className={Style.sectionSubtitle}>
            <strong>Primary skills</strong>
          </p>
          <p className={Style.sectionSubtitle}>Subtitle :</p>
          <MyTextEditor
            blockId={props.blockId}
            contentKey={props.blockId + "subtitle_primary_skills"}
            placeholderText={"Type subtitle ..."}
            contentBlockType="header-three"
            tabIndex={2}
          />
        </>
      )}
      <p className={Style.sectionSubtitle}>
        {!secondaryToggle ? "List skills here:" : "List primary skills here:"}
      </p>
      <HelperBlockEditorItemList
        listName={"primary_skills"}
        blockId={props.blockId}
        placeholderText={"Type a primary skill..."}
        tabIndex={3}
      />
      <MyButton
        className={Style.secondarySkilToggle}
        onClick={() => {
          if (secondaryToggle) {
            clearSecondarySkillInfo(props.blockId);
          }
          setSecondaryToggle(!secondaryToggle);
        }}
      >
        {secondaryToggle ? "Remove secondary skills" : "Add secondary skills "}
      </MyButton>
      {secondaryToggle && (
        <>
          <p className={Style.sectionSubtitle}>
            <strong>Secondary skills</strong>
          </p>
          <p className={Style.sectionSubtitle}>Subtitle :</p>
          <MyTextEditor
            blockId={props.blockId}
            contentKey={props.blockId + "subtitle_secondary_skills"}
            placeholderText={"Type subtitle ..."}
            contentBlockType="header-three"
            tabIndex={2}
            ctaViewSampleText
          />
          <p className={Style.sectionSubtitle}>List of secondary skills :</p>
          <HelperBlockEditorItemList
            listName={"secondary_skills"}
            blockId={props.blockId}
            placeholderText={"Type a secondary skill..."}
            tabIndex={3}
          />
        </>
      )}
    </>
  );
};

const BlockSkillsAndRequirementsContext = (props) => {
  return (
    <HelperBlockContextCard
      header={"SKILLS & REQUIREMENTS"}
      blockType={"skills_and_requirements"}
      color="#ECB0B7"
      image={red_burger}
      iconStyle={Style.red}
    />
  );
};

export {
  BlockSkillsAndRequirementsPreview,
  BlockSkillsAndRequirementsCardPreview,
  BlockSkillsAndRequirementsEditor,
  BlockSkillsAndRequirementsContext,
  block_skills_and_requirements_data_generator,
};
