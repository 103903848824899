import { masculineRegex, feminineRegex, findWithRegex } from "./gender.js";
import { abbreviations, abbreviationsSensitive } from "./abbreviations.js";
import { automatedReadability } from "./automatedReadability";

// Global stats object
class Stats {
  characters = 0;
  words = 0;
  sentences = 0;
  level = 0;
  masculines = 0;
  feminines = 0;
  editors = {};
}

// Stats for every editors
class EditorStats {
  blocks = {};
}

// Stats for every blocks in the editor
class BlockStats {
  characters = 0;
  words = 0;
  sentences = 0;
  level = 0;
  masculines = 0;
  feminines = 0;
}

function getCreateBlockStats(stats, editorId, blockId) {
  if (!stats.editors[editorId]) {
    stats.editors[editorId] = new EditorStats();
  }
  if (!stats.editors[editorId].blocks[blockId]) {
    stats.editors[editorId].blocks[blockId] = new BlockStats();
  }

  return stats.editors[editorId].blocks[blockId];
}

function deleteEditorStats(stats, editorId) {
  delete stats.editors[editorId];

  return stats;
}

//TODO: delete with the prefix instead. ()
//  ex: a9e76a16-e52c-4f26-a94a-a3ffc6dc404fa9e76a16-e52c-4f26-a94a-a3ffc6dc404fbody --> delete using a9e76a16-e52c-4f26-a94a-a3ffc6dc404fa9e76a16-e52c-4f26-a94a-a3ffc6dc404f 
// function deleteEditorStatsByPrefix(stats, editorId) {
//   delete stats.editors[editorId];

//   return stats;
// }

function recalculateStats(stats) {
  stats.characters = 0;
  stats.words = 0;
  stats.sentences = 0;
  stats.level = 0;
  stats.masculines = 0;
  stats.feminines = 0;
  for (let editorId in stats["editors"]) {
    for (let blockId in stats.editors[editorId]["blocks"]) {
      stats.characters += stats.editors[editorId].blocks[blockId].characters;
      stats.words += stats.editors[editorId].blocks[blockId].words;
      stats.sentences += stats.editors[editorId].blocks[blockId].sentences;
      stats.masculines += stats.editors[editorId].blocks[blockId].masculines;
      stats.feminines += stats.editors[editorId].blocks[blockId].feminines;
    }
  }
  stats.level = automatedReadability({
    character: stats.characters,
    word: stats.words,
    sentence: stats.sentences,
  });
}

function recalculateEditorStats(editorId, stats, editorState) {
  const context = editorState.getCurrentContent();
  let activeBlockKeys = context.getBlockMap().keys();
  activeBlockKeys = [...activeBlockKeys];

  // Get a list of block keys we have stats for right now
  let statBlockKeys = [];
  if (stats.editors[editorId]) {
    statBlockKeys = Object.keys(stats.editors[editorId]["blocks"]);
  }

  const newEditorStats = stats.editors[editorId];

  // Compare with the list of active block keys and remove the stats for removed blocks
  const activeSet = new Set(activeBlockKeys);
  for (let statBlockKey of statBlockKeys) {
    if (!activeSet.has(statBlockKey)) {
      // Delete that stat block
      delete newEditorStats.blocks[statBlockKey];
    }
  }

  // Workaround for empty block...
  for (let activeBlockKey of activeBlockKeys) {
    const block = context.getBlockForKey(activeBlockKey);
    if (block.getLength() == 0 && newEditorStats) {
      // Delete that stat block
      delete newEditorStats.blocks[activeBlockKey];
    }
  }

  // Recalculate global state here
  recalculateStats(stats);

  return stats;
}

function decorateTextAndUpdateBlockStats(
  text,
  decoration_callback,
  blockStats
) {
  // Masculine & feminine
  const masculines = findWithRegex(masculineRegex, text, decoration_callback, {
    decorateType: "masculine",
  });
  const feminines = findWithRegex(feminineRegex, text, decoration_callback, {
    decorateType: "feminine",
  });

  blockStats.masculines = masculines;
  blockStats.feminines = feminines;

  return blockStats;
}

function analyseTextAndUpdateBlockStats(text, blockStats) {
  // Strip trailing spaces. They don't add any new information and removing them simplify the
  // logic below
  text = text.replace(/\s+$/g, "");

  let wordBuffer = "";
  let endOfSentence = false;
  let i = 0;
  let charactersInText = 0;
  let wordsInSetence = 0;
  let wordsInText = 0;
  let sentencesInText = 0;
  let res;
  for (; i < text.length; i++) {
    let char = text[i];
    if (char === " ") {
      // Process word
      res = processWord(wordBuffer);
      if (res.actualLength > 0) {
        charactersInText += res.actualLength;
        wordsInSetence += 1;
        wordsInText += 1;
      }
      endOfSentence = res.endOfSentence;
      // flush wordBuffer
      wordBuffer = "";
    } else {
      wordBuffer += char;
    }

    if (endOfSentence) {
      // TODO handle random dots in sentences like Mr. or e.g.
      // flush setenceBuffer
      sentencesInText += 1;
      endOfSentence = false;
    }
  }

  // Process last word/sentence
  if (wordBuffer) {
    res = processWord(wordBuffer);
    if (res.actualLength > 0) {
      charactersInText += res.actualLength;
      wordsInSetence += 1;
      wordsInText += 1;
      if (!endOfSentence) {
        sentencesInText += 1;
      }
    }
  }

  blockStats.characters = charactersInText;
  blockStats.words = wordsInText;
  blockStats.sentences = sentencesInText;
  blockStats.level = automatedReadability({
    character: blockStats.characters,
    word: blockStats.words,
    sentence: blockStats.sentences,
  });

  return blockStats;
}

const startRegex = /^\W*/;
const endRegex = /\W*$/;
function processWord(word) {
  class WordStats {
    actualLength = 0;
    complexWord = false;
    endOfSentence = false;
  }

  const wordStats = new WordStats();
  if (word == "") {
    return wordStats;
  }
  // Get a clean version of the word without the punctionations (like :hello!!!!! => hello)
  let clean = word;
  clean = clean.replace(startRegex, "");
  clean = clean.replace(endRegex, "");
  wordStats.actualLength = clean.length;
  wordStats.complexWord = false; 

  // Check if there is a setence termination Character in the original word buffer.
  if ([".", "!", "?"].indexOf(word[word.length - 1]) !== -1) {
    wordStats.endOfSentence = true;
    if (
      abbreviations.test(clean.toLowerCase()) ||
      abbreviationsSensitive.test(clean)
    ) {
      wordStats.endOfSentence = false;
    }
  }

  return wordStats;
}

export {
  Stats,
  EditorStats,
  BlockStats,
  getCreateBlockStats,
  recalculateEditorStats,
  decorateTextAndUpdateBlockStats,
  analyseTextAndUpdateBlockStats,
  deleteEditorStats,
  // deleteEditorStatsById
};
