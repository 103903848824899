import React, { useState } from "react";
import Style from "./clickToCopyText.module.scss";
import "tippy.js/dist/tippy.css"; // optional
import Tippy from "@tippyjs/react";

const ClickToCopyText = (props) => {
  const [justClicked, setJustClick] = useState(false);

  // console.log(props.elementToCopy);

  const copieToClipboard = () => {
    // console.log(props.elementToCopy);
    navigator.clipboard.writeText(props.elementToCopy);
    setJustClick(true);
    setTimeout(function () {
      setJustClick(false);
    }, 1200);
  };

  return (
    <Tippy
      content={justClicked === false ? "Click to copy" : "Copied!"}
      placement="left"
      hideOnClick={false}
      className={
        justClicked === false ? Style.tooltipNormal : Style.tooltipAfterClick
      }
    >
      <div
        className={Style.clickToCopyText}
        onClick={() => {
          // console.log(props.children);
          copieToClipboard(props.ElementToCopy);
        }}
      >
        {props.children}
      </div>
    </Tippy>
  );
};

export { ClickToCopyText };
