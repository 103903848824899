import React, { useState, useEffect, useContext } from "react";
import { ImageSearch, ImageSelector, ViewContext } from "components/lib";
import Style from "./unsplashSelector.module.scss";
import {
  image_import_helper_block_image,
  image_import_helper_cover_image,
} from "../../lib/imageUploadHelper.js";
//
import Axios from "axios";

import { createApi } from "unsplash-js";
import Masonry from "react-masonry-css";
import { MyButton } from "components/myButton/myButton";

export function UnsplashSelector(props) {
  const viewContext = useContext(ViewContext);
  const [images, setImages] = useState(null);
  const [preview, setPreview] = useState(null);
  const [currentSearchKeyword, setCurrentSearchKeyword] =
    useState("beautiful office");
  const [currentSearchPage, setCurrentSearchPage] = useState(1);
  const jobId = localStorage.getItem("jobId");

  useEffect(() => {
    //sets a default search result
    const photos = getPhotos(currentSearchKeyword, 1, 20);
  }, []);

  const getPhotos = (query, page, perPage) => {
    Axios.post("/api/unsplashGetResults", {
      query: query,
      page: page,
      perPage: perPage,
    })
      .then((result) => {
        // console.log(result);
        result = result.data;
        if (result.errors) {
          // handle error here
          // console.log("error occurred: ", result.errors[0]);
        } else {
          const feed = result.response;

          // console.log(feed);

          // extract total and results array from response
          const { total, results } = feed;
          // handle success here

          //Get what i need from the feedback
          let results_clean = [];
          if (page > 1) {
            results_clean = images;
          }

          results.map((result, index) => {
            const new_image = {
              preview_url: result.urls.regular,
              download_url: result.links.download,
              alt_description: result.alt_description,
              id: result.id,
              photographer: result.user.name,
              photographer_portfolio_url: result.user.links.html,
            };
            results_clean.push(new_image);
            return false;
          });
          if (page > 1) {
            setCurrentSearchPage(page);
          }
          setImages(results_clean);
        }
      })
      .catch((err) => {
        viewContext.handleError(err);
      });
  };

  function getMoreResults() {
    // console.log(currentSearchKeyword);

    getPhotos(currentSearchKeyword, currentSearchPage + 1, 20);
  }

  const user = JSON.parse(localStorage.getItem("user"));
  const token = "Bearer " + (user.token ? user.token : "");

  function upload_file_to_server_unsplash(unsplashId) {
    const file_to_upload = { id: unsplashId, job_id: jobId };
    var xhr = new XMLHttpRequest();

    // get a callback when the server responds
    xhr.addEventListener("load", () => {
      if (props.imageType === "blockImage") {
        image_import_helper_block_image(
          xhr.response,
          ...props.importHelperParameters
        );
      }
      if (props.imageType === "coverImage") {
        // console.log(props.importHelperParameters[1]);
        image_import_helper_cover_image(
          xhr.response,
          ...props.importHelperParameters
        );
      }
    });
    // open the request with the verb and the url
    xhr.open("POST", "/api/unsplashUpload");
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", token);

    // send the request
    xhr.send(JSON.stringify(file_to_upload));
  }

  return (
    <div className={Style.unsplashSelector}>
      {preview === null && (
        <ImageSearch
          get_results={(query) => {
            getPhotos(query, 1, 20);
          }}
          setCurrentSearchKeyword={setCurrentSearchKeyword}
        />
      )}
      {preview === null && images !== null && (
        <>
          <Masonry
            breakpointCols={2}
            className={Style.myMasonryGrid}
            columnClassName={Style.myMasonryGrid_column}
          >
            {images.map((result, index) => {
              return (
                <ImageSelector
                  key={index}
                  result={result}
                  setPreviewFile={(x) => setPreview(x)}
                  upload_file_to_server_unsplash={
                    upload_file_to_server_unsplash
                  }
                />
              );
            })}
          </Masonry>
          <div className={Style.getMoreImages}>
            <button
              className={Style.getMoreImagesButton}
              onClick={getMoreResults}
            >
              Get more results
            </button>
          </div>
        </>
      )}
      {preview !== null && (
        <>
          <div className={Style.buttonWrapper}>
            <MyButton
              size={"small"}
              buttonStyle={"secondary"}
              onClick={() => setPreview(null)}
            >
              Back to results
            </MyButton>
            <MyButton
              size={"small"}
              buttonStyle={"primary"}
              onClick={() => upload_file_to_server_unsplash(preview.id)}
            >
              Select image
            </MyButton>
          </div>
          <img
            src={preview.preview_url}
            className={Style.previewImage}
            alt="Preview"
          ></img>
        </>
      )}
    </div>
  );
}
