import React, { useContext } from "react";
import { AppNav, AuthContext } from "components/lib";
import Style from "./editorLayout.module.scss";
import "../layout.scss"; // globals

export function EditorLayout(props) {
  // context & style
  const context = useContext(AuthContext);

  return (
    <div className={Style.editorLayout}>
      {context && (
        <AppNav
          type="popup"
          items={[
            { label: "Dashboard", icon: "folder", link: "/dashboard" },
            { label: "Account", icon: "user", link: "/account" },
            { label: "Help", icon: "help-circle", link: "/help" },
            { label: "Sign Out", icon: "log-out", action: context.signout },
          ]}
        />
      )}

      <main>{<props.children {...props.data} />}</main>
    </div>
  );
}
