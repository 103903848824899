/*
https://en.wikipedia.org/wiki/Automated_readability_index

Score   Age   Grade Level             School equivalent
1       5-6   Kindergarten            Preschool
2       6-7   First/Second Grade      Elementary school
3       7-9   Third Grade             Elementary school
4       9-10  Fourth Grade            Elementary school
5       10-11   Fifth Grade           Elementary school
6       11-12   Sixth Grade           Middle school
7       12-13   Seventh Grade         Middle school
8       13-14   Eighth Grade          Middle school
9       14-15   Ninth Grade           High school
10      15-16   Tenth Grade           High school
11      16-17   Eleventh Grade        High school
12      17-18   Twelfth grade         High school
13      18-24   College student       Post-secondary education
14      24+   Professor               Post-secondary education

*/

function automatedReadability(params) {
  if ( ! params || ! params.character || ! params.word || ! params.sentence) {
    return 0;
  }
  let res = 4.71 * (params.character / params.word) + 0.5 * (params.word / params.sentence) - 21.43;

  if (res < 0) {
    return 0
  }

  return Math.ceil(res)
}

export { automatedReadability };
