/***
 *
 *   HOMEPAGE
 *   Homepage template with features, testimonials and CTA
 *
 **********/

import React, { useEffect } from "react";
import {
  ScreenShotCaroussel,
  SideBySideTextImage,
  ClientReferalEditorial,
  HowItWorks,
  WeGotYouCovered,
  HomepageFAQ,
  History,
  ReminderCTA
} from "components/lib";

import Style from "./home.module.scss";
import { ScrollArea } from "@mantine/core";

export function Home(props) {

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const coupon = params.get('coupon');
    if (coupon) {
      localStorage.setItem('stripe_coupon', coupon);
    }
  }, []);


  return (
    <>
      <div className={Style.hero}>
        <h1>Create Great Job Posts. Fast.</h1>
        <h2>
          Your perfect recipe to{" "}
          <span className={Style.headerHighlight}>
            {" "}
            attract the best talents
          </span>
          .
        </h2>
        <button
          className={Style.mainCTAButton}
          onClick={() => History.push("/signup")}
        >
          Get started
        </button>
        <p>Edit your first job in less than 5 minutes.</p>
      </div>

      <ScreenShotCaroussel />

      <SideBySideTextImage />

      <ClientReferalEditorial />

      <HowItWorks />
      <WeGotYouCovered />
      <HomepageFAQ />

      <ReminderCTA />

      {/* <Row title='Features' color='white'>
        <Features />
      </Row> */}

      {/* <Row color='brand'>
        <Testimonial
          text='Gravity saved me weeks of development time and thousands of dollars by eliminating the need to hire a developer to built the necessary infrastructure for my app. I had a beautiful product with billing capabilities in a matter of minutes!'
          author='Kyle Gawley, Gravity Founder'
          image={ ImgKyleGawley }
        />
      </Row> */}

      {/* <Row title='Create your first job posting' align='center'>
        <Link btn url='/signup' text='Sign Up Now'/>
      </Row> */}
    </>
  );
}
