/***
 *
 *   BILLING
 *   Change subscription, update card details or view past invoices
 *
 **********/

import React, { Fragment, useContext, useState, useEffect } from "react";
import {
  AuthContext,
  AccountNav,
  TabView,
  Message,
  Animate,
  ViewContext,
} from "components/lib";
import Axios from "axios";

import { BillingPlan } from "./plan";
import { BillingCard } from "./card";
import { BillingInvoices } from "./invoices";

const Messages = require("./messages.json");

export function Billing(props) {
  // state/context
  const context = useContext(AuthContext);
  const viewContext = useContext(ViewContext);

  // fetch subscription
  // const subscription = useAPI('/api/account/subscription');

  const [subscription, setSubscription] = useState();
  const isPaid = context.user.plan === "free" ? false : true;
  const labels = ["Plan"];

  useEffect(() => {
    getSubscription();
  }, []);

  const getSubscription = (callback) => {
    Axios.get("/api/account/subscription", {})
      .then((res) => {
        setSubscription(res.data);
        callback()
      })
      .catch((err) => {
        viewContext.handleError(err);
      });
    return;
  };

  if (isPaid) labels.push("Card", "Invoices");

  // console.log(subscription);

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        {!context?.loading && context.user.plan === "canceled" && (
          <Message
            title="Your account is innactive"
            text="Select Resume subscription below to re-activate your account"
          />
        )}

        <TabView name="Billing" labels={labels}>
          {subscription && <BillingPlan subscription={subscription} getSubscription={getSubscription} />}

          {isPaid && <BillingCard />}

          {isPaid && <BillingInvoices />}
        </TabView>
      </Animate>
    </Fragment>
  );
}
