/***
 *
 *   UPGRADE
 *   Upgrade from a free to paid plan
 *
 **********/

import React, { useContext } from "react";
import {
  AuthContext,
  Card,
  PaymentForm,
  History,
  Animate,
  usePlans,
  ReassurancePayment,
  Message,
} from "components/lib";
import Style from "./upgrade.module.scss";

export function Upgrade(props) {
  // context/data
  const context = useContext(AuthContext);
  const plans = usePlans();
  const list = plans?.data?.list;
  let plan;

  // selected plan
  if (list) {
    const qs = props.location.search;
    plan = qs.substring(qs.indexOf("?plan=") + 6);

    // remove free & set selected
    const index = list?.findIndex((x) => x.value === "free");
    if (index > -1) list.splice(index, 1);
    plan = plan && plan !== "undefined" ? plan : list[0].value;
  }

  return (
    <Animate>
      {context && !context.permission.owner && (
        <Message
          type="error"
          title={`Jobchef Subscription Inactive`}
          text={
            <>
              <p>
                To regain access, please contact the account owner and request
                them to reactivate their subscription.{" "}
              </p>
              <p>
                <i>
                  Note:If you reached this page after clicking on a sign-up
                  invitation link, the subscription might have expired after the
                  invitation was sent.
                </i>
              </p>
              <p>
                If you need help, don't hesitate to{" "}
                <button
                  style={{
                    padding: "4px",
                    color: "#313131",
                    borderRadius: "4px",
                    backgroundColor: "#edced2",
                  }}
                  onClick={() => History.push("/help")}
                >
                  contact us
                </button>
                .
              </p>
            </>
          }
          //From ${subscription.current_period_start} to ${subscription.current_period_end}
        />
      )}
      {context && context.permission.owner && (
        <>
          <Message
            type="error"
            title={`Your account is innactive`}
            text={
              <>
                It appears that your subscription has expired. To reactivate
                your account and regain access to Jobchef, please update your
                payment information below.
                <p>
                  If you need help, don't hesitate to{" "}
                  <button
                    style={{
                      padding: "4px",
                      color: "#313131",
                      borderRadius: "4px",
                      backgroundColor: "#edced2",
                    }}
                    onClick={() => History.push("/help")}
                  >
                    contact us
                  </button>
                  .
                </p>
              </>
            }
            //From ${subscription.current_period_start} to ${subscription.current_period_end}
          />
          <Card
            // restrictWidth
            title="Add a payment method"
            loading={plans.loading}
            className={Style.formWrapper}
          >
            {!plans.loading && plans.data !== null && (
              <PaymentForm
                data={{
                  plan: {
                    label: "Plan",
                    type: "planCard",
                    options: plans.data.list,
                    default: plan,
                    required: true,
                  },
                  currency: {
                    label: "Plan",
                    type: "hidden",
                  },
                  token: {
                    label: "Credit Card",
                    type: "creditcard",
                    required: true,
                  },
                  customSeperator: {
                    type: "seperator",
                    content: <ReassurancePayment />,
                  },
                }}
                url="/api/account/upgrade"
                method="POST"
                buttonText="Reactivate account"
                callback={(res) => {
                  // update the auth context so user can use features on the new plan
                  context.update({
                    plan: res.data.data.plan,
                    account_active: true,
                  });
                  setTimeout(() => History.push("/dashboard"), 2500);
                }}
              />
            )}
          </Card>{" "}
        </>
      )}
    </Animate>
  );
}
