import React, { useState, useContext, useEffect, useRef } from "react";
import { EditorContext, Icon, BlockRenderer, MyButton } from "components/lib";
import Style from "./dragAndDropList.module.scss";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import drag_handle from "./img/dragHandle.svg";
import Modal from "@material-ui/core/Modal";
import composeRefs from "@seznam/compose-react-refs";

const reorder = (list, fromIndex, toIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(fromIndex, 1);
  result.splice(toIndex, 0, removed);
  return result;
};

let helperBorderColor = (blockType) => {
  switch (blockType) {
    case "description":
      return "#cbe5c4";
    case "company_overview":
      return "#BED9F8";
    case "skills_and_requirements":
      return "#ECB0B7";
    case "key_responsabilities":
      return "#C2CFFB";
    case "benefits":
      return "#FDCBCB";
    case "fine_print":
      return "#faeda7";
    case "image":
      return "#F6D6B6";
    default:
      break;
  }
};

function DragAndDropList(props) {
  // 1. Get the list of blocks from the context.
  // const blockDictionary = useContext(EditorContext).blockDictionary;
  // const setBlockDictionary = useContext(EditorContext).setBlockDictionary;
  // 2. Get the block List order setter
  // const setBlockOrder = useContext(EditorContext).setBlockOrder;
  // 3. Get the selected block setter
  // const setSelectedBlock = useContext(EditorContext).setSelectedBlock;
  // 4. Get the ListData getter ans setter
  // const listData = useContext(EditorContext).listData; //  ok
  // const setListData = useContext(EditorContext).setListData;

  const blockDictionary = useContext(EditorContext).blockDictionary;
  const selectedBlock = useContext(EditorContext).selectedBlock;
  const setSelectedBlock = useContext(EditorContext).setSelectedBlock;
  const blockOrder = useContext(EditorContext).blockOrder;
  const setBlockOrder = useContext(EditorContext).setBlockOrder;
  const dragAndDropAreaRef = useContext(EditorContext).dragAndDropAreaRef;

  const [dragging, setDragging] = useState(false);

  //Check if new block added
  useEffect(() => {}, [blockOrder.toString()]); //added  toString to force the useEffect to run.

  function onDragStart(result) {
    setDragging(true);
  }

  function onDragEnd(result) {
    setDragging(false);
    if (!result.destination) return;

    const fromIndex = result.source.index;
    const toIndex = result.destination.index;

    if (fromIndex === toIndex) return;

    setBlockOrder(reorder(blockOrder, fromIndex, toIndex));
  }

  function addBlock(blockClicked) {
    const new_block_list = blockOrder;
    const index_of_item = new_block_list.indexOf(blockClicked);
    setBlockOrder(reorder(new_block_list, index_of_item, index_of_item));
    return new_block_list;
  }

  function removeBlock(blockToRemove) {
    //remove from blocklistOrder
    // console.log(blockToRemove);
    const new_block_list = blockOrder;
    const index_of_item = new_block_list.indexOf(blockToRemove);
    new_block_list.splice(index_of_item, 1);
    //if also in blocklist remove from blockDictionary
  }

  function moveBlock(item, direction) {
    let new_block_list = blockOrder;
    let index_of_item = new_block_list.indexOf(item);
    switch (direction) {
      case "up":
        if (index_of_item === 0) {
          break;
        }
        setBlockOrder(
          reorder(new_block_list, index_of_item, index_of_item - 1)
        );
        break;
      case "down":
        if (index_of_item === new_block_list.length) {
          break;
        }
        setBlockOrder(
          reorder(new_block_list, index_of_item, index_of_item + 1)
        );
        break;
      default:
        // console.log("up didn't work for " + item);
        break;
    }
  }

  return (
    <div className={Style.dragAndDropList}>
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <div
              ref={composeRefs(provided.innerRef, dragAndDropAreaRef)}
              {...provided.droppableProps}
            >
              {blockOrder.map((item, index) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <div className={Style.card_container}>
                        <div className={Style.block_order_tools}>
                          <span
                            onClick={() => {
                              moveBlock(item, "up");
                            }}
                          >
                            <Icon image="chevron-up" size={14} />
                          </span>
                          <span {...provided.dragHandleProps}>
                            <img
                              className={Style.dragHandle}
                              src={drag_handle}
                              alt="Handle to drag block and chang order"
                            />
                          </span>
                          <span
                            onClick={() => {
                              moveBlock(item, "down");
                            }}
                          >
                            <Icon image="chevron-down" size={14} />
                          </span>
                        </div>
                        <div
                          className={
                            selectedBlock === item && selectedBlock !== null
                              ? Style.blockWrapperSelected
                              : Style.blockWrapperNotSelected
                          }
                          style={{
                            borderColor: helperBorderColor(
                              blockDictionary[item]?.type
                            ),
                          }}
                          onClick={() => {
                            setSelectedBlock(item);
                          }}
                        >
                          <div
                            style={{
                              display: selectedBlock === item ? "none" : "",
                            }}
                          >
                            <BlockRenderer
                              blockType={blockDictionary[item]?.type}
                              mode="preview"
                              blockId={item}
                            />
                          </div>
                          <div
                            style={{
                              display: selectedBlock !== item ? "none" : "",
                            }}
                          >
                            <BlockRenderer
                              blockType={blockDictionary[item]?.type}
                              mode="editor"
                              blockId={item}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          selectedBlock === item && item !== null
                            ? Style.blockCardActions
                            : Style.blockCardActionsHidden
                        }
                      >
                        <div className={Style.moveBlockOptions}>
                          <button
                            onClick={() => {
                              moveBlock(item, "up");
                            }}
                          >
                            Move up <Icon image={"chevron-up"} size={12} />
                          </button>
                          <button
                            onClick={() => {
                              moveBlock(item, "down");
                            }}
                          >
                            Move down <Icon image={"chevron-down"} size={12} />
                          </button>
                          <DeleteModal
                            blockToDelete={item}
                            deleteCallBack={removeBlock}
                            addBlock={addBlock}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

function DeleteModal(props) {
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [open, setOpen] = useState(false);

  const blockToDelete = props.blockToDelete;
  const deleteBlockHelper = useContext(EditorContext).deleteBlockHelper;

  const handleDelete = () => {
    //delete block
    deleteBlockHelper(blockToDelete);
    // let item_index = blockOrder.indexOf(blockToDelete);
    // if (blockOrder.length === 0) {
    //   props.deleteCallBack(blockToDelete);
    //   props.addBlock();
    //   setSelectedBlock(null);
    // } else {
    //   console.log("deleted block " + blockToDelete);
    //   props.deleteCallBack(blockToDelete);
    //   setSelectedBlock(
    //     item_index === 0 ? blockOrder[0] : blockOrder[item_index - 1]
    //   );
    // }
    setOpen(false);
  };

  return (
    <>
      <button
        type="button"
        onClick={() => {
          setOpen(true);
        }}
      >
        Delete <Icon image={"trash-2"} size={12} />
      </button>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className={Style.delete_modal}>
          <h3>Delete this block?</h3>
          <p>
            Note that you won't be a able to undo this{" "}
            <span role="img" aria-label="Unsure">
              🙄
            </span>
          </p>
          <div className={Style.flexDivHorizontal}>
            <MyButton
              onClick={handleDelete}
              buttonStyle={"danger"}
              size={"medium"}
            >
              Delete <Icon image={"trash-2"} size={12} />
            </MyButton>
            <MyButton
              buttonStyle={"outline"}
              size={"medium"}
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </MyButton>
          </div>
        </div>
      </Modal>
    </>
  );
}

//// TEMP
export { DragAndDropList };
