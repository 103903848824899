import React, { useState, useEffect } from "react";
import Style from "./sideBySideTextImage.module.scss";

import edito_1 from "./img/edito_1.png";
import edito_2 from "./img/edito_2.png";
import edito_3 from "./img/edito_3.png";

export function SideBySideTextImage(props) {
  return (
    <div className={Style.sideBySideTextImage}>
      <div className={Style.sideBySideitemImgRight}>
        <div className={Style.textWrapper}>
          <span className={Style.tag}>Content</span>
          <h2>Write Killer Job Postings</h2>
          <p>
            Use one of our many templates and real time recommandations to
            create a structured, well-written ad that will make its point.{" "}
          </p>
        </div>
        <div className={Style.ImageWrapper}>
          <img src={edito_1} />
        </div>
      </div>
      <div className={Style.sideBySideitemImgLeft}>
        <div className={Style.ImageWrapper}>
          <img src={edito_2} />
        </div>
        <div className={Style.textWrapper}>
          <span className={Style.tag}>Collaboration</span>
          <h2>You are Not Alone</h2>
          <p>
          Working on a job for a colleague? Create and share a link so they can edit the job posting directly.
          </p>
        </div>
      </div>
      <div className={Style.sideBySideitemImgRight}>
        <div className={Style.textWrapper}>
          <span className={Style.tag}>Design</span>
          <h2>Make it WOW</h2>
          <p>
          Don’t worry about design. We have your back. Simply select a theme and make your job posting beautiful, appealing, on brand. 
          </p>
        </div>
        <div className={Style.ImageWrapper}>
          <img src={edito_3} />
        </div>
      </div>
    </div>
  );
}
