import React, { useContext, useState, lazy, Suspense, useEffect } from "react";
import {
  MyButton,
  ViewContext,
  PdfContentGenerator,
  Icon,
  EditorContext,
} from "components/lib";
import Style from "./pdfRenderer.module.scss";
import Axios from "axios";
import PdfUpdateIndicator from "./pdfUpdateIndicator/pdfUpdateIndicator.js";

// import { Document, Page, pdfjs } from "react-pdf";
// import { pdfjs } from "react-pdf";
const Document = lazy(() =>
  import(/* webpackChunkName: "react-pdf" */"react-pdf").then((module) => ({ default: module.Document }))
);
const Page = lazy(() =>
  import(/* webpackChunkName: "react-pdf" */"react-pdf").then((module) => ({ default: module.Page }))
);


// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// Dynamic import for pdfjs configuration
const loadPdfjsWorker = () => {
  return import("react-pdf").then((pdfjsLib) => {
    pdfjsLib.pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.pdfjs.version}/pdf.worker.js`;
  });
};

export function PdfRenderer(props) {
  const viewContext = useContext(ViewContext);
  // const [pdfFile, setPdfFile] = useState(samplePDF);
  const theme = useContext(EditorContext).theme;
  const jobData = useContext(EditorContext).jobData;
  const jobBasicInfo = useContext(EditorContext).jobBasicInfo.values;

  let currentCoverPageStyle = props.coverPage;

  const [pdfFile, setPdfFile] = useState("");

  useEffect(() => {
    // Dynamically load the PDF.js worker when the component mounts
    loadPdfjsWorker().catch((error) =>
      console.error("Error loading pdfjs worker:", error)
    );
  }, [])

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(0); //setting 1 to show fisrt page

  const generatePDF = (callbackPDFGenerator) => {
    Axios.post("/api/PDFgenerator", {
      backgroundColor: theme.background.color,
      jobId: jobData.id,
      jobTitle: jobBasicInfo.job_public_name,
      coverImage: {
        currentCoverPageStyle: currentCoverPageStyle,
        coverImageLocation:
          currentCoverPageStyle === "noBanner"
            ? null
            : theme.coverImage[currentCoverPageStyle].cropped_file.public_url,
      },
    })
      .then((res) => {
        // console.log(res.data.data.uuid);

        const pdfPublicUrl = "/uploads/pdf/" + res.data.data.uuid + ".pdf";

        callbackPDFGenerator("pdfIsUpdated");
        setTimeout(() => {
          setPdfFile(pdfPublicUrl);
        }, 1000);

        // setPdfFile(pdfPublicUrl)
      })
      .catch((err) => {
        viewContext.notification.show(
          "There was an error generating a PDF ! Please reload",
          "error",
          true,
          "toast"
        );
        callbackPDFGenerator("errorGeneratingPDF");
        viewContext.handleError(err);
      });
  };

  const printPagePreview = () => {
    const row = [];
    for (var item = 0; item < numPages; item++) {
      row.push(item + 1);
    }
    return row;
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
    // console.log(numPages);
  }

  function previousPage() {
    if (pageNumber > 1) {
      changePage(-1);
    }
  }

  function nextPage() {
    if (pageNumber < numPages) {
      changePage(1);
    }
  }

  return (
    <div className={Style.pdfRenderer}>
      <div style={{ display: "none" }} id="HTMLforPDFrendered">
        {/* <div id="HTMLforPDFrendered"> */}
        <PdfContentGenerator />
      </div>
      <div className={Style.buttonWrapper}>
        <MyButton
          onClick={() => {
            previousPage();
          }}
          buttonStyle={"ghost"}
          className={Style.buttonNav}
        >
          <Icon
            className={
              pageNumber > 1 ? Style.buttonNavActive : Style.buttonNavInactive
            }
            image="arrow-up-circle"
            size={"20px"}
          ></Icon>
        </MyButton>

        {printPagePreview().map((value, index) => {
          return (
            <>
              <MyButton
                onClick={() => {
                  setPageNumber(index + 1);
                }}
                className={Style.smallPreview}
                buttonStyle={"secondary"}
              >
                {pdfFile !== "" && (
                  <div>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Document
                        file={pdfFile}
                        onLoadError={(x) => {
                          // console.log(x.message);
                        }}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        <Page pageNumber={value} scale={"0.12"} />
                      </Document>
                    </Suspense>
                  </div>
                )}
              </MyButton>
            </>
          );
        })}
        <MyButton
          onClick={() => {
            nextPage();
          }}
          buttonStyle={"ghost"}
          className={Style.buttonNav}
        >
          <Icon
            className={
              pageNumber < numPages
                ? Style.buttonNavActive
                : Style.buttonNavInactive
            }
            image="arrow-down-circle"
            size={"20px"}
          ></Icon>
        </MyButton>
      </div>
      <div className={Style.pdfViewerWrapper}>
        <div className={Style.pdfViewer}>
          {pdfFile !== "" && (
            <Suspense fallback={<div>Loading...</div>}>
              <Document
                file={pdfFile}
                onLoadError={console.error}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} scale={"0.85"} />
              </Document>
            </Suspense>
          )}
        </div>
      </div>
      <div className={Style.updateIndicatorWrapper}>
        <PdfUpdateIndicator
          generatePDFcallBack={generatePDF}
          currentCoverPageStyle={currentCoverPageStyle}
          previewMode={props.previewMode}
        />
      </div>
    </div>
  );
}
