import React, { useState, useEffect } from "react";
import Style from "./reminderCTA.module.scss";
import { History } from "components/lib";
import dotted_background from "./img/dotted_background.svg";

export function ReminderCTA(props) {
  return (
    <div className={Style.homepageFAQ}>
      <div className={Style.componentWrapper}>
        <h2>It will never have been so easy to write a job posting!</h2>
        <button
          className={Style.mainCTAButton}
          onClick={() => History.push("/signup")}
        >
          Get started
        </button>{" "}
      </div>
      <div className={Style.backgroundImage}>
        <img
          src={dotted_background}
          className={Style.shadowGradient}
          alt="A GIF showing how a text snippet"
        />
      </div>
    </div>
  );
}
