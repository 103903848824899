import React, { useContext } from "react";
import { EditorContext, BlockRenderer, Icon, MyButton } from "components/lib";
import Style from "./blockCompilerToHTML.module.scss";

export function BlockCompilerToHTML(props) {
  const jobBasicInfo = useContext(EditorContext).jobBasicInfo.values;
  const blockDictionary = useContext(EditorContext).blockDictionary;
  const blockOrder = useContext(EditorContext).blockOrder;
  const theme = useContext(EditorContext).theme;

  let isPublicMinHeight = "";
  if (props.islandingPage) {
    isPublicMinHeight = "min-height: 100vh;";
  }
  if (props.isEditorPreview) {
    isPublicMinHeight = "min-height: calc(100vh - 78px);";
  }

  const headerAndBasicInfo = (
    <div className="js_header_content">
      <h1>{jobBasicInfo.job_public_name}</h1>
      <div className="basic_info_jc">
        {jobBasicInfo.employment_type &&
          jobBasicInfo.employment_type_visibility && (
            <div className="basic_info_item">
              <Icon image="briefcase" size="0.85em"></Icon>
              {employmentTypeLabel(jobBasicInfo.employment_type)}
            </div>
          )}
        {jobBasicInfo.location && jobBasicInfo.location_visibility && (
          <div className="basic_info_item">
            <Icon image="map-pin" size="0.85em"></Icon>
            {jobBasicInfo.location}
          </div>
        )}
        {jobBasicInfo.workplace_type &&
          jobBasicInfo.workplace_type_visibility && (
            <div className="basic_info_item">
              <Icon image="home" size="0.85em"></Icon>
              {workplaceTypeLabel(jobBasicInfo.workplace_type)}
            </div>
          )}

        {jobBasicInfo.salary && jobBasicInfo.salary_visibility && (
          <div className="basic_info_item">
            <Icon image="award" size="0.85em"></Icon>
            {jobBasicInfo.salary}
          </div>
        )}
        {jobBasicInfo.company && jobBasicInfo.company_visibility && (
          <div className="basic_info_item">
            <Icon image="flag" size="0.85em"></Icon>
            {jobBasicInfo.company}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .background_jc { 
            background-color: ${theme.background.color};
            ${isPublicMinHeight}
            padding-bottom: 30px;
          }
          .style_jc > h1, .style_jc > h2, .style_jc > h3, .style_jc > h4, .style_jc > h5, .style_jc > h6, .style_jc p, .style_jc li {
            font-weight: normal;
            word-break: break-word;
            hyphens: manual;
            color: pink;
          }
          .style_jc > .jc_no_banner > .js_header_content > h1, .style_jc > .jc_banner > .js_header_content > h1  { 
            hyphens: manual;
            font-family: ${theme.h1?.font};
            font-size: ${theme.h1.size + "px"};
            font-weight: ${theme.h1.blockStyle.fontWeight};
            color: ${
              !theme.banner.isActive
                ? theme.h1.color // apply the normal text colorm if banner is not active.
                : theme.banner.bannerTextColor
            };
            ${
              theme.banner.bannerTextShadow && theme.banner.isActive
                ? "text-shadow: 0px 0px 3px rgb(0,0,0,0.5);"
                : ""
            } 
            text-align: ${theme.h1.textAlign};
            line-height: ${
              theme.h1.lineHeight === ""
                ? theme.h1.size + "px"
                : theme.h1.lineHeight + "px"
            };
            letter-spacing: ${theme.h1.letterSpacing + "px"};
            margin-bottom: ${theme.h1.marginBottom + "px"};
            text-decoration: ${
              theme.h1.blockStyle.underline === true ? "underline" : "none"
            };
            text-decoration-color: ${
              theme.h1.textDecorationColor !== ""
                ? theme.h1.textDecorationColor
                : "none"
            };
            font-style: ${
              theme.h1.blockStyle.italic === true ? "italic" : "normal"
            };
            text-transform: ${
              theme.h1.blockStyle.uppercase === true ? "uppercase" : "normal"
            };

          }

          .style_jc > h2 { 
            font-family: ${theme.h2.font};
            font-size: ${theme.h2.size + "px"};
            font-weight: ${theme.h2.blockStyle.fontWeight};
            color: ${theme.h2.color};
            text-align: ${theme.h2.textAlign};
            line-height: ${theme.h2.lineHeight + "px"};
            letter-spacing: ${1 * (theme.h2.letterSpacing / 100) + "em"};
            margin-bottom: ${theme.h2.marginBottom + "px"};
            text-decoration: ${
              theme.h2.blockStyle.underline === true ? "underline" : "none"
            };
            text-decoration-color: ${
              theme.h2.textDecorationColor !== ""
                ? theme.h2.textDecorationColor
                : "none"
            };
            font-style: ${
              theme.h2.blockStyle.italic === true ? "italic" : "normal"
            };
            text-transform: ${
              theme.h2.blockStyle.uppercase === true ? "uppercase" : "normal"
            };
          }

          .style_jc > h3 { 
            font-family: ${theme.h3.font};
            font-size: ${theme.h3.size + "px"};
            font-weight: ${theme.h3.blockStyle.fontWeight};
            color: ${theme.h3.color};
            text-align: ${theme.h3.textAlign};
            line-height: ${theme.h3.lineHeight + "px"};
            letter-spacing: ${1 * (theme.h3.letterSpacing / 100) + "em"};
            margin-bottom: ${theme.h3.marginBottom + "px"};
            text-decoration: ${
              theme.h3.blockStyle.underline === true ? "underline" : "none"
            };
            text-decoration-color: ${
              theme.h3.textDecorationColor !== ""
                ? theme.h1.textDecorationColor
                : "none"
            };
            font-style: ${
              theme.h3.blockStyle.italic === true ? "italic" : "normal"
            };
            text-transform: ${
              theme.h3.blockStyle.uppercase === true ? "uppercase" : "normal"
            };
          }

          .style_jc p, .jc_block li  {
            font-family: ${theme.body.font};
            font-size: ${theme.body.size + "px"};
            font-weight: ${theme.body.blockStyle.fontWeight};
            color: ${theme.body.color};
            text-align: ${theme.body.textAlign};
            line-height: ${theme.body.lineHeight + "px"};
            letter-spacing: ${1 * (theme.body.letterSpacing / 100) + "em"};
            margin-bottom: ${theme.body.marginBottom + "px"};
            text-decoration: ${
              theme.body.blockStyle.underline === true ? "underline" : "none"
            };
            text-decoration-color: ${
              theme.body.textDecorationColor !== ""
                ? theme.h1.textDecorationColor
                : "none"
            };
            font-style: ${
              theme.body.blockStyle.italic === true ? "italic" : "normal"
            };
            text-transform: ${
              theme.body.blockStyle.uppercase === true ? "uppercase" : "normal"
            };
          }

          .basic_info_jc {
            display:flex;
            flex-wrap: wrap;
            justify-content: left;
            margin-bottom: 16px;

          } 

          .basic_info_item  {
            color: ${
              !theme.banner.isActive
                ? theme.body.color // apply the normal text colorm if banner is not active.
                : theme.banner.bannerTextColor
            };
            font-size: ${theme.body.size + "px"};
            margin-bottom: 6px;
            margin-right: 12px;
            ${
              theme.banner.bannerTextShadow && theme.banner.isActive
                ? "text-shadow: 0px 0px 3px rgb(0,0,0,0.5);"
                : ""
            } 
          }

          .basic_info_item > svg  {
            margin: auto 6px;
            vertical-align: baseline;
            color: ${
              !theme.banner.isActive
                ? theme.body.color // apply the normal text colorm if banner is not active.
                : theme.banner.bannerTextColor
            };
            ${
              theme.banner.bannerTextShadow && theme.banner.isActive
                ? "filter :  drop-shadow( 0px 0px 3px rgb(0,0,0,0.5));"
                : ""
            } 
          }

          .basic_info_label {
            color: ${theme.h2.color};
          }

          .style_jc > img  {
            margin: 0px auto;
          }

          .jc_block {
            margin-bottom: 40px;
            max-width: 760px;
            margin: auto;
            padding: 0px 16px;
          }

          .jc_banner, .jc_no_banner{
            width:100%;
            ${theme.banner.bannerStyle === "image" ? "max-width:960px" : ""} 
          }
          
          .jc_banner{
            
            margin:auto;
            height:auto;
            min-height:240px;
            background-color: ${
              theme.banner.bannerbackgroundColor
                ? theme.banner.bannerbackgroundColor
                : "#333333"
            };
            display:flex;
            flex-direction: column;
            justify-content:flex-end;
            padding: 50px 16px 0px 16px;
            // margin-bottom: 20px;
            ${
              theme.banner.bannerStyle === "image" &&
              theme?.coverImage?.landingCover?.cropped_file?.public_url &&
              !theme.banner.bannerBackgroundGradient
                ? "background-image: url(" +
                  theme.coverImage.landingCover.cropped_file.public_url +
                  ");" +
                  "background-position: center;" +
                  "background-size: contain;" +
                  "background-size: cover;"
                : ""
            };
             ${
               theme.banner.bannerStyle === "image" &&
               theme?.coverImage?.landingCover?.cropped_file?.public_url &&
               theme.banner.bannerBackgroundGradient
                 ? "background-image: linear-gradient(to bottom, rgba(51, 51, 51, 0.02), rgba(51, 51, 51, 0.73)), url(" +
                   theme.coverImage.landingCover.cropped_file.public_url +
                   "); " +
                   "background-position: center;" +
                   "background-size: contain;" +
                   "background-size: cover;"
                 : ""
             };

          }
          .jc_no_banner{
            margin:auto;
            height:auto;
            min-height:240px;
            display:flex;
            flex-direction: column;
            justify-content:flex-end;
            padding: 50px 16px 0px 16px;
            margin-bottom: 20px;
          }
          
          .js_header_content {
            width:100%;
            max-width:760px;
            margin: 0px auto;
            padding: 0px 16px;
          }
        `,
        }}
      />
      <div className="style_jc background_jc">
        {theme.banner.isActive && (
          <div className="jc_banner">{headerAndBasicInfo}</div>
          // <div className="jc_banner"></div>
        )}
        {!theme.banner.isActive && (
          <div className="jc_no_banner">{headerAndBasicInfo}</div>
          // <div className="jc_no_banner"></div>
        )}
        {props.showApplyButton ? (
          <ApplyBar applyUrl={props.applyUrl} />
        ) : (
          <div style={{ height: "20px" }}></div>
        )}

        {blockOrder
          .filter((item) => blockDictionary[item]?.content.file !== null)
          .map((item, index) => (
            <BlockRenderer
              blockType={blockDictionary[item].type}
              mode={"preview"}
              blockId={item}
              key={item}
              publicRender={true}
            />
          ))}
      </div>
    </>
  );
}

const employmentTypeLabel = (key) => {
  switch (key) {
    case "full_time":
      return "Full-time";

    case "part_time":
      return "Part-time";

    case "contract":
      return "Contract";

    case "temporary":
      return "Temporary";

    case "volunteer":
      return "Volunteer";

    case "internship":
      return "Internship";

    default:
      break;
  }
};

const workplaceTypeLabel = (key) => {
  switch (key) {
    case "on_site":
      return "On-site";

    case "hybrid":
      return "Hybrid";

    case "remote":
      return "Remote";

    default:
      break;
  }
};

const colorContrastHelper = (hexColor) => {
  const { r, g, b } = hexToRgb(hexColor);

  // Calculate the relative luminance of the background color
  let L = Math.sqrt((0.299 * r) ^ (2 + 0.587 * g) ^ (2 + 0.114 * b) ^ 2);

  // Determine the text color based on the contrast with the background color
  return L > 11 ? "dark" : "light";
};

function hexToRgb(hex) {
  // Check if the input string is a valid hex color code
  var result = /^#?([a-f\d]{3}|[a-f\d]{6})$/i.exec(hex);
  if (result == null) {
    // If the input string is not a valid hex color code, return r,g,b = 0,0,0
    return { r: 51, g: 51, b: 51 };
  }
  // If the input string is a valid hex color code, convert it to RGB
  var colorCode = result[1];
  if (colorCode.length === 3) {
    // If the hex color code is a 3-character string, duplicate each character to get the 6-character equivalent
    colorCode = colorCode.replace(/./g, "$&$&");
  }
  return {
    r: parseInt(colorCode.substr(0, 2), 16),
    g: parseInt(colorCode.substr(2, 2), 16),
    b: parseInt(colorCode.substr(4, 2), 16),
  };
}

const ApplyBar = (props) => {
  const url = () => {
    let tempUrl = props.applyUrl;

    if (tempUrl.startsWith("https://")) {
      return tempUrl;
    }
    if (tempUrl.startsWith("http://")) {
      return tempUrl;
    }

    return "https://" + tempUrl;
  };

  return (
    <div className={Style.applyBar}>
      <a
        href={url()}
        target="_blank"
        rel="noreferrer"
        className={Style.applyButton}
      >
        Apply now
      </a>
    </div>
  );
};
