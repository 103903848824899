import React, { useContext } from "react";
import { EditorContext } from "components/lib";
import Style from "./themeLandingPagePreview.module.scss";

import Scrollbars from "react-custom-scrollbars";
import { BlockCompilerToHTML } from "components/blockCompilerToHTML/blockCompilerToHTML";
import { ScrollArea } from "@mantine/core";

function LandingPagePreview(props) {
  const theme = useContext(EditorContext).theme;

  return (
    <div className={Style.desktopPreview}>
      <ScrollArea style={{ height: "100%" }} offsetScrollbars>
        <div
          className={Style.desktopInnerWrapper}
          style={
            props.mobileMode !== undefined
              ? {
                  width: "380px",
                  margin: "auto",
                  boxShadow: "0px 4px 54px rgba(0, 0, 0, 0.06)",
                }
              : {}
          }
        >
          <BlockCompilerToHTML isEditorPreview/>
        </div>
      </ScrollArea>
    </div>
  );
}

function MobileLandingPagePreview(props) {
  const theme = useContext(EditorContext).theme;

  return (
    <div className={Style.mobilePreview}>
      <ScrollArea style={{ height: "100%" }} offsetScrollbars>
        <div style={{ width: "380px" }}>
          <div
            className={Style.mobileInnerWrapper}
            style={{ backgroundColor: theme.backgroundColor }}
          >
            <BlockCompilerToHTML />
          </div>
        </div>
      </ScrollArea>
    </div>
  );
}

export { LandingPagePreview, MobileLandingPagePreview };
