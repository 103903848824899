import React, { useState, useEffect } from "react";
import Style from "./weGotYouCovered.module.scss";

import export_to_anything from "./img/export_to_anything.svg";
import first_impressions from "./img/first_impressions.svg";
import job_template from "./img/job_template.svg";
import your_data_is_safe from "./img/your_data_is_safe.svg";

export function WeGotYouCovered(props) {
  const contentEdito = {
    export_to_anything: {
      title: "Export to anything",
      mainText:
        "Ready to share your awesome job posting? Export as HTML, save a PDF, get direct link to your job and more!",
      image: export_to_anything,
    },
    first_impressions: {
      title: "First impression count",
      mainText:
        "Use our job posting builder and its advanced creation tools to create a professional storytelling that engages talents.",
      image: first_impressions,
    },
    job_template: {
      title: "Proven job postings templates",
      mainText:
        "Win over employers and recruiters by using one of our professionally-designed job postings templates.",
      image: job_template,
    },
    your_data_is_safe: {
      title: "Your data is safe",
      mainText:
        "Your data is kept private and protected by strong encryption. Never lose a job posting - they are stored as long as you want them to be.",
      image: your_data_is_safe,
    },
  };

  return (
    <div className={Style.weGotYouCovered}>
      <h2>We’ve got you covered</h2>
      <p className={Style.sectionSubstitle}>
        Jobchef has everything you need to enjoy your job postings publishing
        journey
      </p>
      <div className={Style.cardsWrapper}>
        {Object.keys(contentEdito).map((elem, index) => (
          <div className={Style.card} key={"weGotYouCovered_" + index}>
            <img
              src={contentEdito[elem].image}
              alt="icon representating the card context"
            ></img>
            <h3>{contentEdito[elem].title}</h3>
            <p>{contentEdito[elem].mainText}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
