import React, { Fragment } from 'react';
import { useAPI, Link,Card, AvatarJC } from 'components/lib';

export function Job(props){

  //const [state, setState] = useState({});
  const data = useAPI('/api/job');
  const url = window.location.href;
  const job_id = url.substring(url.indexOf('/job/')+5,url.indexOf('/job/')+41);

  

  if (!data)
  return(
      <Fragment>
        <h1>New job id</h1>

      </Fragment>

  );

  return(
    <Fragment>
      <Card>
        <h1>Job select page</h1>
        <br /> 
        <p>You are currently viewing the job page {job_id}</p>
        <Link 
          url='/joblist' 
          title='Back to jobs' 
          text='Back to jobs'
          btn='true' />
        <Link 
          url={'/jobeditor/'+ job_id}  
          title='edit jobs' 
          text='edit job'
          btn='true' />
          <br/>
          <br/>
          <br/>
      </Card>
    </Fragment>

  );
}
