import React, { useState, useEffect } from "react";
import { History } from "components/lib";
import Style from "./priceCard.module.scss";
import FirstUserImage from "./img/first-user-is-free.svg";
import icon_collaborate from "./img/icon_collaborate.svg";
import icon_edit_pen from "./img/icon_edit_pen.svg";
import icon_image from "./img/icon_image.svg";
import icon_snippets from "./img/icon_snippets.svg";
import icon_theme from "./img/icon_theme.svg";
import icon_unlimited from "./img/icon_unlimited.svg";
import { ReactComponent as CADFlag } from "./img/flags/CAD.svg";
import { ReactComponent as EURFlag } from "./img/flags/EUR.svg";
import { ReactComponent as GBPFlag } from "./img/flags/GBP.svg";
import { ReactComponent as USDFlag } from "./img/flags/USD.svg";
import { ReminderCTA } from "../homepageEditorialComponents/reminderCTA/reminderCTA.js";
import { Select, Group, SegmentedControl } from "@mantine/core";
import { currencyChoiceHelper } from "../../lib/locationHelper";

export function PriceCard(props) {
  const [planPeriod, setPlanPeriod] = useState("monthly");
  const [currencyChoice, setcurrencyChoice] = useState("cad");

  useEffect(() => {
    currencyChoiceHelper(null, setcurrencyChoice);
  }, []);
  
  const FlagIcon = (props) => {
    switch (props.flag) {
      case "eur":
        return <EURFlag height={"12px"} />;
      case "usd":
        return <USDFlag height={"12px"} />;
      case "gbp":
        return <GBPFlag height={"12px"} />;
      case "cad":
        return <CADFlag height={"12px"} />;
      default:
        break;
    }
  };

  const PriceLabel = (props) => {
    switch (props.currency) {
      case "cad":
        return (
          <p className={Style.priceLabel}>
            $29.90<span>CAD</span>
          </p>
        );
      case "usd":
        return (
          <p className={Style.priceLabel}>
            $24.90<span>USD</span>
          </p>
        );
      case "eur":
        return (
          <p className={Style.priceLabel}>
            €24.90<span>EUR</span>
          </p>
        );
      case "gbp":
        return (
          <p className={Style.priceLabel}>
            £21.90<span>GBP</span>
          </p>
        );

      default:
        return (
          <p className={Style.priceLabel}>
            $29.90<span>CAD</span>
          </p>
        );
    }
  };
  const SeatPriceLabel = (props) => {
    switch (props.currency) {
      case "cad":
        return (
          <p className={Style.priceLabel}>
            $8.90<span>CAD</span>
          </p>
        );
      case "usd":
        return (
          <p className={Style.priceLabel}>
            $6.90<span>USD</span>
          </p>
        );
      case "eur":
        return (
          <p className={Style.priceLabel}>
            €6.90<span>EUR</span>
          </p>
        );
      case "gbp":
        return (
          <p className={Style.priceLabel}>
            £5.90<span>GBP</span>
          </p>
        );

      default:
        return (
          <p className={Style.priceLabel}>
            $29.90<span>/month</span>
          </p>
        );
    }
  };

  const SelectItem = React.forwardRef(({ value, label, ...others }, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <FlagIcon
          flag={value}
          style={{ width: 18, height: 12, marginRight: 8 }}
        />
        <div>{label}</div>
      </Group>
    </div>
  ));

  return (
    <div className={Style.priceCard}>
      <h1>One plan, full access.</h1>
      <p style={{textAlign:"center"}}><strong style={{backgroundColor:"#fced74", padding:"4px 2px", marginRight:"2px"}}> Try free for 30 days.</strong>Easy start, hassle-free cancellation. Credit card required.</p>

      <div className={Style.priceCardWrapper}>
        <div className={Style.priceCardInner}>
          <div className={Style.mainCardContent}>
            <h3>Standard</h3>
            <p>
              Full access the Jobchef app and all it’s feature. Including
              collaboration links, job templates and more.
            </p>
            <button onClick={() => History.push("/signup")}>Get started</button>
          </div>
          <div className={Style.priceCard1}>
            <PriceLabel currency={currencyChoice} />
            <p className={Style.subText}>per month</p>
          </div>
          <div className={Style.plusSign}>
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.87014 20.3314V0.100586H11.6027V20.3314H8.87014ZM0.113037 11.5743V8.85769H20.3598V11.5743H0.113037Z"
                fill="#313131"
              />
            </svg>
          </div>
          <div className={Style.priceCard2}>
            <span>(Optional)</span>
            <SeatPriceLabel currency={currencyChoice} />
            <p className={Style.subText}>per additional users</p>
            <img
              className={Style.firstUserFree}
              src={FirstUserImage}
              alt="First user if free"
            />
          </div>
        </div>
      </div>
      <div className={Style.planOptions}>
        {/* <SegmentedControl
          value={planPeriod}
          onChange={setPlanPeriod}
          data={[
            { label: "Monthly", value: "monthly" },
            { label: "Yearly (Save 15%!)", value: "yearly" },
          ]}
          classNames={{
            root: Style.segmentedControlStyle,
            label: Style.segmentedControlLabelStyle,
          }}
        />  */}
        <Select
          value={currencyChoice}
          onChange={(value) => {
            console.log(value);
            currencyChoiceHelper(value, setcurrencyChoice);
          }}
          placeholder="Select currency"
          itemComponent={SelectItem}
          label="Select your currency"
          icon={<FlagIcon size={12} flagname="cad" flag={currencyChoice} />}
          data={[
            { value: "cad", label: "CAD" },
            { value: "usd", label: "USD" },
            { value: "gbp", label: "GBP" },
            { value: "eur", label: "EUR" },
          ]}
          classNames={{
            item: Style.selectStyledropdownItems,
            selected: Style.selectStyleSelected,
            input: Style.selectStyleRoot,
            dropdown: Style.selectStyleDropdown,
          }}
        />
      </div>
      <h2>What’s included</h2>
      <div className={Style.whatsIncludedContent}>
        {Object.keys(cards_included).map((key, index) => {
          return (
            <div className={Style.includeItem}>
              <img
                className={Style.includeCardImage}
                src={cards_included[key].image}
                alt="First user if free"
              />
              <p className={Style.includeCardTitle}>
                {cards_included[key].title}
              </p>
              <p className={Style.includeCardDescription}>
                {cards_included[key].description}
              </p>
            </div>
          );
        })}
      </div>
      <div style={{ width: "100%" }}>
        <ReminderCTA />
      </div>
    </div>
  );
}

const cards_included = {
  icon_unlimited: {
    title: "Unlimited Jobs",
    description: "Create and share as many jobs as you’d need",
    image: icon_unlimited,
  },
  icon_collaborate: {
    title: "Share collaboration links",
    description:
      "Easily get help from a colleague on a job posting creation by sharing a link",
    image: icon_collaborate,
  },
  icon_theme: {
    title: "Apply and modify themes",
    description:
      "Create a job posting with your personal touch by using one of our themes or designing your own",
    image: icon_theme,
  },
  icon_snippets: {
    title: "Copy text snippets",
    description: "Speed up editing your job posting by copying text snippets",
    image: icon_snippets,
  },
  icon_image: {
    title: "Add photos to your ads",
    description:
      "Easily upload or search for photos to help illustrate your job offer",
    image: icon_image,
  },
  icon_edit_pen: {
    title: "Proven job postings templates",
    description:
      "Win over employers and recruiters by using one of our professionally-designed job posting templates",
    image: icon_edit_pen,
  },
};

// export function PriceCard(props) {
//   return (
//     <div className={Style.priceCard}>
//       <h1>One plan, full access.</h1>
//       <div className={Style.priceCardWrapper}>
//         <div className={Style.priceCardInner}>
//           <div className={Style.mainCardContent}>
//             <h3>Standard</h3>
//             <p>
//               Access the Jobchef app and all it’s feature. Including
//               collaboration links, job templates and more.
//             </p>
//             <button onClick={() => History.push("/signup")}>Get started</button>
//           </div>
//           <div className={Style.priceCard1}>
//             <p className={Style.priceLabel}>
//               $99.90<span>/month</span>
//             </p>
//             <p className={Style.subText}>Full access to jobchef</p>
//           </div>
//           <div className={Style.plusSign}>
//             <svg
//               width="21"
//               height="21"
//               viewBox="0 0 21 21"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 d="M8.87014 20.3314V0.100586H11.6027V20.3314H8.87014ZM0.113037 11.5743V8.85769H20.3598V11.5743H0.113037Z"
//                 fill="#313131"
//               />
//             </svg>
//           </div>
//           <div className={Style.priceCard2}>
//             <p className={Style.priceLabel}>
//               $12.90<span>/month</span>
//             </p>
//             <p className={Style.subText}>per additional users</p>
//             <img
//               className={Style.firstUserFree}
//               src={FirstUserImage}
//               alt="First user if free"
//             />
//           </div>
//         </div>
//       </div>
//       <h2 className={Style.subtitle}>What’s included</h2>
//       <div className={Style.whatsIncludedContent}>
//         {Object.keys(cards_included).map((key, index) => {
//           return (
//             <div className={Style.includeItem}>
//               <img
//                 className={Style.includeCardImage}
//                 src={cards_included[key].image}
//                 alt="First user if free"
//               />
//               <p className={Style.includeCardTitle}>
//                 {cards_included[key].title}
//               </p>
//               <p className={Style.includeCardDescription}>
//                 {cards_included[key].description}
//               </p>
//             </div>
//           );
//         })}
//       </div>
//       <div style={{ width: "100%" }}>
//         <ReminderCTA />
//       </div>
//     </div>
//   );
// }
