import React, { useState, useRef, useContext, useEffect } from "react";
import Style from "./pdfUpdateIndicator.module.scss";
import { Icon, EditorContext } from "components/lib";
import _ from "lodash";
import { ReactComponent as MissingImage } from "./img/missing_image.svg"; 


export default function PdfUpdateIndicator(props) {
  const theme = useContext(EditorContext).theme;

  const [updateState, setUpdateState] = useState("pdfIsUpdated");
  const [latestThemeValue, setLatestThemeValue] = useState(_.cloneDeep(theme));

  const buttonRef = useRef();

  const hasCroppedImageOrCovertypeIsnoBanner =
    props.currentCoverPageStyle === "noBanner" ||
    (theme.coverImage[props.currentCoverPageStyle] !== undefined &&
      theme.coverImage[props.currentCoverPageStyle]["cropped_file"] !==
        undefined);

  const updatePDF = (newState) => {
    if (!hasCroppedImageOrCovertypeIsnoBanner) {
      return "An image is needed for this cover type";
    }
    setUpdateState(newState);
    showText();
    props.generatePDFcallBack(callbackPDFGenerator);
  };

  useEffect(() => {
    updatePDF("firstPDFgenerated");
  }, []);

  useEffect(() => {
    let timeoutId = null;

    function handleUpdatePDF() {
      if (
        !_.isEqual(latestThemeValue, theme) &&
        hasCroppedImageOrCovertypeIsnoBanner
      ) {
        // console.log("A change triggered a PDF update");
        updatePDF("pdfIsUpdating");
      }
    }

    function handleChange() {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(handleUpdatePDF, 1600); // 1000ms = 1 second
    }

    handleChange();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [theme, props.currentCoverPageStyle]);

  let infoText = "pdfIsUpdated";
  let infoIcon = "check-circle";

  if (updateState === "firstPDFgenerated") {
    infoText = "Creating PDF...";
    infoIcon = "clock";
  }

  if (updateState === "pdfIsUpdated") {
    infoText = "PDF is up to date";
    infoIcon = "check-circle";
  }

  if (updateState === "pdfIsUpdating") {
    infoText = "PDF is updating...";
    infoIcon = "clock";
  }

  if (updateState === "errorGeneratingPDF") {
    infoText = "Please refresh...";
    infoIcon = "alert-triangle";
  }

  if (!hasCroppedImageOrCovertypeIsnoBanner) {
    infoText = "Needs an image...";
    infoIcon = "needImage";
  }

  const callbackPDFGenerator = (response) => {
    setUpdateState(response);
    if (buttonRef.current) {
      buttonRef.current.classList.remove(Style.showText);
    }
  };

  const showText = () => {
    buttonRef.current.classList.add(Style.showText);
  };

  return (
    <div className={Style.pdfUpdateIndicator}>
      <button
        buttonStyle={"primary"}
        onClick={() => {
          if (
            updateState !== "pdfIsUpdating" &&
            hasCroppedImageOrCovertypeIsnoBanner
          ) {
            if (hasCroppedImageOrCovertypeIsnoBanner) {
              updatePDF("pdfIsUpdating");
              return false;
            }
          }
        }}
        className={Style.enlargeButton}
        ref={buttonRef}
      >
        <span className={Style.text}>{infoText}</span>
        {hasCroppedImageOrCovertypeIsnoBanner && (
          <Icon
            className={Style.icon}
            image={infoIcon}
            color={"white"}
            size={"18px"}
          />
        )}
        {!hasCroppedImageOrCovertypeIsnoBanner && (
          <MissingImage
            className={Style.iconMissingImage}
            image={infoIcon}
            color={"white"}
            size={"18px"}
          />
        )}
      </button>
    </div>
  );
}
