import React, {useContext} from 'react';
import { AppNav, AuthContext } from 'components/lib';
import Style from './editorLayoutNoNav.module.scss';
import '../layout.scss'; // globals

export function EditorLayoutNoNav(props){

    return (
      <div className={ Style.editorLayoutNoNav }>
        { <props.children {...props.data }/> }
     </div>
    );
};

