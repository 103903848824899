/***
 *
 *   ONBOARDING
 *   Example of onboarding flow*
 *
 **********/

import React, { Fragment, useContext, useState } from "react";
import {
  AuthContext,
  Onboarding,
  Form,
  Message,
  Image,
  useAPI,
  Icon,
} from "components/lib";
import cooking_chef from "./images/undraw_cooking.png";
import _ from "lodash";

export function OnboardingView(props) {
  const hash = window.location.hash;
  const [activeView, setActiveView] = useState(
    hash ? hash.substring(1) - 1 : 0
  );

  const [invited, setInvited] = useState([]);

  const context = useContext(AuthContext);
  const views = [
    {
      name: "Getting Started",
      description: `Welcome, ${context.user.name}!`,
      component: (
        <Welcome setActiveView={setActiveView} activeView={activeView} context={context}/>
      ),
    },
  ];

  if (context.permission.admin) {
    views.push({
      name: "Invite your team",
      description: (
        <p>
          Jobchef is more fun when you invite your team. Keep in mind that seats
          will be charged based{" "}
          <a
            href="/pricing"
            style={{
              fontSize: "1em",
              float: "none",
              cursor: "pointer",
              textDecoration: "none",
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            on your plan
          </a>
          .
        </p>
      ),
      component: <InviteUsers invited={invited} setInvited={setInvited} />,
    });
  }

  if (context.permission.admin) {
    views.push({
      name: "Organisation",
      description: `We need some little details on your company/organisation.`,
      component: (
        <FillCompanySettings
          setActiveView={setActiveView}
          activeView={activeView}
        />
      ),
    });
  }

  if (context.user.duplicate_user) {
    views.unshift({
      name: "Important!",
      description: "",
      component: <DuplicateUser />,
    });
  }

  return (
    <Onboarding
      save
      onFinish="/dashboard"
      views={views}
      activeView={activeView}
      setActiveView={setActiveView}
    />
  );
}

function DuplicateUser() {
  return (
    <Message
      type="warning"
      title={`You already have an account`}
      text="We noticed you have already registered an account, so we used your original password to save you any confusion in the future."
    />
  );
}

function Welcome(props) {
  return (
    <>
      {props.context.permission.admin && (
        <p>
          We are happy you are joining jobchef. You will soon be able to create
          your first job posting. Let's first get some of the basics in place.
        </p>
      )}
      {!props.context.permission.admin && (
        <p>
          We are happy you are joining jobchef. You will soon be able to create
          your first job posting.
        </p>
      )}
      <p>
        Press{" "}
        <span
          style={{ cursor: "pointer" }}
          onClick={() => props.setActiveView(props.activeView + 1)}
        >
          <strong>finish</strong>
        </span>{" "}
        to access your dashboard!
      </p>

      <Image source={cooking_chef} />
    </>
  );
}

function FillCompanySettings(props) {
  const company = useAPI("/api/company");

  // console.log(company);

  return (
    <>
      {!company.loading && (
        <Form
          data={{
            company_name: {
              label: "Organisation name",
              type: "text",
              required: true,
              value: company.data?.company_name
                ? company.data?.company_name
                : "",
              errorMessage: "Please enter organisation name",
              placeholder: "ex: apple, google, etc.",
            },
            company_url: {
              label: "Organisation Website URL",
              type: "url",
              required: true,
              value: company.data?.company_url ? company.data?.company_url : "",
              errorMessage: "Please enter organisation name",
              placeholder: "apple.com, google.com, etc.",
            },
          }}
          buttonText="Save"
          url="/api/company"
          method="PATCH"
          callback={() => props.setActiveView(props.activeView + 1)}
        />
      )}
    </>
  );
}

function InviteUsers(props) {
  const appendNewInvite = (res) => {
    const newInvitee = res.data.data.email;
    const inviteeList = _.cloneDeep(props.invited);
    inviteeList.push(newInvitee);
    // console.log(inviteeList);
    props.setInvited(inviteeList);
  };

  return (
    <>
      <Form
        data={{
          email: {
            label: "Email",
            type: "email",
            required: true,
          },
        }}
        buttonText="Send Invite"
        url="/api/invite"
        method="POST"
        callback={(res) => appendNewInvite(res)}
      />
      {props.invited.length !== 0 && (
        <>
          <h4 style={{ marginTop: "12px" }}>Invited :</h4>
          <ul>
            {props.invited.map((email, i) => (
              <li style={{ fontSize: "0.875em", marginBottom: "4px" }} key={i}>
                <Icon image="check-circle" size="0.875em" /> {email}
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  );
}
