import { EditorContext, MyButton, ViewContext } from "components/lib";
import {
  useCallback,
  useContext,
  useRef,
  useState
} from "react";
import { Scrollbars } from "react-custom-scrollbars";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  image_crop_import_helper_block_image,
  image_crop_import_helper_cover_image,
} from "../../lib/imageUploadHelper.js";
import Style from "./imageCropperWrapper.module.scss";
import { ReactComponent as Rectangle1By1 } from "./images/1-1.svg";
import { ReactComponent as Rectangle16By4 } from "./images/16-4.svg";
import { ReactComponent as Rectangle16By9 } from "./images/16-9.svg";

export function ImageCropperWrapper(props) {

  const [currentlyCropping, setCurrentlyCropping] = useState(false);

  const theme = useContext(EditorContext).theme;

  let api_url = "/api/imageCropperUploader"
  const jobId = localStorage.getItem('jobId');
  api_url = api_url + "?job_id=" + jobId


  //Allows to show banner on top for errors

  let imageInformation = props.imageLocation;
  const fixedAspect = props.fixedAspect;
  const imageFixedSize = props.imageFixedSize;
  const imageType = props.imageType;
  const setCropMode = props.setCropMode;
  const importHelperParameters = props.importHelperParameters;

  if (imageType !== "blockImage") {
    imageInformation = theme.coverImage[imageType];
  }

  const context = useContext(ViewContext);

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  let default_crop = { unit: "%", width: 90, height: 90, x: 5, y: 5 };

  if (imageInformation["crop_information"] !== null) {
    default_crop = imageInformation["crop_information"];
  }

  if (fixedAspect) {
    default_crop = fixedAspect;
  }

  const [crop, setCrop] = useState(default_crop);
  const [percentCrop, setPercentCrop] = useState(default_crop);
  const [completedCrop, setCompletedCrop] = useState(null);

  const setPreviewCrop = useContext(EditorContext).setPreviewCrop;

  const img_filename = imageInformation.file.filename;

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const getResizedCanvas = () => {
    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;

    // Create a temporary canvas for the cropped image
    const tempCanvas = document.createElement("canvas");

    tempCanvas.width = crop.width * scaleX;
    tempCanvas.height = crop.height * scaleY;
  
    if(tempCanvas.width === 0 || tempCanvas.height === 0 ) {
      return;
    }

    const tempCtx = tempCanvas.getContext("2d");
    tempCtx.drawImage(
      imgRef.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    // Create the final canvas with the desired size
    const canvas = document.createElement("canvas");

    // Calculate the new width and height maintaining the aspect ratio
    let newWidth = imageFixedSize
      ? imageFixedSize.fixedWidth
      : crop.width * scaleX;
    let newHeight = imageFixedSize
      ? imageFixedSize.fixedHeight
      : crop.height * scaleY;

    if (newWidth > 1200) {
      const aspectRatio = newHeight / newWidth;
      newWidth = 1200;
      newHeight = newWidth * aspectRatio;
    }

    canvas.width = newWidth;
    canvas.height = newHeight;

    const ctx = canvas.getContext("2d");

    // console.log(tempCanvas);

    // if (!tempCanvas) {
    //   return canvas;
    // }

    // try {
      ctx.drawImage(tempCanvas, 0, 0, canvas.width, canvas.height);
    // } catch (error) {
    //   console.log(error);
    // }

    return canvas;
  };

  const generatePreview = async () => {
    if (!completedCrop || !imgRef.current || !previewCanvasRef.current) {
      return;
    }

    const canvas = getResizedCanvas();
    if (!canvas) {
      return;
    }

    const ctx = previewCanvasRef.current.getContext("2d");
    ctx.clearRect(
      0,
      0,
      previewCanvasRef.current.width,
      previewCanvasRef.current.height
    );

    previewCanvasRef.current.width = canvas.width;
    previewCanvasRef.current.height = canvas.height;
    ctx.drawImage(canvas, 0, 0);
  };

  // Get info of user (to add in headers)
  const user = JSON.parse(localStorage.getItem("user"));
  const token = "Bearer " + (user ? user.token : "");

  function Upload_crop_file_to_server(previewCanvas, crop, img_filename) {
    if (!crop) {
      // console.log("no crop");
      return;
    }
    if (!previewCanvas) {
      // console.log("no previewCanvas");
      return;
    }

    const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);

    if (!canvas) {
      return;
    }

    // Might not need a previewCanvas here.^

    canvas.toBlob(
      (blob) => {
        var formData = new FormData();
        formData.append("image", blob, img_filename);

        // create a new XMLHttpRequest
        var xhr = new XMLHttpRequest();

        // get a callback when the server responds
        xhr.addEventListener("load", () => {
          // update the state of the component with the result here

          if (xhr.status === 200) {
            if (imageType === "blockImage") {
              image_crop_import_helper_block_image(
                xhr.response,
                percentCrop,
                setCropMode,
                ...importHelperParameters
              );
            }
            if (imageType !== "blockImage") {
              image_crop_import_helper_cover_image(
                xhr.response,
                percentCrop,
                setCropMode,
                ...importHelperParameters,
                props.imageType
              );
            }
            context.notification.show(
              "Success! Your image was cropped.",
              "success",
              true,
              "toast"
            );
          } else {
            context.notification.show(
              "Something didn't go as planned, please try again",
              "error",
              true,
              "toast"
            );
          }
        });
        // open the request with the verb and the url
        xhr.open("POST", api_url);
        xhr.setRequestHeader("Authorization", token);
        //xhr.setRequestHeader("Content-Type", "application/json");
        // send the request
        xhr.send(formData);
      },
      "image/jpeg",
      1
    );
  }

  return (
    <div
      className={Style.imageCropperWrapper}
      style={!fixedAspect ? { padding: "26px" } : {}}
    >
      {fixedAspect && (
        <div className={Style.infoTextLineBox}>
          <p className={Style.textLine}>
            The image selected needs to be cropped, choose your prefered crop
            and select <i>Apply crop</i>.{" "}
          </p>
        </div>
      )}
      <div className={Style.cropper_menu_main}>
        {!fixedAspect && (
          <MyButton
            className={Style.backBTN}
            onClick={() => {
              // console.log("setCropMode(false);");
              setCropMode(false);
              setPreviewCrop(null);
            }}
            icon={"arrow-left"}
          >
            Back
          </MyButton>
        )}
        <div
          className={Style.applyButton}
          style={
            fixedAspect
              ? { justifyContent: "center" }
              : { justifyContent: "right" }
          }
        >
          <MyButton
            size={"small"}
            buttonStyle={"primary"}
            loading={currentlyCropping}
            disabled={!completedCrop?.width || !completedCrop?.height}
            onClick={() => {
              setCurrentlyCropping(true)
              Upload_crop_file_to_server(
                previewCanvasRef.current,
                completedCrop,
                img_filename
              );
            }}
          >
            Apply crop
          </MyButton>
        </div>
      </div>
      {true && !fixedAspect && (
        <div className={Style.cropper_menu_secondary}>
          <button
            onClick={() => {
              setCrop({ unit: "%", width: 90 });
            }}
            className={`${crop?.aspect == null ? Style.selectedButton : ""}`}
          >
            Free
          </button>
          <button
            onClick={() => {
              setCrop({ unit: "%", width: 90, aspect: 1 / 1 });
            }}
            className={`${crop?.aspect === 1 ? Style.selectedButton : ""}`}
          >
            <Rectangle1By1 />1 / 1
          </button>
          <button
            onClick={() => {
              setCrop({ unit: "%", width: 90, aspect: 16 / 9 });
            }}
            className={`${crop?.aspect === 16 / 9 ? Style.selectedButton : ""}`}
          >
            <Rectangle16By9 />
            16 / 9
          </button>
          <button
            onClick={() => {
              setCrop({ unit: "%", width: 90, aspect: 16 / 4 });
            }}
            className={`${crop?.aspect === 16 / 4 ? Style.selectedButton : ""}`}
          >
            <Rectangle16By4 />
            16 / 4
          </button>
        </div>
      )}
      <Scrollbars
        renderView={(param) => (
          <div style={{ paddingRight: "15px", ...param.style }} />
        )}
      >
        <ReactCrop
          src={imageInformation.file.public_url}
          onImageLoaded={onLoad}
          crop={crop}
          onChange={async (crop, percentCrop) => {
            setCrop(crop);
            setPercentCrop(percentCrop);
            await generatePreview(); // add this line
          }}
          onComplete={(c) => {
            setCompletedCrop(c);
            setPreviewCrop(previewCanvasRef);
          }}
          circularCrop={false}
        />
      </Scrollbars>
    </div>
  );
}
